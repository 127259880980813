export function EmptyCard({
  header,
  desc = 'Looks like there is nothing here',
  buttonText,
}: {
  header?: string
  desc?: string
  buttonText?: string
}) {
  return (
    <div className="empty-cards-lms d-flex justify-content-center">
      <img className="mb-4 w-100" src={`/images/LMS-empty.svg`} alt="" />
      {header && <p className="font_16 font-bold-500">{header}</p>}
      {desc && (
        <p className="font_14 font-bold-500 font-color-gray300">{desc}</p>
      )}
      {buttonText && (
        <button className="empty-cards-lms__button">{buttonText}</button>
      )}
    </div>
  )
}
