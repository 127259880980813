import { PaginatedResponse, Response } from 'core/types/Response'
import { Admin } from 'core/types/User/Admin'
import { AdminAPI } from 'services/API'
import {
  CommonParams,
  extractResponseData,
  fetchAllPages,
  pgQueryString,
} from 'services/utils'

export type GetAdminParams = CommonParams & {
  is_team_lead?: boolean
  is_retention_owner?: boolean
  is_sales_owner?: boolean
  is_ops_supervisor?: boolean
  is_edu_mentor?: boolean
  is_ops_moderator?: boolean
  is_sales_agent?: boolean
  is_hr_agent?: boolean
  is_boarding_member?: boolean
  is_operation_agent?: boolean
  is_operation_lead?: boolean
}
export type FilterParams = { role_id?: number }

export function listAdmins(params: FilterParams & GetAdminParams) {
  return AdminAPI.get<PaginatedResponse<Admin>>(
    `/admins${pgQueryString(params)}`,
  ).then((r) => r.data)
}

export function getAdminById(id: number, minimal?: boolean) {
  return AdminAPI.get<Response<Admin>>(
    `admins/${id}${minimal ? '?minimal=true' : ''}`,
  ).then(extractResponseData)
}

export async function getAllAdmins() {
  return fetchAllPages({
    fetchFn: ({ page, per_page }) =>
      AdminAPI.get<PaginatedResponse<Admin>>(
        `/admins${pgQueryString({ page, per_page })}`,
      ).then((r) => r.data),

    mapFn: (admin) => ({
      ...admin,
    }),
  })
}

//////
export async function getTeamLeaders() {
  return fetchAllPages({
    fetchFn: ({ page, per_page }) =>
      AdminAPI.get<PaginatedResponse<Admin>>(
        `/admins${pgQueryString({ is_team_lead: true, page, per_page })}`,
      ).then((r) => r.data),

    mapFn: (admin) => ({
      ...admin,
      text: admin.name,
      value: admin.id,
    }),
  })
}

export function createAdmin(adminData: any) {
  return AdminAPI.post<Response<Admin>>('/admins', adminData)
}

export function updateAdmin(adminId: number, adminData: any) {
  return AdminAPI.put<Response<Admin>>(`/admins/${adminId}`, adminData)
}
