import { useAuth } from 'auth/useAuth'
import { oneSecond } from 'components/Cards/ClassesCards/NextClass/useTime'
import Logo from 'components/Logo/Logo'
import {
  disableBodyScroll,
  enableBodyScroll,
} from 'components/Modal/hooks/useModalApi'
import { useDebouncedState } from 'core/hooks/useDebouncedState'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import { useOrganization } from './Providers/OrganizationProvider'

const SpinnerOverlayContext = createContext<{
  showSpinner: () => void
  hideSpinner: () => void
  spinnerEnabled: boolean
}>({} as any)

function FullScreenSpinner() {
  return (
    <div
      style={{
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        top: '0',
        background: 'white',
      }}
    >
      <Logo secondaryLogoBigger />
    </div>
  )
}

export const useOverlaySpinner = () => useContext(SpinnerOverlayContext)

export default function SpinnerOverlayProvider({
  children,
}: {
  children: ReactNode
}) {
  const [spinnerEnabled, setShowSpinner] = useDebouncedState(
    true,
    undefined,
    oneSecond,
  )

  const showSpinner = useCallback(() => {
    document.body.scrollTop = 0
    const overlay = document.body.querySelector(
      '#ischool-overlay',
    ) as HTMLDivElement
    overlay.style.display = 'flex'
    disableBodyScroll()
    setShowSpinner((_) => true)
  }, [setShowSpinner])

  const hideSpinner = useCallback(() => {
    const overlay = document.body.querySelector(
      '#ischool-overlay',
    ) as HTMLDivElement
    overlay.style.display = 'none'
    enableBodyScroll()
    setShowSpinner((_) => false)
  }, [setShowSpinner])

  return (
    <SpinnerOverlayContext.Provider
      value={{ spinnerEnabled, showSpinner, hideSpinner }}
    >
      {children}
    </SpinnerOverlayContext.Provider>
  )
}
