import { useQueryClient } from '@tanstack/react-query'
import { useLoggedIn } from 'auth/useAuth'
import { differenceInMinutes } from 'date-fns'
import { useEffect, useState } from 'react'
import { QueryKeys } from 'services/QueryKeys'
import { useOrganization } from 'utils/Providers/OrganizationProvider'
import { useServerCountdown } from './useCountdown'
import { oneHour, oneMinute } from './useTime'

const isEnded = (remainingTime: number) =>
  remainingTime < -(oneHour + 2 * oneMinute)

function calculate(event: {
  start_at: string
  current_time?: string
  end_at?: string
}) {
  let { start_at, current_time } = event
  let eventStartTime: number
  let eventEndTime: number
  eventStartTime = new Date(start_at || '').getTime()
  eventEndTime = !!event.end_at
    ? new Date(event.end_at).getTime()
    : eventStartTime + oneHour

  const serverLocalMachineTimeDiff = Math.abs(
    differenceInMinutes(new Date(), new Date(current_time || '')),
  )

  if (serverLocalMachineTimeDiff <= 1 || current_time === undefined) {
    return {
      counterStartTime: new Date().getTime(),
      eventStartTime,
      eventEndTime,
    }
  } else {
    return {
      counterStartTime: new Date(current_time || '').getTime() - oneMinute, // add some error margin
      eventStartTime,
      eventEndTime,
    }
  }
}
export function useNextSessisoServerCountdown(event: {
  start_at: string
  end_at?: string
  current_time?: string
}) {
  const { loadProfile, loggedInUser } = useLoggedIn()
  const { org } = useOrganization()

  const [{ counterStartTime, eventStartTime, eventEndTime }, setState] =
    useState(() => calculate(event))

  useEffect(() => {
    setState(calculate(event))
  }, [event.start_at, event.current_time])

  const classStartCountdown = useServerCountdown(
    eventStartTime,
    counterStartTime,
  )
  const qc = useQueryClient()
  let isEnded = classStartCountdown.now >= eventEndTime

  let enableJoin =
    classStartCountdown.days == 0 &&
    classStartCountdown.hours === 0 &&
    classStartCountdown.minutes <= (org?.is_whitelabel ? 0 : 5) &&
    !isEnded

  useEffect(() => {
    if (isEnded) {
      qc.invalidateQueries([QueryKeys.sessions])
      qc.invalidateQueries([QueryKeys.sessionsList])
      qc.invalidateQueries([QueryKeys.upcomingSession])
      qc.invalidateQueries([QueryKeys.upcomingOrLastSession])
      loadProfile()
    }
  }, [isEnded])

  return {
    ...classStartCountdown,
    enableJoin,
    isEnded,
  }
}
