import { useQuery } from '@tanstack/react-query'
import { memo, useEffect, useRef } from 'react'
import { getVdoCypherIframeSrc, getVdoCypherOTP } from 'services/vdcypher'

type useLesonOTPQueryProps = {
  videoId?: string
}
function useVdoCypherOTPQuery({ videoId }: useLesonOTPQueryProps) {
  return useQuery({
    queryFn: () => getVdoCypherOTP(videoId!),
    queryKey: ['VdoCypher_otp', videoId],
    keepPreviousData: false,
    enabled: !!videoId,
  })
}

type VdoCypherIframeProps = {
  videoId?: string
  disabled?: boolean
  onPlay?: (player?: any) => void
  onFirstPlay?: () => void
  onVideoEnded?: () => void
  width?: string
  height?: string
  minHeight?: string
  maxHeight?: string
}

type VdoPlayer = {
  video: HTMLVideoElement
  api: any // https://www.vdocipher.com/docs/player/v2/api-reference/vdocipher-custom-api
}
function getVdoPlayer(iframe: HTMLIFrameElement | null): VdoPlayer | null {
  if (!iframe) return null
  // @ts-ignore -- VdoPlayer object loaded in index.html and was attached to window
  return window?.['VdoPlayer']?.getInstance(iframe)
}

export const VdoCypherIframe = memo(
  function VdoCypherIframeComponent({
    disabled,
    videoId,
    onPlay,
    onVideoEnded,
    width = '100%',
    height = '100%',
    minHeight = '50vh',
    maxHeight = '50vh',
  }: VdoCypherIframeProps) {
    const { data } = useVdoCypherOTPQuery({ videoId })

    const src = getVdoCypherIframeSrc(data)
    const ref = useRef<HTMLIFrameElement>(null)

    const handlePlay = (player: any) => {
      if (onPlay) {
        onPlay(player)
      }
    }

    const onStatusChange = (event: { code: number; label: string }) => {
      if (onVideoEnded && event.label === 'ENDED') {
        onVideoEnded()
      }
    }

    useEffect(() => {
      const player = getVdoPlayer(ref.current)
      if (!player) return

      const play = handlePlay.bind(null, player)

      if (onPlay) {
        player?.video.addEventListener('play', play)
      }

      player.api.addEventListener('statusChange', onStatusChange)
      return () => {
        player?.video.removeEventListener('play', play)
      }
    }, [ref.current])

    return (
      <div
        className=" w-100 h-100 d-flex justify-content-center align-items-center bg-black position-relative rounded-base"
        style={{
          minHeight,
          maxHeight,
          width,
          height,
        }}
      >
        {(disabled || !videoId) && (
          <div
            className="border-0 bg-color-black rounded  d-flex align-items-center justify-content-center "
            style={{
              minHeight,
              maxHeight,
              width,
              height,
            }}
          >
            <i className="fa fa-play font_30 font-color-lighter-blue" />
          </div>
        )}
        {!disabled && videoId && (
          <iframe
            ref={ref}
            src={src}
            className="border-0 bg-color-primary300 rounded "
            style={{
              minHeight,
              maxHeight,
              width,
              height,
            }}
            allow="encrypted-media"
            allowFullScreen
          />
        )}
      </div>
    )
  },
  (a, b) => a.videoId == b.videoId,
)
