import Button from 'components/Button/Button'
import Dropdown from 'components/Dropdown/Dropdown'
import { useLanguageProvider } from 'utils/LanguageProvider'

export default function LanguageSwitcher() {
  const { language, setLanguage } = useLanguageProvider()

  return (
    <Dropdown
      label={
        <Button
          isBorderButton
          className=" border-0 px-2 py-1 font_16 font-normal align-items-center  d-flex gap-2 "
        >
          <div className="font-color-grey">
            <i className="fa fa-globe mx-2" />
          </div>
        </Button>
      }
    >
      <li className={`${language === 'ar' ? 'bg-color-primary100' : ''}`}>
        <Button
          className={`dropdown-item text-start d-flex align-items-center gap-2 justify-content-start font_14 `}
          onClick={() => language !== 'ar' && setLanguage('ar', true)}
          isButtonLink
          variant="blue700"
        >
          <span>العربية</span>
        </Button>
      </li>
      <li className={`${language === 'en' ? 'bg-color-primary100' : ''}`}>
        <Button
          className={`dropdown-item text-start d-flex align-items-center gap-2 justify-content-start font_14 `}
          onClick={() => language !== 'en' && setLanguage('en', true)}
          isButtonLink
          variant="blue700"
        >
          <span>English</span>
        </Button>
      </li>
    </Dropdown>
  )
}
