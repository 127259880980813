import { YPagination } from 'components/Pagination/XPagination'
import { ResourceItem } from 'utils/usePaginatedResource'
import { QueryParams, QueryResource } from '../useResource'

export default function QueryTablePagination<
  P extends QueryParams,
  T extends ResourceItem,
>({
  query,
  skipIfOnePage,
}: {
  query: QueryResource<P, T>
  skipIfOnePage?: boolean
}) {
  if (!query.data?.pagination) {
    return null
  }

  if (query.data.pagination.pages <= 1 && skipIfOnePage) {
    return null
  }
  return (
    <YPagination
      minimal
      pagination={{
        count: query.data?.pagination.count || 0,
        next: query.data?.pagination.next || null,
        previous: query.data?.pagination.previous || null,
        pages: query.data?.pagination.pages || 0,
        per_page: query.data?.pagination.per_page || 0,
        current:
          (query.isPreviousData
            ? query.filters?.params.page
            : query.data?.pagination?.current) || 0,
      }}
      onPageChange={(page) => query.filters?.update({ page } as Partial<P>)}
    />
  )
}
