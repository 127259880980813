import { CustomFilter } from 'components/Query/filter'
import {
  GradeCurriculumSelect,
  GradeSelect,
} from 'components/Selectors/curriculum/GradeSelect'
import { defaultParamNames } from './CurriculumSelectFilter'

export const GradeSelectFilter = (
  p = defaultParamNames,
): CustomFilter<number | undefined> => ({
  type: 'custom',
  element: (ctx) => (
    <GradeSelect
      {...ctx}
      curriculumId={ctx.filters?.params?.[p.curriculum]}
      value={ctx.filters?.params[p.grade]}
      onChange={(g) => {
        ctx.filters?.update({
          [p.grade]: g,
          [p.lesson]: undefined,
          [p.level]: undefined,
        })
      }}
    />
  ),
})

export const GradeCurruiculumSelectFilter = (
  p = defaultParamNames,
): CustomFilter<number | undefined> => ({
  type: 'custom',
  element: (ctx) => (
    <GradeCurriculumSelect
      {...ctx}
      value={ctx.filters?.params[p.grade]}
      onChange={(g, c) => {
        ctx.filters?.update({
          [p.curriculum]: c,
          [p.grade]: g,
          [p.lesson]: undefined,
          [p.level]: undefined,
        })
      }}
    />
  ),
})
