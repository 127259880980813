import { MouseEvent, useEffect, useRef, useState } from 'react'
import { Options } from '..'

const DEFAULT_POPUP_OPTIONS: Options<any> = {
  isOpened: false,
  backdropShadow: true,
  disableBackdrop: false,
  disableParentScroll: true,
  data: undefined,
}

export const disableBodyScroll = () =>
  document.body.classList.add('overflow-hidden')
export const enableBodyScroll = () =>
  document.body.classList.remove('overflow-hidden')
const getOpenedModals = () => document.querySelectorAll('.modal-container.open')

export function useModalApi<T>(_options: Options<T> = {}) {
  const popupContainer = useRef<HTMLDivElement>(null)
  const options = { ...DEFAULT_POPUP_OPTIONS, ..._options }
  const [isOpened, setIsOpened] = useState<boolean>(false)
  const [data, setData] = useState<T | undefined>(() => options?.data)

  useEffect(() => {
    setData(options.data)
  }, [options.data])

  useEffect(() => {
    options.isOpened ? openModal(options.data) : closeModal()
  }, [options.isOpened, options.data])

  function openModal(data?: T) {
    if (options.disableParentScroll) disableBodyScroll()
    setData(() => data)
    setIsOpened(true)
    options.onOpenChange && options.onOpenChange(true)
  }

  function closeModal() {
    if (getOpenedModals().length === 1) enableBodyScroll()
    setIsOpened(false)
    options.onOpenChange && options.onOpenChange(false)
  }

  function backdropOrEscapeClose(e: MouseEvent<HTMLDivElement>): void {
    if (e.target !== popupContainer.current) return

    if (options?.disableBackdrop && popupContainer?.current) {
      const modal = popupContainer.current.firstChild as Element
      modal?.classList.add('shake')
      setTimeout(() => modal?.classList.remove('shake'), 500)
    } else {
      closeModal()
    }
  }

  function toggleModal(data?: T) {
    isOpened ? closeModal() : openModal(data)
  }

  return {
    ...options,
    popupContainer,
    backdropOrEscapeClose,
    data,
    isOpened,
    openModal,
    toggleModal,
    setData,
    closeModal,
    onOpenChange: setIsOpened,
  }
}
