import { useTranslation } from 'react-i18next'
import { appLanguage } from 'utils/helpers'
import { convertToArabicNumerals } from 'utils/numbers'
import { useServerCountdown } from './useCountdown'

export function formatNumber(number: number, zeroPadLength = 0) {
  const str = number.toString().padStart(zeroPadLength, '0')

  if (appLanguage() === 'ar') {
    return convertToArabicNumerals(str)
  }

  return str
}

type NumberCounterProps = {
  value: number
  labelClassName?: string
  label: string
}

export function NumberCounter({
  value,
  label,
  labelClassName = 'label',
}: NumberCounterProps) {
  const { t } = useTranslation(['common'])
  return (
    <div className="next-class__time__list__item">
      <div className="next-class__time__list__item__timer">
        {formatNumber(value, 2)}
      </div>
      <span className={`font-color-blue300 ${labelClassName}`}>{t(label)}</span>
    </div>
  )
}
export default function NextClassCountdown({
  days,
  hours,
  minutes,
}: ReturnType<typeof useServerCountdown>) {
  return (
    <div className="next-class__time">
      <div className="next-class__time__list gap-2 d-flex algin-items-center justify-content">
        <NumberCounter value={days} label="day" />
        <NumberCounter value={hours} label="hour" />
        <NumberCounter value={minutes} label="minute" />
      </div>
    </div>
  )
}
