import { useLoggedIn } from 'auth/useAuth'
import Tooltip from 'components/Tooltip/Tooltip'
import { useIsMobile } from 'components/useMediaQueries'
import { StudentPermission } from 'core/types/User/Admin'
import { ReactNode, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { isArabic } from 'utils/helpers'
import { SideBarContext } from './NavSidebar'

interface SideBarNavItemProps {
  to?: string
  text: ReactNode
  iconName?: string
  iconOnly?: boolean
  tooltip?: string
  icon?: ReactNode
  onClick?: () => void
  className?: string
  tabName?: string
  showIndicator?: boolean
}

export function SideBarNavItem({
  text,
  to,
  icon,
  className,
  onClick,
  tooltip,
  tabName,
  showIndicator,
}: SideBarNavItemProps) {
  const isMobile = useIsMobile()
  const { collapsed, setCollapsed } = useContext(SideBarContext)

  const { loggedInUser } = useLoggedIn()
  const placement = isArabic() ? 'left' : 'right'

  if (loggedInUser.isStudent && tabName) {
    const hasPermission =
      loggedInUser?.organization?.student_permissions?.includes(
        tabName as StudentPermission,
      )

    if (!hasPermission) {
      return null
    }
  }
  if (to)
    return (
      <Tooltip
        enabled={collapsed}
        content={tooltip || text}
        placement={placement}
      >
        <NavLink
          to={to}
          className={`item ${className}`}
          onClick={isMobile ? () => setCollapsed(true) : undefined}
        >
          <span className="icon">{icon}</span>
          <span className="label text-nowrap">{text}</span>
          {showIndicator && (
            <i className="fa fa-circle font_10 font-color-orange500 ml-auto" />
          )}
        </NavLink>
      </Tooltip>
    )

  return (
    <Tooltip
      enabled={collapsed}
      content={tooltip || text}
      placement={placement}
    >
      <button onClick={onClick} className={` item ${className}`}>
        <span className="icon">{icon}</span>
        <span className="label text-nowrap">{text}</span>
        {showIndicator && (
          <i className="fa fa-circle font_10 font-color-orange500 ml-auto" />
        )}
      </button>
    </Tooltip>
  )
}
