import { useQueryClient } from '@tanstack/react-query'
import { Role, User } from 'core/types/User'
import { Student, StudentState } from 'core/types/User/Student'
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { router } from 'routes'
import { adminOTPLogin, getMyAdminProfile } from 'services/admin/auth'
import {
  acceptStudentInvitation,
  getMyStudentProfile,
  studentLogin,
  studentSignup,
} from 'services/student/auth'
import { getMyTutorProfile, tutorLogin } from 'services/tutor/auth'

import Action from 'actioncable'
import { AxiosInstance } from 'axios'
import {
  AdminAPI,
  PublicAPI,
  StudentAPI,
  TutorAPI,
  getCableURL,
} from 'services/API'
import { formatLabel } from 'services/utils'
import { storeCurrency } from 'utils/LocalStorage'
import { isProduction } from 'utils/helpers'
import { AddUserRole } from './AddUserRole'
import { getLoginPage } from './RequireAuth'

export type Response<Data> = {
  status: 'success' | 'failure'
  message: string
  data: Data
}

function getLocalStorageRole() {
  return formatLabel(localStorage.getItem('role') || '') as Role | undefined
}

export function clearAuthStorage() {
  localStorage.removeItem('token')
  localStorage.removeItem('role')
  localStorage.removeItem('OrgId')
}

function setStorage(role: Role, token: string) {
  localStorage.setItem('token', token)
  localStorage.setItem('role', role)
}

export function useActionCable(enabled: boolean) {
  const ActionCable = useMemo(() => {
    if (enabled) {
      console.info('Created Action Cable')
      return Action.createConsumer(
        getCableURL(localStorage.getItem('token') || ''),
      )
    }
    return null
  }, [enabled])

  useEffect(() => {
    return () => {
      if (ActionCable) {
        console.info('Disconnecting Action Cable')
        ActionCable.disconnect()
      }
    }
  }, [ActionCable])

  return { ActionCable }
}

async function getUserProfile() {
  const role = getLocalStorageRole()
  const token = localStorage.getItem('token')

  let user: User | null = null

  if (token) {
    if (role === 'Student') {
      user = await getMyStudentProfile()
    }

    if (role === 'Tutor' || role === 'Mentor') {
      user = await getMyTutorProfile()
    }

    if (role === 'Admin') {
      user = await getMyAdminProfile()
    }
  }

  if (!user || !role) return null

  return { ...user } as User
}

async function userLogin(role: Role, email: string, password: string) {
  let user: User | null = null

  if (role === 'Student') {
    let fullUrl = window.location.host
    let websiteDomain = isProduction
      ? fullUrl.split('.')[0]
      : localStorage.getItem('dev_domain') || 'ischool'
    user = await studentLogin(email, password, websiteDomain)
  }

  if (role === 'Tutor') {
    user = await tutorLogin(email, password)
  }

  return user
}

async function userSignup(role: Role, signupData: any) {
  let user: Response<User> | null = null

  if (role === 'Student') {
    user = await studentSignup(signupData)
  }

  return user
}

async function userAcceptInvitation(
  role: Role,
  signupData: any,
  token: string,
) {
  let user: User | null = null

  if (role === 'Student') {
    user = AddUserRole(role, await acceptStudentInvitation(token, signupData))
  }

  return user
}

export interface AuthState<T = User | null | undefined> {
  isLoading: boolean
  isAuthenticated: boolean
  isGuest: boolean
  user: T
  setCurrentRole: Dispatch<SetStateAction<Role | undefined>>
  currentRole?: Role
  logout: () => void
  login: (role: Role, email: string, password: string) => Promise<User | null>
  signup: (role: Role, signupData: any) => Promise<Response<User> | null>
  acceptInvitation: (
    role: Role,
    signupData: any,
    token: string,
  ) => Promise<User | null>
  loadProfile: () => Promise<User | null>
  setStudentState: (state: StudentState) => void
  patchStudent(key: keyof Student, value: any): void
  API: AxiosInstance
  dashboard: string
  autoLogin: (role: Role, token: string) => Promise<User | null>
  ActionCable: T extends User ? ActionCable.Cable : null
  adminLogin: (p: { email: string; password?: string; otp?: string }) => any
}

export const AuthContext = createContext<AuthState>({} as any)

function useAuthState(): AuthState {
  useEffect(() => {
    window.addEventListener('logout', logout)
    return () => {
      window.removeEventListener('logout', logout)
    }
  }, [])

  const [currentRole, setCurrentRole] = useState<Role>()
  let [user, setUser] = useState<User | null | undefined>(undefined)
  const queryClient = useQueryClient()

  const { ActionCable } = useActionCable(!!user)
  async function adminLogin(credentials: {
    email: string
    password?: string
    otp?: string
  }) {
    return adminOTPLogin(credentials).then((response) => {
      if (credentials.password && !credentials.otp) {
        return 'pending_otp'
      }
      if (!credentials.password && credentials.otp) {
        user = response
        if (user) {
          setUser(user)
          setCurrentRole('Admin')
          setStorage('Admin', user.token)
        }

        storeCurrency()

        return user
      }
    })
  }

  async function login(role: Role, email: string, password: string) {
    const user = await userLogin(role, email, password)

    if (user) {
      setUser(user)
      setCurrentRole(user.role)
      setStorage(role, user.token)
    }

    storeCurrency()

    return user
  }

  async function autoLogin(role: Role, token: string) {
    setStorage(role, token)
    storeCurrency()
    return loadProfile()
  }

  async function acceptInvitation(role: Role, signupData: any, token: string) {
    const user = await userAcceptInvitation(role, signupData, token)

    if (user) {
      setUser(user)
      setStorage(role, user.token)
    }

    return user
  }

  async function signup(role: Role, signupData: any) {
    const response = await userSignup(role, signupData)

    if (response?.data) {
      setUser(response?.data)
      setStorage(role, response?.data.token!)
    }

    return response
  }

  const logout = useCallback(() => {
    const role = getLocalStorageRole()
    const loginPage = getLoginPage(role)
    setUser(null)
    clearAuthStorage()
    queryClient.clear()
    if (role) {
      router.navigate(loginPage)
    }
  }, [])

  useEffect(() => {
    loadProfile()
  }, [])

  async function loadProfile() {
    return getUserProfile()
      .catch(() => null)
      .then((user) => {
        if (!user) {
          logout()
          return null
        }
        setUser(user)
        setCurrentRole(user.role)
        return user
      })
  }

  function setStudentState(state: StudentState) {
    setUser((u) => (u ? { ...u, state } : u))
  }

  function patchStudent(key: keyof Student, value: any) {
    setUser((u) => (u ? { ...u, key: value } : u))
  }

  let API = PublicAPI
  let dashboard = '/'

  if (user?.isAdmin) {
    API = AdminAPI
    dashboard = '/admin'
  }

  if (user?.is_mentor) {
    API = currentRole === 'Mentor' ? AdminAPI : TutorAPI
    dashboard = '/mentor'
  }

  if (user?.isTutor) {
    API = TutorAPI
    dashboard = '/tutor'
  }

  if (user?.isStudent) {
    API = StudentAPI
    dashboard = '/student'
  }

  if (user?.is_mentor) {
    // @ts-ignore
    user = {
      ...user,
      ...user.admin,
      // @ts-ignore
      isAdmin: currentRole === 'Mentor',
      // @ts-ignore
      isTutor: currentRole === 'Tutor',
    }
  }

  return {
    API,
    adminLogin,
    dashboard,
    loadProfile,
    setStudentState,
    isLoading: user === undefined,
    isAuthenticated: !!user,
    isGuest: !user,
    user,
    logout,
    login,
    currentRole,
    setCurrentRole,
    patchStudent,
    signup,
    acceptInvitation,
    autoLogin,
    ActionCable,
  }
}

export function AuthProvider({ children }: { children: ReactNode }) {
  const auth = useAuthState()
  return (
    <AuthContext.Provider value={auth}>
      {auth.isLoading ? null : children}
    </AuthContext.Provider>
  )
}
