import { Response } from 'core/types/Response'
import { Organization } from 'core/types/User/Admin'
import { PublicAPI } from 'services/API'
import { extractResponseData } from 'services/utils'

export function getOrganization(websiteDomain: string) {
  return PublicAPI.get<Response<Organization>>(
    `/organizations/${websiteDomain}`,
  ).then(extractResponseData)
}
