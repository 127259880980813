import ErrorPage from 'components/ErrorBoundary/ErrorPage'
import { useTranslation } from 'react-i18next'
import { useRouteError } from 'react-router-dom'
import { isProduction } from 'utils/helpers'

const ErrorBoundary = () => {
  const error = useRouteError() as Error

  const { t } = useTranslation(['common'])

  if (error && error.message && error.message?.includes('chunk')) {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
    )
    // try to import the component

    window.sessionStorage.setItem('retry-lazy-refreshed', 'false') // success so reset the refresh

    if (!hasRefreshed) {
      // not been refreshed yet
      window.sessionStorage.setItem('retry-lazy-refreshed', 'true') // we are now going to refresh
      window.location.reload() // refresh the page
      return null
    }
  }
  console.error(error)

  if (!isProduction) {
    throw error
  }
  return (
    <ErrorPage
      errorStateIcon={
        <img src="/images/error-page.gif" alt="" className="w-100" />
      }
      title={t('oops')}
      content={t('something_went_wrong')}
    />
  )
}

export default ErrorBoundary
