import { RouteObject } from 'react-router-dom'
import { defRoute } from './utils/defRoute'

export const OpenRoutes: RouteObject[] = [
  defRoute('login'),
  {
    path: 'projects',
    children: [
      {
        path: '',
        lazy: async () => {
          const { ProjectsPage: Component } = await import(
            'pages/public/ProjectsPage'
          )
          return { Component }
        },
      },
      {
        path: ':projectId',
        lazy: async () => {
          const { SingleProjectPage: Component } = await import(
            'pages/public/SingleProjectPage'
          )
          return { Component }
        },
      },
    ],
  },
]
