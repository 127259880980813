import { useAdminTabPermissions } from 'auth/useAdminTabPermissions'
import Button from 'components/Button/Button'
import { QuickLoginButton } from 'components/QuickLogin'
import Table, { ViewTableProps } from 'components/Table/Table'
import { Role } from 'core/types/Role'
import { Admin } from 'core/types/User'
import { format } from 'date-fns'

const UserMangeTable = (
  props: ViewTableProps<Admin> & {
    handleEditUser: any
    roles?: { [id: number]: Role }
  },
) => {
  const STATUSCOLOR: any = {
    active: 'green600',
    cancel: 'red500',
  }
  const { readOnly } = useAdminTabPermissions('User Manage')

  return (
    <Table
      {...props}
      columns={[
        '#Ser',
        'Name',
        'Organizaiton',
        'Role',
        'Status',
        'Date',
        'Short Name',

        { hidden: readOnly, header: 'Edit' },
      ]}
    >
      {(data) => [
        data.id,

        <>
          <span className="font-color-blue600 font_14 font-normal">
            {data?.name}
          </span>
          <span className="d-block font-color-blue300 font_12 font-normal">
            {data?.email}
          </span>
          <QuickLoginButton user={data} />
        </>,
        data?.organization ? (
          <>
            <span className="font-color-blue600 font_14 font-normal">
              {data?.organization?.name}
            </span>
            <span className="d-block font-color-blue300 font_12 font-normal">
              https://{data?.organization?.domain}.ischooltech.com
            </span>
          </>
        ) : (
          <span className="d-block font-color-blue300 font_12 font-normal">
            All
          </span>
        ),
        props.roles && props.roles[data?.role_id]?.name,

        <span
          className={`chips chips-color-${
            STATUSCOLOR[data?.active ? 'active' : 'cancel']
          }-opacity-30 rounded-pill font_14 mb-1   text-capitalize`}
        >
          {data?.active ? 'active' : 'disabled'}
        </span>,
        format(new Date(data?.created_at), 'd MMM yyyy'),
        data.short_name,
        <Button
          variant="primary600"
          className=" font_14 font-normal py-02 px-12"
          label="Edit"
          isBorderButton
          onClick={() => props.handleEditUser(data)}
        />,
      ]}
    </Table>
  )
}

export default UserMangeTable
