export const MEDIA_BREAKPOINT_XS = 576
export const MEDIA_BREAKPOINT_SM = 768
export const MEDIA_BREAKPOINT_MD = 992
export const MEDIA_BREAKPOINT_LG = 1024
export const MEDIA_BREAKPOINT_XL = 1200
export const MEDIA_BREAKPOINT_XXL = 1300
export const MEDIA_BREAKPOINT_XXXL = 1400
export const MEDIA_BREAKPOINT_XXXXL = 1550
export const MEDIA_BREAKPOINT_XXXXXL = 1700
export const MEDIA_BREAKPOINT_6L = 2063
