import ErrorPage from './ErrorPage'

const PageNotFound = () => {
  return (
    <ErrorPage
      errorStateIcon={
        <img src='/images/404-error.svg' alt='404'  className='w-100 vh-50' />
      }
      title="Something went wrong."
      content="Sorry, We can't find the page you're looking for."
    />
  )
}

export default PageNotFound
