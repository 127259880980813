import { useAnimatedToggle } from 'components/Select/utils/useAnimatedToggle'
import { CommonParams } from 'services/utils'
import { ResourceItem } from 'utils/usePaginatedResource'
import FilterRenderer from './FilterRenderer'
import { QueryFiltersType } from './Provider/QueryProvider'

export default function HotPickFilters<
  Filters extends { [key: string]: any } & CommonParams,
  Data extends ResourceItem,
>({ query }: { query: QueryFiltersType<Filters, Data> }) {
  const toggle = useAnimatedToggle()

  return (
    <>
      {query.filterInputs &&
        Object.entries(query.filterInputs).map(([filterName, config]) => {
          const value = query?.filters?.params[filterName]
          if (!config.pinned) {
            if (value === undefined || value === null || value === '') {
              return null
            }
          }
          return (
            <FilterRenderer
              className=" py-2"
              key={filterName}
              {...config}
              name={filterName}
              pinned={config.pinned}
              value={query?.filters?.params[filterName]}
              filters={query.filters}
              onChange={(value: any) => {
                const update = {
                  [filterName]: value,
                  page: 1,
                } as Partial<Filters>
                query?.filters?.update(update)
              }}
            />
          )
        })}
    </>
  )
}
