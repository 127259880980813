import { HeaderProp } from '../../Table/Table'

export function TableHeader(props: HeaderProp) {
  const {
    header,
    align = 'top',
    className,
    colSpan,
    text = 'start',
    subheader,
    onSortClick,
    sortValue,
    sortKey,
  } = props

  return (
    <th
      style={{ position: 'sticky', top: 0 }}
      className={`text-nowrap x align-${align}  ${`text-${
        text || 'start'
      }`} ${className}`}
    >
      {header}

      {sortKey && (
        <button
          type="button"
          className="font_12 ms-2 font-color-blue300 "
          onClick={() => onSortClick && onSortClick()}
        >
          {sortKey?.replaceAll('-', '') === sortValue?.replaceAll('-', '') && (
            <i
              className={`fa-solid fa-chevron-${
                sortValue?.includes('-') ? 'down' : 'up'
              }`}
            />
          )}

          {sortKey?.replaceAll('-', '') !== sortValue?.replaceAll('-', '') && (
            <i className={`fa-solid fa-minus`} />
          )}
        </button>
      )}
      {subheader && <span className="d-block font_12">{subheader}</span>}
    </th>
  )
}
