import { Select } from 'components/Select/Select'
import { SelectValue } from 'components/Select/types'
import { SelectProps } from './types'

export default function TagSelect(props: SelectProps<SelectValue[]>) {
  return (
    <Select
      {...props}
      label={'Tags'}
      multi
      renderSelectedAsPills
      options={[
        {
          label: 'VIP',
          value: 'VIP',
        },
        {
          label: 'Shift Complain',
          value: 'shift-complain',
        },
        {
          label: 'Instructor Change',
          value: 'Instructor Change'
        }
      ]}
    />
  )
}
