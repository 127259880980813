export function NoData() {
  return (
    <div className="d-flex w-full mt-5  font-color-grey text-center gap-5 justify-content-center align-items-center">
      <div>
        <i
          className="font-color-grey fas fa-folder-open"
          style={{
            fontSize: '50px',
          }}
        ></i>
        <h3 className="font_16  mt-4"> No Data </h3>
      </div>
    </div>
  )
}
