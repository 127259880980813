import { endOfDay, startOfDay } from 'date-fns'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DATE_FORMAT, formatDate } from 'utils/dateUtils'

interface DatePickerInputProps
  extends Omit<ReactDatePickerProps, 'value' | 'selected'> {
  label?: string
  marginBottom?: number
  error?: string
  touched?: boolean
  minDate?: Date
  maxDate?: Date
  isRequired?: boolean
  inputClassName?: string
  labelClassName?: string
  continerClassName?: string
  isFormInline?: boolean
  value?: Date
  dateHour?: 'startOfDay' | 'endOfDay'
  dateFormat?: string
}

export function BasicDatePickerInput({
  dateHour,
  onChange,
  value,
  dateFormat,
  inputClassName = 'form-control',
  showTimeSelect,
  ...props
}: DatePickerInputProps) {
  return (
    <DatePicker
      {...props}
      onChange={(date, e) => {
        if (date && dateHour === 'startOfDay') {
          onChange(startOfDay(date), e)
        } else if (date && dateHour === 'endOfDay') {
          onChange(endOfDay(date), e)
        } else {
          onChange(date, e)
        }
      }}
      value={formatDate(value, dateFormat)}
      selected={value}
      portalId="modals-container"
      className={` ${inputClassName}`}
      showTimeSelect={showTimeSelect}
      popperClassName="shadow"
      showTimeSelectOnly={false}
      calendarClassName="custom-date-picker"
      wrapperClassName="d-block"
    />
  )
}

const DatePickerInput = ({
  label,
  marginBottom,
  error,
  touched,
  isRequired = false,
  inputClassName = '',
  showTimeSelectOnly = false,
  labelClassName = '',
  continerClassName = '',
  isFormInline,
  value,
  dateHour,
  showTimeSelect = false,
  dateFormat = DATE_FORMAT,
  onChange,
  ...props
}: DatePickerInputProps) => {
  return (
    <div
      className={`input-main input-main-text ${continerClassName} ${
        isFormInline ? 'd-flex align-items-center gap-3' : ''
      }`}
      style={{ marginBottom: marginBottom ? `${marginBottom}px` : 0 }}
    >
      {label && (
        <label
          htmlFor={label}
          className={`form-label ${labelClassName} ${
            !isFormInline ? 'd-block' : 'd-inline-block'
          }`}
        >
          {label}
          {isRequired && <span className="required">*</span>}
        </label>
      )}
      <DatePicker
        {...props}
        onChange={(date, e) => {
          if (date && dateHour === 'startOfDay') {
            onChange(startOfDay(date), e)
          } else if (date && dateHour === 'endOfDay') {
            onChange(endOfDay(date), e)
          } else {
            onChange(date, e)
          }
        }}
        value={formatDate(value, dateFormat)}
        selected={value}
        className={`form-control ${inputClassName}`}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={false}
        calendarClassName="custom-date-picker"
        wrapperClassName="d-block"
      />

      {error && touched ? (
        <div className="input-error font_14 font-color-red500">{error}</div>
      ) : null}
    </div>
  )
}

export default DatePickerInput
