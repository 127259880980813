import { Response } from 'core/types/Response'
import { Student } from 'core/types/User'
import { StudentAPI } from 'services/API'

export function updateProfile(profileData: any) {
  return StudentAPI.put<Response<Student>>(`/profile`, profileData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
