import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useLoggedIn, useLoggedInStudent } from 'auth/useAuth'
import Button from 'components/Button/Button'
import Icon from 'components/Icon/Icon'
import InputSelect from 'components/Inputs/InputSelect'
import InputTextArea from 'components/Inputs/InputTextArea'
import Switch from 'components/Inputs/Switch'
import { useOpenedModal } from 'components/Modal'
import { ModalWindow } from 'components/Modal/ModalWindow'
import Rating from 'components/Rating/Rating'
import { Feedback } from 'core/types'
import { Response } from 'core/types/Response'
import { AdminSession } from 'core/types/session'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { AdminAPI, TutorAPI } from 'services/API'
import { QueryKeys } from 'services/QueryKeys'
import { adminSubmitStudentFeedback } from 'services/admin/sessions'
import { submitStudentFeedback } from 'services/tutor/sessions'
import { extractResponseData } from 'services/utils'
import * as Yup from 'yup'

const initialValues: {
  student_feedback: Feedback[]
  student_feedback_comment: string
  is_student_absent: boolean
} = {
  student_feedback: [],
  student_feedback_comment: '',
  is_student_absent: false,
}

function useGetFeedbackTemplates() {
  const { loggedInUser } = useLoggedIn()
  return useQuery({
    queryFn: () =>
      (loggedInUser.isAdmin ? AdminAPI : TutorAPI)
        .get<Response<{ title: string; template: string }[]>>(
          'feedback_templates',
        )
        .then(extractResponseData),
    queryKey: [QueryKeys.feedbackTemplatesList],
  }).data
}

function useSubmitFeedback() {
  const queryClient = useQueryClient()
  const { closeModal } = useOpenedModal()
  const { loggedInUser } = useLoggedIn()

  return useMutation({
    mutationFn: loggedInUser.isAdmin
      ? adminSubmitStudentFeedback
      : submitStudentFeedback,
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.tutorClasses])
      queryClient.invalidateQueries(['adminSessions'])
      toast.success('Feedback submitted')
      closeModal()
    },
    onError(e: any, variables, context) {
      toast.error(e?.response?.data?.message)
    },
  })
}

const validationSchema = Yup.object().shape({
  student_feedback_comment: Yup.string().when('is_student_absent', {
    is: (is_student_absent: any) => is_student_absent === false,
    then: () => Yup.string().trim().required('Required'),
  }),
})
const FeedbackModalWindow = ({
  session,
  viewOnly = false,
}: {
  session?: AdminSession
  viewOnly?: boolean
}) => {
  const { mutate: SubmitFeedback } = useSubmitFeedback()
  const formik = useFormik({
    onSubmit: (form) => {
      session && SubmitFeedback({ ...form, sessionId: session.id })
    },
    validationSchema,
    initialValues,
  })

  const templates = useGetFeedbackTemplates()
  const [templateTitle, setTemplateTitle] = useState<string>()

  useEffect(() => {
    if (session) {
      formik.setValues({
        student_feedback: session?.lesson.feedback.map((skill) => ({
          skill,
          rating:
            session.student_feedback?.find((fb) => fb.skill.en === skill.en)
              ?.rating || 0,
        })),

        student_feedback_comment: session?.student_feedback_comment || '',
        is_student_absent: session?.is_student_absent,
      })
    }
  }, [session?.id])

  return (
    <>
      <ModalWindow
        titleIcon={
          <Icon iconName="filter-filled" iconSize={25} className="pe-2" />
        }
        title={'Feedback'}
        id={'feedbackClassFilter'}
        containerClassName="feedback-class-filter-modal"
        maxWidth={552}
        hasCloseBtn
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="d-flex justify-content-end">
            <Switch
              id="studentAbsent"
              label={'Student Absent'}
              checked={formik.values.is_student_absent}
              // containerClassName="d-flex flex-row-reverse gap-4"
              onChange={(e) => {
                formik.setValues({
                  student_feedback: formik.values.student_feedback.map(
                    ({ skill }) => ({ skill, rating: 0 }),
                  ),
                  student_feedback_comment: '',
                  is_student_absent: e.target.checked,
                })
                setTemplateTitle(undefined)
              }}
              disabled={viewOnly}
              name="is_student_absent"
            />
          </div>
          <div
            style={{ opacity: formik.values.is_student_absent ? '0.4' : '1' }}
          >
            <div>
              {formik.values.student_feedback?.map((fb, index) => (
                <Rating
                  key={fb.skill.en}
                  value={fb.rating}
                  onChange={(e) => {
                    formik.setFieldValue(`student_feedback.${index}.rating`, e)
                  }}
                  label={fb.skill.en}
                  readOnly={formik.values.is_student_absent || viewOnly}
                  starSize={21}
                  containerClassName="mb-3"
                />
              ))}
            </div>

            <div className="mb-4">
              <InputSelect
                value={templateTitle}
                continerClassName="flex-1 mb-2"
                onChange={(e) => {
                  const template = templates?.find(
                    (t) => t.title === e.target.value,
                  )

                  formik.setFieldValue(
                    'student_feedback_comment',
                    template?.template,
                  )
                  setTemplateTitle(template?.title)
                }}
                label={'Comment'}
                labelClassName="font-semibold font_20 text-nowrap"
                isFormInline
                placeholder="Select Template"
                defaultSelected="Select Template"
                values={templates?.map((t) => ({
                  text: t.title,
                  value: t.title,
                }))}
                disabled={formik.values.is_student_absent || viewOnly}
              />

              <InputTextArea
                rows={5}
                name="student_feedback_comment"
                value={formik.values.student_feedback_comment}
                onChange={formik.handleChange}
                disabled={formik.values.is_student_absent || viewOnly}
                error={formik.errors.student_feedback_comment}
                touched={formik.touched.student_feedback_comment}
                inputClassName="bg-color-primary50-opacity-20 border-0 font_20"
                placeholder="Type here"
              />
            </div>
          </div>

          {!viewOnly && (
            <div className="d-flex align-items-center mt-4  border-0 justify-content-center tutor-class-filter-modal__actions">
              <Button
                className="font_12 rounded-pill"
                label="Submit"
                type="submit"
              />
            </div>
          )}
        </form>
      </ModalWindow>
    </>
  )
}

export default FeedbackModalWindow
