import { useMemo } from 'react'
import { Options, ModalApi, PopupContext, PopupProps } from '..'
import { useModalApi } from './useModalApi'
import Portal from '../Portal'
import ModalContainer from '../ModalContainer'

export function useCreateModal<T>(options?: Options<T>) {
  const api = useModalApi<T>(options)

  const PopupComponent: PopupComponent<T> = useMemo(() => {
    return function (props: PopupProps<T>) {
      if (!PopupComponent.api) return <></>
      const { data } = PopupComponent.api
      return (
        <Portal id={options?.portalRoot || 'modals-container'}>
          <PopupContext.Provider value={PopupComponent.api}>
            <ModalContainer>
              {props.children && typeof props.children === 'function'
                ? props.children({ data })
                : props.children}
            </ModalContainer>
          </PopupContext.Provider>
        </Portal>
      )
    }
  }, [])

  PopupComponent.api = api

  return useMemo(() => ({ Container: PopupComponent, ...api }), [api.isOpened])
}

type PopupComponent<T> = {
  (props: PopupProps<T>): JSX.Element
  api?: ModalApi<T>
}
