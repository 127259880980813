import { useLoggedIn, useLoggedInStudent } from 'auth/useAuth'
import Button from 'components/Button/Button'
import { usePreviewModal } from 'components/Inputs/FilePreviewInput'
import { useCreateModal } from 'components/Modal'
import QuizModalWindow, {
  ViewQuizAnswersModalWindow,
} from 'components/Modals/QuizModal/QuizModalWindow'
import {
  OfflineGroup,
  StudentJoinOfflineSesionModalWindow,
} from 'components/Modals/StudentJoinOfflineSesionModal'
import Rating from 'components/Rating/Rating'
import ReadMore from 'components/ReadMore'
import SunCircle from 'components/SunCircle/SunCircle'
import { VdoCypherIframe } from 'components/Video/VdoCypherIframe'
import { useIsMobile } from 'components/useMediaQueries'
import { Session } from 'core/types/session'
import { useTranslation } from 'react-i18next'
import {
  StudentTrackActivity,
  trackStudentSession,
} from 'services/student/sessions'
import { I18N } from 'utils/I18N'
import { OrganizationEnum } from 'utils/Providers/OrganizationProvider'
import { formatCairoDate, formatCairoTime, formatDate } from 'utils/dateUtils'
import { formatNumber } from 'utils/numbers'
import InfoCard from '../InfoCard'

//SCSS

type QuizState = 'not_available' | 'completed' | 'not_started' | 'paused'

type ClassCardProps = { session: Session }

export default function ClassCard({ session }: ClassCardProps) {
  const lesson = session.lesson
  const { loggedInStudent } = useLoggedInStudent()
  const QuizModal = useCreateModal<Session>()
  const questionsCount = lesson.questions?.length || 0
  const solvedCount = session.answers?.length || 0
  const correctAnswersCount = (session.answers || [])?.filter(
    (a) => a.correct,
  ).length

  const { t } = useTranslation(['common'])

  const PreviewFileModal = usePreviewModal()
  const isMobile = useIsMobile()
  const quizState: QuizState =
    questionsCount === 0
      ? 'not_available'
      : questionsCount === solvedCount
      ? 'completed'
      : solvedCount === 0
      ? 'not_started'
      : 'paused'

  function onResourceViewClick(
    fileUrl: string,
    trackName?: StudentTrackActivity,
  ) {
    trackName && trackStudentSession(session?.id, trackName)
    PreviewFileModal.openModal({
      previewUrl: fileUrl,
      downloadImageName: `${lesson.name}.pdf`,
      type: 'pdf',
    })
  }

  return (
    <>
      <QuizModal.Container>
        {({ data }) => (
          <>
            {data?.answers?.length === data?.lesson.questions.length ? (
              <ViewQuizAnswersModalWindow />
            ) : (
              <QuizModalWindow />
            )}
          </>
        )}
      </QuizModal.Container>

      <div
        className={`${
          isMobile ? '' : 'card-style pt-3'
        } bg-white  rounded  pb-2`}
      >
        <div
          className={`${
            isMobile ? 'flex-column' : ' '
          } text-start gap-1  d-flex  flex-wrap align-items-center font_13`}
        >
          <Button
            isBorderButton
            isButtonLink
            className="border-0 font_14 button-border px-0 py-01 gap-2 font-normal font-color-primary"
          >
            {session.grade.name}{' '}
          </Button>
          {!isMobile && '/'}

          <Button
            isBorderButton
            isButtonLink
            className="border-0 font_14 button-border px-0 py-01 font-normal font-color-primary"
          >
            {session.level.name}
          </Button>
          <SesionStartAt start_at={session.start_at} />
        </div>
        <div className=" text-start  flex-1 class-card pb-0">
          <h3 className="font-color-blue700 text-start font_24 m-0 mt-3 mb-4 font-bold">
            {session.lesson.name}
          </h3>

          {session.is_offline && (
            <>
              <div className="font_14 font-color-blue300">
                {t('practical_session')}
              </div>
              <OfflineSessionLocationButton session={session} />
            </>
          )}

          <div className="row class-card__section">
            <div className="col-md-6 mb-4 mb-md-0">
              <InfoCard
                icon={{ name: 'document-filled', size: 16 }}
                title={t('description')}
                styleTitle={{ fontSize: 14, fontColor: 'blue700' }}
              >
                {lesson?.description ? (
                  <ReadMore
                    lines={3}
                    content={lesson?.description ?? ''}
                    contentClassName="font-color-blue700-opacity-60 font-light mb-0"
                  />
                ) : (
                  <div className="text-center">
                    <h3 className="mb-2 mt-3 font_16">{t('no_description')}</h3>
                  </div>
                )}
              </InfoCard>
            </div>

            <div className="col-sm-6 col-md-3 mb-4 mb-md-0">
              <InfoCard
                icon={{ name: 'briefcase-filled', size: 16 }}
                title={t('resources')}
                styleTitle={{ fontSize: 14, fontColor: 'blue700' }}
              >
                <div
                  className="d-flex flex-column gap-2 mx-auto"
                  style={{ maxWidth: 'min-content' }}
                >
                  <Button
                    isBorderButton
                    className="border-0 align-items-center justify-content-start rounded-pill py-2 font-bold  font_16 font-light gap-3  "
                    disabled={!lesson.resources_url || session.is_locked}
                    children={
                      <>
                        <i style={{ width: '15px' }} className="fa fa-book" />{' '}
                        {t('Reference')}
                      </>
                    }
                    onClick={() =>
                      onResourceViewClick(lesson.resources_url, 'resource_view')
                    }
                  />

                  {lesson.homework_url && (
                    <Button
                      isBorderButton
                      className="border-0 align-items-center justify-content-start  rounded-pill py-2 font-bold  font_16 font-light gap-3 "
                      disabled={session.is_locked}
                      children={
                        <>
                          <i
                            style={{ width: '15px' }}
                            className="fa-solid fa-pencil-alt"
                          />{' '}
                          {t('Homework')}
                        </>
                      }
                      onClick={() => onResourceViewClick(lesson.homework_url)}
                    />
                  )}

                  {lesson.revision_url && (
                    <Button
                      isBorderButton
                      className="border-0 align-items-center justify-content-start  rounded-pill py-2 font-bold  font_16 font-light gap-3 "
                      disabled={session.is_locked}
                      children={
                        <>
                          <i
                            style={{ width: '15px' }}
                            className="fa-solid  fa-clipboard-list"
                          />
                          {t('Revision')}
                        </>
                      }
                      onClick={() => onResourceViewClick(lesson.revision_url)}
                    />
                  )}
                </div>
              </InfoCard>
            </div>

            <div
              className="col-sm-6 col-md-3 mb-md-0"
              style={{
                opacity: session.is_locked ? '0.5' : '1',
              }}
            >
              <InfoCard
                icon={{ name: 'quiz-filled', size: 16 }}
                title={t('quizzes')}
                styleTitle={{ fontSize: 14, fontColor: 'blue700' }}
              >
                <Button
                  disabled={session.is_locked || quizState === 'not_available'}
                  label={
                    quizState === 'not_started'
                      ? t('start')
                      : quizState === 'paused'
                      ? t('resume')
                      : quizState === 'not_available'
                      ? t('n/a')
                      : session.hide_quiz_results
                      ? t('done')
                      : `${correctAnswersCount} / ${questionsCount}`
                  }
                  variant="secondary"
                  className="rounded-pill font_16 font-light py-2 mx-auto"
                  buttonSize="large"
                  onClick={() => {
                    if (
                      quizState === 'not_started' ||
                      quizState === 'paused' ||
                      !session.hide_quiz_results
                    ) {
                      QuizModal.openModal(session)
                    }
                  }}
                />
              </InfoCard>
            </div>
          </div>
          <div className="row class-card__section">
            <div
              className="col-lg-6 mb-3 mb-lg-0"
              style={{
                opacity: session.is_locked ? '0.5' : '1',
              }}
            >
              <InfoCard
                icon={{ name: 'video-filled', size: 16 }}
                title={t('recap')}
                styleTitle={{ fontSize: 14, fontColor: 'blue700' }}
              >
                <VdoCypherIframe
                  videoId={
                    (loggedInStudent.language === 'arabic'
                      ? session.lesson.recap_video_vdocipher_id_ar
                      : session.lesson.recap_video_vdocipher_id_en) ||
                    session.lesson.recap_video_vdocipher_id
                  }
                  onPlay={() => trackStudentSession(session.id, 'video_view')}
                  disabled={session.is_locked}
                />
              </InfoCard>
            </div>

            <div
              className="col-lg-6"
              style={{
                opacity: session.is_locked ? '0.5' : '1',
              }}
            >
              <InfoCard
                icon={{ name: 'square-star-filled', size: 16 }}
                styleTitle={{ fontSize: 14, fontColor: 'blue700' }}
                title={t('feedback')}
              >
                {session.student_feedback ? (
                  <>
                    <div className="d-md-flex align-items-center mb-3 flex-wrap">
                      <ul className="mt-4 me-3 flex-fill">
                        {session.student_feedback?.map((fb) => (
                          <li key={fb.skill.en} className="row  mb-2">
                            <div className="col-12 col-md-6 col-lg-6 pr-lg-0">
                              <p className="mb-0 font-color-blue700-opacity-60 text-break  white-space-wrap">
                                <I18N value={fb.skill} />
                              </p>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 pl-lg-0">
                              <Rating
                                value={fb.rating}
                                starSize={20}
                                containerClassName="flex-grow-1"
                                readOnly
                              />
                            </div>
                          </li>
                        ))}
                      </ul>

                      <div className="flex-fill my-4 my-md-0">
                        <SunCircle circleColor="orange200" circleSize={101}>
                          <h3 className="font_32 font-color-secondary mb-0">
                            {formatNumber(session.feedback_score)}
                          </h3>
                        </SunCircle>
                      </div>
                    </div>

                    {session.student_feedback_comment && (
                      <div className="text-start">
                        <h6 className="font_19 font-regular font-color-blue700">
                          {t('comment')}
                        </h6>
                        <ReadMore
                          lines={3}
                          content={session.student_feedback_comment ?? ''}
                          contentClassName="font-color-blue700-opacity-60"
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="text-center d-flex font-color-background-shade mt-4 gap-4 flex-column align-items-center justify-content-center">
                    <i
                      className="fa fa-search "
                      style={{
                        fontSize: '80px',
                        margin: '0 auto',
                      }}
                    />
                    <h3 className="mb-2  font_16">{t('no_feedback')}</h3>
                  </div>
                )}
              </InfoCard>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export function Location({
  location,
  start_at,
  following_group,
  showDateTime = true,
}: Partial<OfflineGroup> & { showDateTime?: boolean }) {
  const { t } = useTranslation(['common'])
  const { loggedInUser } = useLoggedIn()

  return (
    <div className="d-grid gap-1 rounded py-3 px-3 font-color-blue300 ">
      {location?.map_url && (
        <a
          href={location?.map_url}
          style={{ maxWidth: 'min-content' }}
          className="d-inline-flex gap-2 align-items-start text-nowrap"
          target="_blank"
        >
          <i className="fa fa-location-dot  font-color-red500" />
          <span className="">{t('map_link')}</span>
        </a>
      )}
      {location?.name && (
        <h5 className="text-start font-semibold font_16 py-2 font-color-blue600">
          {location?.name}
        </h5>
      )}
      {location?.address && (
        <h6 className="font-semibold font_14 font-color-blue300">
          {location?.address}
        </h6>
      )}

      {showDateTime && (
        <>
          <div className="d-flex gap-2 font-semibold font_14 align-items-center">
            <i className="fa-regular font-color-blue300  fa-calendar" />
            <span className="text-nowrap">
              {formatCairoDate(start_at, 'EEEE dd MMMM yyyy ')}
            </span>
          </div>

          <div className="d-flex flex-wrap font-semibold gap-2 font_14 align-items-center">
            <i className="fa-regular font-color-blue300  fa-clock" />
            <span className="text-nowrap">
              {formatCairoTime(start_at, 'hh:mm a')}
            </span>
          </div>
        </>
      )}

      {following_group && loggedInUser.isStudent && (
        <span className="bg-color-grey-opacity-5 border rounded px-2 py-2">
          <span className="font_14 font-color-red500">
            <span className="fa fa-exclamation-triangle font-color-orange500"></span>{' '}
            {t('next_semester_time')}
          </span>
          <span className="d-block d-flex gap-2 align-items-center">
            <i className="fa-regular text-nowrap font-color-blue300 fa-calendar" />
            {formatCairoDate(following_group.start_at, 'EEEE dd MMMM yyyy ')}
          </span>
          <span className="d-block d-flex gap-2 align-items-center">
            <i className="fa-regular text-nowrap font-color-blue300 fa-clock" />
            {formatCairoTime(following_group.start_at, 'hh:mm a')}
          </span>
          <span className="font_14">{t('every_day_from_sat_to_thu')}</span>
        </span>
      )}
    </div>
  )
}

function OfflineSessionLocationButton({ session }: { session: Session }) {
  const SaveSeatModal = useCreateModal()
  const { loggedInStudent } = useLoggedInStudent()

  const { t } = useTranslation(['common'])

  return (
    <>
      <SaveSeatModal.Container>
        <StudentJoinOfflineSesionModalWindow />
      </SaveSeatModal.Container>

      <div className="bg-white rounded shadow-sm mt-2 mb-3 border border-color-primary100  d-flex justify-content-between align-items-start border">
        <Location
          showDateTime={true}
          location={session.location}
          start_at={session.start_at}
        />
        {false &&
          loggedInStudent.organization.id == OrganizationEnum.DemiSummer && (
            <Button
              isBorderButton
              type="button"
              className="d-flex justify-content-end font_14 border-0 px-4 py-2 m-2"
              onClick={() => SaveSeatModal.openModal(session)}
            >
              <i className="fa fa-cog" />
              {t('Edit Group')}
            </Button>
          )}
      </div>
    </>
  )
}

function SesionStartAt({
  start_at,
}: {
  start_at?: string
  showTime?: boolean
}) {
  return (
    <div className="ms-auto d-flex gap-2 align-items-baseline font-light font-color-blue300 font_13">
      <i className="fa-regular font-light fa-calendar " />
      <span className=" ">{formatDate(start_at, 'EEEE d MMMM yyyy')}</span>
      {formatDate(start_at, 'hh:mm a z')}
    </div>
  )
}
