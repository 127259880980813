import { ReactNode, createContext, useContext } from 'react'
import { useCreateModal } from './hooks/useCreateModal'

import { useModalApi } from './hooks/useModalApi'
import { useEscapeHandler } from './hooks/useEscapeHandler'

export const PopupContext = createContext<ModalApi<any>>({} as any)

export type ModalApi<T> = ReturnType<typeof useModalApi<T>>

export interface PopupProps<Data = undefined> {
  anchor?: HTMLElement | null
  children?:
    | ReactNode
    | ((context: { data?: Data; anchor?: HTMLElement | null }) => ReactNode)
}
export type Options<T> = {
  name?: string
  backdropShadow?: boolean
  isOpened?: boolean
  disableBackdrop?: boolean
  disableParentScroll?: boolean
  data?: T
  portalRoot?: string
  onOpenChange?: (b: boolean) => void
}

function useOpenedModal<T>() {
  return useContext(PopupContext) as ModalApi<T>
}

const useModalEscapeHandler = useEscapeHandler

export { useCreateModal, useOpenedModal, useModalEscapeHandler }
