import { ChatMessage, MessageSender } from 'components/Community/MessageBubble'

export enum NotificationType {
  LeaveRequest = 'LeaveRequest',
  Tutor = 'Tutor',
  ExportSheet = 'ExportSheet',
  Community = 'Community',
}

export type Notification = {
  created_at: string
  id: number
  key: string
  navigationable_id: number
  navigationable_type: NotificationType
  notificationable_id: number
  read: boolean
  title: string
  body: string
  avatar_url: string
}

export type UserNotificationPayload = {
  not_read_notifications_count: number
  notification: Notification
}

export type CommunityMessagePayload = {
  has_unread_community_messages: number
  message: ChatMessage
}

export type ReactionUpdatePayload = {
  id: number
  community_id: number
  reactions: Reactions
  my_reactions: string[]
}

export type PinMessagePayload = {
  id: number
  pinned: boolean
  community_id: number
}

export type FlagMessagePayload = {
  id: number
  community_id: number
  flagged: boolean
}

export type ReportMessagePayload = {
  id: number
  community_id: number
  reporter: Partial<MessageSender>
  is_reported: boolean
}

export type DeleteMessagePayload = {
  community_id: number
  id: number
  is_deleted: boolean
  content: string
}

export type EditMessagePayload = {
  community_id: number
  id: number
  content: string
  is_edited: boolean
}

export type Reactions = {
  [key: string]: number
}

export type PayloadMap = {
  user_notification: UserNotificationPayload
  community_message: CommunityMessagePayload
  reaction_update: ReactionUpdatePayload
  pin_message: PinMessagePayload
}

export type SocketMessage<T extends keyof PayloadMap> = {
  action: T
  payload: PayloadMap[T]
}
