import { Response } from 'core/types/Response'
import { PublicAPI } from 'services/API'
import { Plan } from 'core/types/SubscriptionPlans.ts'
import { extractResponseData } from 'services/utils'

export function getGrades() {
  return PublicAPI.get<Response<{ id: number; name: string }[]>>(
    '/grades',
  ).then(extractResponseData)
}
