import Tooltip, { TooltipPlacement } from 'components/Tooltip/Tooltip'
import { ReactNode, forwardRef } from 'react'
import { Spinner } from 'utils/Spinner'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: ReactNode
  variant?: string
  buttonSize?: 'small' | 'med' | 'large' | 'xsmall'
  isButtonLink?: boolean
  id?: string
  ref?: React.Ref<HTMLButtonElement>
  isBorderButton?: boolean
  isLoading?: boolean
  icon?: string
  tooltip?: string
  tooltipPalcement?: TooltipPlacement
}

const Button = forwardRef(
  (
    {
      label,
      variant = 'primary',
      buttonSize,
      isButtonLink = false,
      children,
      className,
      id,
      isBorderButton = false,
      type = 'button',
      isLoading,
      ...props
    }: ButtonProps,
    ref?: React.Ref<HTMLButtonElement>,
  ) => {
    return (
      <Tooltip
        content={props.tooltip}
        key={props.tooltip}
        placement={props.tooltipPalcement}
      >
        <button
          {...props}
          ref={ref}
          id={id}
          type={type}
          className={`button gap-2 align-items-center ${
            isButtonLink
              ? `button-text-${variant}`
              : isBorderButton
              ? `button-border button-border-${variant}`
              : `button-color-${variant}`
          }  ${className} ${buttonSize ? `button-size-${buttonSize}` : ''}`}
        >
          <>
            {children && children}
            {label && label}
            {isLoading && <Spinner inline />}
          </>
        </button>
      </Tooltip>
    )
  },
)

export default Button
