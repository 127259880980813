export default function ISchoolLogo({
  useWhiteLogo,
}: {
  useWhiteLogo: boolean
}) {
  return (
    <img
      className="main-logo "
      alt=""
      src={`/images/logo/app/en-${useWhiteLogo ? 'b' : 'a'}.png`}
    />
  )
}
