import { useQuery } from '@tanstack/react-query'
import { Select } from 'components/Select/Select'
import { SelectProps } from 'components/Selectors/types'
import { Response } from 'core/types/Response'
import { AdminAPI } from 'services/API'
import { useGetCurriculum } from 'services/curriculum'

type FlattenCurriculum = {
  curriculums: { id: number; name: string; organization_id: number }[]
  grades: {
    id: number
    name: string
    organization_id: number
    curriculum_id: number
  }[]
  levels: {
    id: number
    name: string
    grade_id: number
    organization_id: number
    curriculum_id: number
  }[]
  lessons: {
    id: number
    name: string
    grade_id: number
    level_id: number
    organization_id: number
    curriculum_id: number
  }[]
}

export function useFlattenedGrades() {
  return useQuery({
    queryFn: () =>
      AdminAPI.get<Response<FlattenCurriculum>>('/grades?flatten=true').then(
        (r) => r.data.data,
      ),
    queryKey: ['grades_flattened'],
    staleTime: Infinity,
  })
}

export function GradeCurriculumSelect(
  props: Omit<SelectProps<number>, 'onChange'> & {
    onChange: (gradeId?: number, curriculumId?: number) => void
  },
) {
  const { data } = useFlattenedGrades()
  return (
    <Select
      label="Grade"
      {...props}
      options={data?.grades?.map((g) => ({
        group:
          data.curriculums.length > 1
            ? data.curriculums.find((c) => c.id == g.curriculum_id)?.name
            : undefined,
        label: (
          <>
            <span className="d-block">{g.name}</span>
          </>
        ),
        data: g,
        value: g.id,
        searcIn: [g.name],
      }))}
      onDataChange={(g) => {
        props.onChange && props.onChange(g.data?.id, g.data?.curriculum_id)
      }}
    />
  )
}

export function GradeSelect({
  curriculumId,
  ...props
}: SelectProps<number> & { curriculumId?: number }) {
  const { data } = useGetCurriculum(curriculumId)

  return (
    <Select
      {...props}
      label="Grade"
      disabled={!curriculumId}
      options={data?.curriculum.grades?.map((r) => ({
        label: r.name,
        value: r.id,
      }))}
    />
  )
}
