import { PaginatedResponse } from 'core/types/Response'
import { Student, Tutor } from 'core/types/User'
import { AdminSupportTicket } from 'core/types/supportTicket'
import { AdminAPI } from 'services/API'
import { CommonParams, Optional, pgQueryString } from 'services/utils'

export type AdminSupportTicketsParams = CommonParams & {
  keyword?: string
  supportable_type?: 'Student' | 'Tutor'
  created_at_from?: Optional<Date>
  created_at_to?: Optional<Date>
  support_topic_id?: number
  status?: string
}

export function getAdminSupportTickets(params: AdminSupportTicketsParams) {
  return AdminAPI.get<PaginatedResponse<AdminSupportTicket>>(
    `support_tickets${pgQueryString(params)}`,
  ).then((r) => {
    const response = r.data
    return {
      ...response,
      data: response.data.map(({ owner, ...t }) =>
        t.supportable_type === 'Student'
          ? { ...t, owner, student: owner as Student }
          : { ...t, owner, tutor: owner as Tutor },
      ),
    }
  })
}
