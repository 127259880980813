import { useQuery } from '@tanstack/react-query'
import { useLoggedIn, useLoggedInStudent } from 'auth/useAuth'
import NextClassCard, {
  MobileNextSessionCounter,
} from 'components/Cards/ClassesCards/NextClass/NextClassCard'
import Rating from 'components/Rating/Rating'
import { AdminSession } from 'core/types/session'
import { useGetUpcomingOrLastSession } from 'pages/student/Classes/useSessionId'
import { useRef } from 'react'
import { QueryKeys } from 'services/QueryKeys'
import { getStudentUpcomingSession } from 'services/student/sessions'
import { getTutorUpcomingSession } from 'services/tutor/sessions'
import { formatLabel } from 'services/utils'
import { useOrganization } from 'utils/Providers/OrganizationProvider'
import { useResizeObserver } from 'utils/useResizeObserver'
import Avatar from '../../components/Avatar/Avatar'
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher'
import Notifications from '../../components/Notifications/NotificationsDropDown'

export function useGetupcomingSession(enabled = true) {
  const { loggedInUser } = useLoggedIn()

  return useQuery({
    queryKey: [QueryKeys.upcomingSession],
    queryFn: loggedInUser.isStudent
      ? getStudentUpcomingSession
      : getTutorUpcomingSession,
    enabled: enabled && (loggedInUser.isStudent || loggedInUser.isTutor),
  })
}

export function useGetTutorUpcomingSession() {
  return useQuery({
    queryKey: [QueryKeys.upcomingSession],
    queryFn: getTutorUpcomingSession,
  })
}

export default function StudentDashboardHeader() {
  const { loggedInStudent } = useLoggedInStudent()
  const { data } = useGetUpcomingOrLastSession(
    'any',
    loggedInStudent.state === 'active',
  )
  const ref = useRef<HTMLDivElement>(null)

  const { width } = useResizeObserver(ref)

  return (
    <nav className="bg-color-white border-b border-color-grey-opacity-10 m-0 ">
      <WhiteLabelBanner />
      <div
        className="bg-white text-start w-100 d-grid  px-4 gap-4 d-flex  align-items-center py-1"
        style={{ minHeight: '70px', maxHeight: '80px' }}
      >
        <div className=" d-flex flex-column justify-content-center  ">
          <StudentAvatar />
        </div>

        <div className="d-flex flex-1 text-start " ref={ref}>
          {loggedInStudent.state === 'active' &&
            data?.id &&
            (width > 700 ? (
              <NextClassCard session={data as AdminSession} />
            ) : (
              <MobileNextSessionCounter session={data as AdminSession} />
            ))}
        </div>
        <div className="ms-auto  ">
          <HeaderIconButtons />
        </div>
      </div>
    </nav>
  )
}

export function WhiteLabelBanner() {
  const { org } = useOrganization()

  if (!org?.is_whitelabel || !org?.welcome_message) return null

  return (
    <h1 className="pt-2 pb-1 bg-color-primary300 font-color-blue700 font_18 font-bold text-center border-b border-color-grey-opacity-5 m-0">
      {org?.welcome_message}
    </h1>
  )
}

export function HeaderIconButtons() {
  const { org } = useOrganization()

  return (
    <div className="d-flex ms-auto gap-2 align-items-center ">
      {org?.is_whitelabel && <LanguageSwitcher />}
      <Notifications />
    </div>
  )
}

export function StudentAvatar() {
  const { loggedInStudent } = useLoggedInStudent()
  const { org } = useOrganization()

  return (
    <Avatar
      imageSize={50}
      containerClassName="order-0"
      userImage={loggedInStudent?.avatar_url}
      userName={formatLabel(loggedInStudent.display_name)}
      imageClassName={'user-image d-none d-md-flex'}
      userNameSize={18}
      userNameClassName="text-nowrap m-0"
    >
      <div className="d-flex align-items-center">
        <p className="font_12 mb-0 text-nowrap navbar-user-grade me-2">
          {loggedInStudent.s_id}
        </p>
        {loggedInStudent.rating !== null && org?.domain?.includes('deci') && (
          <span className="d-flex align-items-center">
            <Rating count={1} readOnly value={loggedInStudent.rating} />{' '}
            {loggedInStudent.rating}
          </span>
        )}
      </div>
    </Avatar>
  )
}
