import { Select } from 'components/Select/Select'
import { useGetRoles } from 'pages/admin/UserManage'
import { SelectProps } from '../types'

export function AdminRoleSelect(props: SelectProps<number>) {
  const roles = useGetRoles().data?.list
  return (
    <Select
      {...props}
      searchable
      label={'Role'}
      options={roles?.map((r) => ({ value: r.id, label: r.name }))}
    />
  )
}
