import { useQuery, useQueryClient } from '@tanstack/react-query'
import Button from 'components/Button/Button'
import { useOpenedModal } from 'components/Modal'
import { ModalWindow } from 'components/Modal/ModalWindow'
import { Student, Tutor } from 'core/types/User'
import { Session } from 'core/types/session'
import { Subscription } from 'core/types/subscription'

import GroupsSelect from 'components/Selectors/GroupsSelect'
import { Response } from 'core/types/Response'
import { Quiz } from 'core/types/quiz'
import { QuizAnswer } from 'pages/student/learn/content/learning-center/content-types/quiz/QuizAnswer'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { AdminAPI } from 'services/API'
import { QueryKeys } from 'services/QueryKeys'
import { extractResponseData } from 'services/utils'
import { Spinner } from 'utils/Spinner'
import { formatDate, formatTime } from 'utils/dateUtils'
import { errorToast } from 'utils/errorToast'
import { ResourceItem } from 'utils/usePaginatedResource'

export type AdminStudent = Student &
  ResourceItem & {
    session?: Session & { tutor: Tutor } & { is_free: boolean }
    subscription?: Subscription & { status: 'active' | 'pending' | 'expired' }
    subscription_quota_count?: number
    is_group?: boolean
    group_id?: number
    signed_up_free?: boolean
    has_projects?: boolean
  }
interface StudentTableProps {
  studentsData: AdminStudent[]
  handleEditStudent: (s: Student) => void
  isLoading: boolean
  isReadOnly: boolean
}
const STATUSCOLOR = {
  active: 'green600',
  pending: 'yellow400',
  expired: 'red500',
  canceled: 'yellow400',
}

interface AssignStudentGroupModalWindowProps {
  type: string
}

export function AssignStudentGroupModalWindow({
  type,
}: AssignStudentGroupModalWindowProps) {
  const { data, closeModal } = useOpenedModal<{
    studentId: number
    slug: string
    isOffline: boolean
  }>()
  const [groupId, setGroupId] = useState()
  const qc = useQueryClient()
  return (
    <ModalWindow
      title={`${type} Student Group`}
      headerClassName="gradient-header text-center"
      hasCloseBtn
      mainButtonType="button"
      mainBtn="Confirm"
      mainButtonDisabled={!groupId}
      mainBtnClassName="py-17 px-24 font_14"
      mainBtnOnclick={() =>
        AdminAPI.put(`/students/${data?.studentId}/groups/${groupId}/reassign`)
          .then(() => {
            toast.success(`Student ${type}ed`)
            closeModal()
            qc.invalidateQueries([QueryKeys.adminStudents])
          })
          .catch(errorToast)
      }
    >
      <GroupsSelect
        label="Groups"
        labelPlacement="block"
        placeholder="Groups"
        labelClassName="form-label font-light  font_16 m-0 text-nowrap font-color-blue600 "
        value={groupId}
        onChange={(e: any) => setGroupId(e)}
        isOffline={!!data?.isOffline}
      />{' '}
    </ModalWindow>
  )
}

export function QuizEnrollmentCellStatus({ student }: { student: Student }) {
  const Status = ({ status }: { status: string }) => {
    return (
      <span className="font-color-blue600 font_14 font-normal ">{status}</span>
    )
  }
  if (!student.quiz) {
    return <Status status="Pending Email Invitation" />
  }

  if (['pending', 'pending_configuration'].includes(student.quiz.status)) {
    return <Status status="Pending Quiz" />
  }

  if (student.quiz.status == 'in_progress') {
    return <Status status="Quiz In Progress" />
  }

  if (student.quiz.score === null && student.quiz.answer_start_at) {
    student.quiz['score'] = 0
    // student.quiz['answers'] = []
  }

  if (student.quiz.accepted === true) {
    return <Status status="Accepted" />
  }

  if (student.quiz.accepted === false) {
    return <Status status="Rejected" />
  }

  if (student.quiz.answer_start_at === null) {
    return <Status status="Skipped" />
  }

  if (student.quiz.score === null && student.quiz.answer_start_at) {
    return <Status status="Pending Action" />
  }
  if (student.quiz.score !== null && student.quiz.accepted === null) {
    return <Status status="Pending Action" />
  }

  return null
}
export function QuizEnrollmentCell({
  student,
  onClick,
}: {
  student: Student
  onClick: (s: Student) => void
}) {
  if (!student.quiz) {
    return null
  }

  if (student.quiz.score === null && student.quiz.answer_start_at) {
    student.quiz['score'] = 0
    // student.quiz['answers'] = []
  }

  if (student.quiz.status != 'completed') {
    return null
  }

  if (student.quiz.score === null && student.quiz.accepted === null) {
    return <AcceptRejectQuizButtons student={student} />
  }

  if (student.quiz.score !== null) {
    return (
      <div
        className="border"
        style={{
          borderRadius: '5px',
          overflow: 'hidden',
        }}
      >
        <Button
          isBorderButton
          onClick={() => onClick(student)}
          variant={'blue600'}
          className="d-block font_12 font-normal py-2 px-12 w-100 border-0 d-flex gap-4 align-items-center"
        >
          <span className="justify-self-start">
            Score: {student.quiz?.score} / {student.quiz?.questions_count}
          </span>
          {student.quiz.is_passed && (
            <i className="fa-solid fa-circle-check font-color-green500 font_14"></i>
          )}
          {/* {differenceInMinutes(
            new Date(student?.quiz?.answer_end_at || ''),
            new Date(student?.quiz?.answer_start_at || ''),
          )}{' '} */}
          {/* min */}
        </Button>
        <AcceptRejectQuizButtons student={student} />
      </div>
    )
  }
  return null
}

export function AcceptRejectQuizButtons({ student }: { student: Student }) {
  const queryClient = useQueryClient()
  const modal = useOpenedModal()

  const onSubmit = () => {
    queryClient.invalidateQueries([QueryKeys.adminStudents])
    modal?.closeModal()
  }
  return (
    <div className="d-flex gap-1  font-normal font_12">
      {student.quiz?.accepted !== true && (
        <Button
          isBorderButton
          variant="green500"
          className=" font_14 flex-1 font-normal py-2 px-12 border-0"
          onClick={() =>
            AdminAPI.put(`students/${student.id}/quiz_status`, {
              accepted: true,
            })
              .then(onSubmit)
              .catch(errorToast)
          }
        >
          Accept
        </Button>
      )}

      {student.quiz?.accepted !== false && (
        <Button
          isBorderButton
          variant="red500"
          className=" font_14  flex-1 font-normal py-02 px-12 border-0"
          onClick={() =>
            AdminAPI.put(`students/${student.id}/quiz_status`, {
              accepted: false,
            })
              .then(onSubmit)
              .catch(errorToast)
          }
        >
          Reject
        </Button>
      )}
    </div>
  )
}

export function ViewStudentQuizModalWindow() {
  const { data: student } = useOpenedModal<Student>()

  const { data: quiz } = useQuery({
    keepPreviousData: false,
    queryFn: () =>
      AdminAPI.get<Response<Quiz>>(`/quizzes/${student?.quiz?.id}`).then(
        extractResponseData,
      ),
    enabled: !!student?.quiz?.id,
    queryKey: ['quizzes', student?.quiz?.id],
  })

  if (!student || !quiz) return null

  return (
    <ModalWindow
      title={<>{student?.name}</>}
      headerClassName="gradient-header text-center"
      hasCloseBtn
      maxWidth={1132}
      mainBtnClassName="py-17 px-71 font_24 my-4"
    >
      {!quiz && <Spinner />}
      <div className="d-flex justify-content-between mb-4 font_14 w-100">
        <div className=" font-color-300">
          <span>{student?.grade?.name} Enrollment Quiz</span>

          <span className="d-block ">
            Date: {formatDate(quiz?.start_at, 'dd MMM yyy')}
          </span>
          <span className="d-block">Time: {formatTime(quiz?.start_at)}</span>
          <span className="d-block font-color-blue300">
            Duration: {quiz?.duration} min
          </span>
        </div>

        <div className="ml-auto">
          <QuizEnrollmentCell onClick={() => {}} student={student} />
        </div>
      </div>

      <ViewQuizSubmission {...quiz} />
    </ModalWindow>
  )
}

export function ViewQuizSubmission(quiz: {
  questions: Quiz['questions']
  answers: Quiz['answers']
}) {
  return (
    <div className="">
      <div className="d-grid gap-4 ">
        {quiz?.questions.map((q, index) => {
          const studentAnswer = quiz?.answers?.find(
            (a) => a.id === q.id,
          )?.answer
          const correctAnswer = q.correct_answer
          const correctProps =
            studentAnswer === correctAnswer
              ? {
                  style: {
                    backgroundColor: '#0aba9830',
                  },
                }
              : {}

          return (
            <div
              key={q.id}
              className="font-color-blue600 quiz-question text-start my-3 p-4 rounded shadow-sm"
            >
              <div className="auto">
                {q.image && (
                  <div className="d-flex justify-content-center align-items-center mb-2">
                    <img
                      src={q.image}
                      style={{
                        width: 'auto',
                        height: '200px',
                      }}
                    />
                  </div>
                )}
                <div className="text-base d-flex flex-1 flex-column   font-bold-500 font-color-text-normal">
                  {q.question.en && (
                    <div dir="ltr" className="d-flex">
                      {q.question.en}
                    </div>
                  )}
                  {q.question.ar && (
                    <div dir="rtl" className="d-flex">
                      {q.question.ar}
                    </div>
                  )}
                </div>
              </div>
              <ul className="question-answers d-flex flex-column align-items-start">
                {(q.answers || [])
                  .filter((a) => !!a.ar || !!a.en)
                  .map((a, index) => {
                    let state:
                      | 'default'
                      | 'correct'
                      | 'wrong'
                      | 'selected'
                      | 'correction' = 'default'

                    // const studentAnswer =
                    const correctAnswerString = q.correct_answer?.en
                      ? q.correct_answer.en
                      : q.correct_answer?.ar
                    const studentAnswerString = studentAnswer?.en
                      ? studentAnswer.en
                      : studentAnswer?.ar
                    const choiceString = a.en ? a.en : a.ar

                    if (
                      studentAnswerString !== choiceString &&
                      correctAnswerString !== choiceString
                    ) {
                      state = 'default'
                    }
                    if (
                      studentAnswerString === choiceString &&
                      choiceString === correctAnswerString
                    ) {
                      state = 'correct'
                    }

                    if (
                      studentAnswerString === choiceString &&
                      choiceString !== correctAnswerString
                    ) {
                      state = 'wrong'
                    }

                    if (
                      studentAnswerString !== correctAnswerString &&
                      choiceString === correctAnswerString
                    ) {
                      state = 'correction'
                    }

                    return (
                      <QuizAnswer
                        key={index}
                        showBothAnswers
                        value={a}
                        onClick={() => {}}
                        state={state}
                        disabled={true}
                      />
                    )
                  })}
              </ul>
              {(q.hint?.en || q.hint?.ar) && (
                <div className="question-footer my-base d-flex align-items-center justify-content-end gap-lg">
                  <span className="question-hint d-inline-flex align-items-center flex-1 font-color-orange-dark bg-orange-light border border-orange-medium rounded-3xs p-xs gap-3xs">
                    <i className="fa-solid fa-lightbulb text-md" />
                    <span className="font-bold-500 text-xs">
                      {q.hint?.en || q.hint?.ar}
                    </span>
                  </span>
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
