import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import { RouteObject } from 'react-router-dom'

export const TutorHelpRoute: RouteObject = {
  path: 'help',
  lazy: async () => {
    const { default: Component } = await import(
      'pages/teacher/Help/TeacherHelp'
    )
    return { Component }
  },
  ErrorBoundary,
}
