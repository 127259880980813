import { Notification } from 'core/types/notification'
import { formatDistanceToNow } from 'date-fns'
import { formatDate } from 'utils/dateUtils'
import { useNotificationNavigation } from './useNotificationNavigation'

export function NotificationItem(
  notification: Notification & {
    showDate?: boolean
    showDistance?: boolean
    onClick?: () => void
  },
) {
  const notificationNavigate = useNotificationNavigation()
  return (
    <div
      onClick={() => {
        notificationNavigate(notification)
        notification?.onClick?.()
      }}
      className="dropdown-item d-flex gap-4 py-2 cursor-pointer"
      key={notification.id}
    >
      <div className={`flex-center`}>
        {notification.avatar_url ? <img src={notification.avatar_url} width="35" /> : <i className={`fa-solid fa-bell font-color-primary font_14`} />}
        
      </div>
      <div className="gap-1">
        <h4
          className={`font_16 font-color-blue600 ${
            notification.read ? '' : 'font-bold'
          } my-0 line-clamp-2`}
        >
          {notification.title}
        </h4>
        <p className="font_14 m-0 font-color-blue300 text-wrap">
          {notification.body}
        </p>
        {notification.showDistance && (
          <p className=" font_13 mb-0 font-color-blue700-opacity-50">
            {formatDistanceToNow(new Date(notification.created_at))}{' '}
          </p>
        )}
        {notification.showDate && (
          <p className="font_13 mb-0 font-color-blue700-opacity-50">
            {formatDate(notification.created_at, 'd MMM yyy - hh:mm a')}
          </p>
        )}
      </div>
    </div>
  )
}
