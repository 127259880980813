import { useQueryClient } from '@tanstack/react-query'
import { useAuth } from 'auth/useAuth'
import { useCallback } from 'react'
import { isProduction } from './helpers'

export function useDomain() {
  let fullUrl = window.location.host
  const { user } = useAuth()

  const qc = useQueryClient()
  const changeDevDomain = useCallback(
    (domain: string) => {
      localStorage.setItem('dev_domain', domain)

      window.location.href = '/'
    },
    [qc],
  )
  if (!isProduction) {
    const domain = localStorage.getItem('dev_domain') || 'ischool'

    if (user?.isAdmin || user?.isTutor) {
      return { domain: 'ischool', is_whitelabel: false }
    }
    return {
      changeDevDomain,
      domain,
      is_whitelabel: domain !== 'ischool',
    }
  } else {
    const domain = fullUrl.split('.')[0] || 'app'

    return {
      domain,
      is_whitelabel: domain !== 'app',
    }
  }
}
