import { OrgSchedule, TutorOrganizationSchedule } from 'core/types/User/Tutor'
import { DaysToDayId } from './sessions'
const bgcolorPalette = [
  '#E2ECFB', // Blue
  '#DBF4F3', // Teal (Lighter than #39A8A6)
  '#C9B9D8', // Purple (Lighter than #A14E95)
  '#E0F4CF', // Lime Green (Lighter than #7DC72B)
  '#FDE6B3', // Golden Yellow (Lighter than #F4B400)
  '#F1BCAF', // Reddish-Orange (Lighter than #E9573F)
]

const colorPalette = [
  '#076FEC', // Blue
  '#39A8A6', // Teal
  '#A14E95', // Purple
  '#7DC72B', // Lime Green
  '#F4B400', // Golden Yellow
  '#E9573F', // Reddish-Orange
]

const colors = bgcolorPalette.map((key, index) => ({
  bgColor: bgcolorPalette[index],
  color: colorPalette[index],
}))
export function AdjustTutorScheduleSlots(
  organizations: TutorOrganizationSchedule[],
): OrgSchedule[] {
  return organizations.map((org, index) => {
    const o = {
      schedule: (org.schedule || []).map((i) => {
        let [hour, minutes] = i.time
          .replace(' AM', '')
          .replace(' PM', '')
          .split(':')
          .map(Number)

        hour += i.time.includes('PM') && hour !== 12 ? 12 : 0

        return {
          ...i,
          day_id: DaysToDayId[i.day],
          hour,
          minutes,
          duration: i?.duration || 60,
        }
      }),
      org: {
        id: org.id,
        name: org.name,
        ...colors[index],
      },
    }

    return o
  })
}
