// import useAuth from 'auth/useAuth';
import Button from 'components/Button/Button'
import { useOpenedModal } from 'components/Modal'
import { useIsMobile } from 'components/useMediaQueries'
import { ReactNode } from 'react'

interface ModalProps {
  children: ReactNode
  title?: ReactNode
  id?: string
  mainBtn?: string
  mainBtnOnclick?: () => any
  containerClassName?: string
  headerClassName?: string
  titleClassName?: string
  footerClassName?: string
  mainBtnClassName?: string
  bodyClassName?: string
  maxWidth?: number
  unit?: 'px' | '%' | 'vw'
  hasCloseBtn?: boolean
  titleIcon?: ReactNode
  formId?: string
  mainButtonDisabled?: boolean
  mainButtonType?: 'submit' | 'reset' | 'button'
  borderRadius?: number
  contentClassName?: string
  titleFontSize?: number
  styleCloseIcon?: {
    className?: string
    size?: number
    color?: string
  }
  isMainButtonLoading?: boolean
}

export function ModalWindow({
  title,
  children,
  mainBtn,
  formId,
  mainButtonType,
  mainBtnOnclick,
  titleIcon,
  hasCloseBtn,
  maxWidth = 500,
  headerClassName = '',
  bodyClassName = '',
  mainBtnClassName = '',
  footerClassName = '',
  titleClassName = '',
  containerClassName = '',
  contentClassName = '',
  borderRadius = 8,
  titleFontSize = 20,
  mainButtonDisabled,
  unit = 'px',
  styleCloseIcon = {
    className: '',
    size: 20,
    color: 'primary',
  },
  isMainButtonLoading,
}: ModalProps) {
  const { closeModal } = useOpenedModal()
  const isMobile = useIsMobile()

  return (
    <div
      className={`modal-window ${containerClassName} border-radius-${borderRadius}`}
      style={{
        maxWidth: isMobile ? '100vw' : maxWidth + unit,
        maxHeight: isMobile ? '100vh' : '90vh',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        className={`modal-content ${contentClassName}`}
        style={{
          height: '100%',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          className={`modal-header border-b  border-color-grey-opacity-10 ${headerClassName}`}
        >
          {title && (
            <h5
              className={`modal-title d-flex align-items-center gap-2 font_${
                isMobile ? 16 : titleFontSize
              } flex-grow-1 ${titleClassName} text-capitalize`}
            >
              {titleIcon && titleIcon}
              {title}
            </h5>
          )}

          {(hasCloseBtn || (isMobile && hasCloseBtn)) && (
            <Button
              aria-label="Close"
              onClick={closeModal}
              variant={styleCloseIcon.color}
              isBorderButton
              type="button"
              className={`p-2 border-0  font_${styleCloseIcon.size} ms-auto ${styleCloseIcon.className} `}
            >
              <i className="fa-solid fa-x" />
            </Button>
          )}
        </div>

        <div
          className={`modal-body ${bodyClassName}`}
          style={{
            height: '100%',
            overflowY: 'auto',
          }}
        >
          {children}
        </div>

        {mainBtn && (
          <div className={`modal-footer border-0 mx-auto ${footerClassName}`}>
            <Button
              form={formId}
              type={mainButtonType}
              className={`rounded-pill font_16 font-semibold main-modal__main-btn gap-2 align-items-center ${mainBtnClassName}`}
              onClick={mainBtnOnclick}
              disabled={mainButtonDisabled}
              isLoading={isMainButtonLoading}
            >
              {mainBtn}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
