import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import { RouteObject } from 'react-router-dom'

export const TutorStudentsProjectsRoute: RouteObject = {
  path: 'projects',
  lazy: async () => {
    const { StudentsProjects: Component } = await import(
      'pages/teacher/StudentProjects/StudentsProjects'
    )
    return { Component }
  },

  ErrorBoundary,
}
