import { CustomFilter } from 'components/Query/filter'
import { CurriculumSelect } from 'components/Selectors/curriculum/CurriculumSelect'

export type CurriculumParamNames = {
  grade: string
  curriculum: string
  level: string
  lesson: string
}

export const defaultParamNames: CurriculumParamNames = {
  curriculum: 'curriculum',
  grade: 'grade',
  lesson: 'lesson',
  level: 'level',
}

export const CurriculumSelectFilter = (
  p = defaultParamNames,
): CustomFilter<number | undefined> => ({
  type: 'custom',
  element: (ctx) => (
    <CurriculumSelect
      {...ctx}
      value={ctx.filters?.params?.[p.curriculum]}
      onChange={(curriculum) => {
        ctx.filters?.update({
          [p.curriculum]: curriculum,
          [p.level]: undefined,
          [p.lesson]: undefined,
          [p.grade]: undefined,
        })
      }}
    />
  ),
})
