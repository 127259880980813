import { Admin, Role, Student, Tutor, User } from 'core/types/User'

export function AddUserRole<T extends User = User>(role: Role, user: T): T {
  let flags = {
    isAdmin: false,
    isStudent: false,
    isTutor: false,
  }

  if (role === 'Mentor') {
    return {
      ...user,
      ...flags,
      role: 'Mentor',
      is_mentor: true,
    }
  }

  if (role === 'Admin') {
    return {
      ...user,
      ...flags,
      role: 'Admin',
      isAdmin: true,
    }
  }

  if (role === 'Student') {
    return {
      ...user,
      ...flags,
      role: 'Student',
      isStudent: true,
    }
  }

  if (role === 'Tutor') {
    return {
      ...user,
      ...flags,
      role: 'Tutor',
      isTutor: true,
    }
  }
  return {
    ...user,
    ...flags,
  }
}

export function addStudnetRole(user: Student) {
  return AddUserRole('Student', user)
}

export function addTutorRole(user: Tutor) {
  if (user.is_mentor) {
    return AddUserRole('Mentor', user)
  } else {
    return AddUserRole('Tutor', user)
  }
}

export function addAdminRole(user: Admin) {
  return AddUserRole('Admin', user)
}
