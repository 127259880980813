import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import { RouteObject } from 'react-router-dom'

export const TutorProfileRoute: RouteObject = {
  path: 'profile',
  lazy: async () => {
    const { TeacherProfile: Component } = await import(
      'pages/teacher/Profile/TeacherProfile'
    )
    return { Component }
  },
  ErrorBoundary,
}
