import { Select } from 'components/Select/Select'
import { ReactNode } from 'react'
import { Level, useGetCurriculum } from 'services/curriculum'
import { SelectProps } from '../types'

export function LevelSelect({
  value,
  onChange,
  allLevels = false,
  showGradeName = false,
  label,
  gradeId,
  curriculumId,
}: SelectProps<number> & {
  allLevels?: boolean
  label?: ReactNode
  showGradeName?: boolean
  gradeId?: number
  curriculumId?: number
}) {
  const { data } = useGetCurriculum(curriculumId)
  let levels: Level[] | undefined = undefined
  if (allLevels && data) {
    levels = Object.values(data.levels)
  } else {
    levels = data?.levels && gradeId ? data?.grades[gradeId]?.levels : undefined
  }

  return (
    <Select
      disabled={allLevels ? !curriculumId : !gradeId}
      searchable
      label={label || 'Level'}
      value={value}
      renderTrigger={(p) => p.data.name}
      onChange={onChange}
      options={(levels || []).map((o) => ({
        value: o.id,
        data: o,
        label: (
          <span className="d-flex gap-2 align-items-center">
            {showGradeName && (
              <span className="font_12 d-block d-block font-color-blue300">
                {o.grade_name}
              </span>
            )}
            <span>{o.text}</span>
          </span>
        ),
        searchIn: [o.grade_name, o.name, o.text],
      }))}
    />
  )
}
