import { BaseFilter } from 'components/Query/filter'
import { BooleanSelect } from 'components/Selectors/base/BooleanSelect'
import { ReactNode } from 'react'

export interface BooleanSelectFilter extends BaseFilter<boolean | undefined> {
  label: string
  type: 'boolean_select'
  labels?: {
    yes: ReactNode
    no: ReactNode
  }
}

export function BooleanSelectFilter(props: BooleanSelectFilter) {
  let {
    labels = {
      yes: 'Yes',
      no: 'No',
    },
  } = props

  return <BooleanSelect {...props} labels={labels} />
}
