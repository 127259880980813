import { useQueryClient } from '@tanstack/react-query'
import InputField from 'components/Inputs/InputField'
import InputPhone from 'components/Inputs/InputPhone'
import { useOpenedModal } from 'components/Modal'
import { Select } from 'components/Select/Select'
import ActiveInactiveSelect from 'components/Selectors/ActiveInactiveSelect'
import { AdminRoleSelect } from 'components/Selectors/users/RoleSelect'
import useViewPort from 'core/hooks/useViewPort'
import { Admin } from 'core/types/User'
import { useFormik } from 'formik'
import { useOrganizationsList } from 'pages/admin/Organization/OrganizationsPage'
import { useGetRoles } from 'pages/admin/UserManage'
import { toast } from 'react-toastify'
import { createAdmin, updateAdmin } from 'services/admin/admins'
import { errorToast } from 'utils/errorToast'
import { PasswordValidation } from 'Validations/PasswordValidation'
import * as yup from 'yup'
import { MEDIA_BREAKPOINT_MD } from '../../../constants'
import { ModalWindow } from '../../Modal/ModalWindow'

function AdminUserModal() {
  const { data: roles } = useGetRoles()

  const { data: user, closeModal } = useOpenedModal<Admin>()
  const validationSchema = yup.object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().required(),
    password: PasswordValidation,
    role_id: yup.number().required(),
  })
  const queryClient = useQueryClient()

  const formik = useFormik({
    initialValues: {
      id: user?.id,
      name: user?.name ? user?.name : '',
      email: user?.email ? user?.email : '',
      password: user?.id ? undefined : '',
      role_id: user?.role_id,
      phone: user?.phone,
      organization_id: user?.organization?.id,
      active: user?.id ? user?.active : true,
      short_name: user?.short_name ? user.short_name : '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!!values.id) {
        updateAdmin(values.id, {
          ...values,
          organization_id: values.organization_id || null,
        })
          .then(() => {
            toast.success('Admin has been updated')
            queryClient.invalidateQueries(['adminUserTable'])
            closeModal()
          })
          .catch(errorToast)
      } else {
        createAdmin({
          ...values,
        })
          .then(() => {
            toast.success('Admin has been created')
            queryClient.invalidateQueries(['adminUserTable'])
            closeModal()
          })
          .catch(errorToast)
      }
    },
  })
  const { data: orgs } = useOrganizationsList()

  const { width } = useViewPort()
  const labelClassName =
    'w-25 text-nowrap font_16 font-semibold font-color-blue600'

  const inlineForm = width >= MEDIA_BREAKPOINT_MD ? true : false

  return (
    <ModalWindow
      title={!!user?.id ? 'Edit User' : 'Add User'}
      headerClassName="gradient-header text-center"
      hasCloseBtn
      maxWidth={1132}
      mainBtn="Confirm"
      mainBtnClassName="py-17 px-71 font_24 my-4"
      mainBtnOnclick={formik.submitForm}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault()
          formik.handleSubmit(e)
        }}
      >
        <div className="mb-3 ">
          <Select
            labelPlacement="inline"
            label={`Organization`}
            placeholder={'All'}
            options={orgs?.map((org) => ({
              label: org?.name,
              value: org?.id,
            }))}
            value={formik.values.organization_id}
            onChange={(org_id) =>
              formik.setFieldValue('organization_id', org_id)
            }
          />
        </div>
        <InputPhone
          isFormInline={inlineForm}
          labelClassName={labelClassName}
          label="Phone"
          setFieldValue={formik.setFieldValue}
          name="phone"
          value={formik.values.phone}
          isRequired
          error={formik.errors.phone}
          touched={formik.touched.phone}
        />

        <div className="row">
          <div className="col-12 col-lg-6">
            <InputField
              isFormInline={inlineForm}
              labelClassName={labelClassName}
              label="Name"
              isRequired
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              // error={formik.errors.name}
              // touched={formik.touched.name}
            />
          </div>
          <div className="col-12 col-lg-6">
            <InputField
              isFormInline={inlineForm}
              labelClassName={labelClassName}
              label="Email"
              isRequired
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              // error={formik.errors.email}
              // touched={formik.touched.email}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-6">
            <AdminRoleSelect
              labelPlacement="inline"
              label={`Role`}
              value={formik.values.role_id}
              onChange={(role) => formik.setFieldValue('role_id', role)}
            />
          </div>
          <div className="col-12 col-lg-6">
            <InputField
              isFormInline={inlineForm}
              labelClassName={labelClassName}
              label="Password"
              // isRequired
              name="password"
              placeholder="********"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.errors.password}
              touched={formik.touched.password}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-6">
            <ActiveInactiveSelect
              labelPlacement="inline"
              label="Status"
              value={formik.values.active}
              onChange={(active) => formik.setFieldValue('active', active)}
            />
          </div>
          <div className="col-12 col-lg-6">
            <InputField
              isFormInline={inlineForm}
              labelClassName={labelClassName}
              label="Short Name"
              name="short_name"
              value={formik.values.short_name}
              onChange={formik.handleChange}
            />
          </div>
        </div>
      </form>
    </ModalWindow>
  )
}

export default AdminUserModal
