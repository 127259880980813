import { useQueryClient } from '@tanstack/react-query'
import { Notification, NotificationType } from 'core/types/notification'
import { useCallback, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useUserChannelData } from 'socket/UserChannelProvider'
import { NotificationItem } from './NotificationItem'

function useNotificationsSideEffects() {
  const qc = useQueryClient()

  return useCallback((notification: Notification) => {
    switch (notification.navigationable_type) {
      case NotificationType.LeaveRequest:
        qc.invalidateQueries(['leaves'])
        break
      default:
        qc.invalidateQueries([])
    }
  }, [])
}

export function useUserNotificationChannelData() {
  const data = useUserChannelData('user_notification')

  const sideEffects = useNotificationsSideEffects()

  useEffect(() => {
    if (data) {
      sideEffects(data.notification)
      toast.info(<NotificationItem {...data?.notification} showDistance />, {
        position: 'top-center',
        icon: false,
        className: 'p-0',
        bodyClassName: 'p-0',
        closeButton: (
          <div className="flex-center p-2 bg-color-blue300-opacity-20">
            <i className="fa fa-times font_20 font-color-blue300" />
          </div>
        ),

        hideProgressBar: true,
      })
    }
  }, [data?.notification?.id])

  return data
}
