import ISchoolLogo from 'components/Logo/ISchoolLogo'
import { ReactNode, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { CollapseExpandButton } from './CollapseExpandButton'
import LogoutButton from './LogoutButton'
import { SideBarContext } from './NavSidebar'

export default function MobileLayout({
  header,
  sideBar,
}: {
  header?: ReactNode
  sideBar?: ReactNode
}) {
  const { collapsed, setCollapsed } = useContext(SideBarContext)

  return (
    <div
      className="relative flex-scroll flex-row overlfow-auto mobile-layout"
      style={{ height: '100vh', width: '100vw' }}
    >
      <div
        className={`backdrop bg-color-blue900-opacity-50 ${
          collapsed ? '' : 'active'
        }`}
        onClick={() => setCollapsed(true)}
      />
      <div
        className={`sidebar shadow px-4 py-3 ${collapsed ? 'collapsed' : ''}`}
      >
        <ISchoolLogo useWhiteLogo={false} />
        <div className="mt-4" />
        {sideBar}
        <div className="mt-4 border-b border-color-grey-opacity-10" />
        <LogoutButton />
      </div>
      <div className=" flex-scroll flex-column ">
        <div className="d-flex border-b px-2 gap-1  py-2 justify-content-between">
          <CollapseExpandButton />

          {header}
        </div>
        <div className="flex-scroll bg-color-primary-300 px-2">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
