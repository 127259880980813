import { useQuery } from '@tanstack/react-query'
import { useLoggedIn } from 'auth/useAuth'
import { oneDay } from 'components/Cards/ClassesCards/NextClass/useTime'
import { useIsMobile } from 'components/useMediaQueries'
import { Response } from 'core/types/Response'
import { Tutor } from 'core/types/User'
import { AdminAPI, TutorAPI } from 'services/API'
import { extractResponseData } from 'services/utils'

type LeavesSummary = {
  execuse: number
  emergency: number
  vacation: number
  sick: number
}

export function useGetTutorLeavesSummary(tutorId?: number) {
  const { loggedInUser } = useLoggedIn()
  return useQuery({
    queryFn: () =>
      (loggedInUser.isAdmin
        ? AdminAPI.get<Response<LeavesSummary>>(
            `leave_requests/balance?tutor_id=${tutorId}`,
          )
        : TutorAPI.get<Response<LeavesSummary>>('leave_requests/balance')
      ).then(extractResponseData),
    queryKey: ['leaves_summary', tutorId],
    staleTime: oneDay,
  })
}

export function TutorLeavesSummary({
  className,
  tutor: value,
}: {
  className?: string
  tutor?: Partial<Tutor>
}) {
  const { loggedInUser } = useLoggedIn()
  let tutor = loggedInUser.isTutor ? loggedInUser : value
  const { data } = useGetTutorLeavesSummary(tutor?.id)
  const isMobile = useIsMobile()

  return (
    <div className={className}>
      <span className="font-color-blue300 font-semibold ">Leaves Summary</span>
      <div
        className={`font_14 font-color-blue300 d-flex px-2 gap-2 flex-wrap ${
          isMobile ? 'flex-column' : ''
        }`}
      >
        <div className="d-flex gap-2 border rounded px-2 py-1  text-nowrap align-items-baseline">
          <div>Vacation</div>

          <div className=" font_12 ">
            {data?.vacation || 0} /{' '}
            {tutor?.employment_type === 'full_time' ? 15 : 5}
          </div>
        </div>

        <div className="d-flex gap-2 border rounded px-2 py-1  text-nowrap align-items-baseline">
          <div>Emergency</div>

          <div className=" font_12">{data?.emergency || 0} / 1</div>
        </div>

        <div className="d-flex gap-2 border rounded px-2 py-1  text-nowrap align-items-baseline">
          <div>Execuses</div>

          <div className=" font_12">{data?.execuse || 0}</div>
        </div>

        <div className="d-flex gap-2 border rounded px-2 py-1  text-nowrap align-items-baseline">
          <div>Sick</div>

          <div className=" font_12">{data?.sick || 0} / 7</div>
        </div>
      </div>
    </div>
  )
}
