enum DayId {
  Sun = 0,
  Mon = 1,
  Tue = 2,
  Wed = 3,
  Thu = 4,
  Fri = 5,
  Sat = 6,
}

type DayObject = {
  id: Day
  camelCase: string
  pascalCase: string
  shortCamel: string
  shortPascal: string
}
const Sunday: DayObject = {
  id: DayId.Sun,
  camelCase: 'sunday',
  pascalCase: 'Sunday',
  shortCamel: 'sun',
  shortPascal: 'Sun',
}

const Monday: DayObject = {
  id: DayId.Mon,
  camelCase: 'monday',
  pascalCase: 'Monday',
  shortCamel: 'mon',
  shortPascal: 'Mon',
}
const Tuesday: DayObject = {
  id: DayId.Tue,
  camelCase: 'tuesday',
  pascalCase: 'Tuesday',
  shortCamel: 'tue',
  shortPascal: 'Tue',
}

const Wednesday: DayObject = {
  id: DayId.Wed,
  camelCase: 'wednesday',
  pascalCase: 'Wednesday',
  shortCamel: 'wed',
  shortPascal: 'Wed',
}

const Thursday: DayObject = {
  id: DayId.Thu,
  camelCase: 'thursday',
  pascalCase: 'Thursday',
  shortCamel: 'thu',
  shortPascal: 'Thu',
}

const Friday: DayObject = {
  id: DayId.Fri,
  camelCase: 'friday',
  pascalCase: 'Friday',
  shortCamel: 'fri',
  shortPascal: 'Fri',
}

const Saturday: DayObject = {
  id: DayId.Sat,
  camelCase: 'saturday',
  pascalCase: 'Saturday',
  shortCamel: 'sat',
  shortPascal: 'Sat',
}

function getDayById(number: DayId) {
  return {
    [DayId.Sun]: Sunday,
    [DayId.Mon]: Monday,
    [DayId.Tue]: Tuesday,
    [DayId.Wed]: Wednesday,
    [DayId.Thu]: Thursday,
    [DayId.Fri]: Friday,
    [DayId.Sat]: Saturday,
  }[number]
}
function getDayByCamelCaseName(camelCaseName: string) {
  return {
    [Sunday.camelCase]: Sunday,
    [Monday.camelCase]: Monday,
    [Tuesday.camelCase]: Tuesday,
    [Wednesday.camelCase]: Wednesday,
    [Thursday.camelCase]: Thursday,
    [Friday.camelCase]: Friday,
    [Saturday.camelCase]: Saturday,
  }[camelCaseName]
}

const list = [Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday]

export const DayUtil = {
  getDayByCamelCaseName,
  getDayById,
  list,
}
