import { useDebouncedState } from 'core/hooks/useDebouncedState'

export function SelectSearch(props: {
  value: string
  onChange: (d: string) => void
  className?: string
  placeholder?: string
  autoFocus?: boolean
}) {
  const [search, setSearch] = useDebouncedState<string>(
    props.value,
    props.onChange,
  )
  return (
    <div
      style={{ minWidth: '210px' }}
      className={`select-option-search   ${props.className}`}
    >
      <span className="input-icon">
        <span className="fas fa-search fa-fw"></span>
      </span>
      <input
        autoFocus={props.autoFocus}
        type="text"
        placeholder={props.placeholder || 'Search'}
        value={search}
        onKeyDown={(e) => e.stopPropagation()}
        onChange={(e) => {
          setSearch(e.target.value)
          e.stopPropagation()
        }}
      />
    </div>
  )
}
