import { Admin } from 'core/types/User'
import { getAdminById, GetAdminParams, listAdmins } from 'services/admin/admins'
import { SelectProps } from '../types'
import {
  PaginatedUserSelect,
  PaginatedUserSelectProps,
} from './common/PaginatedUserSelect'
import UserSelect from './common/UserSelect'
export function AdminSelect(props: SelectProps<number> & { data?: Admin[] }) {
  return (
    <UserSelect {...props} data={props.data} label={props.label || 'Admin'} />
  )
}
export type AdminSelectProps = Partial<
  PaginatedUserSelectProps<Admin, GetAdminParams>
>

export type AdminRole =
  | 'is_team_lead'
  | 'is_sales_agent'
  | 'is_sales_owner'
  | 'is_edu_mentor'
  | 'is_ops_supervisor'
  | 'is_ops_moderator'
  | 'is_sales_owner'
  | 'is_boarding_member'
  | 'is_operation_lead'
  | 'is_operation_agent'
  | 'is_hr_agent'
  | 'is_retention_owner'

const AdminRoleLabel: { [key in AdminRole]: string } = {
  is_team_lead: 'Team Lead',
  is_edu_mentor: 'Edu. Mentor',
  is_operation_lead: 'Operation Lead',
  is_operation_agent: 'Operation Agent',
  is_ops_supervisor: 'Supervisor',
  is_boarding_member: 'Boarding Member',
  is_ops_moderator: 'Moderator',
  is_hr_agent: 'HR Agent',
  is_sales_agent: 'Sales Agent',
  is_sales_owner: 'Sales Owner',
  is_retention_owner: 'Retention Owner',
}

export function PaginatedAdminSelect(
  props: AdminSelectProps & { adminRole?: AdminRole },
) {
  const role = props.adminRole ? { [props.adminRole]: true } : {}
  const labelRole = props.adminRole ? AdminRoleLabel[props.adminRole] : 'Admin'
  return (
    <PaginatedUserSelect
      {...props}
      params={{ ...props.params, ...role }}
      querykey={['admins', 'minimal']}
      label={props.label || labelRole}
      optionsQueryFn={(p) => listAdmins({ ...p, minimal: true })}
      valueQueryFn={(id) => getAdminById(id, true)}
    />
  )
}
