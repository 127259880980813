import { Response } from 'core/types/Response'
import { Slot } from 'core/types/Slots'
import { addMinutes } from 'date-fns'
import groupBy from 'lodash.groupby'
import { StudentAPI } from 'services/API'
import { extractResponseData, toQueryString } from 'services/utils'
import { formatDate } from 'utils/dateUtils'
import { StudentGroupedSlots } from './groups'

function mapToGroupedSlots(s: Slot[]): StudentGroupedSlots[] {
  let slots = s.map((slot) => ({
    id: slot.id,
    weekday: formatDate(slot.start_at, 'EEE'),
    start_at_day: formatDate(slot.start_at, 'd'),
    start_at_month: formatDate(slot.start_at, 'MMMM'),
    start_at: slot.start_at,
    end_at: addMinutes(new Date(slot.start_at), slot.duration || 60),
  }))

  let grouped = groupBy(slots, (g) =>
    JSON.stringify({
      weekday: g.weekday,
      start_at_day: g.start_at_day,
      start_at_month: g.start_at_month,
    }),
  )
  let groupedSlots = Object.entries(grouped).map(([key, s], index) => {
    const info = JSON.parse(key) as {
      weekday: string
      start_at_day: string
      start_at_month: string
    }
    const slots = s.map((s) => ({
      id: s.id,
      start_at: s.start_at,
      end_at: s.end_at.toISOString(),
    }))
    const groupedSlot = { index, ...info, slots }
    return groupedSlot
  })
  return groupedSlots
}

export type GetStudentBookingSlotsParams = {
  booking_type?: string
  weekly?: boolean
  sessionsFrequency?: number
  language?: string
  grade?: number
  email?: string
  first_booking_id?: number
}
export function studentListSlots2({
  sessionsFrequency,
  ...params
}: GetStudentBookingSlotsParams): Promise<StudentGroupedSlots[]> {
  return StudentAPI.get<Response<Slot[]>>(
    `/slots?${toQueryString({ weekly: sessionsFrequency == 1, ...params })}`,
  )
    .then(extractResponseData)
    .then(mapToGroupedSlots)
}

export function studentListSlots(params?: {
  booking_type?: string
  language?: string
  grade?: number
  email?: string
}) {
  return StudentAPI.get<Response<Slot[]>>(
    `/slots?${params ? toQueryString(params) : ''}`,
  )
}

export function studentLockSlot(slotId: number, email?: string) {
  return StudentAPI.put<Response<Slot>>(`/slots/${slotId}/book`, { email })
}
