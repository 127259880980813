import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useLoggedIn } from 'auth/useAuth'
import { useEffect } from 'react'
import { useUserChannelData } from 'socket/UserChannelProvider'

export function useCommunityUnreadStatus() {
  const { API } = useLoggedIn()
  const socketMessage = useUserChannelData('unread_messages')
  const qc = useQueryClient()
  const query = useQuery<{ has_unread_messages: boolean }>({
    queryKey: ['has_unread_message'],
    queryFn: () =>
      API.get('/communities/has_unread_messages').then(
        (response) => response.data,
      ),
  })
  useEffect(() => {
    qc.setQueryData(['has_unread_message'], () => ({
      has_unread_messages: socketMessage?.has_unread_messages,
    }))
  }, [socketMessage])

  return {
    hasUnreadMessages: query.data?.has_unread_messages,
  }
}
