import Portal from 'components/Modal/Portal'
import { ReactNode, Ref, forwardRef } from 'react'

type PopupWindowProps = {
  children: ReactNode
  className?: string
}

export function PopupWindowComponent(
  props: PopupWindowProps,
  ref: Ref<HTMLDivElement>,
) {
  const { children, className } = props
  return (
    <Portal>
      <div
        tabIndex={0}
        autoFocus
        ref={ref}
        className={`shadow border border-bottom-0 border-top-0 flex-scroll `}
        style={{
          position: 'fixed',
          top: '100%',
          backgroundColor: 'white',
          zIndex: 99999999,
          height: 'min-content',
          overflow: 'hidden',
        }}
      >
        {children}
      </div>
    </Portal>
  )
}
export const PopupWindow = forwardRef(PopupWindowComponent)
