import Icons from './sprite.svg'
import v from '../../scss/helpers/_export-theme-variables.scss'

interface IconProps {
  iconName: string
  iconSize?: number
  className?: string
  style?: object
  iconColor?: any
  height?: number
  hasStroke?: boolean
  fillOpacity?: number
}

const Icon = ({
  iconName,
  iconSize = 20,
  className = '',
  style,
  iconColor = v.colorprimary,
  height,
  hasStroke = false,
  fillOpacity,
}: IconProps) => {
  return (
    <svg
      className={`icon icon-${iconName} ${className}`}
      width={iconSize}
      height={height ? height : iconSize}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      fill={hasStroke ? 'none' : iconColor}
      stroke={hasStroke ? iconColor : 'none'}
      fillOpacity={fillOpacity}
    >
      <use
        xlinkHref={`${Icons}#${iconName}`}
        stroke={hasStroke ? iconColor : 'none'}
      />
    </svg>
  )
}

export default Icon
