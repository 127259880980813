import { Select } from 'components/Select/Select'
import { useGetSlots } from 'components/Tables/Admin/classTable'
import { DayUtil } from 'utils/DayUtil'
import { SelectProps } from './types'

export default function SlotDaySelect(props: SelectProps<Day>) {
  const { data: slots } = useGetSlots()

  return (
    <Select
      label="Day"
      value={props.value}
      options={slots?.days.map((name) => {
        const day = DayUtil.getDayByCamelCaseName(name)
        return {
          value: day.id,
          data: day,
          label: day.pascalCase,
        }
      })}
      onChange={props.onChange}
    />
  )
}
