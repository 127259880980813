import { useLoggedIn } from 'auth/useAuth'
import { useTranslation } from 'react-i18next'
import { isArabic } from 'utils/helpers'
import { SideBarNavItem } from './SideBarNavItem'

export default function LogoutButton() {
  const { logout } = useLoggedIn()

  const { t } = useTranslation(['common'])

  return (
    <SideBarNavItem
      icon={
        <i
          className="fa fa-right-from-bracket"
          style={{ rotate: isArabic() ? '' : '180deg' }}
        />
      }
      text={t('logout')}
      tooltip={t('logout')}
      onClick={logout}
    />
  )
}
