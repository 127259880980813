import { AdminStudent } from 'components/Tables/Admin/StudentTable'
import {
  StudentParams,
  getStudents,
  getStudentsById,
} from 'services/admin/students'
import { SelectProps } from '../types'
import { PaginatedUserSelect } from './common/PaginatedUserSelect'
import UserSelect from './common/UserSelect'

export function StudentSelect(
  props: SelectProps<number> & { data?: AdminStudent[] },
) {
  return (
    <UserSelect {...props} data={props.data} label={props.label || 'Student'} />
  )
}

export function PaginatedStudentSelect(
  props: SelectProps<number> & {
    params?: StudentParams
    onUserChange?: (s?: AdminStudent) => void
  },
) {
  return (
    <PaginatedUserSelect
      {...props}
      querykey={['students', 'minimal']}
      label={props.label || 'Student'}
      onUserChange={(e) => {
        props.onUserChange?.(e as AdminStudent)
      }}
      optionsQueryFn={(p) => getStudents({ ...p, minimal: true })}
      valueQueryFn={(id) => getStudentsById(id, true)}
    />
  )
}
