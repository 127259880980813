import { ReactNode, useEffect } from 'react'
import { isArabic } from 'utils/helpers'
import { useOpenedModal } from '.'

export function getScrollbarWidth() {
  // Creating invisible container
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.overflow = 'scroll' // forcing scrollbar to appear

  document.body.appendChild(outer)

  // Creating inner element and placing it in the container
  const inner = document.createElement('div')
  outer.appendChild(inner)

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth

  // Removing temporary elements from the DOM
  outer.parentNode?.removeChild(outer)

  return scrollbarWidth
}

export default function ModalContainer({ children }: { children: ReactNode }) {
  const {
    name,
    popupContainer,
    backdropOrEscapeClose,
    isOpened,
    backdropShadow,
  } = useOpenedModal()

  const width = getScrollbarWidth()
  useEffect(() => {
    const app = document.getElementById('root')
    if (isOpened && app) {
      if (isArabic()) {
        app.style.paddingLeft = width + 'px'
      } else {
        app.style.paddingRight = width + 'px'
      }
      return () => {
        app.style.paddingRight = ''
        app.style.paddingLeft = ''
      }
    }
  }, [isOpened])

  return (
    <div
      id={name}
      ref={popupContainer}
      className={`modal-container ${isOpened ? 'open' : ''} ${
        backdropShadow ? 'backdrop-shadow' : ''
      }`}
      onClick={backdropOrEscapeClose}
    >
      {isOpened && children}
    </div>
  )
}
