import { useQuery, useQueryClient } from '@tanstack/react-query'
import InputField from 'components/Inputs/InputField'
import Switch from 'components/Inputs/Switch'
import { useOpenedModal } from 'components/Modal'
import useViewPort from 'core/hooks/useViewPort'
import { Role } from 'core/types/Role'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { createRole, listPermissions, updateRole } from 'services/admin/roles'
import { errorToast } from 'utils/errorToast'
import { MEDIA_BREAKPOINT_MD } from '../../../constants'
import { ModalWindow } from '../../Modal/ModalWindow'

function useGetPermissions() {
  return useQuery({
    queryFn: listPermissions,
    queryKey: ['adminPermissions'],
    staleTime: Infinity,
  })
}
export function AdminRoleModalWindow() {
  const { data: permissions } = useGetPermissions()

  const { data: role, closeModal } = useOpenedModal<Role>()

  const [roleName, setRoleName] = useState(role?.name ? role?.name : '')
  const [rolePermissions, setRolePermissions] = useState<any>(
    role?.permissions ? role?.permissions : {},
  )
  const queryClient = useQueryClient()

  function handleCreatingRole() {
    createRole({
      name: roleName.trim(),
      permissions: rolePermissions,
    })
      .then(() => {
        queryClient.invalidateQueries(['adminRoles'])
        toast.success('Role has been created successfully')
        closeModal()
      })
      .catch(errorToast)
  }

  function handleEditRole() {
    updateRole(role!.id, {
      id: role!.id,
      name: roleName.trim(),
      permissions: rolePermissions,
    })
      .then(() => {
        queryClient.invalidateQueries(['adminRoles'])
        toast.success('Role has been updated successfully')
        closeModal()
      })
      .catch(errorToast)
  }

  const { width } = useViewPort()
  const labelClassName =
    'w-25 text-nowrap font_16 font-semibold font-color-blue600'

  const inlineForm = width >= MEDIA_BREAKPOINT_MD ? true : false

  return (
    <ModalWindow
      title={!!role?.id ? 'Edit Role' : 'Add Role'}
      headerClassName="gradient-header text-center"
      hasCloseBtn
      maxWidth={1132}
      mainBtn="Confirm"
      bodyClassName="flex-scroll"
      mainBtnClassName="py-17 px-71 font_24 my-4 d-flex flex-column"
      mainBtnOnclick={() =>
        role?.id ? handleEditRole() : handleCreatingRole()
      }
    >
      <div className="row">
        <div className="col-12 col-lg-12">
          <InputField
            isFormInline={inlineForm}
            labelClassName={labelClassName}
            label="Name"
            isRequired
            name="name"
            value={roleName}
            onChange={(e) => setRoleName(e.target.value)}
          />
        </div>
      </div>

      <div className=" flex-scroll">
        <div
          className={
            'input-main input-main-text d-flex align-items-center gap-3'
          }
        >
          <label
            htmlFor="permissions"
            className={`form-label ${labelClassName}`}
          >
            Permissions
            {<span className="required">*</span>}{' '}
          </label>
        </div>

        <div className=" flex-scroll flex-wrap ">
          {permissions?.map((permission: any) => {
            let isPermissionChecked =
              Object.keys(rolePermissions)?.includes(permission)
            return (
              <div className="col-12 col-lg-6 d-flex">
                <div className="flex-1">
                  <Switch
                    label={permission}
                    checked={isPermissionChecked}
                    onChange={() => {
                      if (isPermissionChecked)
                        setRolePermissions((prev: any) => {
                          const { age, ...rest } = Object.fromEntries(
                            Object.entries(prev).filter(
                              ([key]) => key !== permission,
                            ),
                          )
                          return rest
                        })
                      else {
                        setRolePermissions((prev: any) => {
                          return {
                            ...prev,
                            [permission]: ['write'],
                          }
                        })
                      }
                    }}
                  />
                </div>
                {isPermissionChecked && (
                  <div className="form-check col-7">
                    <label className="form-check-label font-light font-color-blue700-opacity-60 font_14">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={rolePermissions[permission].length > 0}
                        onChange={(e) => {
                          if (e.target.checked)
                            setRolePermissions((prev: any) => {
                              return {
                                ...prev,
                                [permission]: ['write'],
                              }
                            })
                          else
                            setRolePermissions((prev: any) => {
                              return {
                                ...prev,
                                [permission]: [],
                              }
                            })
                        }}
                      />
                      Add/Edit
                    </label>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </ModalWindow>
  )
}
