import { useLoggedIn } from 'auth/useAuth'
import { Notification, NotificationType } from 'core/types/notification'
import { useNavigate } from 'react-router-dom'

export function useNotificationNavigation() {
  const { loggedInUser, dashboard } = useLoggedIn()
  const navigate = useNavigate()

  return (notification: Notification) => {
    let navLink = ''
    if (loggedInUser.isAdmin) {
      if (notification.navigationable_type === NotificationType.LeaveRequest) {
        navLink += 'leaves'
      } else if (notification.navigationable_type === NotificationType.Tutor) {
        navLink += `teachers/${notification.navigationable_id}`
      } else if (
        notification.navigationable_type === NotificationType.ExportSheet
      ) {
        navLink += 'export-center'
      }
    }

    if (loggedInUser.isTutor) {
      if (notification.navigationable_type === NotificationType.LeaveRequest) {
        navLink = 'request'
      }
    }

    if (notification.navigationable_type === NotificationType.Community) {
     navLink += `community?channel=${notification.navigationable_id}&messageId=${notification.notificationable_id}`
    }

    if (navLink) {
      navigate(dashboard + '/' + navLink)
    }
  }
}
