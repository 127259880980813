import { FromDateFilter } from 'components/Query/Filters/Basic/FromDateFilter'
import { ToDateFilter } from 'components/Query/Filters/Basic/ToDateFilter'
import { LabelWithIcon } from 'components/Query/Filters/Extended'
import { useResource } from 'components/Query/useResource'
import { Select } from 'components/Select/Select'
import { SelectProps } from 'components/Selectors/types'
import SupportTable from 'components/Tables/Admin/SupportTable'
import { AdminPage } from 'layout/AdminDashboard'
import { getAdminSupportTickets } from 'services/admin/support'
import { useGetAdminSupportTopics } from './SupportTopics'

function SupportTopicSelect(
  props: { supportable_type: 'Student' | 'Tutor' } & SelectProps<number>,
) {
  const { data } = useGetAdminSupportTopics()

  return (
    <Select
      {...props}
      label={'Topic'}
      options={data?.data
        ?.filter((t) => t.user_type === props.supportable_type)
        .map((t) => ({ label: t.name, value: t.id }))}
    />
  )
}

export function UserTypeSelect({
  allowed = ['Student', 'Tutor', 'Admin'],
  ...props
}: SelectProps<string> & {
  allowed?: string[]
}) {
  return (
    <Select
      {...props}
      label={'Type'}
      options={[
        {
          value: 'Admin',
          label: <LabelWithIcon faIcon="fa fa-user-tie">Admin</LabelWithIcon>,
        },
        {
          value: 'Student',
          label: (
            <LabelWithIcon faIcon="fa fa-user-graduate">Student</LabelWithIcon>
          ),
        },
        {
          value: 'Tutor',
          label: (
            <LabelWithIcon faIcon="fa fa-chalkboard-user">
              Teacher
            </LabelWithIcon>
          ),
        },
      ].filter((options) => allowed.includes(options.value))}
    />
  )
}

function useAdminSupportQuery() {
  return useResource({
    queryFn: getAdminSupportTickets,
    name: 'adminTickets',
    initialParams: {
      supportable_type: 'Student',
    },
    filters: {
      supportable_type: {
        type: 'custom',
        element: (ctx) => (
          <UserTypeSelect
            {...ctx}
            allowed={['Student', 'Tutor']}
            onChange={(type) =>
              ctx.filters?.update({
                supportable_type: type,
                support_topic_id: undefined,
              })
            }
          />
        ),
        pinned: true,
        clearable: false,
      },
      support_topic_id: {
        type: 'custom',
        element: (p) => (
          <SupportTopicSelect
            {...p}
            supportable_type={p.filters!.params!.supportable_type}
          />
        ),
      },
      created_at_from: FromDateFilter({ toDateFilterName: 'created_at_to' }),
      created_at_to: ToDateFilter({ fromDateFilterName: 'created_at_from' }),
      status: {
        type: 'select',
        label: 'Status',
        options: [
          { label: 'In Progress', value: 'in_progress' },
          { label: 'Pending', value: 'pending' },
          { label: 'Completed', value: 'completed' },
        ],
      },
    },
  })
}

const Support = () => {
  const query = useAdminSupportQuery()
  // Filter

  return (
    <AdminPage title="Support" query={query}>
      {/* @ts-ignore */}
      <SupportTable {...query} />
    </AdminPage>
  )
}

export default Support
