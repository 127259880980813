import { RefObject } from '@fullcalendar/core/preact'
import { useEffect, useState } from 'react'

export const useResizeObserver = (ref: RefObject<HTMLElement>) => {
  const [size, setSize] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const element = ref.current
    if (!element) return

    const resizeObserver = new ResizeObserver((entries) => {
      if (entries.length === 0) return
      const entry = entries[0]
      setSize({
        width: entry.contentRect.width,
        height: entry.contentRect.height,
      })
    })

    resizeObserver.observe(element)

    return () => {
      resizeObserver.unobserve(element)
    }
  }, [ref])

  return size
}
