interface SwitchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  values?: any
  label?: string
  defaultSelected?: any
  inputClassName?: string
  labelClassName?: string
  containerClassName?: string
  id?: string
  styleLabel?: {
    size?: number
    color?: string
    weight?: string
    verticalAlign?: string
    marginStart?: number
  }
}

const Switch = ({
  containerClassName = '',
  inputClassName = '',
  id,
  labelClassName = '',
  label,
  styleLabel = {
    size: 14,
    color: 'blue700-opacity-60',
    weight: 'medbold',
    verticalAlign: 'text-top',
    marginStart: 2,
  },
  ...props
}: SwitchProps) => {
  return (
    <div className={`form-check form-switch input-main  ${containerClassName}`}>
      <input
        {...props}
        className={`form-check-input ${inputClassName}`}
        type="checkbox"
        role="switch"
      />
      {label && (
        <label
          className={`idm-check-label font_${styleLabel.size} font-color-${styleLabel.color} ms-${styleLabel.marginStart} font-${styleLabel.weight} align-${styleLabel.verticalAlign} ${labelClassName}`}
          htmlFor={id}
        >
          {' '}
          {label}{' '}
        </label>
      )}
    </div>
  )
}

export default Switch
