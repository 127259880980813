import Button from 'components/Button/Button'
import { ModalWindow } from 'components/Modal/ModalWindow'

const ConfirmationModal = ({
  confirmationMessage,
  onSubmit,
}: {
  confirmationMessage: string
  onSubmit: () => any
}) => {
  return (
    <ModalWindow maxWidth={1000} title={confirmationMessage}>
      <div className="d-flex justify-content-end">
        <Button
          className={`rounded-pill mt-2 font_14 font-semibold main-modal__main-btn `}
          onClick={onSubmit}
        >
          Confirm
        </Button>
      </div>
    </ModalWindow>
  )
}

export default ConfirmationModal
