import { PopupWindow } from 'components/Select/PopupWindow'
import { usePopup } from 'components/Select/utils/usePopup'
import { ReactNode, RefObject } from 'react'
import { Link } from 'react-router-dom'

interface Item {
  href: string
  id: string | number
  title: string
}

interface DropdownProps {
  label: string | ReactNode
  dropdownBtnClassName?: string
  hasOpenIcon?: boolean
  items?: Item[]
  children?: ReactNode
  dropdownMenuClassName?: string
  containerClassName?: string
  onClick?: () => void
}

const Dropdown = ({
  label,
  dropdownBtnClassName = '',
  hasOpenIcon,
  items = [],
  children,
  dropdownMenuClassName,
  containerClassName,
  onClick,
}: DropdownProps) => {
  return (
    <div className={`dropdown  ${containerClassName}`}>
      <button
        className={`${dropdownBtnClassName}  dropdown-toggle d-flex align-items-center gap-2`}
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={onClick}
      >
        <span>{label}</span>
        {hasOpenIcon && <i className="fa-solid fa-chevron-down" />}
      </button>

      <ul
        className={`dropdown-menu shadow overflow-auto ${dropdownMenuClassName}`}
        style={{ maxHeight: '60vh', backgroundClip: 'border-box' }}
      >
        {items.map((item) => (
          <li key={item.id}>
            <Link className="dropdown-item" to={item.href}>
              {item.title}
            </Link>
          </li>
        ))}
        {children}
      </ul>
    </div>
  )
}

export function DropDown({
  trigger,
  placement = 'bottom-right',
  children,
}: {
  placement?: 'bottom-left' | 'bottom-right'
  trigger: (p: {
    toggle: () => void
    triggerRef: RefObject<HTMLButtonElement>
  }) => ReactNode
  children: ReactNode | ((p: { toggle: () => void }) => ReactNode)
}) {
  const Popup = usePopup(placement)
  return (
    <>
      {trigger({ toggle: Popup.toggle, triggerRef: Popup.triggerRef })}

      {Popup.opened && (
        <PopupWindow
          ref={Popup.popupRef}
          className={`${Popup.className} rounded my-1 `}
        >
          {typeof children === 'function'
            ? children({ toggle: Popup.toggle })
            : children}
        </PopupWindow>
      )}
    </>
  )
}

export default Dropdown
