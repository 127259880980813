import StudentAutoLogin from 'pages/public/StudentAutoLogin'
import { RouteObject } from 'react-router-dom'
import { defRoute } from './utils/defRoute'

export const PublicRoutes: RouteObject[] = [
  defRoute('login'),
  {
    path: 'auto_login',
    element: <StudentAutoLogin />,
  },
  {
    path: 'login',
    lazy: async () => {
      const { StudentLoginPassword: Component } = await import(
        'pages/public/StudentLoginPassword'
      )
      return { Component }
    },
  },
  {
    path: 'login_otp',
    lazy: async () => {
      const { StudentLoginOTP: Component } = await import(
        'pages/public/StudentLoginOTP'
      )
      return { Component }
    },
  },
  {
    path: 'login_password',
    lazy: async () => {
      const { StudentLoginPassword: Component } = await import(
        'pages/public/StudentLoginPassword'
      )
      return { Component }
    },
  },
  {
    path: 'invitation',
    lazy: async () => {
      const { StudentInvitation: Component } = await import(
        'pages/public/StudentInvitation'
      )
      return { Component }
    },
  },
  {
    path: 'login/tutor',
    lazy: async () => {
      const { TutorLogin: Component } = await import('pages/public/TutorLogin')
      return { Component }
    },
  },
  {
    path: 'login/admin',
    lazy: async () => {
      const { AdminLogin: Component } = await import('pages/public/AdminLogin')
      return { Component }
    },
  },
  {
    path: 'signup',
    lazy: async () => {
      const { SignUp: Component } = await import('pages/public/SignUp')
      return { Component }
    },
  },
  {
    path: 'signup/success',
    lazy: async () => {
      const { SignUpSuccess: Component } = await import(
        'pages/public/SignUpSuccess'
      )
      return { Component }
    },
  },
  {
    path: 'forget-password',
    lazy: async () => {
      const { ForgetPassword: Component } = await import(
        'pages/public/ForgetPassword'
      )
      return { Component }
    },
  },
  {
    path: 'reset-password',
    lazy: async () => {
      const { ResetPassword: Component } = await import(
        'pages/public/ResetPassword'
      )
      return { Component }
    },
  },
  {
    path: 'stripe/callback',
    lazy: async () => {
      const { StripeCallback: Component } = await import(
        'pages/public/StripeCallback'
      )
      return { Component }
    },
  },
]
