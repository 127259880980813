import { addTutorRole } from 'auth/AddUserRole'
import { Response } from 'core/types/Response'
import { Tutor } from 'core/types/User'
import { TutorAPI } from 'services/API'
import { extractResponseData } from 'services/utils'
import { AdjustTutorScheduleSlots } from './schedule'

export function tutorLogin(email: string, password: string) {
  return TutorAPI.post<Response<Tutor>>('/login', {
    email,
    password,
  })
    .then(extractResponseData)
    .then(addTutorRole)
    .then((t) => {
      return {
        ...t,
        orgSchedules: AdjustTutorScheduleSlots(t.organizations),
      }
    })
}

export function getMyTutorProfile() {
  return TutorAPI.get<Response<Tutor>>('/profile')
    .then(extractResponseData)
    .then(addTutorRole)
    .then((t) => {
      return {
        ...t,
        orgSchedules: AdjustTutorScheduleSlots(t.organizations),
      }
    })
}
