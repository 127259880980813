import { BooleanSelectFilter } from './Filters/Basic/BooleanSelectFilter'
import { SearchFilter } from './Filters/Basic/SearchFilter'
import { SelectFilter } from './Filters/Basic/SelectFilter'
import { CustomFilter, Filter } from './filter'

function isCustomFilter(filter: Filter<any>): filter is CustomFilter<any, any> {
  return filter.type === 'custom'
}

export default function FilterRenderer({
  className,
  onPinnedChange,
  ...filter
}: Filter<any> & {
  name: string
  className?: string
  onPinnedChange: (p: boolean) => void
}) {
  return (
    <>
      {isCustomFilter(filter) && filter.element(filter)}

      {filter.type === 'search' && (
        <SearchFilter
          {...(filter as SearchFilter)}
          className="border rounded"
        />
      )}

      {filter.type === 'boolean_select' && (
        <BooleanSelectFilter {...(filter as BooleanSelectFilter)} />
      )}

      {filter.type === 'select' && (
        <SelectFilter {...(filter as SelectFilter)} name={filter.name} />
      )}

      {/* {filter.type === 'date' && <DateSelect {...filter} />} */}
    </>
  )
}
