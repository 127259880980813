import { useEffect, useState } from 'react'

export const oneSecond = 1000
export const oneMinute = 60 * oneSecond
export const oneHour = 60 * oneMinute
export const oneDay = 24 * oneHour

export function useTime(delay = oneSecond, enabled = true) {
  const [elapsed, setElapsed] = useState<number>(0)

  useEffect(() => {
    if (!enabled) return
    const interval = setInterval(() => {
      setElapsed((t) => t + delay)
    }, delay)

    return () => clearInterval(interval)
  }, [enabled])

  return { elapsed, now: new Date().getTime() }
}
