import { AdminRoute } from '.'

export const AdminTraineesRoute: AdminRoute = {
  organization_scoped: true,
  permission: 'Trainees',
  link: 'trainees',
  children: [
    {
      path: '',
      lazy: async () => {
        const { default: Component } = await import('pages/admin/Trainees')
        return { Component }
      },
    },
    {
      path: ':teacherId',
      lazy: async () => {
        const { default: Component } = await import(
          'pages/admin/Tutors/TutorPage'
        )
        return { Component }
      },
    },
  ],
}
