import { useAdminTabPermissions } from 'auth/useAdminTabPermissions'
import Button from 'components/Button/Button'
import { SelectSearch } from 'components/Select/SelectSearch'
import Table, { ViewTableProps } from 'components/Table/Table'
import { Role } from 'core/types/Role'
import { useState } from 'react'
import { formatDate } from 'utils/dateUtils'

interface User {
  id: number
  name: string
  grade: string
  email: string
  status: string
  date: string
  role: string
}

const RoleMangeTable = (
  props: ViewTableProps<Role> & { handleEditRole: (role?: Role) => void },
) => {
  const { readOnly } = useAdminTabPermissions('User Manage')
  const [search, setSearch] = useState('')
  const filtered = props.list?.filter((role) =>
    role.name.toLowerCase().includes(search.toLowerCase()),
  )
  return (
    <>
      <div className="bg-white px-3 py-3">
        <div style={{ maxWidth: '200px' }}>
          <SelectSearch
            className="border rounded font_14"
            onChange={setSearch}
            value={search}
          />
        </div>
      </div>
      <Table
        {...props}
        list={filtered}
        columns={['Name', 'Date', { header: 'Edit', hidden: readOnly }]}
      >
        {(data) => [
          data?.name,
          formatDate(data.created_at, 'dd/MM/yyyy'),
          <Button
            variant="primary600"
            className=" font_14 font-normal py-02 px-12"
            label="Edit"
            isBorderButton
            onClick={() => props.handleEditRole(data)}
          />,
        ]}
      </Table>
    </>
  )
}

export default RoleMangeTable
