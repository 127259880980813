import { useLoggedIn } from 'auth/useAuth'
import { Tutor } from 'core/types/User'
import { TutorFilters, getAllTutors, getTutorById } from 'services/admin/tutors'
import { SelectProps } from '../types'
import { PaginatedUserSelect } from './common/PaginatedUserSelect'
import UserSelect from './common/UserSelect'

export function TutorSelect(props: SelectProps<number> & { data?: Tutor[] }) {
  return (
    <UserSelect {...props} data={props.data} label={props.label || 'Teacher'} />
  )
}

export function PaginatedTutorSelect(
  props: SelectProps<number> & { params: TutorFilters },
) {
  const { loggedInUser } = useLoggedIn()
  if (!loggedInUser.isAdmin) return null
  return (
    <PaginatedUserSelect
      clearable={props.clearable}
      className={props.className}
      querykey={['tutors', 'minimal']}
      value={props.value}
      label={props.label || 'Teacher'}
      onChange={props.onChange}
      optionsQueryFn={(p) => getAllTutors({ ...p, minimal: true })}
      valueQueryFn={(id) => getTutorById(id, true)}
      params={props.params}
    />
  )
}
