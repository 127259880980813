import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import { RouteObject } from 'react-router-dom'

export const TutorCommunityRoute: RouteObject = {
  path: 'community',
  lazy: async () => {
    const { default: Component } = await import('pages/student/Communities')
    return { Component }
  },
  ErrorBoundary,
}
