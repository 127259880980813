import Icon from 'components/Icon/Icon'
import v from 'scss/helpers/_export-theme-variables.scss'

export default function FilterIcon({
  color = 'white',
}: {
  color?: 'orange' | 'primary' | 'white' | 'grey'
}) {
  return (
    <Icon
      iconName="filter-filled"
      iconSize={20}
      iconColor={
        color === 'white'
          ? v.colorwhite
          : color === 'orange'
          ? v.colororange500
          : v.primary500
      }
      className=" font-color-orange500"
    />
  )
}
