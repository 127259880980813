import { RouteObject } from 'react-router-dom'
import { defRoute } from '../utils/defRoute'
import { TutorClassesRoute } from './TutorClassesRoute'
import { TutorCommunityRoute } from './TutorCommunityRoute'
import { TutorHelpRoute } from './TutorHelpRoute'
import { TutorLMSRoute } from './TutorLMSRoute'
import { TutorNotificationRoute } from './TutorNotificationRoute'
import { TutorProfileRoute } from './TutorProfileRoute'
import { TutorRequestRoute } from './TutorRequestRoute'
import { TutorStudentsProjectsRoute } from './TutorStudentsProjectsRoute'

export const TutorRoutes: RouteObject[] = [
  defRoute('my-classes'),
  TutorClassesRoute,
  TutorStudentsProjectsRoute,
  TutorLMSRoute,
  TutorProfileRoute,
  TutorRequestRoute,
  TutorHelpRoute,
  TutorCommunityRoute,
  TutorNotificationRoute,
]
