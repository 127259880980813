import { ReactNode, useEffect, useState } from 'react'
import { SideBarContext } from './NavSidebar'

export default function SideBarContextProvider({
  children,
}: {
  children: ReactNode
}) {
  // Retrieve the collapsed state from localStorage, defaulting to false if not present
  const [collapsed, setCollapsed] = useState(() => {
    const storedCollapsed = localStorage.getItem('sidebar-collapsed')
    return storedCollapsed ? JSON.parse(storedCollapsed) : false
  })

  // Update localStorage whenever the collapsed state changes
  useEffect(() => {
    localStorage.setItem('sidebar-collapsed', JSON.stringify(collapsed))
  }, [collapsed])

  return (
    <SideBarContext.Provider value={{ collapsed, setCollapsed }}>
      {children}
    </SideBarContext.Provider>
  )
}
