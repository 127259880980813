import { Feedback } from 'core/types'
import { PaginatedResponse, Response } from 'core/types/Response'
import { AdminGroupSession, AdminSession, Session } from 'core/types/session'
import { AdminAPI } from 'services/API'
import { GetSessionsRequestQP, InternalFeedback } from 'services/tutor/sessions'
import { extractResponseData, pgQueryString } from 'services/utils'

export function getAdminSessions({
  organization_id,
  ...params
}: GetSessionsRequestQP) {
  return AdminAPI.get<PaginatedResponse<AdminSession>>(
    `sessions${pgQueryString(params)}`,
    {
      headers: organization_id
        ? { 'Organization-Id': organization_id }
        : undefined,
    },
  ).then((r) => r.data)
}

export function getAdminGroupSessions(params: GetSessionsRequestQP) {
  return AdminAPI.get<PaginatedResponse<AdminGroupSession>>(
    `group_sessions${pgQueryString(params)}`,
  ).then((r) => r.data)
}

export function adminSubmitStudentFeedback(data: {
  student_feedback: Feedback[]
  student_feedback_comment: string
  is_student_absent: boolean
  sessionId: number
}) {
  const { sessionId, ...body } = data
  return AdminAPI.put<Response<Session>>(`sessions/${sessionId}`, body).then(
    extractResponseData,
  )
}
export function adminSubmitTutorReport(data: {
  internal_feedback: InternalFeedback
  sessionId: number
}) {
  const { sessionId, ...body } = data
  return AdminAPI.put<Response<Session>>(`sessions/${sessionId}`, body).then(
    extractResponseData,
  )
}
