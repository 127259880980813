import { useEffect, useRef, useState } from 'react'

export function useDebouncedState<T>(
  value: T,
  onChange?: (v: T) => void,
  delay = 200,
) {
  const [state, setState] = useState<T>(value)
  const lastEmitted = useRef(value)

  useEffect(() => {
    if (value !== lastEmitted.current) {
      lastEmitted.current = value
      setState(value)
    }
  }, [value])

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        if (lastEmitted.current != state) {
          onChange && onChange(state)
          lastEmitted.current = state
        }
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [state, delay], // Only re-call effect if value or delay changes
  )

  return [state, setState] as const
}
