import { User } from '@sentry/react'

export default function UserOption({ user }: { user: User }) {
  return (
    <div className="">
      <div className="d-flex gap-2 font_14 font-color-blue600">
        <span>{user.name}</span>
      </div>
      <span className="d-block font_12 font-color-blue300">
        <span>{user.s_id || user.t_id || user.id}</span>
      </span>
      <span className="d-flex font_12 font-color-blue300">{user.email}</span>
    </div>
  )
}
