import { animated, useTransition } from '@react-spring/web'
import { ActionButtonProp } from 'components/Admin/HeaderPage_v2'
import Button from 'components/Button/Button'
import { useCollection } from 'components/useCollection'
import useMediaQueries from 'components/useMediaQueries'
import { useAdminTableColumns } from 'layout/AdminDashboard'
import { Fragment, ReactNode, isValidElement, useEffect, useRef } from 'react'
import { CommonParams } from 'services/utils'
import { Spinner } from 'utils/Spinner'
import { ResourceItem } from 'utils/usePaginatedResource'
import { QueryFiltersType } from '../Provider/QueryProvider'
import { QueryResource } from '../useResource'
import { ItemMobileCard } from './ItemMobileCard'
import { NoDataTableRow } from './NoDataTableRow'
import { TableHeader } from './TableHeader'

type QueryParams = Partial<{ [key: string]: any } & CommonParams>

export type CellProp = {
  cell: ReactNode
  className?: string
  colSpan?: number
  width?: string
  text?: 'start' | 'center' | 'end'
  align?: 'top' | 'baseline' | 'bottom'
  header: string
}

export type HeaderProp<T = any> = {
  header: string
  subheader?: ReactNode
  hidden?: boolean
  config_hidden?: boolean
  colSpan?: number
  width?: string
  className?: string
  text?: 'start' | 'center' | 'end'
  align?: 'top' | 'baseline' | 'bottom'
  sortKey?: string
  sortValue?: string
  index?: number
  onSortClick?: () => void
  cell?: (d: T) => void
}

export type TableProps<T extends ResourceItem, P extends QueryParams> = {
  columns: (HeaderProp | string)[]
  fullColSpan?: number
  children: (d: T) => any | ReactNode | ReactNode[] | CellProp[]
  containerClassName?: string
  selectable?: boolean
  SelectedC?: ReturnType<typeof useCollection<T>>
  singleSelect?: boolean
  selected?: T[]
  onSelectChange?: (d: T[]) => void
  expandable?: boolean
  canExpand?: (d: T) => boolean
  expanded?: (d: T) => ReactNode
  canClick?: (d: T) => boolean
  onClick?: (d: T) => void
  selectOnRowClick?: boolean
  placeholder?: ReactNode
  maxHeight?: string
  query: QueryResource<P, T>
  actions?: ActionButtonProp[]
  showPagination?: boolean
  expandOnRowClick?: boolean
}

export type ViewTableProps<
  T extends ResourceItem,
  P extends QueryParams,
> = Omit<TableProps<T, P>, 'children' | 'columns'> & QueryFiltersType<P, T>

function getCells<T>(
  item: T,
  columns: { header: string }[],
  children: (d: T) => (ReactNode | CellProp)[],
): CellProp[] {
  let result: (ReactNode | CellProp)[] = children(item)

  return result?.map((cell: any, index) => {
    if (!cell) {
      // if cell is undefined | null | ''
      return { cell: null, header: columns[index].header }
    }
    if (Object.hasOwn(cell, 'cell')) {
      return { ...cell, header: columns[index].header } as CellProp
    } else {
      // if cell is a valid react element JSX or string
      return { cell, header: columns[index].header } as CellProp
    }
  })
}

function DataToCells<T>({
  list,
  cellsRenderer,
  columns,
}: {
  list?: T[]
  cellsRenderer: (d: T) => (ReactNode | CellProp)[]
  columns: { header: string }[]
}) {
  return list?.map((item) => {
    const cells = getCells(item, columns, cellsRenderer)
    return { cells, data: item }
  })
}
function ListToTableCells<T>({
  list,
  cellsRenderer,
  children,
  columns,
}: {
  list?: T[]
  children: (cells: CellProp[], item: T) => ReactNode
  cellsRenderer: (d: T) => (ReactNode | CellProp)[]
  columns: { header: string }[]
}) {
  return (
    <>
      {list?.map((item) => {
        const cells = getCells(item, columns, cellsRenderer)
        if (isValidElement(cells)) return cells
        else return children(cells as CellProp[], item)
      })}
    </>
  )
}
export default function QueryTable<
  T extends ResourceItem,
  P extends QueryParams = QueryParams,
>(props: TableProps<T, P>) {
  const {
    columns,
    children,
    onSelectChange,
    maxHeight,
    selectable,
    expandable,
    singleSelect,
    fullColSpan = 30,
    canClick,
    canExpand,
    containerClassName,
    onClick,
    expanded,
    selectOnRowClick,
    placeholder,
    SelectedC,
    expandOnRowClick,
    query,
  } = props
  const { list, filters, Pagination, isFetching, isLoading } = query!

  const { md_down: isMobile } = useMediaQueries()

  const ExpandedC = useCollection<T>([])

  const { hiddenColumns, setAvailableColumns } = useAdminTableColumns()

  let headers: HeaderProp[] = columns.map((header) =>
    typeof header === 'string' ? ({ header } as HeaderProp) : header,
  )
  const tableColumns = headers.filter((h) => !h.hidden).map((h) => h.header)

  useEffect(() => {
    setAvailableColumns?.(tableColumns)
  }, [tableColumns.toString()])

  let dataCells = DataToCells({
    columns: headers,
    cellsRenderer: children,
    list,
  })

  if (isMobile) {
    return (
      <>
        <div
          className="mt-2 grid gap-2"
          style={{
            opacity: isFetching ? '0.5' : '1',
          }}
        >
          {isLoading && <Spinner />}
          {dataCells?.map((row) => (
            <ItemMobileCard cells={row.cells} headers={headers} />
          ))}
          <ListToTableCells
            list={list}
            columns={headers}
            cellsRenderer={children}
          >
            {(cells) => (
              <ItemMobileCard cells={cells as CellProp[]} headers={headers} />
            )}
          </ListToTableCells>
        </div>
        {Pagination}
      </>
    )
  }

  return (
    <>
      <div
        className={`table-responsive border bg-white position-relative border-color-grey-opacity-10  rounded p-0 m-0 `}
      >
        <table
          className={`table bg-white table-main text-start  `}
          style={{
            overflow: 'auto',
          }}
        >
          <thead>
            <tr>
              {expandable && (
                <th style={{ position: 'sticky', top: 0, width: '50px' }}></th>
              )}
              {selectable && (
                <th style={{ position: 'sticky', top: 0, width: '50px' }}>
                  {!singleSelect && (
                    <TableRowSelect
                      checked={!!SelectedC?.currentPageExists()}
                      onClick={() => SelectedC?.toggleCurrentPage()}
                    />
                  )}
                </th>
              )}
              {headers.map((header, index) =>
                header.hidden ||
                hiddenColumns?.includes(header.header) ? null : (
                  <TableHeader
                    onSortClick={() =>
                      filters?.update({
                        page: 1,
                        sort:
                          header.sortKey?.replaceAll('-', '') ===
                          filters?.params.sort?.replaceAll('-', '')
                            ? filters?.params.sort?.includes('-')
                              ? filters?.params.sort?.replace('-', '')
                              : `-${filters?.params.sort}`
                            : header.sortKey,
                      } as Partial<P>)
                    }
                    sortValue={
                      header.sortKey?.replaceAll('-', '') ===
                      filters?.params.sort?.replaceAll('-', '')
                        ? filters?.params.sort
                        : undefined
                    }
                    key={index}
                    {...header}
                    index={index}
                  />
                ),
              )}
            </tr>
          </thead>

          <tbody
            className="text-start"
            style={{
              height: '100%',
              overflow: 'auto',
              opacity: isFetching ? '0.5' : '1',
            }}
          >
            {isLoading && (
              <tr>
                <td colSpan={30} className="text-center border-0 p-0">
                  <div className="mt-4">
                    {placeholder ? placeholder : <Spinner />}
                  </div>
                </td>
              </tr>
            )}

            {dataCells?.map(({ cells, data }) => (
              <Fragment key={data.id}>
                <tr
                  key={data.id}
                  onClick={() => {
                    if (onClick) {
                      if (canClick === undefined || canClick(data))
                        onClick(data)
                    }

                    if (selectOnRowClick) {
                      singleSelect
                        ? SelectedC?.addOneRemoveAll(data)
                        : SelectedC?.toggleItem(data)
                    }

                    if (expandOnRowClick) {
                      if (
                        canExpand === undefined ||
                        (canExpand && canExpand(data))
                      ) {
                        ExpandedC.toggleItemRemoveAll(data)
                      }
                    }
                  }}
                  className={`${
                    (onClick && (canClick === undefined || canClick(data))) ||
                    selectOnRowClick ||
                    expandOnRowClick
                      ? 'cursor-pointer tr-click'
                      : ''
                  }`}
                >
                  {expandable && (
                    <td width={'5%'}>
                      <button
                        disabled={
                          canExpand === undefined
                            ? false
                            : !(canExpand && canExpand(data))
                        }
                        onClick={() => ExpandedC.toggleItemRemoveAll(data)}
                      >
                        {ExpandedC.exists(data) && (
                          <i className="fa-solid fa-chevron-up"></i>
                        )}
                        {!ExpandedC.exists(data) && (
                          <i className="fa-solid fa-chevron-down"></i>
                        )}
                      </button>
                    </td>
                  )}

                  {selectable && (
                    <td className="align-top" style={{ width: '50px' }}>
                      <TableRowSelect
                        checked={!!SelectedC?.exists(data)}
                        onClick={() =>
                          singleSelect
                            ? SelectedC?.addOneRemoveAll(data)
                            : SelectedC?.toggleItem(data)
                        }
                      />
                    </td>
                  )}
                  {cells.map((cell, index) =>
                    headers[index]?.hidden ||
                    hiddenColumns?.includes(cell.header) ? null : (
                      <TableData key={index} {...headers[index]} {...cell} />
                    ),
                  )}
                </tr>

                {expandable && (
                  <ExpandRow isOpen={ExpandedC.exists(data)}>
                    {expanded && expanded(data)}
                  </ExpandRow>
                )}
                {/* {expandable && ExpandedC.exists(data) && (
                  <tr>
                    <td
                      colSpan={fullColSpan}
                      className="custom bg-color-gray200 text-start"
                    >
                      {expanded && expanded(data)}
                    </td>
                  </tr>
                )} */}
              </Fragment>
            ))}

            {list?.length === 0 && <NoDataTableRow colSpan={fullColSpan} />}
          </tbody>
        </table>
      </div>
      {props.showPagination && query.Pagination}
    </>
  )
}

function ExpandRow({
  isOpen,
  children,
}: {
  isOpen: boolean
  children: ReactNode
}) {
  // In your component:
  const transitions = useTransition(isOpen, {
    from: {
      opacity: 0,
      maxHeight: '0px',
      transform: 'translateY(-10px)',
    },
    enter: {
      opacity: 1,
      maxHeight: '800px', // Set this to a value larger than your content
      transform: 'translateY(0px)',
    },
    leave: {
      opacity: 0,
      maxHeight: '0px',
      transform: 'translateY(-10px)',
    },
    config: {
      tension: 200,
      friction: 20,
      bounce: 0,
    },
  })
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (ref.current) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [ref.current, isOpen])

  return (
    <>
      {transitions(
        (style, show) =>
          show && (
            <tr className="expandable-row">
              <td
                colSpan={30}
                className="custom bg-color-gray200 text-start"
                style={{ padding: 0 }}
              >
                <animated.div
                  style={{ ...style, overflow: 'auto' }}
                  className="tiny-scrollbar"
                >
                  {children}
                </animated.div>
              </td>
            </tr>
          ),
      )}
    </>
  )
}

function TableData(props: CellProp) {
  const {
    cell,
    align = 'top',
    className,
    colSpan,
    text = 'start',
    width,
    header,
  } = props

  return (
    <td
      className={`font-color-blue600 font-normal font_14 text-${text} align-${align} ${className}`}
      colSpan={colSpan}
      width={width}
    >
      {cell}
    </td>
  )
}

function TableRowSelect({
  checked,
  onClick,
}: {
  checked: boolean
  onClick: () => void
}) {
  return (
    <Button
      isBorderButton
      className=" m-0 p-1 border-0 align-items-center justify-content-center"
      onClick={onClick}
    >
      <i
        className={` fa font_24 border border-color-grey-opacity-10  rounded ${
          checked
            ? 'fa-square-check font-color-primary'
            : 'fa-square font-color-white '
        }
      `}
      />
    </Button>
  )
}
