import { useEffect, useRef } from 'react'

export const useInitializeInfobip = (enabled = false) => {
  const scriptRef = useRef(null)

  useEffect(() => {
    if (enabled) {
      // Create a function on the window object if it doesn't already exist
      window['liveChat'] =
        window['liveChat'] ||
        function () {
          ;(window['liveChat'].q = window['liveChat'].q || []).push(arguments)
        }

      // Set the timestamp for tracking when the function was initialized
      window['liveChat'].t = 1 * new Date()

      // Create a script element and set its attributes
      const scriptElement = document.createElement('script')
      scriptElement.async = true
      scriptElement.src = 'https://livechat.infobip.com/widget.js'

      // Insert the script element into the document
      const firstScript = document.getElementsByTagName('script')[0]
      firstScript.parentNode.insertBefore(scriptElement, firstScript)

      window.liveChat('init', 'c05ff182-89f5-4054-ac82-7ac89980da54')
    } else {
      if (window.liveChat) {
        window.liveChat('hide_launcher')
      }
    }
  }, [enabled])

  return scriptRef
}
