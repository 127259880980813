import { AdminRoute } from '.'

export const AdminLMSRoute: AdminRoute = {
  permission: 'LMS',
  link: 'lms',
  children: [
    {
      path: '',
      lazy: async () => {
        const { default: Component } = await import('pages/admin/LMS/LMSList')
        return { Component }
      },
    },
    {
      path: ':levelId',
      lazy: async () => {
        const { default: Component } = await import(
          'pages/admin/LMS/AsssignedTutors'
        )
        return { Component }
      },
    },
  ],
}
