import { useQuery, useQueryClient } from '@tanstack/react-query'
import Button from 'components/Button/Button'
import RadioGroup from 'components/Inputs/RadioGroup'
import Switch from 'components/Inputs/Switch'
import { useOpenedModal } from 'components/Modal'
import { ModalWindow } from 'components/Modal/ModalWindow'
import { Select } from 'components/Select/Select'
import { DateSelect } from 'components/Selectors/base/DateSelect'
import { SessionBanner, useGetSlots } from 'components/Tables/Admin/classTable'
import Tabs from 'components/Tabs/Tabs'
import Tooltip from 'components/Tooltip/Tooltip'
import { DateOrEmpty, QuerySpinFetchEffect } from 'components/misc'
import { Response } from 'core/types/Response'
import { Tutor } from 'core/types/User'
import { AdminSession } from 'core/types/session'
import { Subscription } from 'core/types/subscription'
import {
  addDays,
  eachDayOfInterval,
  format,
  isAfter,
  startOfDay,
  subDays,
} from 'date-fns'

import { EmptyCard } from 'components/Cards/LMS/EmptyLMSCards'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { AdminAPI } from 'services/API'
import { extractResponseData } from 'services/utils'
import { formatDate } from 'utils/dateUtils'
import { errorToast } from 'utils/errorToast'
import { SessionInfo } from './Common/SessionInfo'
import { useOrganization } from 'utils/Providers/OrganizationProvider'
import { useLoggedInAdmin } from 'auth/useAuth'

type ReasgignAffetedSessionQueryProps = {
  sessionId: number
  isAllSessions: boolean
  start_at?: string
  slotId?: number
}

function useReassignAffectedSessions({
  sessionId,
  isAllSessions,
  start_at,
  slotId,
}: ReasgignAffetedSessionQueryProps) {
  return useQuery({
    queryKey: ['reassignTutors', isAllSessions, slotId, start_at],
    enabled: !!slotId && !!start_at,
    queryFn: () =>
      AdminAPI.get<
        Response<{
          tutors: Tutor[]
          sessions: AdminSession[]
          subscription: Subscription
        }>
      >(
        `sessions/${sessionId}/reassign_tutors?is_all_sessions=${isAllSessions}&slot_id=${slotId}&day=${start_at}`,
      ).then(extractResponseData),
  })
}

function TransFormToTutorSelectOption(t: Tutor) {
  return {
    text: (
      <div className="d-block">
        <span className="d-flex align-items-center gap-2 font_16">
          {t.name}{' '}
          {t.match && (
            <Tooltip content="Verfied">
              <i className="fa fa-circle-check font-color-primary" />
            </Tooltip>
          )}
          {t.mentor_match && (
            <Tooltip content="Mentor Match">
              <i className="fa fa-flag font-color-orange500" />
            </Tooltip>
          )}
        </span>
        <span className="d-block font-color-gray font_12">{t.email}</span>
      </div>
    ),
    value: t.id,
  }
}

export function TutorReassignModalWindow() {
  const { data: session, closeModal } = useOpenedModal<AdminSession>()
  const { loggedInAdmin } = useLoggedInAdmin()
  const { data: slots } = useGetSlots()
  const { org } = useOrganization()
  const isDemi =
    !loggedInAdmin.is_mentor && org?.domain.toLowerCase().includes('demi')

  const [isAllSessions, setIsAllSessions] = useState(true)
  const queryClient = useQueryClient()
  const [tutorId, setTutorId] = useState<number>()
  const [state, setState] = useState<{
    start_at?: Date
    slot_id?: number
  }>({
    start_at: startOfDay(new Date(session!.start_at)),
    slot_id: session!.slot_id,
  })

  const { data, isFetching, isLoading } = useReassignAffectedSessions({
    sessionId: session!.id,
    isAllSessions,
    start_at: state.start_at?.toISOString(),
    slotId: state.slot_id,
  })

  const isSessionReassignDisabled = !tutorId
  const isScheduleReassignDisabled =
    !tutorId || !state.slot_id || !state.start_at

  const disabled =
    (data?.sessions && data?.sessions.length === 0) ||
    (isAllSessions ? isScheduleReassignDisabled : isSessionReassignDisabled)
  const mentorTutors = data?.tutors.filter((t) => t.is_mentor) || []
  const tutors = data?.tutors.filter((t) => !t.is_mentor) || []
  const [isSubmitting, setIsSubmitting] = useState(false)

  function handleReassignTypeChange(allSessions: boolean) {
    setTutorId(undefined)
    setState({
      start_at: new Date(session!.start_at),
      slot_id: session!.slot_id,
    })
    setIsAllSessions(allSessions)
  }

  async function onSubmit() {
    try {
      setIsSubmitting(true)
      await AdminAPI.put(`sessions/${session?.id}/reassign`, {
        is_all_sessions: isAllSessions,
        tutor_id: tutorId,
        slot_id: state.slot_id,
        day: state.start_at,
      })
      toast.success(`${isAllSessions ? 'Schedule' : 'Session'} Reassigned`)
      queryClient.invalidateQueries(['adminSessions'])
      closeModal()
    } catch (e) {
      setIsSubmitting(false)
      errorToast(e)
    }
  }

  return (
    <ModalWindow
      maxWidth={1280}
      headerClassName=" py-0 px-0"
      bodyClassName="bg-color-primary300-opacity-10 flex-scroll"
      title={
        <div className="border-b border-color-grey-opacity-10 py-3 px-3 d-flex w-100 align-items-center justify-content-between">
          <div className="d-flex flex-1 gap-2 align-items-center">
            Reassign
            <span className=" font-color-blue300 font_12">Session</span>
            <Switch
              checked={isAllSessions}
              onChange={(e) => handleReassignTypeChange(e.target.checked)}
            />
            <span className=" font-color-blue300 font_12">Schedule</span>
          </div>
        </div>
      }
    >
      <SessionBanner
        view={isAllSessions ? 'schedule' : 'session'}
        session={session}
        date={new Date(session!.start_at)}
        rescheduleCount={data?.subscription?.reschedule_count}
        freezeCount={data?.subscription?.freeze_count}
        title={<></>}
      >
        {isAllSessions &&
        slots &&
        session &&
        !data?.subscription?.is_multiple_sessions_weekly ? (
          <div className="col-12 col-lg-4 mb-3 mb-3 mb-lg-0">
            <ScheduleSelectInput
              session={session}
              slots={slots}
              onChange={({ start_at, slot_id }) => {
                setTutorId(undefined)
                setState({ start_at, slot_id })
              }}
            />
          </div>
        ) : (
          <SessionInfo session={session} />
        )}
      </SessionBanner>
      <div
        className="d-grid gap-4 grid-cols-2 p-2 h-100 overflow-auto"
        style={{ height: '1000px' }}
      >
        <div className="card-style py-0 px-0 flex-scroll">
          <TabsContent
            title="Availabe Substitutes"
            activeTab={'Mentors'}
            tabs={[
              {
                name: isDemi ? 'Cover Tutors' : 'Mentors',
                label: (
                  <div className="d-flex  gap-2">
                    {isDemi ? 'Cover Tutors' : 'Mentors'}
                    <span
                      className="bg-color-primary d-flex justify-content-center align-items-center p-1 font-color-white rounded-circle"
                      style={{ width: '20px', height: '20px' }}
                    >
                      {mentorTutors?.length}
                    </span>
                  </div>
                ),
              },

              {
                name: 'Tutors',
                label: (
                  <div className="d-flex  gap-2">
                    Tutors{' '}
                    <span
                      className="bg-color-primary d-flex justify-content-center align-items-center p-1 font-color-white rounded-circle"
                      style={{ width: '20px', height: '20px' }}
                    >
                      {tutors?.length}
                    </span>
                  </div>
                ),
              },
            ]}
            onTabChange={() => setTutorId(undefined)}
          >
            {(tab) => (
              <div className="flex-scroll">
                <div style={{ minHeight: '400px' }}>
                  <QuerySpinFetchEffect
                    isFetching={isFetching}
                    isLoading={isLoading}
                  >
                    <DateOrEmpty
                      list={tab === 'Mentors' ? mentorTutors : tutors}
                    >
                      <RadioGroup
                        className=""
                        value={tutorId}
                        clearable
                        onChange={setTutorId}
                        name="tutorId"
                        values={(tab === 'Mentors' ? mentorTutors : tutors).map(
                          TransFormToTutorSelectOption,
                        )}
                      />
                    </DateOrEmpty>
                  </QuerySpinFetchEffect>
                </div>
              </div>
            )}
          </TabsContent>
        </div>

        <div className="card-style py-0 px-0 flex-scroll">
          <h6 className="px-3 py-3 border-b border-color-grey-opacity-10 font-semibold font_14 font-color-blue300 d-flex gap-2 align-items-center">
            Affected Sessions{' '}
            <span
              className="bg-color-primary d-flex justify-content-center align-items-center p-1 font-color-white rounded-circle"
              style={{ width: '20px', height: '20px' }}
            >
              {data?.sessions?.length || 0}
            </span>
          </h6>
          <div className="px-3 py-3 flex-scroll">
            <SessionsList sessions={data?.sessions} />
          </div>

          <Button
            disabled={disabled || isSubmitting}
            isLoading={isSubmitting}
            className={`rounded-pill ms-auto  m-2 font_14 font-semibold d-flex align-items-center gap-2`}
            onClick={onSubmit}
          >
            Confirm
          </Button>
        </div>
      </div>
    </ModalWindow>
  )
}

function SessionsList({ sessions }: { sessions?: AdminSession[] }) {
  return (
    <div className="flex-fill">
      {sessions?.map((s, index) => (
        <div key={s.id} className="mb-4 d-flex gap-2">
          <span className=" font-color-blue300 font_12">
            <span> {index + 1} -</span>
          </span>

          <div className="font_12">
            <span className="d-block font-color-blue600 font_14">
              {formatDate(s.start_at, 'eee d MMM yyy')}
            </span>

            <span className="d-block font-color-blue300 gap-2">
              <span className="d-block"> {s.grade.name}</span>
              <span className="d-block"> {s.level.name}</span>
              <span className="d-block"> {s.lesson.name}</span>
            </span>
          </div>
        </div>
      ))}

      {sessions && sessions.length === 0 && <EmptyCard desc="No data" />}
    </div>
  )
}

type TSlots = NonNullable<ReturnType<typeof useGetSlots>['data']>

function getDayName(datestring: string | Date) {
  return formatDate(new Date(datestring), 'eeee').toLowerCase()
}

type ScheduleSelectInputProps = {
  showCancel?: boolean
  session: { start_at: string; slot_id: number }
  slots: TSlots
  onChange: (state: { start_at?: Date; slot_id?: number }) => void
}

export function ScheduleSelectInput({
  slots,
  onChange,
  session,
}: ScheduleSelectInputProps) {
  const sessionDate = new Date(session.start_at)
  const [date, setDate] = useState<Date | undefined>(() => sessionDate)
  const [slotId, setSlotId] = useState<number | undefined>(
    () => session.slot_id,
  )

  useEffect(() => {
    onChange({
      start_at: date,
      slot_id: slotId,
    })
  }, [date, slotId])

  const { minDate, maxDate } = useMemo(() => {
    const days = Array.from(new Set(slots.list.map((s) => s.day)))

    const days_options = getPreviousAndNextDays(new Date(session.start_at))
      .filter((day) => days.includes(getDayName(day)))
      .map(startOfDay)
      .map((d) => ({
        label: format(d, 'eee dd MMM '),
        value: d,
      }))

    const minDate = new Date(days_options?.[0]?.value)
    const maxDate = new Date(days_options?.[days_options.length - 1]?.value)

    return { minDate, maxDate }
  }, [])

  return (
    <div className="d-flex flex-column gap-3 my-2">
      <DateSelect
        label="Date"
        minDate={minDate}
        maxDate={maxDate}
        value={date}
        clearable={false}
        onChange={(date) => {
          setDate(date)
          setSlotId(undefined)
        }}
      />

      <Select
        label="Cairo Time"
        value={slotId}
        clearable={false}
        onChange={setSlotId}
        disabled={!date}
        options={slots.list
          .filter((s) => s.day_id === new Date(date || '').getDay())
          .map((s) => ({
            label: s.time_str,
            value: s.id,
          }))}
      />
    </div>
  )
}

function getPreviousAndNextDays(date: Date, numDays = 6) {
  let count = 1
  const today = subDays(new Date(), 1)

  let prevDays: Date[] = []

  while (count <= numDays) {
    const sub = subDays(date, count)

    if (isAfter(sub, today)) {
      prevDays.unshift(sub)
    }
    count++
  }

  const nextDays = eachDayOfInterval({
    start: addDays(date, 1),
    end: addDays(date, numDays),
  })

  return [...prevDays, date, ...nextDays]
}

function TabsContent({
  tabs,
  activeTab,
  title,
  children,
  onTabChange,
}: {
  children?: (activeTab: string) => ReactNode
  title?: ReactNode
  activeTab: string
  tabs: { name: string; label: ReactNode }[]
  onTabChange?: (tab: string) => void
}) {
  const [tab, setTab] = useState(() => activeTab)

  useEffect(() => onTabChange?.(tab), [tab])

  return (
    <>
      <div
        className="p-3 font-semibold font_14 font-color-blue300 d-flex gap-2 align-items-center border-b border-color-grey-opacity-10"
        style={{ height: '50px' }}
      >
        {title}
        <Tabs
          activeTab={activeTab}
          tabsConfig={tabs.map((tab) => ({
            name: tab.name,
            clickHandler: setTab,
            label: tab.label,
          }))}
        />
      </div>
      {children?.(tab)}
    </>
  )
}
