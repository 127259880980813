import { useAuth } from 'auth/useAuth'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { OrganizationEnum, useOrganization } from './OrganizationProvider'
import { useInitializeInfobip } from './infobip'

// const StudentSignupChat = {
//   src: '//fw-cdn.com/2436593/3018724.js',
//   name: 'signup',
//   widgetUuid: '',
// }

const StudentChat = {
  src: '//fw-cdn.com/10823527/3602360.js',
  name: 'Student',
  widgetUuid: '',
}

const TutorChat = {
  src: '//fw-cdn.com/10823527/3602360.js',
  widgetUuid: '5fda21a7-f115-4053-a11f-e948f03127b5',
  name: 'Tutor',
}

const DemiChat = {
  src: '//fw-cdn.com/10823527/3602360.js',
  widgetUuid: '8bc5c0c4-3178-4f64-8b23-2f8810404b47',
  name: 'DEMI',
}

function useGetChatConfig() {
  const { user } = useAuth()
  const { org } = useOrganization()
  const path = useLocation().pathname
  useInitializeInfobip(path.includes('signup'))

  if (!org) return null

  if (org.id === OrganizationEnum.Demi || org.id === OrganizationEnum.DemiSummer || org.id === OrganizationEnum.DemiBatch2) {
    return DemiChat
  }

  if (path === '/login/tutor' || user?.isTutor) {
    return TutorChat
  }

  if (
    org.id === OrganizationEnum.iSchool &&
    (path === '/login' || user?.isStudent)
  ) {
    return StudentChat
  }

  return null
}

export function ChatProvider() {
  useLoadChatWidgetSciprt()
  return null
}

export function useLoadChatWidgetSciprt() {
  const chat = useGetChatConfig()
  const fcWidget = (window as any).fcWidget

  useEffect(() => {
    const script = document.createElement('script')
    if (chat?.src) {
      script.src = chat.src
    }
    if (chat?.widgetUuid) {
      script.setAttribute('widgetId', chat.widgetUuid)
    } else {
      script.removeAttribute('widgetId')
    }
    script.setAttribute('chat', 'true')
    script.async = true
    document.body.appendChild(script)
    return () => {
      fcWidget?.destroy()
      script.remove()
    }
  }, [chat?.name])
}
