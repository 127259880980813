import { NavSideBar } from 'layout/common/NavSidebar'
import { Outlet } from 'react-router-dom'
import { TutorHeaderBar } from './TutorHeaderBar'
import { TutorNavLinks } from './TutorNavLinks'

export default function TutorDesktopLayout() {
  return (
    <div className=" layout">
      <NavSideBar>
        <TutorNavLinks />
      </NavSideBar>
      <div className=" flex-scroll flex-column " style={{}}>
        <TutorHeaderBar />
        <div className="rounded p-0  flex-scroll bg-color-primary300-opacity-50 flex-scroll">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
