import { ReactNode } from 'react'
import { CellProp, HeaderProp } from '../../Table/Table'

export function ItemMobileCard({
  cells,
  headers,
}: {
  cells: CellProp[]
  headers: HeaderProp[]
}) {
  return (
    <div className="shadow-sm rounded p-0">
      {cells.map(({ cell }, index) =>
        headers[index]?.hidden ? null : (
          <div
            key={index}
            className="border-b bg-color-white pb-2 px-2 py-1 grid"
            style={{ display: 'grid' }}
          >
            <div className="d-block font_12 font-semibold py-2">
              {headers[index]?.header}
            </div>
            <div className="py-2 font_14 ">{cell as ReactNode}</div>
          </div>
        ),
      )}
    </div>
  )
}
