import { useQuery } from '@tanstack/react-query'
import { oneDay } from 'components/Cards/ClassesCards/NextClass/useTime'
import { PaginatedResponse, Response } from 'core/types/Response'
import { Organization as TOrganization } from 'core/types/User/Admin'
import { AdminPage } from 'layout/AdminDashboard'
import { Link, Outlet, useParams } from 'react-router-dom'
import { AdminAPI } from 'services/API'
import { QueryKeys } from 'services/QueryKeys'
import { extractResponseData } from 'services/utils'

export function useOrganizationsList() {
  return useQuery({
    queryFn: () =>
      AdminAPI.get<PaginatedResponse<TOrganization>>('organizations').then(
        extractResponseData,
      ),
    queryKey: [QueryKeys.organizationsList],
    staleTime: oneDay,
  })
}

export function useOrganizationDetails() {
  const { organizationId } = useParams()

  return useQuery({
    queryFn: () =>
      AdminAPI.get<Response<TOrganization>>(
        `organizations/${organizationId}`,
      ).then(extractResponseData),
    queryKey: [QueryKeys.organization, organizationId],
    staleTime: oneDay,
    enabled: !!organizationId,
  })
}

export default function OrganizationsPage() {
  const { data: org, isFetching, isLoading } = useOrganizationDetails()

  return (
    <AdminPage
      title="Organizations"
      buttons={
        <>
          {' '}
          {org && (
            <Link
              to="/admin/organizations"
              className="font_20 font-semibold mb-3 mb-lg-0"
            >
              {'/ '}
              {isLoading && (
                <div className={`spinner-border  spinner-border-sm font_14`}>
                  {' '}
                </div>
              )}
              {org?.name}
            </Link>
          )}
        </>
      }
    >
      <div className="px-4 py-3" style={{ opacity: isFetching ? '0.5' : '1' }}>
        <Outlet />
      </div>
    </AdminPage>
  )
}
