import { ReactNode, Ref, forwardRef } from 'react'
import './styles.scss'

type SelectButtonProps = {
  labelPlacement?: 'compact' | 'inline' | 'block'
  labelClassName?: string
  leftIcon?: ReactNode
  onClicK?: () => void
  disabled?: boolean
  className?: string
  label?: ReactNode
  placeholder?: ReactNode
  children?: ReactNode
  value?: any
  onClear?: () => void

  error?: string
}
function _SelectButton(props: SelectButtonProps, ref: Ref<HTMLButtonElement>) {
  const { leftIcon, className, disabled, onClicK, children, onClear, label } =
    props
  let hasValue = !!props.value
  const showLabelWhenHasValue = props.labelPlacement === 'compact'
  const placeholder =
    props.labelPlacement !== 'compact'
      ? props.placeholder || 'Select'
      : props.label

  return (
    <button
      type="button"
      ref={ref}
      disabled={disabled}
      onClick={onClicK}
      className={`d-flex cursor-pointer border ${
        props.error ? 'border-color-red500-opacity-30' : ' font-color-blue300'
      } rounded text-nowrap  text-start  gap-2  flex-1 align-items-center  ps-10 ${
        disabled ? 'bg-color-grey-opacity-5' : ''
      } ${className === 'opened' ? 'rounded-0' : ''}`}
      style={{
        width: '100%',
        position: 'relative',
        maxHeight: '45px',
        height: '45px',
        minWidth: '210px',
        fontSize: '0.95em',
      }}
    >
      {leftIcon}
      <span
        className="d-flex flex-1 font_14 align-items-center justify-content-between"
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {/* placeholder */}
        {!showLabelWhenHasValue && !hasValue && (
          <span className="">{placeholder}</span>
        )}
        {/* placeholder */}
        {label && showLabelWhenHasValue && (
          <span
            className={props.labelClassName}
            style={{
              position: hasValue ? 'absolute' : 'relative',
              top: hasValue ? '-10px' : '0',
              fontSize: hasValue ? '12px' : '14px',
              left: hasValue ? '20px' : '0',
              backgroundColor: hasValue ? 'white' : undefined,
              padding: '0px 10px',

              transition:
                'top 0.5s ease-in-out, background-color 0.3 ease-in-out, font-size 0.3 ease-in-out',
            }}
          >
            {hasValue ? label : placeholder || label}
          </span>
        )}

        <span
          className="text-nowrap"
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {hasValue && children}
        </span>

        <span
          style={{ height: '40px', minWidth: '30px', borderLeft: '1px solid' }}
          className="d-flex align-items-center justify-content-center border-color-grey-opacity-10 cursor-pointer font_14 font-color-blue300-opacity-50"
          onClick={(e) => {
            if (hasValue && onClear) {
              onClear()
              e.stopPropagation()
            }
          }}
        >
          {disabled ? (
            <i className="fa-solid fa-ban" />
          ) : (
            <>
              {(!hasValue || !onClear) && (
                <i className="fa-solid fa-chevron-down" />
              )}

              {hasValue && onClear && <i className="fa-solid fa-times" />}
            </>
          )}
        </span>
      </span>
    </button>
  )
}

export const SelectButton = forwardRef(_SelectButton)
