import { Select } from 'components/Select/Select'
import { SelectProps } from './types'

export default function TopicSelect(props: SelectProps<string>) {
  return (
    <Select
      {...props}
      label="Topics"
      options={[
        { label: 'Eid Vacation', value: 'Eid Vacation', group: 'Admin' },
        { label: 'Freeze No cover', value: 'Freeze No cover', group: 'Admin' },
        { label: 'Ramadan', value: 'Ramadan', group: 'Admin' },
        { label: 'System issue', value: 'System issue', group: 'Admin' },
        {
          label: 'System issue Out Tutor',
          value: 'System issue Out Tutor',
          group: 'Admin',
        },
        {
          label: 'System issue Duplicate',
          value: 'System issue Duplicate',
          group: 'Admin',
        },
        {
          label: 'System issue wrong assign',
          value: 'System issue wrong assign',
          group: 'Admin',
        },
        { label: 'DEMI', value: 'DEMI', group: 'Admin' },
        { label: 'Termination', value: 'Termination', group: 'Admin' },
        { label: 'Resigned', value: 'Resigned', group: 'Admin' },
        { label: 'Zoom issue', value: 'Zoom issue', group: 'Admin' },
        { label: 'Emergency', value: 'Emergency', group: 'Tutor' },
        {
          label: 'Electricity issue',
          value: 'Electricity issue',
          group: 'Tutor',
        },
        {
          label: 'Instructor issue',
          value: 'Instructor issue',
          group: 'Tutor',
        },
        { label: 'No Show', value: 'No Show', group: 'Tutor' },
        { label: 'Sick', value: 'Sick', group: 'Tutor' },
        { label: 'Vacation', value: 'Vacation', group: 'Tutor' },
        { label: 'Tickets', value: 'Tickets', group: 'Student' },
        { label: 'Student issue', value: 'Student issue', group: 'Student' },
        { label: 'Exams', value: 'Exams', group: 'Student' },
        { label: 'Travel', value: 'Travel', group: 'Student' },
        { label: 'Time Violation', value: 'Time Violation', group: 'Student' },
        { label: 'Recap', value: 'Recap', group: 'Student' },
        {
          label: 'Shift session exceed limit',
          value: 'Shift session exceed limit',
          group: 'Student',
        },
        { label: 'Re-Schedule', value: 'Re-Schedule', group: 'Student' },
      ]}
    />
  )
}
