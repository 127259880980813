import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import moment from 'moment'
// import 'moment/locale/ar'
// import { LANGUAGE_COOKIE_NAME, FALLBACK_LANGUAGE } from './constants'

// English translation files imports
import commonEn from './locales/en/common.json'

// Arabic translation files imports
import commonAr from './locales/ar/common.json'

const LANGUAGE_COOKIE_NAME = 'lang'
const FALLBACK_LANGUAGE = 'en'

const resources = {
  en: {
    common: commonEn,
  },
  ar: {
    common: commonAr,
  },
}

const setLanguageCookie = (lng) => {
  document.cookie = `${LANGUAGE_COOKIE_NAME}=${lng}`
}

const setDocumentLang = (lng) => {
  const direction = lng === 'ar' ? 'rtl' : 'ltr'
  const htmlTag = document.querySelector('html')
  let element = document.getElementById('web-app-body-tag')

  if (lng === 'ar') {
    element?.classList?.add('direction-rtl')
    element?.setAttribute('lng', 'ar')
  } else {
    element?.classList?.remove('direction-rtl')
    element?.setAttribute('lng', 'en')
  }
  // assign multiple attributes at once to an <html> tag
  Object.assign(htmlTag, { lang: lng, id: lng, dir: direction })
}

const getLanguageCookie = () => {
  const match = document.cookie.match(
    new RegExp(`(^| )${LANGUAGE_COOKIE_NAME}=([^;]+)`),
  )

  // returns fallback language if language cookie not found
  return match ? match[2] : FALLBACK_LANGUAGE
}

i18n.on('initialized', (options) => {
  // sets the language cookie value on initialization to be read later
  setLanguageCookie(options.lng)
  // sets initial document language, id and direction
  setDocumentLang(options.lng)
  // moment.locale(options.lng)
})

i18n.on('languageChanged', (lng) => {
  // update language cookie on change
  setLanguageCookie(lng)
  // update document lang, direction and id attr for styling purpose
  setDocumentLang(lng)
  // moment.locale(lng)
})

let intialLanguage = localStorage.getItem('lng')
let dev_domain = localStorage?.getItem('dev_domain')

if (!intialLanguage) {
  let fullUrl = window.location.host
  const domain = fullUrl.split('.')[0]

  if (
    'demi' === domain ||
    'rubikal' === dev_domain ||
    domain.includes('deci-demo')
  ) {
    localStorage.setItem('lng', 'ar')
    intialLanguage = 'ar'
  } else {
    intialLanguage = FALLBACK_LANGUAGE
    localStorage.setItem('lng', intialLanguage)
  }
}

i18n.use(initReactI18next).init({
  resources,
  // lng: getLanguageCookie(),
  lng: intialLanguage,
  // lng: 'ar',
  fallbackLng: FALLBACK_LANGUAGE,
  supportedLngs: ['en', 'ar'],
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
