import { CustomFilter } from 'components/Query/filter'
import { Select } from 'components/Select/Select'
import { SelectProps } from 'components/Selectors/types'
import { SelectFilter } from '../Basic/SelectFilter'

function StatusSelect({ value, onChange }: SelectProps<string>) {
  return (
    <Select
      clearable={false}
      label="History / Today / Upcoming"
      value={value}
      onChange={onChange}
      options={[
        { label: 'Upcoming', value: 'upcoming' },
        { label: 'Today', value: 'today' },
        { label: 'History', value: 'completed' },
      ]}
    />
  )
}

export const SessionTimeStatusSelectFilter: CustomFilter<string | undefined> = {
  pinned: true,
  type: 'custom',
  element: (ctx) => (
    <StatusSelect
      {...ctx}
      value={ctx.filters?.params?.status}
      onChange={(status) => {
        ctx.filters?.update({
          status,
          sort:
            status === 'upcoming' || status === 'today'
              ? 'start_at'
              : '-start_at',
        })
      }}
    />
  ),
}

export const SessionStatusSelectFilter: CustomFilter<string | undefined> = {
  pinned: true,
  type: 'custom',
  element: (ctx) => (
    <SelectFilter
      label="Session Status"
      options={[
        { label: 'Frozen', value: 'frozen' },
        { label: 'Active', value: 'active' },
        { label: 'Canceled', value: 'canceled' },
      ]}
      {...ctx}
    />
  ),
}
