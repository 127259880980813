import { useQuery } from '@tanstack/react-query'
import { useLoggedIn } from 'auth/useAuth'
import Button from 'components/Button/Button'
import { DropDown } from 'components/Dropdown/Dropdown'
import { NoData } from 'components/NoData'
import { Response } from 'core/types/Response'
import { NavLink } from 'react-router-dom'
import { Spinner } from 'utils/Spinner'
import { isArabic } from 'utils/helpers'
import { NotificationItem } from './NotificationItem'
import { useGetNotifications } from './useGetNotifications'
import { useUserNotificationChannelData } from './useNotificationStream'

function useUnreadCount() {
  const { API } = useLoggedIn()
  const data = useUserNotificationChannelData()

  return useQuery({
    keepPreviousData: true,
    queryFn: () =>
      API.get<Response<{ not_read_notifications_count: number }>>(
        `/notifications/unread_count`,
      ).then((r) => r.data.data),
    queryKey: [
      'notifications_unread_count',
      data?.not_read_notifications_count,
    ],
  })
}

const Notifications = () => {
  const { data, refetch } = useUnreadCount()

  return (
    <DropDown
      placement={isArabic() ? 'bottom-left' : 'bottom-right'}
      trigger={({ toggle, triggerRef }) => (
        <Button
          isBorderButton
          className="d-flex border-0 p-2"
          onClick={() => {
            toggle()
            refetch()
          }}
          ref={triggerRef}
        >
          <i className={`fa-solid fa-bell font-color-grey font_20`} />
          {!!data?.not_read_notifications_count &&
            data?.not_read_notifications_count > 0 && (
              <div
                className="bg-color-orange500 font_14 font-semibold  font-color-white rounded-circle d-flex-inline justify-content-center align-items-center"
                style={{ width: '25px', height: '25px', display: 'flex' }}
              >
                {data?.not_read_notifications_count}
              </div>
            )}
        </Button>
      )}
    >
      {({ toggle }) => <NotificationsList onClick={toggle} />}
    </DropDown>
  )
}

function NotificationsList({ onClick }: { onClick: () => void }) {
  const { dashboard } = useLoggedIn()
  const { list, isLoading, isFetching } = useGetNotifications()
  return (
    <div
      className="flex-scroll"
      style={{
        maxHeight: '50vh',
        width: '25vw',
        minWidth: '300px',
      }}
    >
      <div className="flex-scroll">
        {isLoading && <Spinner />}
        {list?.length === 0 && <NoData />}
        <div style={{ opacity: isFetching ? '0.5' : '1' }}>
          {list?.map((notification) => (
            <NotificationItem
              {...notification}
              showDistance
              onClick={onClick}
              key={`${notification.id}`}
            />
          ))}
        </div>
        <hr className="m-0 mt-2" />
      </div>
      <NavLink
        onClick={onClick}
        className="px-4 text-center  d-block px-4 py-2 font-semibold bg-color-primary300 rounded"
        to={`${dashboard}/notifications`}
      >
        Show All Notifications
      </NavLink>
    </div>
  )
}

export default Notifications
