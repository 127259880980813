export const closeModal = () => {
  document.getElementById('close-modal-button')!.click()
}

export function numberOrUndefined<T>(v: T) {
  if (v === '' || v == null || v === undefined || v === 'All') return undefined
  return +v
}

const oneSecond = 1000
const oneMinute = 60 * oneSecond
const oneHour = 60 * oneMinute
const oneDay = 24 * oneHour

export const TimeInteral = {
  oneSecond,
  oneMinute,
  fiveMinutes: 5 * oneMinute,
  tenMinutes: 10 * oneMinute,
  halfHour: 30 * oneMinute,
  oneHour,
  oneDay,
}

export const isProduction = process.env.REACT_APP_ENV === 'production'

export const isLocalhost = (url: string) => {
  return url.includes('localhost') || url.includes('127.0.0.1')
}

export const isArabic = () => document.body.getAttribute('lng') === 'ar'
export const isEnglish = () => document.body.getAttribute('lng') === 'en'

export const appLanguage = () =>
  (document.body.getAttribute('lng') as 'ar' | 'en') || 'en'

export function getCookieValue(name: string): string | null {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop()?.split(';').shift() || null
  return null
}
