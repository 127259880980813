import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import { RouteObject } from 'react-router-dom'

export const TutorNotificationRoute: RouteObject = {
  path: 'notifications',
  lazy: async () => {
    const { NotificationsList: Component } = await import(
      'components/Notifications/NotificationsList'
    )
    return { Component }
  },
  ErrorBoundary,
}
