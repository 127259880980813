import {
  QuizAnswer,
  QuizAnswerState,
  QuizQuesiton,
  Session,
  i18n,
} from 'core/types/session'
import { Reducer, useReducer } from 'react'
import { isArabic } from 'utils/helpers'

function getNextQuestionState(
  prevQuestion: QuizQuesiton | null,
  session: Session | null,
) {
  const answers = session?.answers || []
  const questions = session?.lesson.questions || []
  const answeredIds = answers.map(({ id }) => id)
  const unanswered = questions.filter(({ id }) => !answeredIds.includes(id))
  const prevIndex = unanswered.findIndex((q) => q.id === prevQuestion?.id)
  const nextIndex = prevIndex + 1
  const nextQuestion = unanswered[nextIndex] || null
  return nextQuestion
}

const reducer: Reducer<State, Action> = (
  prevState: State,
  action: Action,
): State => {
  switch (action.type) {
    case 'START_QUIZ': {
      const session = action.payload
      const answers = session?.answers || []
      const questions = session?.lesson.questions || []
      const answeredIds = answers.map(({ id }) => id)
      const unanswered = questions.filter(({ id }) => !answeredIds.includes(id))
      const currentQuestion = unanswered[0]
      return {
        state: 'ask_question',
        session,
        answers: currentQuestion.answers.map((a) => ({
          value: a,
          state: '',
        })),
        currentQuestion: unanswered[0],
        buttonState: { text: 'confirm', color: 'secondary' },
      }
    }

    case 'SUBMIT_ANSWER': {
      const { currentQuestion, session } = prevState

      const nextQuestion = getNextQuestionState(
        currentQuestion,
        prevState.session,
      )

      const { answer: a, correct_answer: ca } = action.payload
      return {
        session,
        state: nextQuestion ? 'show_correct_answer' : 'quiz_finished',
        currentQuestion,
        answers:
          currentQuestion?.answers.map((c, index) => {
            const choice = JSON.stringify(c)
            const answer = JSON.stringify(a)
            const correct_answer = JSON.stringify(ca)
            if (choice === answer && answer === correct_answer) {
              return {
                value: c,
                state: 'success',
              }
            }

            if (choice === answer && answer !== correct_answer) {
              return {
                value: c,
                state: 'fail',
              }
            }

            if (choice === correct_answer && answer !== correct_answer) {
              return {
                value: c,
                state: 'correction',
              }
            }

            return {
              value: c,
              state: '',
            }
          }) || [],
        buttonState: {
          text: nextQuestion ? 'next' : 'close',
          color: 'primary',
        },
      }
    }

    case 'NEXT_ANSWER': {
      const { currentQuestion, session } = prevState
      const nextQuestion = getNextQuestionState(currentQuestion, session)
      return {
        session,
        state: 'ask_question',
        currentQuestion: nextQuestion,
        answers: nextQuestion.answers.map((a) => ({
          value: a,
          state: '',
        })),
        buttonState: { text: 'confirm', color: 'primary' },
      }
    }

    default:
      throw new Error(`Invalid action`)
  }
}

type QuizState = 'ask_question' | 'show_correct_answer' | 'quiz_finished'

type State = {
  state: QuizState
  currentQuestion: QuizQuesiton | null
  answers: { value: i18n; state: QuizAnswerState }[]
  session: Session
  buttonState: {
    text: 'confirm' | 'next' | 'close'
    color: 'primary' | 'secondary'
  }
}
const initialState: State = {
  state: 'ask_question',
  answers: [],
  session: {} as Session,
  currentQuestion: null,
  buttonState: { text: 'confirm', color: 'primary' },
}

type Action =
  | { type: 'START_QUIZ'; payload: Session }
  | {
      type: 'SUBMIT_ANSWER'
      payload: {
        questionId: number
        session: Session
        answer: i18n
        correct_answer: i18n
      }
    }
  | {
      type: 'NEXT_ANSWER'
    }
export function useQuizReducer() {
  return useReducer(reducer, initialState)
}
