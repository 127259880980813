import { i18n } from 'core/types/session'
import { isArabic } from 'utils/helpers'

interface QuizAnswerProps {
  onClick: (answer: i18n) => void
  disabled?: boolean
  value: i18n
  state: 'default' | 'selected' | 'correct' | 'wrong' | 'correction'
  showBothAnswers?: boolean
}

export function QuizAnswer({
  onClick,
  value,
  disabled,
  showBothAnswers,
  state,
}: QuizAnswerProps) {
  const handleClick = () => onClick(value)

  const getAnswerClasses = () => {
    if (state === 'correct') {
      return 'border-success font-color-text-success'
    } else if (state === 'correction') {
      return 'border-orange-light font-color-orange-medium'
    } else if (state === 'wrong') {
      return 'border-error font-color-text-error'
    } else if (state === 'selected') {
      return 'border-primary-blue bg-lightest-blue font-color-primary-blue'
    }

    return `font-color-text-light ${
      disabled ? '' : 'hover:bg-lightest-blue hover:border-color-primary-blue'
    }`
  }

  return (
    <li className="mt-base rounded-3xs bg-white flex-1 w-100">
      <button
        className={`d-flex flex-column text-sm p-xs d-block w-100 rounded-3xs ${getAnswerClasses()}`}
        onClick={handleClick}
        disabled={state === 'correct' || state === 'wrong' || disabled}
      >
        {showBothAnswers ? (
          <>
            <RenderAnswer value={value.en} dir="ltr" />
            <RenderAnswer value={value.ar} dir="rtl" />
          </>
        ) : (
          <RenderAnswer
            value={isArabic() && value.ar ? value.ar : value.en || value.ar}
            dir="auto"
          />
        )}
      </button>
    </li>
  )
}

function RenderAnswer({
  value,
  dir = 'auto',
}: {
  value?: string
  dir?: 'rtl' | 'ltr' | 'auto'
}) {
  if (!value) return null
  return (
    <div dir={dir} className="d-flex flex-1 w-100">
      {value}
    </div>
  )
}
