import { addStudnetRole } from 'auth/AddUserRole'
import { AxiosResponse } from 'axios'
import { SignupData } from 'components/Cards/SignUp/SignUpCard'
import { Response } from 'core/types/Response'
import { Student } from 'core/types/User'
import { toast } from 'react-toastify'
import { router } from 'routes'
import { EnrollmentQuizPageStates } from 'routes/StudentRoutes'
import { StudentAPI } from 'services/API'
import { extractResponseData } from 'services/utils'

function addStudentState(student: Student): Student {
  let state: Student['state'] =
    student.quiz?.status === 'pending_configuration'
      ? 'enrollment_quiz_pending_configuration'
      : student.quiz?.status === 'pending'
      ? 'enrollment_quiz_pending'
      : student.quiz?.status === 'in_progress'
      ? 'enrollment_quiz_inprogess'
      : student.quiz?.status === 'completed'
      ? student.quiz?.result_status === 'pending'
        ? 'enrollment_quiz_pending_results'
        : student.quiz?.result_status === 'failed'
        ? 'enrollment_quiz_failed'
        : student.pending_session_schedule
        ? 'pending_session_schedule'
        : 'active'
      : student.pending_session_schedule
      ? 'pending_session_schedule'
      : 'active'

  let student_permissions = EnrollmentQuizPageStates.includes(state)
    ? student.organization?.student_permissions?.concat(['Quiz'])
    : student.organization?.student_permissions

  if (student?.s_id === 'DEMI2-S-84380')
    student_permissions = student_permissions?.concat(['Community'])

  return {
    ...student,
    state,
    organization: {
      ...student.organization,
      student_permissions,
    },
  }
}

export function mapStudentRepsonse(
  resp: AxiosResponse<Response<Student>>,
): Student {
  return addStudentState(addStudnetRole(extractResponseData(resp)))
}

export function studentLogin(
  email: string,
  password: string,
  organization_domain: string | null,
) {
  return StudentAPI.post<Response<Student>>('/login', {
    email,
    password,
    organization_domain,
  }).then(mapStudentRepsonse)
}

export function studentSendVerification(body: any) {
  return StudentAPI.post<Response<[]>>('/registrations/send_verification', body)
}

export function studentVerifyOTP(body: any) {
  return StudentAPI.post<Response<[]>>('/registrations/verify', body)
}

export function studentSignup(signupData: SignupData) {
  return StudentAPI.post<Response<Student>>('/registrations', signupData).then(
    (res) => {
      return { ...res?.data, data: mapStudentRepsonse(res) }
    },
  )
}

export function getMyStudentProfile(): Promise<Student> {
  return StudentAPI.get<Response<Student>>('/profile').then(mapStudentRepsonse)
}

export function showStudentInvitation(token: string) {
  return StudentAPI.get<Response<Student>>(`/invitations/${token}`)
    .then(extractResponseData)
    .catch((e) => {
      if (e?.response?.data?.message === 'Record not found') {
        toast.error('Invalid Token')
        router.navigate('/')
      }
      return null
    })
}

export function acceptStudentInvitation(token: string, signupData: SignupData) {
  return StudentAPI.put<Response<Student>>(
    `/invitations/${token}/accept`,
    signupData,
  ).then(mapStudentRepsonse)
}
