import { useQueryClient } from '@tanstack/react-query'
import { useAdminTabPermissions } from 'auth/useAdminTabPermissions'
import { useLoggedInAdmin } from 'auth/useAuth'
import Button from 'components/Button/Button'
import InputField from 'components/Inputs/InputField'
import Switch from 'components/Inputs/Switch'
import { useCreateModal, useOpenedModal } from 'components/Modal'
import { ModalWindow } from 'components/Modal/ModalWindow'
import Table from 'components/Table/Table'
import Tabs from 'components/Tabs/Tabs'
import useViewPort from 'core/hooks/useViewPort'
import { useFormik } from 'formik'
import { AdminPage } from 'layout/AdminDashboard'
import { useState } from 'react'
import { toast } from 'react-toastify'
import {
  SupportTopic,
  createSupportTopic,
  getSupportTopics,
  updateSupportTopic,
} from 'services/admin/supportTopics'
import { usePaginatedResource } from 'utils/usePaginatedResource'
import * as yup from 'yup'
import { MEDIA_BREAKPOINT_MD } from '../../constants'

export function useGetAdminSupportTopics() {
  return usePaginatedResource({
    indexFn: getSupportTopics,
    initialParams: {},
    name: 'adminSupportTopics',
  })
}

const STATUSCOLOR = {
  Active: 'green600',
  Inactive: 'gray400',
}

export function SupportTopics() {
  const query = useGetAdminSupportTopics()
  const [currentTab, setCurrentTab] = useState<'Student' | 'Tutor'>('Student')
  const CreateSupportTopic = useCreateModal()
  const EditSupportTopicModal = useCreateModal<SupportTopic>()

  const { loggedInAdmin } = useLoggedInAdmin()
  const { readOnly } = useAdminTabPermissions('Support Topics')

  return (
    <AdminPage
      title="Support Topics"
      actions={[
        {
          label: 'Topic',
          icon: 'fa fa-plus',
          hidden: readOnly,
          onClick: CreateSupportTopic.openModal,
        },
      ]}
      buttons={
        <Tabs
          className="order-1 order-md-0 mb-0 font_12"
          activeTab={currentTab}
          tabsConfig={[
            {
              name: 'Student',
              label: 'Student',
              clickHandler: setCurrentTab,
            },
            {
              name: 'Tutor',
              label: 'Teacher',
              clickHandler: setCurrentTab,
            },
          ]}
        />
      }
    >
      <Table
        {...query}
        list={query.list?.filter((data) => data.user_type === currentTab)}
        columns={[
          { header: 'Name', width: '40%' },
          { header: 'Status', width: '20%' },
          { header: '', hidden: readOnly },
        ]}
      >
        {(topic) => [
          topic.name,
          <span
            className={`chips chips-border-color-${
              STATUSCOLOR[topic.active ? 'Active' : 'Inactive']
            } rounded-pill font_12 `}
          >
            {topic.active ? 'Active' : 'Inactive'}
          </span>,
          <Button
            variant="primary600"
            className=" font_14 font-normal py-02 px-12"
            label="Edit"
            isBorderButton
            onClick={() => EditSupportTopicModal.openModal(topic)}
          />,
        ]}
      </Table>

      <CreateSupportTopic.Container>
        <CreateSupportTopicWindow user_type={currentTab} />
      </CreateSupportTopic.Container>

      <EditSupportTopicModal.Container>
        <EditSupportTopicModalWindow />
      </EditSupportTopicModal.Container>
    </AdminPage>
  )
}

function EditSupportTopicModalWindow() {
  const { closeModal, data } = useOpenedModal()
  const { width } = useViewPort()
  const qc = useQueryClient()
  const labelClassName =
    'w-25 text-nowrap font_16 font-semibold font-color-blue600'

  const inlineForm = width >= MEDIA_BREAKPOINT_MD ? true : false

  const formik = useFormik<Partial<SupportTopic>>({
    onSubmit: (topic) => {
      updateSupportTopic(topic).then((newTopic) => {
        qc.invalidateQueries(['adminSupportTopics'])
        toast.success('Topic Updated')
        closeModal()
        return newTopic
      })
    },
    initialValues: data!,
  })

  return (
    <ModalWindow
      formId="EditSupportTopic"
      mainBtn="Confirm"
      title="Edit Support Topic"
      headerClassName="gradient-header text-center"
      mainButtonType="submit"
      mainBtnClassName="py-2 px-4 font_12 my-4"
      hasCloseBtn
    >
      <form id="EditSupportTopic" onSubmit={formik.handleSubmit}>
        <div className="row">
          <InputField
            isFormInline={inlineForm}
            labelClassName={labelClassName}
            inputClassName="border-color-primary100"
            label="Name"
            name="name"
            value={formik.values?.name}
            onChange={formik.handleChange}
            error={formik.errors.name}
            touched={formik.touched.name}
          />
        </div>

        <div className="d-flex align-items-center gap-3">
          <label className={labelClassName}>Status</label>
          <Switch
            label={formik.values?.active ? 'Active' : 'Inactive'}
            checked={formik.values?.active}
            name="active"
            onChange={formik.handleChange}
          />
        </div>
      </form>
    </ModalWindow>
  )
}

const validationSchema = yup.object({
  name: yup.string().trim().nullable().required('Required'),
})

function CreateSupportTopicWindow({
  user_type,
}: {
  user_type: 'Student' | 'Tutor'
}) {
  const { closeModal } = useOpenedModal()
  const { width } = useViewPort()
  const queryClient = useQueryClient()
  const labelClassName =
    'w-70 text-nowrap font_16 font-semibold font-color-blue600'

  const inlineForm = width >= MEDIA_BREAKPOINT_MD ? true : false

  const formik = useFormik<Partial<SupportTopic>>({
    onSubmit: (topic) => {
      createSupportTopic(topic).then((newTopic) => {
        toast.success('Support Topic Created')
        closeModal()
        queryClient.invalidateQueries(['adminSupportTopics'])
      })
    },
    validationSchema,

    initialValues: {
      active: true,
      user_type,
      name: '',
    },
  })

  return (
    <ModalWindow
      formId="CreateTopicForm"
      mainBtn="Confirm"
      title="Add support topic"
      headerClassName="gradient-header text-center"
      mainButtonType="submit"
      mainBtnClassName="py-2 px-4 font_12 my-4"
      hasCloseBtn
    >
      <form id="CreateTopicForm" onSubmit={formik.handleSubmit}>
        <InputField
          isFormInline={inlineForm}
          labelClassName={labelClassName}
          inputClassName="border-color-primary100"
          label="Name"
          name="name"
          value={formik.values?.name}
          onChange={formik.handleChange}
          error={formik.errors.name}
          touched={formik.touched.name}
        />
      </form>
    </ModalWindow>
  )
}
