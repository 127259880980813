import Button from 'components/Button/Button'
import { InputFileProps } from 'components/Inputs/InputFile'
import { useCreateModal } from 'components/Modal'
import { PreviewFileModalWindow } from 'components/Modals/PreviewFileModalWindow'
import {
  ReactNode,
  Reducer,
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react'
import {
  FileType,
  getFileInfoFromUrl,
  getFileTypeFromMimeType,
} from 'utils/fileUrl'

type Action =
  | {
      type: 'delete_file_url' | 'cancel_staged_file' | 'cancel_delete_file_url'
    }
  | { type: 'set_staged_file'; payload: File }
  | {
      type: 'reset'
      payload: { url?: string; title?: string; base64?: string }
    }

type InputState = {
  name:
    | 'empty_url_empty_upload'
    | 'empty_url_has_upload'
    | 'has_url_empty_upload'
    | 'deleted_url_empty_upload'
    | 'has_url_has_upload'
  showDelete?: boolean
  showCancel?: boolean
  showPreview?: boolean
  showUploadIcon?: boolean
  showDownload?: boolean
  previewUrl?: string
  inputText?: string
  fileUrl?: { url: string; title?: string }
  type?: FileType
}
const placeholder = 'Click to upload File'
const reducerFn: Reducer<InputState, Action> = (state, action) => {
  let type: FileType | undefined

  switch (action.type) {
    case 'reset':
      return getInitialState(action.payload)

    case 'set_staged_file':
      type = getFileTypeFromMimeType(action.payload.type)

      return {
        ...state,
        name: state.fileUrl?.url
          ? 'has_url_has_upload'
          : 'empty_url_has_upload',
        inputText: action.payload.name,
        previewUrl: URL.createObjectURL(action.payload) || undefined,
        type,
        showCancel: true,
        showDelete: false,
        showPreview: type !== 'file',
        showDownload: type === 'file',
        showUploadIcon: false,
      }

    case 'cancel_staged_file':
      type = getFileInfoFromUrl(state.fileUrl?.url)?.type

      return {
        ...state,
        name: state.fileUrl?.url
          ? 'has_url_empty_upload'
          : 'empty_url_empty_upload',
        inputText: state.fileUrl?.title || placeholder,
        previewUrl:
          state.fileUrl?.url.replace(
            'disposition=attachment',
            'disposition=inline',
          ) || undefined,
        type,
        showCancel: false,
        showDelete: !!state.fileUrl?.url,
        showPreview: !!state.fileUrl?.url && type !== 'file',
        showDownload: type === 'file',
        showUploadIcon: !!state.fileUrl?.url,
      }

    case 'delete_file_url':
      return {
        ...state,
        name: 'deleted_url_empty_upload',
        showCancel: true,
        showDelete: false,
        showPreview: false,
        showDownload: false,
        previewUrl: undefined,
        inputText: placeholder,
        showUploadIcon: true,
      }

    case 'cancel_delete_file_url':
      type = getFileInfoFromUrl(state.fileUrl!.url)?.type
      return {
        ...state,
        name: 'has_url_empty_upload',
        showCancel: false,
        showDelete: true,
        showUploadIcon: false,
        showPreview: type !== 'file',
        showDownload: type === 'file',
        previewUrl: state.fileUrl?.url.replace(
          'disposition=attachment',
          'disposition=inline',
        ),
        inputText: state.fileUrl?.title,
        type,
      }
    default:
      return state
  }
}

const getInitialState = ({
  url,
  title,
  base64,
}: {
  base64?: string
  url?: string
  title?: string
}): InputState => {
  return url
    ? {
        name: 'has_url_empty_upload',
        showDelete: true,
        showPreview: getFileInfoFromUrl(url)?.type !== 'file',
        fileUrl: { url, title },
        inputText: title,
        showDownload: getFileInfoFromUrl(url)?.type === 'file',
        showUploadIcon: false,
        previewUrl: url.replace('disposition=attachment', 'disposition=inline'),
        type: getFileInfoFromUrl(url)?.type,
      }
    : {
        name: 'empty_url_empty_upload',
        inputText: placeholder,
        showUploadIcon: true,
      }
}

export function useFileUploadState({
  url,
  title,
  onChange,
  placeholder,
  base64,
}: {
  url?: string
  title: string
  base64?: string
  onChange: (f?: File | null) => void
  placeholder?: string
}) {
  const inputRef = useRef<HTMLInputElement>(null)

  const [state, dispatch] = useReducer(
    reducerFn,
    getInitialState({ url, title }),
  )

  useEffect(() => {
    dispatch({ type: 'reset', payload: { url, title, base64 } })
  }, [url, title, base64])

  function setStagedFile(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files ? e.target.files[0] : undefined
    if (file) {
      dispatch({ type: 'set_staged_file', payload: file })
      onChange(file)
    }
  }

  function deleteFileUrl() {
    dispatch({ type: 'delete_file_url' })
    onChange(null)
  }

  function cancelChanges() {
    if (state.name === 'deleted_url_empty_upload') {
      dispatch({ type: 'cancel_delete_file_url' })
    } else if (
      state.name === 'empty_url_has_upload' ||
      state.name === 'has_url_has_upload'
    ) {
      dispatch({ type: 'cancel_staged_file' })

      if (inputRef.current) inputRef.current.value = ''
    }
    onChange(undefined)
  }

  return { state, setStagedFile, deleteFileUrl, cancelChanges, inputRef }
}

export function FilePreviewInput({
  containerClassName = 'mt-3 lesson-upload-file',
  acceptFile,
  label,
  placeholder = 'Click to upload File',
  file_url,
  isRequired = false,
  labelClassName = '',
  isFormInline = false,
  onChange,
  name,
  canDelete = true,
  disabled,
}: Omit<InputFileProps, 'onChange'> & {
  onChange: (v: File | null | undefined) => void
}) {
  // TODO add reset value by passing empty value, to use in formik reset
  const { state, setStagedFile, deleteFileUrl, cancelChanges, inputRef } =
    useFileUploadState({
      url: file_url?.url || '',
      title:
        file_url?.title || getFileInfoFromUrl(file_url?.url)?.fullName || '',
      onChange,
      placeholder,
    })

  const PreviewFileModal = usePreviewModal()

  return (
    <div
      className={`input-main input-main--file ${containerClassName} ${
        isFormInline ? 'd-flex align-items-center gap-3' : ''
      }`}
    >
      {label && (
        <label htmlFor={label} className={`form-label ${labelClassName}`}>
          {label}
          {isRequired && <span className="required">*</span>}{' '}
        </label>
      )}

      <div
        className={`form-control overflow-hidden d-flex align-items-center justify-content-between ${
          state.showUploadIcon ? '' : 'input-main--file__uploaded'
        }`}
      >
        <label className="flex-1 h-100 input-main--file__label">
          <input
            ref={inputRef}
            type="file"
            className={`form-file`}
            accept={acceptFile}
            onChange={setStagedFile}
            name={name}
            disabled={disabled}
          />

          <i className="fa fa-upload me-2 align-middle" />
          <div className="d-flex flex-grow-1">
            <span className="file-name flex-grow-1">{state.inputText}</span>
          </div>
        </label>
        <div className="d-flex gap-3">
          {state.showDownload && (
            <Button
              variant="blue300"
              className="rounded-circle py-10 px-10 font_12 "
              onClick={() => window.open(state.previewUrl, '_blank')}
            >
              <i className="fa-solid fa-download" />
            </Button>
          )}
          {state.showPreview && (
            <Button
              variant="blue300"
              className="rounded-circle py-10 px-10 font_12 "
              onClick={() =>
                PreviewFileModal.openModal({
                  previewUrl: state.previewUrl || '',
                  type: state.type,
                })
              }
            >
              <i className="fa-solid fa-eye" />
            </Button>
          )}

          {canDelete && state.showDelete && (
            <Button
              variant="red500"
              className="rounded-circle py-10 px-10 font_12 "
              onClick={deleteFileUrl}
            >
              <i className="fa-solid fa-trash-can" />
            </Button>
          )}

          {state.showCancel && (
            <button
              className="button rounded-circle py-10 px-10 font_12 button-border-primary500"
              style={{
                width: '30px',
                height: '30px',
              }}
              onClick={cancelChanges}
            >
              <i className="fa-solid fa-x font-color-primary500" />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

function fileToBase64(file: File, callback: (base64String: string) => void) {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => {
    if (reader.result && typeof reader.result === 'string')
      callback(reader.result)
  }
  reader.onerror = (error) => {
    console.error('Error while converting file to base64:', error)
  }
}

function createFileFromBase64(base64String: string) {
  // Remove the data URL prefix
  const base64Data = base64String.replace(/^data:.*,/, '')

  // Convert the base64 string to a byte array
  const byteCharacters = atob(base64Data)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)

  const blob = new Blob([byteArray], { type: 'application/javascript' })

  const fileUrl = URL.createObjectURL(blob)

  return fileUrl
}

export function Base64FilePreviewInput({
  containerClassName = 'mt-3 lesson-upload-file',
  acceptFile,
  label,
  placeholder = 'Click to upload File',
  file_url,
  isRequired = false,
  labelClassName = '',
  isFormInline = false,
  onChange,
  name,
  value,
  canDelete = true,
  disabled,
}: Omit<InputFileProps, 'onChange' | 'value'> & {
  value?: string
  onChange: (v?: string) => void
}) {
  // TODO add reset value by passing empty value, to use in formik reset
  // const { state, setStagedFile, deleteFileUrl, cancelChanges, inputRef } =
  //   useFileUploadState({
  //     url: file_url?.url || '',

  //     title:
  //       file_url?.title || getFileInfoFromUrl(file_url?.url)?.fullName || '',
  //     onChange,
  //     placeholder,
  //   })

  const inputRef = useRef<HTMLInputElement>(null)
  const PreviewFileModal = usePreviewModal()

  return (
    <div>
      <div className="d-flex align-items-center gap-2">
        {value && (
          <>
            <button
              tabIndex={-1}
              type="button"
              className="font-color-primary"
              onClick={() =>
                value &&
                PreviewFileModal.openModal({
                  previewUrl: createFileFromBase64(value),
                  type: 'image',
                })
              }
            >
              Image
            </button>

            <button
              tabIndex={-1}
              className="ml-auto"
              type="button"
              onClick={() => onChange(undefined)}
            >
              <i className="fa-solid fa-trash-can font-color-red500" />
            </button>
          </>
        )}
        <label
          className={`${
            value ? '' : 'flex-1'
          } d-flex justify-content-between align-items-center base64-upload font-color-blue300`}
        >
          <input
            type="file"
            tabIndex={-1}
            className={`form-file w-0`}
            onChange={(e) => {
              const file = e.target.files ? e.target.files[0] : undefined
              if (file) {
                fileToBase64(file, onChange)
              } else {
                onChange(undefined)
              }
            }}
            ref={inputRef}
            accept={acceptFile}
            name={name}
          />
          {!value && 'Upload Image '}

          <i className="fa-solid fa-upload" />
        </label>
      </div>
    </div>
  )
}
const MediaViewerContext = createContext<
  ReturnType<
    typeof useCreateModal<{
      previewUrl: string
      type?: FileType
      downloadImageName?: string
    }>
  >
>({} as any)

export function usePreviewModal() {
  return useContext(MediaViewerContext)
}

export function MediaViewerContextProvider({
  children,
}: {
  children: ReactNode
}) {
  const PreviewFileModal = useCreateModal<{
    previewUrl: string
    type?: FileType
    downloadImageName?: string
  }>({
    portalRoot: 'preview-modal',
  })

  return (
    <>
      <MediaViewerContext.Provider value={PreviewFileModal}>
        {children}
        <PreviewFileModal.Container>
          {({ data }) =>
            data?.previewUrl &&
            data.type && (
              <PreviewFileModalWindow
                previewUrl={data.previewUrl.replace(
                  'disposition=attachment',
                  'disposition=inline',
                )}
                downloadImageName={data.downloadImageName}
                type={data.type}
              />
            )
          }
        </PreviewFileModal.Container>
      </MediaViewerContext.Provider>
    </>
  )
}
