import { useOrganization } from 'utils/Providers/OrganizationProvider'

import { Select } from 'components/Select/Select'
import { useGetRoadMaps } from 'services/curriculum'
import { SelectProps } from '../types'

export type CurriculumParamNames = {
  grade: string
  curriculum: string
  level: string
  lesson: string
}

export function CurriculumSelect(props: SelectProps<number>) {
  const { org } = useOrganization()
  const { data: roadMaps } = useGetRoadMaps(org?.id)

  return (
    <Select
      {...props}
      options={(roadMaps || []).map((o) => ({
        value: o.id,
        data: o,
        label: o.name,
      }))}
      label="Curriculum"
    />
  )
}
