import { QueryKey, useQuery, useQueryClient } from '@tanstack/react-query'
import { XPagination } from 'components/Pagination/XPagination'
import { useFilters } from 'core/hooks/useFilters'
import { PaginatedResponse } from 'core/types/Response'
import { useMemo } from 'react'
import { CommonParams } from 'services/utils'

export type ResourceItem = {
  id: number
  __state?: 'is_updating' | 'is_deleting' | 'is_deleted'
}

/**
 * @deprecated you can use `useResource` instead of this one
 */
export function usePaginatedResource<
  Filters extends CommonParams,
  Item extends ResourceItem,
>({
  exportFn,
  name,
  indexFn,
  initialParams,
  querykey,
  enabled,
  minimalPagination,
}: {
  name?: string
  querykey?: QueryKey
  indexFn: (params: Filters) => Promise<PaginatedResponse<Item>>
  initialParams: Filters
  enabled?: boolean
  exportFn?: (params?: Filters) => void
  minimalPagination?: boolean
}) {
  const queryClient = useQueryClient()
  const filters = useFilters<Filters>(initialParams)
  let querykeys: any[] = [filters.toString()]
  if (name) {
    querykeys.unshift(name)
  }
  if (querykey) {
    querykeys.push(...querykey)
  }

  const indexQuery = useQuery({
    queryFn: () => indexFn(filters.params),
    queryKey: querykeys,
    keepPreviousData: true,
    enabled,
  })

  const Pagination = useMemo(() => {
    return (
      indexQuery.data &&
      indexQuery.data.pagination &&
      indexQuery.data.data.length > 0 && (
        <XPagination
          minimal={minimalPagination}
          pagination={{
            ...indexQuery.data?.pagination,
            current:
              (indexQuery.isPreviousData
                ? filters.params.page
                : indexQuery.data.pagination?.current) || 0,
          }}
          // @ts-ignore
          onPageChange={(page) => filters.update({ page })}
        />
      )
    )
  }, [indexQuery.data, filters.params.page])

  const updateQueryCache = (mapFn: (item: Item) => Item) =>
    queryClient.setQueryData(
      [name, filters.toString()],
      (old?: PaginatedResponse<Item>) =>
        old && {
          ...old,
          data: old?.data.map(mapFn),
        },
    )

  const replaceListItem = (newItem: Item) =>
    updateQueryCache((i) => (i.id === newItem.id ? newItem : i))

  return {
    list: indexQuery.data?.data,
    filters,
    Pagination,
    exportFn,
    updateQueryCache,
    replaceListItem,
    ...indexQuery,
  }
}

export type ResourceItemUpdatePatcher<Item extends ResourceItem> = (
  item: Partial<Item> & { id: number },
  updateFn: () => Promise<Item>,
) => void
