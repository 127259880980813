import {
  ActionButtonProp,
  ActionsDropdown,
} from 'components/Admin/HeaderPage_v2'
import Button from 'components/Button/Button'
import { useAdminTableColumns } from 'layout/AdminDashboard'
import HotPickFilters from '../HotPickFilters'
import QueryFilterModal from '../QueryFilterModal'
import { QueryParams, QueryResource, ResourceItem } from '../useResource'
import QueryTablePagination from './QueryPagination'

export default function QueryTableHeader<
  P extends QueryParams,
  T extends ResourceItem,
>({ query }: { query: QueryResource<P, T> }) {
  return (
    <div
      className="border-b font_14 bg-white  font-semibold d-flex align-items-baseline  border-color-grey-opacity-10 "
      style={{ width: '100%' }}
    >
      <div
        style={{
          width: '100%',
          display: 'grid',
          alignItems: 'center',
          gap: '10px',
          gridAutoFlow: 'dense',
          gridTemplateColumns: `repeat(auto-fill, minmax(210px, 1fr))`,
          padding: '14px 15px',
        }}
      >
        <HotPickFilters query={query} />
      </div>
    </div>
  )
}

export function QueryControls<P extends QueryParams, T extends ResourceItem>({
  query,
  actions,
  exportFn,
  showReport,
  onReportClick,
  onColumnsConfigClick,
  canHideColumns = true,
}: {
  showReport?: boolean
  onReportClick?: () => void
  onColumnsConfigClick?: () => void
  canHideColumns?: boolean
  exportFn?: () => void
  query?: QueryResource<P, T>
  actions?: ActionButtonProp[]
}) {
  const { hiddenColumns, availableColumns } = useAdminTableColumns()
  return (
    <div className="ms-auto gap-1 gap-1 px-2 d-flex  border-color-grey-opacity-10 px-2 align-items-center  justify-content-end">
      {!showReport && (
        <>
          {actions
            ?.filter((q) => !q.appliedOnFilter && !q.hidden)
            .map((button, index) => (
              <Button
                disabled={button.disabled}
                key={index}
                isBorderButton
                variant="primary"
                onClick={() => button.onClick?.()}
                className="p-0 border-0 px-2 font_12  d-flex align-items-center  font-normal "
                style={{ borderLeft: '1px solid', height: '30px' }}
              >
                <span className="font-color-blue300 d-flex align-items-center gap-1">
                  <i className={button.icon} />
                  {button.label}
                </span>
              </Button>
            ))}
          {query && (
            <div style={{ width: 'max-content', whiteSpace: 'nowrap' }}>
              <QueryTablePagination query={query} />
            </div>
          )}

          {query && (
            <Button
              isBorderButton
              variant="grey"
              onClick={() => query?.refetch?.()}
              className="p-0 border-0 px-2 font_14 border d-flex align-items-center gap-1 font-semibold"
              style={{ borderLeft: '1px solid', width: '40px', height: '30px' }}
            >
              <i className="fa fa-refresh" />
            </Button>
          )}

          {exportFn && (
            <Button
              isBorderButton
              variant="grey"
              disabled={!exportFn}
              onClick={() => exportFn?.()}
              className="p-0 border-0 px-2 font_14 border d-flex align-items-center gap-1 font-semibold"
              style={{ borderLeft: '1px solid', width: '40px', height: '30px' }}
            >
              <i className="fa fa-file-export" />
            </Button>
          )}

          <ActionsDropdown
            actions={actions?.filter((q) => q.appliedOnFilter)}
            trigger={({ toggle, triggerRef }) => (
              <Button
                ref={triggerRef}
                onClick={toggle}
                isBorderButton
                variant="grey"
                className="p-0 border-0  px-2 font_14 border d-flex align-items-center gap-1 font-semibold"
                style={{
                  borderLeft: '1px solid',
                  width: '40px',
                  height: '30px',
                }}
              >
                <i className="fa fa-ellipsis-vertical" />
              </Button>
            )}
          />
          {canHideColumns && availableColumns && (
            <Button
              onClick={onColumnsConfigClick}
              isBorderButton
              tooltip={
                hiddenColumns?.length > 0
                  ? `${hiddenColumns.length} Hidden columns`
                  : 'Show/hide table columns'
              }
              variant={hiddenColumns?.length > 0 ? 'orange500' : 'grey'}
              className="p-2 font_14 border-0"
            >
              <i className="fa fa-table-columns" />
            </Button>
          )}
          {query && <QueryFilterModal query={query} />}
        </>
      )}
      {query?.data?.report_url && (
        <Button
          isBorderButton
          variant="grey"
          onClick={onReportClick}
          className="p-0 border-0 px-2 font_14 border d-flex align-items-center gap-1 font-semibold"
          style={{ borderLeft: '1px solid', width: '40px', height: '30px' }}
        >
          <i
            className={
              showReport
                ? 'fa fa-table-cells-large font-color-primary'
                : 'fa fa-chart-line font-color-primary'
            }
          />
        </Button>
      )}
    </div>
  )
}
