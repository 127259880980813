import { ReactNode, useEffect, useMemo } from 'react'
import { createPortal } from 'react-dom'

export default function Portal({
  children,
  id,
}: {
  children: ReactNode
  id?: string
}) {
  const container = id ? document.getElementById(id) : document.body

  return createPortal(<>{children}</>, container || document.body)
}
