import { useAuth } from 'auth/useAuth'
import useViewPort from 'core/hooks/useViewPort'
import { Admin, Role, Student, Tutor, UnknownUser } from 'core/types/User'
import { OrangeButton } from 'layout/AdminDashboard'
import { ReactNode, createContext, useContext } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useBaseUrl } from 'services/API'
import { updateAdmin } from 'services/admin/admins'
import { updateTutor } from 'services/admin/tutors'
import { useDomain } from 'utils/DomainProvider'
import { useLanguageProvider } from 'utils/LanguageProvider'
import { isProduction } from 'utils/helpers'
import { generateStudentAutoLoginLink } from './Modals/Admin/StudentModalWindow'

type FakeUser = {
  email: string
  name: Value
  role: Role
  password: string
}
const ischoolStudent: FakeUser = {
  email: 'ischool-student@rubikal.com',
  name: 'student',
  password: '123',
  role: 'Student',
}
const rubikalStudent: FakeUser = {
  email: 'rubikal-student@rubikal.com',
  name: 'student',
  password: '123',
  role: 'Student',
}
const tutor: FakeUser = {
  email: 'tutor@ischool.com',
  name: 'tutor',
  role: 'Tutor',
  password: '123',
}

const mentor: FakeUser = {
  email: 'mentor_x@rubikal.com',
  name: 'mentor',
  role: 'Mentor',
  password: '123',
}
const tutorPartTimer: FakeUser = {
  email: 'tutor_part_timer@rubikal.com',
  name: 'tutor_part_timer',
  role: 'Tutor',
  password: '123',
}

const tutorFullTimer: FakeUser = {
  email: 'tutor_full_timer@rubikal.com',
  name: 'tutor_full_timer',
  role: 'Tutor',
  password: '123',
}

const admin: FakeUser = {
  email: 'admin@ischool.com',
  name: 'admin',
  password: '123',
  role: 'Admin',
}

const adminTutorTeamLead: FakeUser = {
  email: 'admin_teamlead@ischool.com',
  name: 'admin_teamlead',
  role: 'Admin',
  password: '123',
}
const readOnlyAdmin: FakeUser = {
  email: 'readonly_admin@rubikal.com',
  name: 'readonly_admin',
  role: 'Admin',
  password: '123',
}
type Value =
  | 'student'
  | 'student_signup_plan'
  | 'student_signup_free'
  | 'student_login'
  | 'tutor'
  | 'tutor_part_timer'
  | 'tutor_full_timer'
  | 'tutor_login'
  | 'admin'
  | 'admin_login'
  | 'admin_teamlead'
  | 'readonly_admin'
  | 'production_tutor_login'
  | 'production_admin_login'
  | 'production_student_login'
  | 'production_student_signup_plan'
  | 'production_student_signup_free'
  | 'production_logged_in'
  | 'logged_in'
  | 'mentor'

const capitalize = (text: string) =>
  text.replace(/\b\w/g, (char) => char.toUpperCase())

export const QuickLoginProvider = createContext<{
  login: (user: UnknownUser) => void
}>({} as any)

type QuickLoginProps = { children: ReactNode }

export function QuickLogin({ children }: QuickLoginProps) {
  const { isMobile } = useViewPort()
  const { login, adminLogin, logout, user } = useAuth()
  const { domain, changeDevDomain, is_whitelabel } = useDomain()
  const { language, setLanguage } = useLanguageProvider()
  const navigate = useNavigate()
  const path = useLocation().pathname
  const [params] = useSearchParams()
  const plan = params.get('plan_id')
  const { isStaging, setProduction, setStaging } = useBaseUrl()

  const value: Value = isStaging
    ? path === '/login/admin'
      ? 'admin_login'
      : path === '/login/tutor'
      ? 'tutor_login'
      : path === '/login'
      ? 'student_login'
      : path === '/signup' && !plan
      ? 'student_signup_free'
      : path === '/signup' && plan
      ? 'student_signup_plan'
      : (user?.name as Value)
    : path === '/login/admin'
    ? 'production_admin_login'
    : path === '/login/tutor'
    ? 'production_tutor_login'
    : path === '/login'
    ? 'production_student_login'
    : path === '/signup' && !plan
    ? 'production_student_signup_free'
    : path === '/signup' && plan
    ? 'production_student_signup_plan'
    : (user?.name as Value)

  function quickAdminLogin(email: string, password: string) {
    adminLogin({ email, password }).then(() =>
      adminLogin({ email, otp: '1241' }),
    )
  }

  const Actions: { [key in Value]: () => void } = {
    admin_login: () => navigate('/login/admin'),
    admin: () => quickAdminLogin(admin.email, admin.password),
    readonly_admin: () =>
      quickAdminLogin(readOnlyAdmin.email, readOnlyAdmin.password),
    admin_teamlead: () =>
      quickAdminLogin(adminTutorTeamLead.email, adminTutorTeamLead.password),
    tutor_login: () => navigate('/login/tutor'),
    mentor: () => {
      navigate('/login/tutor')
      login('Tutor', mentor.email, mentor.password)
    },
    tutor: () => {
      navigate('/login/tutor')
      login('Tutor', tutor.email, tutor.password)
    },
    tutor_part_timer: () => {
      navigate('/login/tutor')
      login('Tutor', tutorPartTimer.email, tutorPartTimer.password)
    },
    tutor_full_timer: () => {
      navigate('/login/tutor')
      login('Tutor', tutorFullTimer.email, tutorFullTimer.password)
    },
    student_login: () => navigate('/login'),
    student_signup_free: () => navigate('/signup'),
    student_signup_plan: () => navigate('/signup?plan_id=advanced'),

    student: () =>
      is_whitelabel
        ? login('Student', rubikalStudent.email, rubikalStudent.password)
        : login('Student', ischoolStudent.email, ischoolStudent.password),
    production_student_login: () => navigate('/login'),
    production_admin_login: () => navigate('/login/admin'),
    production_tutor_login: () => navigate('/login/tutor'),
    production_student_signup_free: () => navigate('/signup'),
    production_student_signup_plan: () => navigate('/signup?plan_id=advanced'),
    production_logged_in: () => {},
    logged_in: () => {},
  }

  function userLogin(user: UnknownUser) {
    if (user.s_id) {
      generateStudentAutoLoginLink(user as Student).then((quickLoginUrl) => {
        logout()
        const urlObject = new URL(quickLoginUrl)
        const path = urlObject.pathname + urlObject.search
        navigate(path, { replace: true })
      })
    } else if (user.t_id) {
      updateTutor({ ...(user as Tutor), password: '123' }).then(() => {
        logout()
        login('Tutor', user.email, '123')
      })
    } else {
      updateAdmin(user.id, { ...(user as Admin), password: '123' }).then(() => {
        logout()
        login('Admin', user.email, '123')
      })
    }
  }

  function handleDomainChange(domain: string) {
    logout()
    navigate('/login')
    changeDevDomain && changeDevDomain(domain)
  }

  function handleChange(value: Value) {
    logout()
    value.includes('production') ? setProduction() : setStaging()
    Actions[value]()
  }

  return (
    <QuickLoginProvider.Provider value={{ login: userLogin }}>
      {!isProduction && (
        <div className="quick-login">
          <select
            className="rounded"
            style={{
              padding: '1px',
              border: '1px solid #333',
              // width: '100px',
            }}
            value={language}
            onChange={(e) => setLanguage(e.target.value as 'en' | 'ar')}
          >
            <option className="font_16" value={'en'}>
              en
            </option>
            <option value={'ar'}>ar</option>
          </select>
          <select
            className="rounded p-1 border"
            placeholder="Select Domain"
            defaultValue={domain}
            onChange={(e) => handleDomainChange(e.target.value)}
          >
            <option value="" disabled>
              Select Domain
            </option>
            <option value={'ischool'}>iSchool</option>
            <option value={'rubikal'}>rubikal</option>
          </select>
          <select
            className="rounded flex-1 overflow-hidden p-1 border"
            value={value}
            onChange={(e) => handleChange(e.target.value as Value)}
          >
            <optgroup label="Public">
              {['student_login'].map((key) => (
                <option key={key} value={key} className="text-capitalize">
                  {capitalize(key.replaceAll('_', ' '))}
                </option>
              ))}

              {!is_whitelabel &&
                [
                  'tutor_login',
                  'admin_login',
                  'student_signup_free',
                  'student_signup_plan',
                ].map((key) => (
                  <option key={key} value={key} className="text-capitalize">
                    {capitalize(key.replaceAll('_', ' '))}
                  </option>
                ))}
            </optgroup>

            {domain && (
              <optgroup label="Student">
                <option value={'student'}>{`${domain}  student`}</option>
              </optgroup>
            )}

            {!is_whitelabel && (
              <optgroup label="Tutor">
                {[mentor, tutor, tutorPartTimer, tutorFullTimer].map((t) => (
                  <option
                    key={t.name}
                    value={t.name}
                    disabled={is_whitelabel}
                    className="text-capitalize"
                  >
                    {t.email}
                  </option>
                ))}
              </optgroup>
            )}

            {!is_whitelabel && (
              <optgroup label="Admin">
                <option
                  value={admin.name}
                  disabled={is_whitelabel}
                  className="text-capitalize"
                >
                  {admin.email}
                </option>
                <option
                  value={readOnlyAdmin.name}
                  disabled={is_whitelabel}
                  className="text-capitalize"
                >
                  {readOnlyAdmin.email}
                </option>
                <option
                  value={adminTutorTeamLead.name}
                  disabled={is_whitelabel}
                  className="text-capitalize"
                >
                  {adminTutorTeamLead.email}
                </option>
              </optgroup>
            )}

            {isStaging && user && (
              <option disabled value={user?.name} className="text-capitalize">
                {user?.name || ''}
              </option>
            )}
          </select>
          {isStaging && !isMobile && (
            <div>
              {value === 'admin_login' && <Account {...admin} />}

              {value === 'tutor_login' && <Account {...tutor} />}

              {value === 'student_login' && is_whitelabel && (
                <Account {...rubikalStudent} />
              )}
              {value === 'student_login' && !is_whitelabel && (
                <Account {...ischoolStudent} />
              )}
            </div>
          )}
        </div>
      )}

      {children}
    </QuickLoginProvider.Provider>
  )
}

type QuickLoginButtonProps = { user: any }

export function QuickLoginButton({ user }: QuickLoginButtonProps) {
  const { login } = useContext(QuickLoginProvider)
  if (isProduction) return null
  return (
    <OrangeButton
      isBorderButton
      buttonSize="small"
      className="border-0 font_12 p-2 gap-2"
      icon="fa fa-right-to-bracket"
      onClick={() => {
        login(user as UnknownUser)
      }}
    >
      Login
    </OrangeButton>
  )
}

function Account(a: { email: string; password: string }) {
  return (
    <div className="d-flex gap-2">
      <EmailPass value={a.email} />
      <EmailPass value={a.password} />
    </div>
  )
}
function EmailPass({ value }: { value: string }) {
  return (
    <span className="text-select cursor-pointer font-color-white px-2 py-0 border rounded bg-color-blue600 ">
      {value}
    </span>
  )
}
