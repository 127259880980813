import { Select } from 'components/Select/Select'
import { ReactNode } from 'react'
import { SelectProps } from '../types'

export function BooleanSelect(
  props: SelectProps<boolean> & {
    labels?: {
      yes: ReactNode
      no: ReactNode
    }
  },
) {
  let { labels, value, onChange } = props

  if (!labels) {
    labels = {
      yes: 'Yes',
      no: 'No',
    }
  }
  const values = [
    { text: labels.yes, value: 'yes' },
    { text: labels.no, value: 'no' },
  ]

  return (
    <Select
      {...props}
      value={value === true ? 'yes' : value === false ? 'no' : undefined}
      onChange={(newValue) =>
        onChange && onChange(newValue ? newValue === 'yes' : undefined)
      }
      options={(values || []).map((o) => ({
        value: o.value as 'yes' | 'no',
        data: o.value,
        label: o.text,
      }))}
    />
  )
}
