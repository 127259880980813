import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from './Button/Button'

interface IReadMoreProps {
  content?: string
  lines?: number
  contentClassName?: string
}

const ReadMore = ({
  content = '',
  lines = 4,
  contentClassName,
}: IReadMoreProps) => {
  const [truncated, setTruncated] = useState(true)
  const [expanded, setExpanded] = useState(false)

  const { t } = useTranslation(['common'])
  const toggleLines = (event: any) => {
    // set a breakpoint at this line
    event.preventDefault()
    setExpanded(!expanded)
    setTruncated(!truncated)
  }

  return (
    <>
      {content?.length > 100 ? (
        <>
          <p
            dangerouslySetInnerHTML={{ __html: content }}
            className={`mb-0 ${contentClassName} ${
              !expanded ? `line-clamp-${lines}` : ''
            }`}
          />

          {!expanded && (
            <Button
              variant="primary200"
              className="p-0 font_13 text-nowrap"
              onClick={toggleLines}
              id={'readMore'}
              isButtonLink
            >
              {t('read_more')}
            </Button>
          )}

          {!truncated && expanded && (
            <>
              <Button
                variant="primary200"
                className="p-0 font_13 text-nowrap"
                onClick={toggleLines}
                id={'readLess'}
                isButtonLink
              >
                {t('read_less')}
              </Button>
            </>
          )}
        </>
      ) : (
        <p
          className={`mb-0  ${contentClassName}`}
          dangerouslySetInnerHTML={{ __html: content }}
        ></p>
      )}
    </>
  )
}
export default ReadMore
