import { TextareaHTMLAttributes } from 'react'

interface InputTextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  isRequired?: boolean
  error?: string
  touched?: boolean
  containerClassName?: string
  inputClassName?: string
  marginBottom?: number
  labelClassName?: string
  isFormInline?: boolean
  onEnterKeyDown?: () => void
}
const InputTextArea = ({
  label,
  isRequired = false,
  error,
  touched,
  rows = 7,
  containerClassName = '',
  inputClassName,
  onEnterKeyDown,
  marginBottom,
  labelClassName,
  isFormInline = false,
  ...props
}: InputTextAreaProps) => {
  return (
    <div
      className={`mb-3 input-main input-main-text ${containerClassName} ${
        isFormInline ? 'd-flex align-items-center gap-3' : ''
      }`}
      style={{ marginBottom: marginBottom ? `${marginBottom}px` : 0 }}
    >
      {label && (
        <label htmlFor={label} className={`form-label ${labelClassName}`}>
          {label}
          {isRequired && <span className="required">*</span>}{' '}
        </label>
      )}

      <textarea
        {...props}
        value={props.value}
        className={`form-control h-auto ${inputClassName} ${
          error && touched ? 'is-invalid' : ''
        } `}
        onKeyDown={(e) => {
          if (onEnterKeyDown && e.key === 'Enter') {
            e.stopPropagation()
            e.preventDefault()
            onEnterKeyDown()
          } else {
            props.onKeyDown && props.onKeyDown(e)
          }
        }}
        rows={rows}
      ></textarea>
      {/* {error && touched && <div className="invalid-feedback">{error}</div>} */}
    </div>
  )
}

export default InputTextArea
