import { ReactNode } from 'react'

type RadioGroupFieldState = '' | 'fail' | 'success' | 'correction'

interface RadioGroupLabelsProps<T extends number | string> {
  values?: {
    id?: number | string
    value: T
    text: ReactNode
    groupName?: string
    state?: RadioGroupFieldState
  }[]
  name?: string
  disabled?: boolean
  value?: T | undefined
  onChange?: (v: T | undefined) => void
  defaultValue?: string
  className?: string
  clearable?: boolean
}
const RadioGroupLabels = <T extends string | number>({
  value: radioValue,
  values,
  onChange,
  disabled,
  defaultValue,
  clearable,
  className = 'select-list-style__radio',
  name: _groupName,
}: RadioGroupLabelsProps<T>) => {
  const name = _groupName ? _groupName : values ? values[0].groupName : ''

  return (
    <ul className={`  ${className}`}>
      {values?.map(({ value, text, state = '' }, index) => {
        return (
          <li
            key={value}
            className="form-check d-flex align-items-center"
            style={{ maxWidth: 'max-content' }}
          >
            <label className="d-flex items-content-center form-check-label ">
              <input
                className={`form-check-input`}
                type="radio"
                name={name}
                disabled={disabled}
                onClick={() => {
                  onChange &&
                    onChange(
                      clearable && value === radioValue ? undefined : value,
                    )
                }}
                readOnly
                checked={value == radioValue}
              />
              <span className="form-check-text ">
                <span>{text}</span>
              </span>
            </label>
          </li>
        )
      })}
    </ul>
  )
}

export default RadioGroupLabels
