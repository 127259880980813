import Avatar from 'components/Avatar/Avatar'
import Button from 'components/Button/Button'
import { Location } from 'components/Cards/ClassesCards/ClassCard'
import {
  LessonHomework,
  LessonRevision,
  SessionManual,
  SessionSlides,
} from 'components/Cards/ClassesCards/NextClass/TutorNextClassCard'
import { useCreateModal } from 'components/Modal'
import FeedbackModalWindow from 'components/Modals/FeedbackModalWindow'
import QueryTableHeader, {
  QueryControls,
} from 'components/Query/QueryTable/QueryTableHeader'
import { useResource } from 'components/Query/useResource'
import Rating from 'components/Rating/Rating'
import Table from 'components/Table/Table'
import { SessionQuizModalWindow } from 'components/Tables/Admin/classTable'
import { AdminSession } from 'core/types/session'
import { SessionsResourceFilters } from 'pages/admin/Classes_v2'
import { QueryKeys } from 'services/QueryKeys'
import { getTutorSessions } from 'services/tutor/sessions'
import { formatDate, formatTimeRange } from 'utils/dateUtils'
import { StudentFeedbackStatus } from './StudentFeedbackStatus'
import ReportModalWindow from 'components/Modals/ReportModalWindow'

//SCSS

function useGetTutorClasses() {
  return useResource({
    queryFn: getTutorSessions,
    name: QueryKeys.tutorClasses,
    initialParams: {
      is_offline: false,
      status: 'completed',
      sort: '-start_at',
      page: 1,
    },
    filters: SessionsResourceFilters,
  })
}

export const TeacherClasses = () => {
  const query = useGetTutorClasses()
  const { filters } = query
  const FeedbackModal = useCreateModal<AdminSession>()
  const ReportModal = useCreateModal<AdminSession>()

  const ViewStudentQuizModal = useCreateModal()

  return (
    <>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-baseline bg-color-white shadow-sm">
        <QueryTableHeader query={query} />
        <QueryControls query={query} />
      </div>

      <Table
        {...query}
        columns={[
          'Student',
          { header: 'Lesson', text: 'start' },
          'Type',
          'Language',
          'Schdeule',
          { header: 'Location', hidden: !filters.params.is_offline },
          'Material',
          'Internal Feedback',
          'Quiz',
          'Feedback',
          'Review',
        ]}
      >
        {(data) => [
          <Avatar
            userImage={''}
            imageSize={40}
            userName={data.student.name}
            userNameSize={16}
            iconSize={20}
            userNameFontWeight="light"
            userNameFontColor="blue600"
            userNameClassName="line-clamp-1"
          >
            <p className="mb-0 font_14 font-light font-color-blue300 line-clamp-1 text-start">
              {data.student.s_id} - {data.grade.name}
            </p>
          </Avatar>,
          <p className="mb-0">
            <span className="font_14 font-light font-color-blue600 line-clamp-2 mb-2">
              {data.lesson.name}
            </span>
            <span className="font_14 font-light line-clamp-2 font-color-blue300">
              {data.level.name}
            </span>
          </p>,

          <p className="mb-0 font_14 font-light font-color-blue600">
            {data.is_free ? 'Free' : 'Normal'}
          </p>,

          <p className="mb-0 font_14 font-light font-color-blue600 text-capitalize">
            {data.student.language}
          </p>,

          <p className="mb-0 font_14 font-light font-color-black">
            <span className="d-xl-block mb-3 me-3 me-xl-0  ">
              {formatDate(data.start_at)}
            </span>
            <span className="text-break text-wrap">
              {formatTimeRange(data.start_at, data.end_at)}
            </span>
          </p>,
          <Location location={data.location} />,

          <TutorSessionMaterial session={data} />,
          <div className="classes-tutor-table__feedback text-capitalize text-center">
            <StudentFeedbackStatus
              submitOnStart={true}
              onFeedbackEdit={ReportModal.openModal}
              session={data}
              status={data.internal_feedback_status}
            />
          </div>,

          data.quiz_result && (
            <Button
              variant="primary600"
              className=" font_12 mt-1 mb-1 font-normal py-02 px-12"
              label={`${data.quiz_result.correct_count} / ${data.quiz_result.questions_count}`}
              isBorderButton
              onClick={() => ViewStudentQuizModal.openModal(data)}
            />
          ),

          <div className="classes-tutor-table__feedback text-capitalize text-center">
            <StudentFeedbackStatus
              onFeedbackEdit={FeedbackModal.openModal}
              session={data}
              status={data.feedback_status}
            />
          </div>,

          <Rating
            readOnly={true}
            value={data.tutor_feedback || undefined}
            starSize={18}
            containerClassName="rate"
          />,
        ]}
      </Table>
      <ViewStudentQuizModal.Container>
        <SessionQuizModalWindow />
      </ViewStudentQuizModal.Container>
      <FeedbackModal.Container>
        {({ data }) => (
          <FeedbackModalWindow
            session={data}
            viewOnly={data?.feedback_status === 'done'}
          />
        )}
      </FeedbackModal.Container>
      <ReportModal.Container>
        {({ data }) => <ReportModalWindow session={data} />}
      </ReportModal.Container>
    </>
  )
}

export function TutorSessionMaterial({ session }: { session: AdminSession }) {
  return (
    <div className="d-flex flex-wrap gap-2 mt-2  classes-tutor-table__chips">
      <SessionManual session={session} />
      <SessionSlides session={session} />
      {session.lesson.homework_url && <LessonHomework session={session} />}
      {session.lesson.revision_url && <LessonRevision session={session} />}
    </div>
  )
}
