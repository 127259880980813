import { useEffect } from 'react'

export function useEscapeHandler() {
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        const openedModals = document.querySelectorAll('.modal-container.open')

        if (openedModals.length) {
          const lastModal = openedModals[openedModals.length - 1] as HTMLElement
          lastModal.click()
        }
      }
    }

    document.addEventListener('keydown', handleEscape)

    return () => {
      document.removeEventListener('keydown', handleEscape)
    }
  }, [])
}
