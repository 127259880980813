import { BooleanSelect } from './base/BooleanSelect'
import { SelectProps } from './types'

export default function ActiveInactiveSelect(p: SelectProps<boolean>) {
  return (
    <BooleanSelect
      {...p}
      label={p.label || 'Active / Inactive'}
      labels={{
        yes: (
          <span className="d-flex gap-2 align-items-baseline">
            <i className="fa fa-circle font-color-green500 font_12" /> Active
          </span>
        ),
        no: (
          <span className="d-flex gap-2 align-items-baseline">
            <i className="fa-regular fa-circle font-color-grey font_12" />{' '}
            Inactive
          </span>
        ),
      }}
    />
  )
}
