import InputSelect from '../InputSelect'
import { TimezoneOptions } from './timezones'
import { useTranslation } from 'react-i18next'

export function TimezoneSelectIput({
  value,
  onChange,
}: {
  value?: string
  onChange: (timezone: string) => void
}) {
  const { t } = useTranslation(['common'])
  return (
    <InputSelect
      isFormInline
      label={t('timezone')}
      value={value}
      values={TimezoneOptions}
      onChange={(e) => onChange(e.currentTarget.value)}
    />
  )
}

export const getCurrentTimezone = () => {
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const currentDateOffset = new Date().getTimezoneOffset() / -60

  if (TimezoneOptions.find((t) => t.value === currentTimezone)) {
    return currentTimezone
  }

  return (
    TimezoneOptions.find((t) => t.offset === currentDateOffset)?.value ||
    'Africa/Cairo'
  )
}
