import { ReactNode } from 'react'

interface Tab {
  name: string
  clickHandler: (name: any) => any
  label: ReactNode
  disabled?: boolean
  completed?: boolean
  hidden?: boolean
}
interface TabsProps {
  tabsConfig: Tab[]
  className?: string
  tabId?: string
  activeTab?: string | number
  hidden?: boolean
}

const Tabs = ({
  tabsConfig,
  activeTab,
  className,
  tabId = 'nav-tab',
}: TabsProps) => {
  return (
    <div
      className={`nav mb-1 nav-pills tabs-main  ${className}`}
      id={tabId}
      role="tablist"
    >
      {tabsConfig
        .filter((t) => !t.hidden)
        .map((tab, idx) =>
          tab.hidden ? null : (
            <button
              className={`nav-link  ${activeTab == tab.name && 'active'} ${
                tab.completed && 'completed'
              }

          ${tab.name === 'FreeClass' && 'freeclass'}
          `}
              id={`nav-${tab.name}-tab`}
              data-bs-toggle="tab"
              data-bs-target={`#nav-${tab.name}`}
              type="button"
              role="tab"
              aria-controls={`nav-${tab.name}`}
              aria-selected="true"
              disabled={tab.disabled}
              onClick={() => tab.clickHandler(tab.name)}
              key={tab.name}
              style={{ fontSize: '14px' }}
            >
              {tab.label}{' '}
              {tab.completed && (
                <span className="fa-sharp fa-solid fa-circle-check fa-fw complete-icon"></span>
              )}
            </button>
          ),
        )}
    </div>
  )
}

export default Tabs
