import { CustomFilter } from 'components/Query/filter'
import { DateSelect } from 'components/Selectors/base/DateSelect'
import { Optional } from 'services/utils'
import { dateOrUndefined } from 'utils/dateUtils'

export const ToDateFilter = ({
  fromDateFilterName,
  label = 'End Date',
}: {
  label?: string
  fromDateFilterName: string
}): CustomFilter<Optional<Date>> => {
  return {
    type: 'custom',
    element: (props) => (
      <DateSelect
        {...props}
        value={dateOrUndefined(props.value)}
        label={label}
        dateHour="endOfDay"
        minDate={dateOrUndefined(props.filters?.params?.[fromDateFilterName])}
      />
    ),
  }
}
