import clsx from 'clsx'
import { HTMLProps, ReactNode } from 'react'

export type AlertProps = {
  // TODO: add more types
  type: 'warning'
  title?: string | ReactNode
  children?: ReactNode
} & HTMLProps<HTMLDivElement>

const icons = {
  warning: <i className="fa fa-triangle-exclamation" />,
}

const Alert = ({ title, type, children, className, ...props }: AlertProps) => {
  return (
    <div className={clsx(`alert alert--${type}`, className)} {...props}>
      <div className="alert__icon">{icons[type]}</div>

      <div className="alert__body">
        {!!title && <div className="alert__title">{title}</div>}

        {children}
      </div>
    </div>
  )
}

export default Alert
