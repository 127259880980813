import Axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import { Response } from 'core/types/Response'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { getCookieValue, isProduction } from 'utils/helpers'

export const BASE_URL = process.env.REACT_APP_API_URL || ''
export const BASE_URL_V2 = BASE_URL.replace('v1', 'v2')
export const STAGING_URL = 'https://ischool-api.rubikal.com/api/v1'
export const PRODUCTION_URL = 'https://api.ischooltech.com/api/v1'

export const FormDataHeaders = {
  headers: { 'Content-Type': 'multipart/form-data' },
}

export function useBaseUrl() {
  const [value, setValue] = useState<string>(
    () => localStorage.getItem('BASE_URL') || BASE_URL || '',
  )

  function setbaseUrl(baseURL: string) {
    setValue(baseURL)
    localStorage.setItem('BASE_URL', baseURL)
  }

  function setProduction() {
    setbaseUrl(PRODUCTION_URL)
  }

  function setStaging() {
    setbaseUrl(STAGING_URL)
  }

  return {
    isProduction: isProduction,
    isStaging: !isProduction,
    setProduction,
    setStaging,
  } as const
}

export const getCableURL = (token: string) =>
  `${process.env.REACT_APP_WS_URL}?token=${token}`

const headers: AxiosRequestConfig['headers'] = {
  'Content-Type': 'application/json',
  'app-version': '1',
}

function studentPrepareRequest(config: InternalAxiosRequestConfig<any>) {
  const HTTP_ACCEPT_LANGUAGE = localStorage.getItem('lng')
  config.headers['Accept-Language'] = HTTP_ACCEPT_LANGUAGE

  return prepareRequest(config)
}

function prepareRequest(config: InternalAxiosRequestConfig<any>) {
  const token = localStorage.getItem('token')
  const orgId = localStorage.getItem('OrgId')

  if (orgId && !config.headers['Organization-Id']) {
    config.headers['Organization-Id'] = orgId
  }

  config.headers['X-Fbp'] =  getCookieValue('_fbp')
  config.headers['X-Fbc'] =  getCookieValue('_fbc')

  if (token) {
    config.headers.Authorization = token
  }
  // if (process.env.REACT_APP_ENV !== 'production') {
  //   const url = config.baseURL?.split('/api/v1')
  //   if (url) {
  //     const [domain, role] = url

  //     config.baseURL = localStorage.getItem('BASE_URL') + role || BASE_URL || ''
  //   }
  // }

  return config
}

function prepareRequestError(error: any) {
  return Promise.reject(error)
}

function onSuccessResponse<Data, Config>(
  response: AxiosResponse<Data, Config>,
) {
  return response
}

function onErrorResponse(error: AxiosError<Response<any>>) {
  if (error.status === 401) {
    console.log('on error response, unauthorized 401')
  }

  if (error?.status === 426) {
    window.location.reload()
  }

  if (error.response?.data.message) {
    if (error.response.data.message === 'unauthorized') {
      window.dispatchEvent(new Event('logout'))

      toast.error('Unauthorized')
    }
    // errorToast(error)
    // Handle unauthorized requests here, such as redirecting to a login page
  }
  return Promise.reject(error)
}

const PublicAPI = Axios.create({
  baseURL: `${BASE_URL}/public`,
  headers,
})

PublicAPI.interceptors.request.use(studentPrepareRequest, prepareRequestError)
PublicAPI.interceptors.response.use(onSuccessResponse, onErrorResponse)

const StudentAPI = Axios.create({
  baseURL: `${BASE_URL}/student`,
  headers,
})

StudentAPI.interceptors.request.use(studentPrepareRequest, prepareRequestError)
StudentAPI.interceptors.response.use(onSuccessResponse, onErrorResponse)

const TutorAPI = Axios.create({
  baseURL: `${BASE_URL}/tutor`,
  headers,
})

TutorAPI.interceptors.request.use(prepareRequest, prepareRequestError)
TutorAPI.interceptors.response.use(onSuccessResponse, onErrorResponse)

const AdminAPI = Axios.create({
  baseURL: `${BASE_URL}/admin`,
  headers,
})

AdminAPI.interceptors.request.use(prepareRequest, prepareRequestError)
AdminAPI.interceptors.response.use(onSuccessResponse, onErrorResponse)

const UserAPI = Axios.create({
  baseURL: `${BASE_URL}/user`,
  headers,
})

UserAPI.interceptors.request.use(prepareRequest, prepareRequestError)
UserAPI.interceptors.response.use(onSuccessResponse, onErrorResponse)

export { AdminAPI, PublicAPI, StudentAPI, TutorAPI, UserAPI }
