import { CommunityType } from 'components/Community/Sidebar/SideBar'
import { UserNotificationPayload } from 'core/types/notification'
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { useCreateChannelSubscription } from './useCreateChannel'

type UserNotification = {
  action: 'user_notification'
  payload: UserNotificationPayload
}
type UnreadMessages = {
  action: 'unread_messages'
  payload: {
    community_id: number
    community_type: CommunityType
    has_unread_messages: boolean
    avatar_url: string
    last_message_at: string
    name_en: string
    name_ar: string
    name: string
  }
}

type UserChannelSocketMessageMap = {
  user_notification: UserNotification
  unread_messages: UnreadMessages
}

type UserChannelSocketMessage = UserNotification | UnreadMessages

const UserChannelProviderContext = createContext<{
  data: UserChannelSocketMessage | null
}>({} as any)

export default function UserChannelProvider({
  children,
}: {
  children: ReactNode
}) {
  const sub = useCreateChannelSubscription<UserChannelSocketMessage>()

  useEffect(() => sub.subscribe({ channel: 'UserChannel' }), [])

  const value = useMemo(() => ({ data: sub.data }), [sub.data])

  return (
    <UserChannelProviderContext.Provider value={value}>
      {children}
    </UserChannelProviderContext.Provider>
  )
}

export function useUserChannelData<T extends keyof UserChannelSocketMessageMap>(
  action: T,
): UserChannelSocketMessageMap[T]['payload'] | null {
  const { data } = useContext(UserChannelProviderContext)
  const previousDataRef = useRef<
    UserChannelSocketMessageMap[T]['payload'] | null
  >(null)

  if (data?.action === action) {
    previousDataRef.current =
      data.payload as UserChannelSocketMessageMap[T]['payload']
  }

  return previousDataRef.current
}
