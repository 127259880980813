import { useCallback, useEffect, useMemo, useState } from 'react'
import { pgQueryString } from 'services/utils'

export type FiltersHandles<T extends Object> = ReturnType<typeof useFilters<T>>

export function useFilters<T extends Object>(value: T) {
  const [state, set] = useState<T>(() => value)

  const update = useCallback((filters: Partial<T>) => {
    set((state) => ({
      ...state,
      ...filters,
    }))
  }, [])

  const reset = useCallback(() => {
    set(() => value)
  }, [])

  const clear = useCallback(() => {
    set(() => ({} as T))
  }, [])

  const clearAll = () => {
    set((s) =>
      Object.keys(s).reduce(
        (acc, key) => ({ ...acc, [key]: undefined }),
        {} as T,
      ),
    )
  }

  useEffect(() => {
    update(value)
  }, [JSON.stringify(value)])

  const toString = useMemo(() => () => JSON.stringify(state), [state])

  const toQueryString = useMemo(() => () => pgQueryString(state), [state])

  return {
    params: state,
    set,
    toString,
    toQueryString,
    update,
    reset,
    clear,
    clearAll,
  }
}
