import { TimezoneOptions } from 'components/Inputs/TimezoneSelect/timezones'
import { Select } from 'components/Select/Select'
import { SelectProps } from 'components/Selectors/types'
import { useTranslation } from 'react-i18next'

export function TimezoneSelect(props: SelectProps<string>) {
  const { t } = useTranslation(['common'])
  return (
    <Select
      {...props}
      label={t('timezone')}
      searchable
      renderTrigger={(tz) => (
        <span className="d-flex gap-2 font_14 align-items-baseline">
          <span className="font_12 ">{tz.data.GMT_offset_string}</span>
          <span>{tz.data.cityName}</span>
        </span>
      )}
      options={TimezoneOptions.map((tz) => ({
        value: tz.value,
        data: tz,
        label: (
          <span>
            <span>{tz.cityName}</span>
            <span className="font_12 d-block">
              {tz.GMT_offset_string} - {tz.altName}
            </span>
            {tz.isDST && <span className="font_12 d-block">Summer Time</span>}
          </span>
        ),
        searchIn: [tz.cityName, tz.GMT_offset_string, tz.altName, tz.value],
      }))}
    />
  )
}
