import { SelectProps } from 'components/Selectors/types'
import { ReactNode } from 'react'

export default function SelectContainer({
  labelPlacement = 'compact',
  labelClassName = 'form-label font-light  font_16 m-0 text-nowrap  font-semibold font-color-blue600',
  isRequired = false,

  ...props
}: SelectProps<any> & { children: ReactNode }) {
  return (
    <div
      className={`input-main flex-1 d-flex   ${
        labelPlacement === 'inline'
          ? 'align-items-center gap-3'
          : 'flex-column gap-2'
      }`}
      style={{
        maxWidth: '100%',
        position: 'relative',
      }}
    >
      {props.label && labelPlacement !== 'compact' && (
        <>
          <label htmlFor="" style={{ width: '20%' }} className={labelClassName}>
            {props.label}
            {isRequired && <span className="required">*</span>}{' '}
          </label>
        </>
      )}
      {props.children}
    </div>
  )
}
