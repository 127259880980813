import React from 'react'

interface InputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: string
  label?: string
  isRequired?: boolean
  placeholder?: string
  name?: string
  value?: string | number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  error?: string
  touched?: boolean
  inputClassName?: string
  containerClassName?: string
  labelClassName?: string
  isFormInline?: boolean
  onEnterKeyDown?: () => void
  marginBottom?: number
  minValue?: number
  maxValue?: number
}
const InputField = ({
  type = 'text',
  label,
  placeholder,
  isRequired = false,
  name,
  value,
  onChange,
  error,
  touched,
  inputClassName = '',
  containerClassName = '',
  labelClassName = '',
  onEnterKeyDown,
  isFormInline = false,
  marginBottom = 3,
  minValue,
  maxValue,
  ...props
}: InputFieldProps) => {
  const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onEnterKeyDown && e.key === 'Enter') {
      e.stopPropagation()
      e.preventDefault()
      onEnterKeyDown()
    } else {
      props.onKeyDown && props.onKeyDown(e)
    }
  }

  return (
    <div
      className={`mb-${marginBottom} input-main input-main-text ${containerClassName} ${
        isFormInline ? 'd-flex align-items-baseline gap-3' : ''
      }`}
    >
      {label && (
        <label htmlFor={label} className={`form-label ${labelClassName}`}>
          {label}
          {isRequired && <span className="required">*</span>}{' '}
        </label>
      )}
      <div className="w-100">
        <input
          {...(isRequired ? { required: isRequired } : {})}
          {...props}
          type={type}
          className={`form-control ${inputClassName} ${
            error && touched ? 'is-invalid' : ''
          } `}
          id={label}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyDown={onEnterKeyDown ? handleEnterKey : undefined}
          min={minValue}
          max={maxValue}
        />
        {error && touched && (
          <div className="invalid-feedback font-color-red500 font_14">
            {error}
          </div>
        )}
      </div>
    </div>
  )
}

export default InputField
