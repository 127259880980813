import { CustomFilter } from 'components/Query/filter'
import { DateSelect } from 'components/Selectors/base/DateSelect'
import { Optional } from 'services/utils'
import { dateOrUndefined } from 'utils/dateUtils'

export const FromDateFilter = ({
  toDateFilterName,
  label = 'From Date',
}: {
  label?: string
  toDateFilterName: string
}): CustomFilter<Optional<Date>> => {
  return {
    type: 'custom',
    element: (props) => (
      <DateSelect
        {...props}
        value={dateOrUndefined(props.value)}
        label={label}
        dateHour="startOfDay"
        maxDate={dateOrUndefined(props.filters?.params?.[toDateFilterName])}
      />
    ),
  }
}
