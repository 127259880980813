import { AdminSession } from 'core/types/session'
import { formatDate, formatTime } from 'utils/dateUtils'

export function SessionInfo({
  session,
}: {
  session?: {
    start_at: string
    lesson: { name: string }
    level: { name: string }
  }
}) {
  return (
    <div className="col-12 col-lg-4 mb-3 mb-lg-0">
      <div>
        <span className="d-block font-color-blue300 font_12 text-capitalize">
          Session
        </span>
        <span className="font-color-blue600 font_14 font-normal">
          {formatDate(session?.start_at, 'eeee d MMM yyyy')}
        </span>
        <span className="d-block font-color-blue300 font_12 mb-1">
          {formatTime(session?.start_at)}
        </span>
        <span
          style={{ height: '20px' }}
          className="d-block font-color-blue300 font_12 mb-1"
        >
          {session?.lesson.name} - {session?.level.name}
        </span>
      </div>
    </div>
  )
}
