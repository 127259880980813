import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import { TutorLeavesSummary } from 'pages/teacher/TeacherRequests/TutorLeavesSummary'
import { Outlet, RouteObject } from 'react-router-dom'

export const TutorRequestRoute: RouteObject = {
  path: 'request',
  element: (
    <div className="p-3">
      <TutorLeavesSummary className="d-flex gap-1 align-items-center flex-wrap border-b bg-white p-3 border-color-grey-opacity-5 rounded mb-3 shadow-sm" />
      <Outlet />
    </div>
  ),
  children: [
    {
      path: '',
      lazy: async () => {
        const { default: Component } = await import(
          'pages/teacher/TeacherRequests/index'
        )
        return { Component }
      },
    },
    {
      path: 'new',
      lazy: async () => {
        const { default: Component } = await import(
          'pages/teacher/TeacherRequests/CreateTutorRequestForm/CreateTutorRequestForm'
        )
        return { Component }
      },
    },
  ],
  ErrorBoundary,
}
