import { oneDay, oneHour, oneMinute, oneSecond, useTime } from './useTime'

export const useServerCountdown = (
  sessionTime: number,
  counterStart: number,
) => {
  const { elapsed } = useTime(oneSecond) // render trigger
  const remainingTime = sessionTime - (counterStart + elapsed)

  return { now: counterStart + elapsed, ...countdownParts(remainingTime) }
}

function countdownParts(remainingTime: number) {
  if (isNaN(remainingTime)) {
    return {
      remainingTime,
      days: NaN,
      hours: NaN,
      minutes: NaN,
      seconds: NaN,
    }
  }
  if (remainingTime < 0) {
    return {
      remainingTime,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  }

  return {
    days: calculateDays(remainingTime),
    hours: calculateHours(remainingTime),
    minutes: calculateMinutes(remainingTime),
    seconds: calculateSeconds(remainingTime),
    remainingTime,
  }
}

const calculateDays = (remainingTime: number) => {
  return Math.floor(remainingTime / oneDay)
}

const calculateHours = (remainingTime: number) => {
  return Math.floor((remainingTime % oneDay) / oneHour)
}

const calculateMinutes = (remainingTime: number) => {
  return Math.ceil((remainingTime % oneHour) / oneMinute)
}

const calculateSeconds = (remainingTime: number) => {
  return Math.floor((remainingTime % oneMinute) / oneSecond)
}
