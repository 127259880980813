import { CustomFilter } from 'components/Query/filter'
import { Select } from 'components/Select/Select'
import { useFlattenedGrades } from 'components/Selectors/curriculum/GradeSelect'
import { LessonSelect } from 'components/Selectors/curriculum/LessonSelect'
import { SelectProps } from 'components/Selectors/types'
import { defaultParamNames } from './CurriculumSelectFilter'

export const AllLessonsSelectFilter: CustomFilter<number | undefined> = {
  type: 'custom',
  element: (ctx) => <AllLessonsSelect {...ctx} label={'Label'} />,
}

function AllLessonsSelect(props: SelectProps<number>) {
  const { data } = useFlattenedGrades()

  return (
    <Select
      {...props}
      searchable
      renderTrigger={({ data: item }) => (
        <>
          {' '}
          {data?.grades?.find((g) => g.id === item.grade_id)?.name} -{' '}
          {item.name}
        </>
      )}
      options={data?.lessons.map((lesson) => ({
        value: lesson.id,
        group: data.grades.find((g) => g.id === lesson.grade_id)?.name,
        label: lesson.name,
        data: lesson,
        searchIn: [
          data.grades.find((g) => g.id === lesson.grade_id)!.name,
          lesson.name,
        ],
      }))}
    />
  )
}

export const LessonSelectFilter = (
  p = defaultParamNames,
): CustomFilter<number | undefined> => ({
  type: 'custom',
  element: (ctx) => (
    <LessonSelect
      {...ctx}
      curriculumId={ctx.filters?.params?.[p.curriculum]}
      levelId={ctx.filters?.params?.[p.level]}
      value={ctx.filters?.params?.[p.lesson]}
      onChange={(lessonId) => {
        ctx.filters?.update({
          [p.lesson]: lessonId,
        })
      }}
    />
  ),
})
