import SunIcon from 'components/Icon/IconsTSX'
import { MEDIA_BREAKPOINT_SM } from '../../constants'
import useViewPort from 'core/hooks/useViewPort'
import { ReactNode } from 'react'

interface SunCircleProps {
  circleSize?: number
  circleColor?: string
  opacity?: number
  sunIconSize?: number
  children: string | ReactNode
  breakpointSize?: number
  conatinerClassName?: string
}

const SunCircle = ({
  circleSize = 30,
  circleColor = 'primary',
  opacity,
  children,
  sunIconSize = 161,
  breakpointSize,
  conatinerClassName = "",
}: SunCircleProps) => {
  const { width } = useViewPort()

  const size =
    width <= MEDIA_BREAKPOINT_SM ? breakpointSize ?? circleSize : circleSize

  return (
    <div
      className={`sun-circle d-flex align-items-center justify-content-center position-relative mt-lg-4 mt-xl-0 ${conatinerClassName}`}
    >
      <img
        src="/images/sun.svg"
        alt="sun"
        width={sunIconSize}
        height={sunIconSize}
        className="position-absolute z-1"
      />
      <div
        className={`rounded-circle d-flex align-items-center justify-content-center z-2 bg-color-${circleColor}${
          opacity ? `-opacity-${opacity}` : ''
        }`}
        style={{ height: size, width: size }}
      >
        {children}
      </div>
    </div>
  )
}

export default SunCircle
