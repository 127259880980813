import { PaginatedResponse, Response } from 'core/types/Response'
import { AdminAPI } from 'services/API'
import {
  CommonParams,
  extractResponseData,
  pgQueryString,
} from 'services/utils'

export type SupportTopic = {
  id: number
  user_type: 'Student' | 'Tutor'
  name: string
  active: boolean
}

export type GetSupportTopicsQueryParams = {} & CommonParams

export function getSupportTopics(params: GetSupportTopicsQueryParams) {
  return AdminAPI.get<PaginatedResponse<SupportTopic>>(
    `support_topics${pgQueryString(params)}`,
  ).then((r) => r.data)
}

export function updateSupportTopic(plan: Partial<SupportTopic>) {
  return AdminAPI.put<Response<SupportTopic>>(
    `support_topics/${plan.id}`,
    plan,
  ).then(extractResponseData)
}

export function createSupportTopic(plan: Partial<SupportTopic>) {
  return AdminAPI.post<Response<SupportTopic>>(`support_topics`, plan).then(
    extractResponseData,
  )
}
