import useViewPort from 'core/hooks/useViewPort'
import { ReactNode } from 'react'
import { MEDIA_BREAKPOINT_XXL } from '../../constants'

interface AvatarProps {
  userImage: string
  userName?: string
  children?: ReactNode
  imageClassName?: string
  imageSize?: number
  iconColor?: string
  icon?: string
  iconSize?: number
  userNameSize?: number
  containerClassName?: string
  breakPointImageSize?: number
  userNameFontWeight?: string
  userNameFontColor?: string
  userNameClassName?: string
  userInfoClassName?: string
}

const Avatar = ({
  userImage,
  userName,
  children,
  imageClassName = '',
  imageSize = 100,
  iconColor = 'primary',
  iconSize = 34,
  icon = 'fa-user',
  userNameSize = 26,
  containerClassName = '',
  breakPointImageSize,
  userNameFontWeight = 'medbold',
  userNameFontColor = 'blue700',
  userNameClassName = '',
  userInfoClassName = '',
}: AvatarProps) => {
  const { width } = useViewPort()

  const avatarImageSize =
    width <= MEDIA_BREAKPOINT_XXL ? breakPointImageSize ?? imageSize : imageSize

  return (
    <div
      className={`${containerClassName} d-flex gap-3 align-items-center text-start`}
    >
      {userImage ? (
        <img
          src={userImage}
          className={`${imageClassName} rounded-circle`}
          width={avatarImageSize}
          height={avatarImageSize}
          alt={`${userName}-img`}
        />
      ) : (
        <div
          className={`bg-color-primary50-opacity-20 rounded-circle d-flex align-items-center justify-content-center ${imageClassName}`}
          style={{ width: avatarImageSize, height: avatarImageSize }}
        >
          <i
            className={`fa-solid ${icon} font-color-${iconColor} font_${iconSize}`}
          />
        </div>
      )}
      {(userName || children) && (
        <div className={`user-info ${userInfoClassName}`}>
          <h1
            className={`font_${userNameSize} font-${userNameFontWeight} font-color-${userNameFontColor} ${userNameClassName}`}
          >
            {userName}
          </h1>
          {children}
        </div>
      )}
    </div>
  )
}

export const TutorNextClassStudentAvatar = ({
  userImage,
  userName,
  children,
  imageClassName = '',
  imageSize = 200,
  iconColor = 'primary',
  iconSize = 34,
  icon = 'fa-user',
  userNameSize = 26,
  containerClassName = '',
  breakPointImageSize,
  userNameFontWeight = 'medbold',
  userNameFontColor = 'blue700',
  userNameClassName = '',
  userInfoClassName = '',
}: AvatarProps) => {
  const { width } = useViewPort()

  const avatarImageSize =
    width <= MEDIA_BREAKPOINT_XXL ? breakPointImageSize ?? imageSize : imageSize

  return (
    <div
      className={`${containerClassName}  d-flex gap-3 align-items-center text-start`}
    >
      {userImage ? (
        <img
          src={userImage}
          className={`${imageClassName} shadow-sm border-color-primary-opacity-30  rounded`}
          style={{
            objectFit: 'contain',
            border: '5px solid ',
            borderStyle: 'double',
            maxHeight: '120px',
            maxWidth: '200px',
          }}
          alt={`${userName}-img`}
        />
      ) : (
        <div
          className={`bg-color-primary50-opacity-20 rounded-circle d-flex align-items-center justify-content-center ${imageClassName}`}
          style={{ width: avatarImageSize, height: avatarImageSize }}
        >
          <i
            className={`fa-solid ${icon} font-color-${iconColor} font_${iconSize}`}
          />
        </div>
      )}
      {(userName || children) && (
        <div className={`user-info ${userInfoClassName}`}>
          <h1
            className={`font_${userNameSize} font-${userNameFontWeight} font-color-${userNameFontColor} ${userNameClassName}`}
          >
            {userName}
          </h1>
          {children}
        </div>
      )}
    </div>
  )
}
export default Avatar
