import { useLoggedIn } from 'auth/useAuth'
import { PaginatedResponse } from 'core/types/Response'
import { Notification } from 'core/types/notification'
import { pgQueryString } from 'services/utils'
import { usePaginatedResource } from 'utils/usePaginatedResource'

export function useGetNotifications() {
  const { API } = useLoggedIn()
  return usePaginatedResource({
    indexFn: (params) =>
      API.get<PaginatedResponse<Notification>>(
        `/notifications${pgQueryString(params)}`,
      ).then((r) => r.data),
    initialParams: {},
    querykey: ['notifications'],
  })
}
