import { RefObject, useEffect, useState } from 'react'

interface ArrowMoveOptions {
  index?: number | null
  length: number
  resetOnChange?: boolean
  onChange: (newIndex: number) => void
  onEnter?: (newIndex: number) => void
  containerRef?: RefObject<HTMLElement>
  enterKeys?: string[]
}

export function useArrowMove({
  index,
  length,
  onChange,
  onEnter,
  containerRef,
  enterKeys = ['Enter'],
}: ArrowMoveOptions) {
  const [navIndex, setNavIndex] = useState<number>(index || 0)
  useEffect(() => {
    if (navIndex > length) {
      setNavIndex(() => 0)
    }
  }, [length, navIndex, setNavIndex])

  const getMoveDownPosition = (currentIndex: number): number => {
    return currentIndex < length - 1 ? currentIndex + 1 : 0
  }

  const getMoveUpPosition = (currentIndex: number): number => {
    return currentIndex > 0 ? currentIndex - 1 : length - 1
  }

  useEffect(() => {
    if (typeof index === 'number') setNavIndex(index)
  }, [index])

  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'ArrowUp') {
      e.preventDefault()
      let newPosition = getMoveUpPosition(navIndex)
      setNavIndex(newPosition)
      onChange(newPosition)
    } else if (e.key === 'ArrowDown') {
      e.preventDefault()
      let newPosition = getMoveDownPosition(navIndex)
      setNavIndex(newPosition)
      onChange(newPosition)
    } else if (enterKeys.includes(e.key)) {
      onEnter && onEnter(navIndex)
    }
  }

  useEffect(() => {
    if (containerRef?.current) {
      containerRef.current.addEventListener('keydown', handleKeyDown)
      return () => {
        containerRef.current?.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [containerRef?.current, navIndex, handleKeyDown])

  return {
    navIndex,
    setNavIndex,
    getMoveDownPosition,
    getMoveUpPosition,
  }
}
