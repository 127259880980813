import PaginatedSelect from 'components/Select/PaginatedSelect'
import { SelectProps } from 'components/Selectors/types'
import { PaginatedResponse } from 'core/types/Response'
import { CommonParams } from 'services/utils'
import { ResourceItem } from 'utils/usePaginatedResource'
import SelectedUser from './SelectedUser'
import UserOption from './UserOption'

export type PaginatedUserSelectProps<
  T extends ResourceItem,
  P extends CommonParams,
> = SelectProps<number> & {
  disabled?: boolean
  querykey: string[]
  params?: P
  optionsQueryFn: (params: P) => Promise<PaginatedResponse<T>>
  valueQueryFn?: (id: number) => Promise<T>
  onUserChange?: (u: T) => void
  initialValue?: T
}

export function PaginatedUserSelect<
  T extends ResourceItem,
  P extends CommonParams,
>(props: PaginatedUserSelectProps<T, P>) {
  return (
    <PaginatedSelect
      {...props}
      showHeader
      leftIcon={
        <i className="fa-solid fa-user font_12 font-color-blue300 me-1" />
      }
      optionsQueryFn={props.optionsQueryFn}
      searchable
      renderSelected={(user) => <SelectedUser user={user} />}
      renderOption={(user) => <UserOption user={user} />}
      onChange={(v) => {
        props.onChange && props.onChange(v?.id)
        props.onUserChange && props.onUserChange(v as T)
      }}
    />
  )
}
