import { AdminPermissionKey } from 'auth/useAdminTabPermissions'
import { useLoggedInAdmin } from 'auth/useAuth'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import {
  Navigate,
  NonIndexRouteObject,
  Outlet,
  RouteObject,
} from 'react-router-dom'
import { AdminLessonsRoute } from 'routes/AdminRoutes/AdminLessonsRoute'
import { defRoute } from '../utils/defRoute'
import { AdminLMSRoute } from './AdminLMSRoute'
import { AdminOrganizationsRoute } from './AdminOrganizationsRoute'
import { AdminTeachersRoute } from './AdminTeachersRoute'
import {
  AdminActivityLogRoute,
  AdminBlockedDaysRoute,
  AdminBlogsRoute,
  AdminBookingRoute,
  AdminCategoriesRoute,
  AdminClassesRoute,
  AdminCouponsRoute,
  AdminDashboardRoute,
  AdminExportCenterRoute,
  AdminCommunityRoute,
  AdminFeedbackTemplatesRoute,
  AdminFinanceRoute,
  AdminGroupsRoute,
  AdminLeavesRoute,
  AdminMarketingLeadsRoute,
  AdminNotificationsRoute,
  AdminPayrollRoute,
  AdminPlansRoute,
  AdminProjectsRoute,
  AdminRankingRoute,
  AdminReportsRoute,
  AdminSlotsRoute,
  AdminStudentsRoute,
  AdminSupportRoute,
  AdminSupportTopicsRoute,
  AdminUserManageRoute,
  AdminOrdersRoute,
  AdminPhysicalLocationsRoute,
  AdminFlaggedMessagesRoute,
  SessionAreasRoute,
  AdminBadgesRoute,
  AdminModerationReportsRoute,
} from './FlatRoutes'
import { AdminTraineesRoute } from './AdminTraineesRoute'

export type AdminRoute = {
  permission: AdminPermissionKey
  link: string
  element?: JSX.Element
  text?: string
  children?: RouteObject[]
  lazy?: any
  hidden?: boolean
  organization_scoped?: boolean
}

export const AdminsRoutesPermission: AdminRoute[] = [
  AdminDashboardRoute,
  AdminOrganizationsRoute,
  AdminSlotsRoute,
  AdminLessonsRoute,
  AdminLMSRoute,
  AdminTraineesRoute,
  AdminTeachersRoute,
  AdminStudentsRoute,
  AdminClassesRoute,
  AdminGroupsRoute,
  AdminProjectsRoute,
  AdminCommunityRoute,
  AdminFlaggedMessagesRoute,
  AdminBookingRoute,
  AdminFinanceRoute,
  AdminSupportRoute,
  AdminCouponsRoute,
  AdminSupportTopicsRoute,
  AdminPayrollRoute,
  AdminReportsRoute,
  AdminOrdersRoute,
  AdminUserManageRoute,
  AdminPlansRoute,
  AdminRankingRoute,
  AdminCategoriesRoute,
  AdminFeedbackTemplatesRoute,
  AdminActivityLogRoute,
  AdminLeavesRoute,
  AdminBlogsRoute,
  AdminMarketingLeadsRoute,
  AdminBlockedDaysRoute,
  AdminExportCenterRoute,
  AdminNotificationsRoute,
  AdminPhysicalLocationsRoute,
  SessionAreasRoute,
  AdminBadgesRoute,
  AdminModerationReportsRoute,
]

const AdminRouteMap = AdminsRoutesPermission.reduce(
  (acc, cur) => ({ ...acc, [cur.permission]: cur.link }),
  {},
) as { [key: string]: string }

function AdminPermissionGuard({
  permission,
}: {
  permission: AdminPermissionKey
}) {
  const { loggedInAdmin } = useLoggedInAdmin()

  const permissionsArray = Object.keys(loggedInAdmin.permissions)

  if (permissionsArray.includes(permission) || permission === 'no_permission') {
    return <Outlet />
  }

  const link = AdminRouteMap[permissionsArray[0]]
  return <Navigate to={link} />
}

export const AdminRoutes: NonIndexRouteObject[] = [
  defRoute('dashboard'),
  ...AdminsRoutesPermission.map(({ permission, ...rest }) => ({
    element: <AdminPermissionGuard permission={permission} />,
    children: [
      {
        path: AdminRouteMap[permission],
        ErrorBoundary,
        ...rest,
      },
    ],
  })),
]
