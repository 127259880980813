import Axios from 'axios'
import { AdminAPI, StudentAPI, TutorAPI, UserAPI } from './API'

type ClientPayload = {
  policy: string
  key: string
  'x-amz-signature': string
  'x-amz-algorithm': string
  'x-amz-date': string
  'x-amz-credential': string
  uploadLink: string
}
type LessonPresignedUploadUrl = {
  clientPayload: ClientPayload
  videoId: string
}

export function getPresignedUploadUrl() {
  return AdminAPI.get<LessonPresignedUploadUrl>(
    `media/video_presigned_upload_url`,
  ).then((data) => data.data)
}

export function uploadFileToVdoCypher(
  { uploadLink, ...config }: ClientPayload,
  file: File,
) {
  return Axios.post(
    uploadLink,
    {
      ...config,
      success_action_status: 201,
      success_action_redirect: '',
      file,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    },
  )
}

export async function uploadLessonVideoToVdoCypher(file: File) {
  const { clientPayload, videoId } = await getPresignedUploadUrl()
  await uploadFileToVdoCypher(clientPayload, file)

  return videoId
}

type VdoCypherOTP = {
  otp: string
  playbackInfo: string
}
export function getVdoCypherOTP(videoId: string) {
  return UserAPI.get<VdoCypherOTP>(`media/${videoId}/video_otp`).then(
    (r) => r.data,
  )
}

export function getStudentVdoCypherOTP(sessionId: number) {
  return StudentAPI.get<VdoCypherOTP>(
    `sessions/${sessionId}/recap_video_otp`,
  ).then((r) => r.data)
}

export function getTutorVdoCypherOTP(lessonLogId: number) {
  return TutorAPI.get<VdoCypherOTP>(
    `lesson_logs/${lessonLogId}/recap_video_otp`,
  ).then((r) => r.data)
}

export function getVdoCypherIframeSrc(lessonOTP?: VdoCypherOTP) {
  return lessonOTP
    ? `https://player.vdocipher.com/v2/?otp=${lessonOTP.otp}&playbackInfo=${lessonOTP.playbackInfo}`
    : ''
}
