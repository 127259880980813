import { HTMLAttributes, ReactNode } from 'react'
import { Spinner } from 'utils/Spinner'
import { EmptyCard } from './Cards/LMS/EmptyLMSCards'

type DateOrEmptyProps = {
  list?: any[]
  children: ReactNode
  header?: string
  desc?: string
  buttonText?: string
}

export function DateOrEmpty({ list, children, ...props }: DateOrEmptyProps) {
  if (list?.length == 0) {
    return <EmptyCard {...props} />
  }
  return <>{children}</>
}

export function QuerySpinFetchEffect({
  isFetching,
  isLoading,
  children,
  skipSpinner,
  ...props
}: {
  skipSpinner?: boolean
  isLoading?: boolean
  isFetching?: boolean
  children: ReactNode
} & HTMLAttributes<HTMLDivElement>) {
  if (isLoading && !skipSpinner) {
    return <Spinner />
  }

  return (
    <div
      style={{ opacity: isFetching ? '0.5' : '1', ...props.style }}
      {...props}
    >
      {children}
    </div>
  )
}

export function AutoFitGrid<T>({
  itemMaxWidth,
  itemMinWidth,
  children,
  gap = '30px',
  className,
  ...props
}: {
  itemMaxWidth?: string
  itemMinWidth?: string
  children: ReactNode
  className?: string
  gap?: string
} & Omit<HTMLAttributes<HTMLDivElement>, 'children'>) {
  return (
    <div
      className={className}
      style={{
        display: 'grid position-relative',
        gap,
        gridTemplateColumns: `repeat(auto-fit, 
          minmax(
          ${itemMinWidth ? itemMinWidth : 'auto'},
          ${itemMaxWidth ? itemMaxWidth : '1fr'}
          )`,
      }}
      {...props}
    >
      {children}
    </div>
  )
}
