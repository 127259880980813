import { format, parse } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { arEG } from 'date-fns/locale'
import { appLanguage } from './helpers'
import { convertToArabicNumerals } from './numbers'

type Input = string | Date | null | undefined
type Options = {
  locale?: Locale | undefined
  weekStartsOn?: 0 | 2 | 1 | 3 | 5 | 4 | 6 | undefined
  firstWeekContainsDate?: number | undefined
  useAdditionalWeekYearTokens?: boolean | undefined
  useAdditionalDayOfYearTokens?: boolean | undefined
}

function toString(formatString: string, date: Input, timezone?: string) {
  if (!date) return ''

  let d = new Date(date)

  if (isNaN(d.getTime())) {
    return ''
  }

  const isArabic = appLanguage() === 'ar'

  let formattedDate = ''
  if (timezone) {
    formattedDate = formatInTimeZone(
      d,
      timezone,
      formatString,
      isArabic ? { locale: arEG } : undefined,
    )
  } else {
    formattedDate = format(
      d,
      formatString,
      isArabic ? { locale: arEG } : undefined,
    )
  }

  if (isArabic) {
    return convertToArabicNumerals(formattedDate)
  }

  return formattedDate
}
const isDateObject = (value: any): value is Date => value instanceof Date

const toDate = (format: string, date: string | Date) =>
  isDateObject(date) ? date : parse(date, format, new Date())

export const dateOrUndefined = (date: Input, format = DATE_FORMAT) =>
  !date ? undefined : toDate(format, date)

export const DATE_FORMAT = 'd/MM/yyyy'
export const TIME_FORMAT = 'h:mm a'

export function formatDate(
  date: Input,
  format = DATE_FORMAT,
  timezone?: string,
) {
  return toString(format, date, timezone)
}

export function formatTime(
  date: Input,
  format = TIME_FORMAT,
  timezone?: string,
) {
  return toString(format, date, timezone)
}

export function formatTimeRange(date1: Input, date2: Input) {
  return toString('h:mm', date1) + ' - ' + toString(TIME_FORMAT, date2)
}

export const cairoTimezone = 'Africa/Cairo'

export function formatCairoDate(date: Input, format = DATE_FORMAT) {
  return toString(format, date, cairoTimezone)
}

export function formatCairoTime(date: Input, format = TIME_FORMAT) {
  return toString(format, date, cairoTimezone)
}

export type Month = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const currentYear = new Date().getFullYear()
export const years = Array.from({ length: 4 }).map((_, i) => currentYear - i +1)

export function convertSecondstoDay(n: number) {
  if (!n) return '0H 0M'
  let days = Math.floor(n / (24 * 3600))

  n = n % (24 * 3600)
  let hours = Math.floor(n / 3600)

  n %= 3600
  let minutes = Math.floor(n / 60)

  n %= 60
  let seconds = n

  let dateFormated = ``

  if (days) dateFormated += `${days}D `
  dateFormated += `${hours}H ${minutes}M `

  return dateFormated || '0H 0M'
}

export function addHours(date: Date, hours: number) {
  const hoursToAdd = hours * 60 * 60 * 1000
  date.setTime(date.getTime() + hoursToAdd)
  return date
}
