import { AdminRoute } from '.'

export const AdminOrganizationsRoute: AdminRoute = {
  permission: 'Organizations',
  link: 'organizations',
  lazy: async () => {
    const { default: Component } = await import(
      'pages/admin/Organization/OrganizationsPage'
    )
    return { Component }
  },
  children: [
    {
      path: '',
      lazy: async () => {
        const { default: Component } = await import(
          'pages/admin/Organization/OrganizationsList'
        )
        return { Component }
      },
    },
    {
      path: 'create',
      lazy: async () => {
        const { default: Component } = await import(
          'pages/admin/Organization/CreateOrganization'
        )
        return { Component }
      },
    },
    {
      path: ':organizationId',
      lazy: async () => {
        const { default: Component } = await import(
          'pages/admin/Organization/EditOrganization'
        )
        return { Component }
      },
    },
  ],
}
