import Logo from 'components/Logo/Logo'
import Portal from 'components/Modal/Portal'
import {
  disableBodyScroll,
  enableBodyScroll,
} from 'components/Modal/hooks/useModalApi'
import { useEffect } from 'react'

export function FullScreenSpinner() {
  useEffect(() => {
    disableBodyScroll()
    return () => {
      enableBodyScroll()
    }
  }, [])
  return (
    <Portal>
      <div
        id="spinner-overlay"
        className="mt-4 d-flex justify-content-center align-items-center"
        style={{ height: '100%', marginTop: '200px' }}
      >
        <Logo />
        <div className="spinner-border text-primary" role="status" />
      </div>
    </Portal>
  )
}
export function Spinner({
  className = '',
  size = 'lg',
  inline,
}: {
  size?: 'sm' | 'lg'
  className?: string
  inline?: boolean
}) {
  return (
    <div
      className={`${
        inline ? 'd-inline-flex' : 'd-flex my-3'
      } justify-content-center  font_12 align-items-center ${className} `}
      style={{ height: '100%' }}
    >
      <div
        className={`spinner-border spinner-border-sm  ${
          size == 'lg' ? '' : 'spinner-border-sm font_12'
        }`}
        role="status"
      />
    </div>
  )
}
