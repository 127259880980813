import { useLoggedInStudent } from 'auth/useAuth'
import Alert from 'components/Alert'
import { useIsMobile } from 'components/useMediaQueries'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export type WarnningBannerProps = {}

const WarnningBanner = () => {
  const { t } = useTranslation(['common'])
  const { loggedInStudent } = useLoggedInStudent()
  const isMobile = useIsMobile()

  if (
    !loggedInStudent.material_view_warning &&
    !loggedInStudent.online_attendance_warning
  )
    return null

  return (
    <Alert
      style={{
        position: isMobile ? 'relative' : 'fixed',
        ...(isMobile
          ? {}
          : {
              zIndex: 20,
              top: 85,
              right: 0,
              left: 0,
              width: 'fit-content',
            }),
        margin: '0 auto',
      }}
      type="warning"
      title={
        loggedInStudent.material_view_warning
          ? t('title_material_pending')
          : t('title_attendance_needed')
      }
    >
      {loggedInStudent.material_view_warning ? (
        <Link
          to={`/student/classes?id=${loggedInStudent.first_unviewed_material_session_id}`}
        >
          {t('body_material_pending')}
        </Link>
      ) : (
        t('body_attendance_needed')
      )}
    </Alert>
  )
}

export default WarnningBanner
