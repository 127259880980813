import { useQuery } from '@tanstack/react-query'
import { Select } from 'components/Select/Select'
import { getPlans } from 'services/admin/plans'
import { SelectProps } from './types'

export function useGetActivePlans() {
  return useQuery({
    queryFn: () => getPlans().then((p) => p.data.filter((p) => p.active)),
    queryKey: ['active_plans'],
    staleTime: Infinity,
  })
}

export default function PlanSelect(props: SelectProps<number>) {
  const { data } = useGetActivePlans()
  return (
    <Select
      {...props}
      label="Plan"
      renderTrigger={({ data }) => data.name}
      options={data?.map((p) => ({
        value: p.id,
        data: p,
        label: (
          <>
            <span className="font-color-blue600 font_14">{p.name}</span>
            <span className="d-block font_12 font-color-blue300">{p.slug}</span>
          </>
        ),
      }))}
    />
  )
}
