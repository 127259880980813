import { appLanguage } from './helpers'

const arabicNumerals = {
  0: '٠',
  1: '١',
  2: '٢',
  3: '٣',
  4: '٤',
  5: '٥',
  6: '٦',
  7: '٧',
  8: '٨',
  9: '٩',
}

export function convertToArabicNumerals(stringWithEnglishNumerals: string) {
  return stringWithEnglishNumerals.replace(
    /[0-9]/g,
    // @ts-ignore
    (match) => arabicNumerals[match],
  )
}

export function formatNumber(text: string | number, zeroPadLength = 0) {
  if (text === undefined || text === null || text === '') return ''
  const str = text?.toString().padStart(zeroPadLength, '0')

  return appLanguage() === 'ar' ? convertToArabicNumerals(str) : str
}
