import { useEffect } from 'react'

function bindPopupPosition(
  target: HTMLElement,
  popup: HTMLElement,
  placement: 'bottom' | 'bottom-left' | 'bottom-right',
  fixToTriggerWidth: boolean,
) {
  const targetRect = target.getBoundingClientRect()
  const popupRect = popup.getBoundingClientRect()

  // Calculate initial position based on placement
  if (placement === 'bottom-left') {
    popup.style.top = targetRect.top + targetRect.height + 1 + 'px'
    popup.style.left = targetRect.left + 'px'
  } else if (placement === 'bottom-right') {
    const documentWidth = document.documentElement.clientWidth
    popup.style.top = targetRect.top + targetRect.height + 1 + 'px'
    popup.style.right = documentWidth - targetRect.right + 'px'
  }

  popup.style.display = 'block'
  popup.style.width = fixToTriggerWidth
    ? `${targetRect.width}px`
    : `${Math.max(popupRect.width, targetRect.width)}px`
}

export const useClickOutside = (
  refs: React.RefObject<HTMLElement>[],
  callback: () => void,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        refs.every(
          (ref) => ref.current && !ref.current.contains(event.target as Node),
        )
      ) {
        callback()
      }
    }

    // Add event listener when the component mounts
    document.addEventListener('click', handleClickOutside)
    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [refs, callback])
}

export function usePopupPlacement({
  triggerRef,
  popupRef,
  closePopup,
  placement = 'bottom-left',
  fixToTriggerWidth,
}: {
  triggerRef: React.RefObject<HTMLElement>
  popupRef: React.RefObject<HTMLElement>
  closePopup: () => void
  placement: 'bottom' | 'bottom-left' | 'bottom-right'
  fixToTriggerWidth: boolean
}) {
  useClickOutside([triggerRef, popupRef] || [], closePopup)

  useEffect(() => {
    function updatePosition() {
      if (triggerRef.current && popupRef.current)
        bindPopupPosition(
          triggerRef.current,
          popupRef.current,
          placement,
          fixToTriggerWidth,
        )
    }
    window.addEventListener('scroll', updatePosition)
    window.addEventListener('resize', updatePosition)

    return () => {
      window.removeEventListener('scroll', updatePosition)
      window.removeEventListener('resize', updatePosition)
    }
  }, [])

  useEffect(() => {
    let trigger = triggerRef.current
    let container = popupRef.current
    if (trigger && container) {
      bindPopupPosition(trigger, container, placement, fixToTriggerWidth)
    }
  }, [triggerRef.current, popupRef.current])

  return null
}
