import { Response } from 'core/types/Response'
import { Hash } from 'core/types/Hash'
import { StudentAPI } from 'services/API'
import { SignupData } from 'components/Cards/SignUp/SignUpCard'

export function getPaymentMethod(location?: string, currency?: string) {
  let url = '/payments/payment_method?'
  if (location) url += `location=${location}&`
  if (currency) url += `currency=${currency}&`
  return StudentAPI.get(url)
}

export function createOrderKashier(
  metaData: SignupData,
  paymentMethod: 'online_card' | 'mobile_wallet' | 'bank_installment' | 'fawry',
) {
  return StudentAPI.post<Response<Hash>>(`/payments/kashier/order`, {
    meta: metaData,
    payment_method: paymentMethod,
  })
}

export function createOrderPaymob(
  metaData: SignupData,
  paymentMethod: 'online_card' | 'mobile_wallet' | 'bank_installment',
) {
  return StudentAPI.post(`/payments/paymob/order`, {
    meta: { ...metaData, order_type: 'booking', booking_time: 'book_now' },
    payment_method: paymentMethod,
  })
}

export function createOrderStripe(
  metaData: SignupData,
  paymentMethod: 'online_card',
) {
  return StudentAPI.post(`/payments/stripe/order`, {
    meta: { ...metaData, booking_time: 'book_now' },
    payment_method: paymentMethod,
  })
}

export function upgradePlanKashier(
  metaData: SignupData,
  paymentMethod: 'online_card' | 'mobile_wallet' | 'bank_installment' | 'fawry',
) {
  return StudentAPI.post<Response<Hash>>(`/payments/kashier/upgrade_plan`, {
    meta: {
      plan_id: metaData.plan_id,
      booking_id: metaData.bookingId,
      order_type: 'upgrade_plan',
      booking_time: 'later',
      promo_code: metaData.promo_code,
      currency: metaData.currency,
    },
    payment_method: paymentMethod,
  })
}

export function UpgradePlanStripe(
  metaData: SignupData,
  paymentMethod: 'online_card' | 'mobile_wallet' | 'bank_installment' | 'fawry',
) {
  return StudentAPI.post<Response<Hash>>(`/payments/stripe/upgrade_plan`, {
    meta: {
      plan_id: metaData.plan_id,
      booking_id: metaData.bookingId,
      order_type: 'upgrade_plan',
      booking_time: 'later',
      promo_code: metaData.promo_code,
      currency: metaData.currency,
    },
    payment_method: paymentMethod,
  })
}

export function UpgradePlanPaymob(
  metaData: SignupData,
  paymentMethod: 'online_card' | 'mobile_wallet' | 'bank_installment' | 'fawry',
) {
  return StudentAPI.post<Response<Hash>>(`/payments/paymob/upgrade_plan`, {
    meta: {
      plan_id: metaData.plan_id,
      booking_id: metaData.bookingId,
      order_type: 'upgrade_plan',
      booking_time: 'later',
      promo_code: metaData.promo_code,
      currency: metaData.currency,
    },
    payment_method: paymentMethod,
  })
}

export function webhook() {
  return StudentAPI.post(`/payments/stripe/webhook`)
}

export function getSessionStatus(sessionId: string) {
  return StudentAPI.post(
    `/payments/stripe/session_status?session_id=${sessionId}`,
  )
}

export type PaymentProviders = {
  kashier: {
    online_card: boolean
    mobile_wallet: boolean
    bank_installment: boolean
    fawry: boolean
  }
  paymob: {
    online_card: boolean
    mobile_wallet: boolean
    bank_installment: boolean
  }
  stripe: {
    online_card: boolean
  }
}

type AvailableProviders = {
  payment_providers: PaymentProviders
}

export type PaymentMethod =
  | 'online_card'
  | 'bank_installment'
  | 'mobile_wallet'
  | 'fawry'

type PaymentVendor = 'kashier' | 'stripe' | 'paymob'

type MethodVendor = {
  online_card: PaymentVendor | false
  bank_installment?: PaymentVendor | false
  mobile_wallet?: PaymentVendor | false
  fawry?: PaymentVendor | false
}

export function getAvailableProviders(location?: string) {
  return StudentAPI.get<Response<AvailableProviders>>(
    '/payments/payment_method/available_providers',
  ).then((response) => {
    let pp = response?.data?.data?.payment_providers
    let methodVendor: MethodVendor
    if (location !== 'egyptian') {
      methodVendor = {
        online_card: 'stripe',
      }
    } else {
      methodVendor = {
        online_card:
          (pp?.kashier?.online_card && 'kashier') ||
          (pp?.paymob?.online_card && 'paymob'),

        bank_installment:
          (pp?.kashier?.bank_installment && 'kashier') ||
          (pp?.paymob?.bank_installment && 'paymob'),

        mobile_wallet:
          (pp?.kashier?.mobile_wallet && 'kashier') ||
          (pp?.paymob?.mobile_wallet && 'paymob'),

        fawry: pp?.kashier?.fawry && 'kashier',
      }
    }

    let paymentMethods: {
      icon: string
      value: PaymentMethod
    }[] = []

    if (location !== 'egyptian') {
      paymentMethods = [renderMethodOption('online_card')]
    } else {
      paymentMethods = Object.keys(methodVendor)
        .filter((method) => methodVendor[method as PaymentMethod])
        .map((method) => renderMethodOption(method as PaymentMethod))
    }

    return { methodVendor, paymentMethods }
  })
}

const PaymentMethodsIcons = {
  online_card: '/images/payment/visa.svg',
  bank_installment: '/images/payment/installment.svg',
  mobile_wallet: '/images/payment/wallet.svg',
  fawry: '/images/payment/fawry.png',
}

const renderMethodOption = (value: PaymentMethod) => {
  return {
    icon: PaymentMethodsIcons[value],
    value,
  }
}
