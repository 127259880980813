import { EmptyCard } from 'components/Cards/LMS/EmptyLMSCards'

export function NoDataTableRow({ colSpan }: { colSpan: number }) {
  return (
    <tr style={{ height: '100%' }}>
      <td
        colSpan={colSpan}
        className="text-center border-0 p-0"
        style={{ height: '100%' }}
      >
        <EmptyCard />
      </td>
    </tr>
  )
}
