import { CustomFilter } from 'components/Query/filter'
import SlotDaySelect from 'components/Selectors/SlotDaySelect'

export const SlotDaySelectFilter = ({
  slotIdFilterName,
}: {
  slotIdFilterName: string
}) =>
  ({
    type: 'custom',
    element: (ctx) => (
      <SlotDaySelect
        label="Day"
        {...ctx}
        onChange={(d) =>
          ctx.filters?.update({ [ctx.name!]: d, [slotIdFilterName]: undefined })
        }
      />
    ),
  } as CustomFilter<Day | undefined>)
