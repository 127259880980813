import { useQuery } from '@tanstack/react-query'
import { AuthContext, AuthState } from 'auth/AuthProvider'
import Button from 'components/Button/Button'
import Switch from 'components/Inputs/Switch'
import { getCurrentTimezone } from 'components/Inputs/TimezoneSelect/TimezoneSelect'
import { TimezoneSelect } from 'components/Selectors/TimezoneSelect'
import { User } from 'core/types/User'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { listGroups2, StudentGroupedSlots } from 'services/student/groups'
import {
  GetStudentBookingSlotsParams,
  studentListSlots2,
  studentLockSlot,
} from 'services/student/slots'
import { formatDate } from 'utils/dateUtils'

function formatDateRangeInTimezone(
  timezone: string,
  start_date: string,
  end_date: string,
) {
  const startTime = formatDate(new Date(start_date), 'h:mm', timezone)
  const endTime = formatDate(new Date(end_date), 'h:mm a', timezone)
  return `${startTime} - ${endTime}`
}

function useGetFirstSlotBookings({
  isStudentInGroup,
  ...params
}: GetStudentBookingSlotsParams & { isStudentInGroup: boolean }) {
  return useQuery({
    queryKey: ['first_bookings', params.sessionsFrequency],
    keepPreviousData: false,
    queryFn: () =>
      isStudentInGroup ? listGroups2() : studentListSlots2(params),
  })
}

function useGetSecondSlotBookings({
  enabled,
  ...params
}: {
  enabled: boolean
  first_booking_id?: number
} & GetStudentBookingSlotsParams) {
  return useQuery({
    queryFn: () => studentListSlots2({ ...params }),
    queryKey: ['second_bookings', params.first_booking_id],
    keepPreviousData: false,
    enabled,
  })
}

const StepFour = <T extends User = User>({
  setStep,
  grade,
  language,
  email,
  signupData,
  setSignupData,
  IsUserFreeClass,
  isUpgradingPlan,
  isStudentInGroup,
  setBookingId,
  bookingOnlyHandler,
  gtmAction,
}: any) => {
  const [firstBookingId, setFirstBookingId] = useState(signupData?.booking_id)
  const [secondBookingId, setSecondBookingId] = useState(signupData?.booking_id)
  const [timezone, setTimezone] = useState(getCurrentTimezone())

  const [sessionsFrequency, setSessionsFrequency] = useState(1)
  const params = {
    isStudentInGroup,
    sessionsFrequency,
    booking_type: IsUserFreeClass ? 'free' : 'paid',
    language,
    grade,
    email,
  }
  const { data: firstBookings, isLoading } = useGetFirstSlotBookings(params)

  const { data: secondBookings } = useGetSecondSlotBookings({
    enabled: !!firstBookingId && !isStudentInGroup && sessionsFrequency > 1,
    first_booking_id: firstBookingId,
    ...params,
  })

  const { signup } = useContext(AuthContext) as AuthState<T>

  const { t } = useTranslation(['common'])

  const handleSigningup = async () => {
    let booking_id = secondBookingId
      ? [firstBookingId, secondBookingId].join(',')
      : firstBookingId
    try {
      if (bookingOnlyHandler)
        bookingOnlyHandler(booking_id, parseInt(grade), language)
      else {
        let SignupFullData = {
          ...signupData,
          booking_id,
          timezone,
        }
        if (setBookingId) setBookingId(booking_id)
        if (IsUserFreeClass) {
          const response: any = await signup('Student', SignupFullData)
          gtmAction && gtmAction(response?.fb_tracking_events!)
        } else {
          await studentLockSlot(booking_id, signupData?.email)
          setSignupData(SignupFullData)
          setStep(4)
        }
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message, { position: 'top-center' })
    }
  }

  const handleSkip = () => {
    setSignupData({
      ...signupData,
      timezone,
    })
    setStep(4)
  }

  return (
    <div className="d-grid gap-3">
      {!IsUserFreeClass && !isStudentInGroup && (
        <div className="py-2 font-color-blue300  font-semibold align-items-center justify-content-end d-flex gap-3 ">
          <span>1 / week</span>
          <Switch
            checked={sessionsFrequency === 2}
            onClick={(e) => {
              setSessionsFrequency((s) => (s === 2 ? 1 : 2))
              setFirstBookingId(undefined)
              setSecondBookingId(undefined)
            }}
          />
          <span>2 / week</span>
        </div>
      )}
      {!isStudentInGroup &&
      <div className="d-flex font-color-black font_16 font-bold mb-2 text-center select-list-style-timezone">
        <TimezoneSelect
          value={timezone}
          labelPlacement="inline"
          labelClassName="font-normal font-color-blue300"
          onChange={(t) => setTimezone(t!)}
          clearable={false}
        />
      </div>}

      {!isLoading && firstBookings && firstBookings?.length === 0 && (
        <div className="rounded border p-4 text-center font_14">
          {t('no_available_sessions')}
        </div>
      )}

      {!isLoading && firstBookings && firstBookings?.length > 0 && (
        <>
          <BookingSelect
            label={sessionsFrequency === 1 ? 'Select Day' : '1st Day'}
            timezone={timezone}
            value={firstBookingId}
            onChange={(bookingId) => {
              setFirstBookingId(bookingId)
              setSecondBookingId(undefined)
            }}
            slotsGroupedByDay={firstBookings || []}
          />
          {firstBookingId && sessionsFrequency > 1 && (
            <BookingSelect
              label={'2nd Day'}
              timezone={timezone}
              value={secondBookingId}
              onChange={setSecondBookingId}
              slotsGroupedByDay={secondBookings || []}
            />
          )}
        </>
      )}

      {!IsUserFreeClass && !bookingOnlyHandler && (
        <p
          className="link-text font-light font_16 font-color-primary text-center mt-3"
          role="button"
          onClick={handleSkip}
        >
          {t('choose_date_later')}
        </p>
      )}
      <button
        className={`${
          !firstBookingId || (sessionsFrequency > 1 && !secondBookingId)
            ? 'button-disabled-login'
            : ''
        } button button-color-primary w-100 rounded-pill font_16 font-semibold mt-4 m-auto  button-size-med`}
        onClick={handleSigningup}
        disabled={
          !firstBookingId || (sessionsFrequency > 1 && !secondBookingId)
        }
      >
        {t('book')}
      </button>
    </div>
  )
}

function BookingSelect({
  value,
  onChange,
  slotsGroupedByDay,
  timezone,
  label,
}: {
  slotsGroupedByDay: StudentGroupedSlots[]
  value: number
  timezone: string
  label?: ReactNode
  onChange: (bookingId?: number) => void
}) {
  const [selectedDay, setDay] = useState<StudentGroupedSlots>()

  useEffect(() => {
    if (slotsGroupedByDay)
      setDay(
        slotsGroupedByDay?.find((g) => g?.slots.find((i) => i.id === value)) ||
          slotsGroupedByDay[0],
      )
  }, [slotsGroupedByDay])

  const slot = selectedDay?.slots?.find((s) => s.id === value)
  if (slot) {
    return (
      <div className="border-b border-color-grey-opacity-10">
        <SlotSummary
          label={label}
          {...slot}
          {...selectedDay}
          timezone={timezone}
        >
          <Button
            tooltip="Change Time"
            type="button"
            isBorderButton
            variant="grey"
            className="border-0 px-2 py-2  font_16 gap-2  font-semibold   rounded  cursor-pointer "
            onClick={() => onChange(undefined)}
          >
            <span className="fa fa-times " />
          </Button>
        </SlotSummary>
      </div>
    )
  }

  return (
    <div className="d-grid gap-2">
      <SlotSummary label={label} {...selectedDay} timezone={timezone} />

      <div className="d-flex gap-2 px-1 pb-2 overflow-x-auto">
        {slotsGroupedByDay.map((group) => {
          const isActive = group.start_at_day == selectedDay?.start_at_day

          return (
            <div
              style={{ borderRadius: '22px', border: '1px solid' }}
              className={`py-2 px-3 font_16 align-items-center border-color-grey font-color-blue300 justify-content-center d-flex flex-column cursor-pointer ${
                isActive
                  ? 'font-color-white bg-color-primary'
                  : ' bg-color-white hover-primary300'
              }`}
              onClick={() => setDay(group)}
            >
              <span
                className={`font-normal font-semibold text-nowrap ${
                  isActive ? 'font-color-white' : 'font-color-primary '
                }`}
              >
                {group.weekday}
              </span>
              <span
                className={`font-semibold  ${
                  isActive ? 'font-color-white' : 'font-color-blue300'
                }`}
              >
                {group.start_at_day}
              </span>
              <span
                className={` ${
                  isActive ? 'font-color-white' : 'font-color-grey '
                }`}
              >
                {group.start_at_month}
              </span>
            </div>
          )
        })}
      </div>
      <div className="d-flex flex-wrap gap-2 mt-2">
        {selectedDay?.slots?.map((slot) => (
          <div
            style={{ borderRadius: '22px', border: '1px solid' }}
            className="font-color-blue300 hover-primary300 d-flex flex-column cursor-pointer py-2 border-color-grey-opacity-20 px-3 font-semibold align-items-center justify-content-center"
            onClick={() => onChange(slot.id)}
          >
            <span className=" text-nowrap">
              {formatDateRangeInTimezone(timezone, slot.start_at, slot.end_at)}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default StepFour

function SlotSummary(props: {
  timezone?: string
  weekday?: string
  start_at_day?: string
  start_at_month?: string
  start_at?: string
  end_at?: string
  label?: ReactNode
  children?: ReactNode
}) {
  return (
    <div className="d-flex gap-2 align-items-baseline px-1 py-2">
      <span className="text-nowrap font_13" style={{ minWidth: '50px' }}>
        {props.label}
      </span>
      <span className="flex-1 d-flex gap-2 align-items-center font-semibold font-color-blue300 text-nowrap ">
        <span className="font-color-primary text-end" style={{ width: '40px' }}>
          {props.weekday}
        </span>
        <span className="">{props.start_at_day}</span>
        <span className="">{props.start_at_month}</span>
        {props.timezone && props.start_at && props.end_at && (
          <span className="font-color-primary">
            {formatDateRangeInTimezone(
              props.timezone,
              props.start_at,
              props.end_at,
            )}
          </span>
        )}
      </span>
      {props.children}
    </div>
  )
}
