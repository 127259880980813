import { useQueryClient } from '@tanstack/react-query'
import { useAuth } from 'auth/useAuth'
import { oneSecond } from 'components/Cards/ClassesCards/NextClass/useTime'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import i18n from '../i18n'
import { useOverlaySpinner } from './SpinnerOverlayProvider'

function getStoredLanguage() {
  const storedLang = localStorage.getItem('lng') as 'en' | 'ar' | null
  if (storedLang && ['en', 'ar'].includes(storedLang)) {
    return storedLang
  }

  return 'en'
}

const LanguageContext = createContext<{
  language: 'en' | 'ar'
  setLanguage: (l: 'en' | 'ar', refresh?: boolean) => void
}>({} as any)

export function LanguageProvider({ children }: { children: ReactNode }) {
  const path = useLocation().pathname
  const qc = useQueryClient()

  const { user } = useAuth()

  const { showSpinner, hideSpinner } = useOverlaySpinner()

  const [language, setLanguage] = useState(getStoredLanguage)

  const mustBeEnglish =
    path === '/login/admin' ||
    path === '/login/tutor' ||
    !!path.match(/^\/tutor/) ||
    !!path.match(/^\/admin/) ||
    user?.isAdmin ||
    user?.isTutor

  const changeLanguage = useCallback(
    (newLanguage: 'ar' | 'en', refresh = false) => {
      if (language === newLanguage) return

      if (mustBeEnglish) {
        localStorage.setItem('lng', 'en')
        setLanguage('en')
      } else {
        showSpinner()
        localStorage.setItem('lng', newLanguage)
        setLanguage(newLanguage)

        setTimeout(() => {
          hideSpinner()
        }, 1 * oneSecond)
        if (refresh) {
          // window.location.reload()
          qc.refetchQueries([])
        }
        i18n.changeLanguage(newLanguage)
      }
    },
    [mustBeEnglish, user, language, setLanguage, showSpinner, hideSpinner],
  )

  if (mustBeEnglish) changeLanguage('en')

  return (
    <LanguageContext.Provider
      key={language}
      value={{
        language,
        setLanguage: changeLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

export function useLanguageProvider() {
  return useContext(LanguageContext)
}
