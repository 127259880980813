import { PaginatedResponse, Response } from 'core/types/Response'
import { Tutor } from 'core/types/User'
import { AdminAPI } from 'services/API'
import {
  CommonParams,
  extractResponseData,
  pgQueryString,
} from 'services/utils'
export type FileImportStatusTrainee = {
  id?: number
  name?: string
  status?: 'pending' | 'proccessed' | 'failed' | 'contains failed records'
  error_message?: string
  file_url?: string
  metadata?: {
    success_count?: number
  }
  created_at?: string
  updated_at?: string
}
export type TutorFilters = Partial<{
  active: boolean
  language: string
  employment_type: string
  level: number
  grade: number
  lesson: number
  slot_id: string
  team_lead_id: number
  organization_Id?: number
  assigned_to_level?: number
  curriculum?: number
  not_assigned_to_level?: number
  is_mentor?: boolean
  mentor_id?: number
  supervisor_id?: number
  moderator_id?: number
  status: string
  tags?:string[]
  study_plan_state: 'pending' | 'in-progress' | 'completed'
  study_plan_is_published: false
  study_plan_quiz_state: 'pending' | 'completed'
  batch_number: string
  final_training_status?:
    | 'accepted'
    | 'rescheduled'
    | 'pending'
    | 'rejected'
    | 'out'
}> &
  CommonParams

export function getAllTutors({
  level,
  organization_Id,
  ...params
}: TutorFilters) {
  return AdminAPI.get<PaginatedResponse<Tutor>>(
    `tutors${pgQueryString({ ...params, level_id: level })}`,
    organization_Id
      ? { headers: { 'Organization-Id': organization_Id } }
      : undefined,
  ).then((r) => r.data)
}

export function getTutorById(tutorID: number, minimal?: boolean) {
  return AdminAPI.get<Response<Tutor>>(
    `tutors/${tutorID}${minimal ? '?minimal=true' : ''}`,
  ).then(extractResponseData)
}

export function createTutor(tutor: Tutor) {
  return AdminAPI.post<Response<Tutor>>(`tutors`, tutor).then(
    extractResponseData,
  )
}
export function updateTutor(tutor: Tutor) {
  return AdminAPI.put<Response<Tutor>>(`tutors/${tutor.id}`, tutor).then(
    extractResponseData,
  )
}

export function addTutorLevel(id: number, level_id: number) {
  return AdminAPI.post<Response<Tutor>>(`tutors/${id}/add_level`, {
    level_id,
  }).then(extractResponseData)
}

export function removeTutorLevel(id: number, level_id: number) {
  return AdminAPI.delete<Response<Tutor>>(`tutors/${id}/remove_level`, {
    data: { level_id },
  }).then(extractResponseData)
}

export async function getTutorsSelectOptions() {
  return AdminAPI.get<PaginatedResponse<Tutor>>(`/tutors?minimal=true`)
    .then((r) => r.data)
    .then(({ data }) =>
      data.map((t) => ({
        ...t,
        text: `${t.t_id ? `${t.t_id} - ` : ''}${t.name}`,
        value: t.id,
      })),
    )
}

export function addTutorOrganization(
  tutor_id: number,
  organization_id: number,
) {
  return AdminAPI.post<Response<Tutor>>(`tutors/${tutor_id}/add_organization`, {
    organization_id,
  }).then(extractResponseData)
}

export function removeTutorOrganization(
  tutor_id: number,
  organization_id: number,
) {
  return AdminAPI.delete<Response<Tutor>>(
    `tutors/${tutor_id}/remove_organization`,
    {
      data: { organization_id },
    },
  ).then(extractResponseData)
}

export function importTutorsTemplate(tutorsFile: FormData) {
  return AdminAPI.get<Response<any>>('tutors/import_template').then(
    extractResponseData,
  )
}

export function importTutors(tutorsFile: FormData) {
  return AdminAPI.post<Response<any>>('tutors/import', tutorsFile, {
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then(extractResponseData)
}

export function importTutorsStatus() {
  return AdminAPI.get<PaginatedResponse<FileImportStatusTrainee>>(
    'import_sheets?sheet_type=tutors&per_page=3',
  ).then(extractResponseData)
}
export function downloadTemplateTrainees() {
  return AdminAPI.get('/tutors/import_template', {
    responseType: 'text',
  }).then((response) => response.data)
}
