import { Placement } from '@popperjs/core'
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { usePopper } from 'react-popper'

interface PopupProps {
  renderTrigger: (p: {
    setAnchor: Dispatch<SetStateAction<HTMLElement | null>>
  }) => ReactNode
  children: (p: { anchor: HTMLElement | null }) => ReactNode
  showOverlayShadow?: boolean
  matchTriggerWidth?: boolean
  minWidth?: number
  placement?: Placement
  isOpen?: boolean
  onOpenChange: (v: boolean) => void
}

export default function Popup({
  renderTrigger,
  children,
  matchTriggerWidth = false,
  showOverlayShadow = false,
  placement = 'bottom-start',
  minWidth,
  onOpenChange,
  isOpen,
}: PopupProps) {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)
  const [popupRoot, setPopupRoot] = useState<HTMLElement | null>(null)
  const overlayRef = useRef<HTMLDivElement>(null)
  const [anchorWindowSize, setAnchorWindowSize] = useState<number>()

  const { styles, attributes, update } = usePopper(anchor, popupRoot, {
    strategy: 'fixed',
    modifiers: [
      { name: 'offset', options: { offset: [0, 4] } },
      {
        name: 'preventOverflow',
        options: {
          boundary: 'clippingParents',
          padding: 8,
          altAxis: true, // Prevents overflow on both axes
        },
      },
      {
        name: 'flip',
        options: { fallbackPlacements: ['top', 'bottom', 'left', 'right'] },
      },
    ],
    placement,
  })

  useLayoutEffect(() => {
    if (!anchor) return
    if (matchTriggerWidth) {
      if (minWidth) {
        if (anchor.clientWidth > minWidth) {
          setAnchorWindowSize(anchor.clientWidth)
        } else {
          setAnchorWindowSize(minWidth)
        }
      } else {
        setAnchorWindowSize(anchor.clientWidth)
      }
    }
  }, [
    anchor,
    update,
    matchTriggerWidth,
    anchor?.clientHeight,
    anchor?.clientWidth,
  ])

  return (
    <>
      {renderTrigger({ setAnchor })}

      {isOpen && (
        <div
          className="overflow-hidden position-fixed flex-scroll "
          ref={overlayRef}
          onClick={() => onOpenChange(false)}
          style={{
            height: '100vh',
            width: '100vw',
            top: 0,
            left: 0,
            zIndex: 9999,
            backgroundColor: showOverlayShadow
              ? 'rgba(0, 0, 0, 0.01)'
              : undefined,
          }}
        >
          <div
            ref={setPopupRoot}
            className="flex-scroll bg-color-white shadow rounded"
            style={{
              ...styles.popper,
              display: 'flex',
              flexDirection: 'column',
              width: anchorWindowSize,
              maxHeight: '50vh',
              height: 'min-content',
              zIndex: 1,
            }}
            {...attributes.popper}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            {children({ anchor })}
          </div>
        </div>
      )}
    </>
  )
}
