import { PaginatedResponse, Response } from 'core/types/Response'
import { addMinutes, parse } from 'date-fns'
import groupBy from 'lodash.groupby'
import { StudentAPI } from 'services/API'
import { extractResponseData } from 'services/utils'
import { formatDate } from 'utils/dateUtils'

export type StudentGroupedSlots = {
  index: number
  weekday: string
  start_at_day: string
  start_at_month: string
  slots: { id: number; start_at: string; end_at: string }[]
}

type GroupSlot = {
  day: string
  duration: number
  from_time: string
  id: number
  time_str: string
  from_time_with_zone: string
}
export type Group = {
  id: number
  slug: string
  start_at: string
  slot: GroupSlot
  second_slot?: GroupSlot
}

export function abbreviateDay(day: string) {
  // Create a date object for the given day string (assuming it's in lowercase)
  const dayDate = parse(day, 'EEEE', new Date())

  // Format the date to get the abbreviated day
  return formatDate(dayDate, 'EEE')
}

export function groupSlotsByStartDate<T extends { start_at: string }>(
  slots: T[],
) {
  return groupBy(slots, (s) => new Date(s.start_at).toISOString().split('T')[0])
}

export function formatSessionStartTime(s: GroupSlot) {
  return formatDate(s.from_time)
}

function mapToGroupedSlots(groups: Group[]): StudentGroupedSlots[] {
  let g = groups.map((g) => ({
    weekday: g.slot?.day
      ? `${abbreviateDay(g.slot.day)} ${
          g.second_slot ? `- ${abbreviateDay(g.second_slot.day)}` : ''
        }`
      : formatDate(g.start_at, 'EEE'),
    start_at_day: formatDate(g.start_at, 'd'),
    start_at_month: formatDate(g.start_at, 'MMMM'),
    start_at: g.start_at,
    end_at: addMinutes(new Date(g.start_at), g.slot?.duration || 60),
    id: g.id,
  }))
  return Object.entries(
    groupBy(g, (g) =>
      JSON.stringify({
        weekday: g.weekday,
        start_at_day: g.start_at_day,
        start_at_month: g.start_at_month,
      }),
    ),
  ).map(([key, s], index) => {
    const info = JSON.parse(key) as {
      weekday: string
      start_at_day: string
      start_at_month: string
    }
    const slots = s.map((s) => ({
      id: s.id,
      start_at: s.start_at,
      end_at: s.end_at.toISOString(),
    }))

    const groupSlot = { index, ...info, slots }

    return groupSlot
  })
}

export function listGroups2(): Promise<StudentGroupedSlots[]> {
  return StudentAPI.get<Response<Group[]>>('groups')
    .then(extractResponseData)
    .then(mapToGroupedSlots)
}

export function listGroups() {
  return StudentAPI.get<PaginatedResponse<Group>>('groups').then((d) => d.data)
}

export function updateGroup(groupId: number) {
  return StudentAPI.put<Response<any>>(`groups/${groupId}/join`)
}
