interface Timezone {
  value: string
  offset: number
  abbrev: string
  altName: string
  GMT_offset_number: number
  GMT_offset_string: string
  cityName: string
  text: string
  isDST: boolean
}

export const timezones = [
  {
    value: 'Pacific/Midway',
    offset: -11,
    abbrev: 'SST',
    altName: 'Samoa Standard Time',
    cityName: 'Midway Island, Samoa',
  },
  {
    value: 'Pacific/Honolulu',
    offset: -10,
    abbrev: 'HAST',
    altName: 'Hawaii-Aleutian Standard Time',
    cityName: 'Hawaii',
  },
  {
    value: 'America/Juneau',
    offset: -8,
    abbrev: 'AKDT',
    altName: 'Alaska Daylight Time',
    cityName: 'Alaska',
  },
  {
    value: 'America/Dawson',
    offset: -7,
    abbrev: 'MST',
    altName: 'Mountain Standard Time',
    cityName: 'Dawson, Yukon',
  },
  {
    value: 'America/Phoenix',
    offset: -7,
    abbrev: 'MST',
    altName: 'Mountain Standard Time',
    cityName: 'Arizona',
  },
  {
    value: 'America/Tijuana',
    offset: -7,
    abbrev: 'PDT',
    altName: 'Pacific Daylight Time',
    cityName: 'Tijuana',
  },
  {
    value: 'America/Los_Angeles',
    offset: -7,
    abbrev: 'PDT',
    altName: 'Pacific Daylight Time',
    cityName: 'Pacific Time',
  },
  {
    value: 'America/Boise',
    offset: -6,
    abbrev: 'MDT',
    altName: 'Mountain Daylight Time',
    cityName: 'Mountain Time',
  },
  {
    value: 'America/Chihuahua',
    offset: -6,
    abbrev: 'HNPMX',
    altName: 'Mexican Pacific Standard Time',
    cityName: 'Chihuahua, La Paz, Mazatlan',
  },
  {
    value: 'America/Regina',
    offset: -6,
    abbrev: 'CST',
    altName: 'Central Standard Time',
    cityName: 'Saskatchewan',
  },
  {
    value: 'America/Mexico_City',
    offset: -6,
    abbrev: 'CST',
    altName: 'Central Mexico Standard Time',
    cityName: 'Guadalajara, Mexico City, Monterrey',
  },
  {
    value: 'America/Belize',
    offset: -6,
    abbrev: 'CST',
    altName: 'Central Standard Time',
    cityName: 'Central America',
  },
  {
    value: 'America/Chicago',
    offset: -5,
    abbrev: 'CDT',
    altName: 'Central Daylight Time',
    cityName: 'Central Time',
  },
  {
    value: 'America/Bogota',
    offset: -5,
    abbrev: 'COT',
    altName: 'Colombia Standard Time',
    cityName: 'Bogota, Lima, Quito',
  },
  {
    value: 'America/Detroit',
    offset: -4,
    abbrev: 'EDT',
    altName: 'Eastern Daylight Time',
    cityName: 'Eastern Time',
  },
  {
    value: 'America/Caracas',
    offset: -4,
    abbrev: 'VET',
    altName: 'Venezuela Standard Time',
    cityName: 'Caracas, La Paz',
  },
  {
    value: 'America/Santiago',
    offset: -3,
    abbrev: 'CLST',
    altName: 'Chile Summer Time',
    cityName: 'Santiago',
  },
  {
    value: 'America/Sao_Paulo',
    offset: -3,
    abbrev: 'BRT',
    altName: 'Brasilia Standard Time',
    cityName: 'Brasilia',
  },
  {
    value: 'America/Montevideo',
    offset: -3,
    abbrev: 'UYT',
    altName: 'Uruguay Standard Time',
    cityName: 'Montevideo',
  },
  {
    value: 'America/Argentina/Buenos_Aires',
    offset: -3,
    abbrev: 'ART',
    altName: 'Argentina Standard Time',
    cityName: 'Buenos Aires, Georgetown',
  },
  {
    value: 'America/St_Johns',
    offset: -2.5,
    abbrev: 'HETN',
    altName: 'Newfoundland Daylight Time',
    cityName: 'Newfoundland and Labrador',
  },
  {
    value: 'America/Godthab',
    offset: -2,
    abbrev: 'WGST',
    altName: 'West Greenland Summer Time',
    cityName: 'Greenland',
  },
  {
    value: 'Atlantic/Cape_Verde',
    offset: -1,
    abbrev: 'CVT',
    altName: 'Cape Verde Standard Time',
    cityName: 'Cape Verde Islands',
  },
  {
    value: 'Atlantic/Azores',
    offset: 0,
    abbrev: 'AZOST',
    altName: 'Azores Summer Time',
    cityName: 'Azores',
  },
  {
    value: 'Etc/GMT',
    offset: 0,
    abbrev: 'GMT',
    altName: 'British Standard Time',
    cityName: 'UTC',
  },
  {
    value: 'Africa/Casablanca',
    offset: 0,
    abbrev: 'WET',
    altName: 'Morocco Standard Standard Time',
    cityName: 'Casablanca, Monrovia',
  },
  {
    value: 'Europe/London',
    offset: 1,
    abbrev: 'BST',
    altName: 'British Summer Time',
    cityName: 'Edinburgh, London',
  },
  {
    value: 'Europe/Dublin',
    offset: 1,
    abbrev: 'IST',
    altName: 'Irish Standard Time',
    cityName: 'Dublin',
  },
  {
    value: 'Europe/Lisbon',
    offset: 1,
    abbrev: 'WEST',
    altName: 'Western European Summer Time',
    cityName: 'Lisbon',
  },
  {
    value: 'Atlantic/Canary',
    offset: 1,
    abbrev: 'WEST',
    altName: 'Western European Summer Time',
    cityName: 'Canary Islands',
  },
  {
    value: 'Africa/Algiers',
    offset: 1,
    abbrev: 'CET',
    altName: 'Central European Standard Time',
    cityName: 'West Central Africa',
  },
  {
    value: 'Europe/Belgrade',
    offset: 2,
    abbrev: 'CEST',
    altName: 'Central European Summer Time',
    cityName: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  },
  {
    value: 'Europe/Sarajevo',
    offset: 2,
    abbrev: 'CEST',
    altName: 'Central European Summer Time',
    cityName: 'Sarajevo, Skopje, Warsaw, Zagreb',
  },
  {
    value: 'Europe/Brussels',
    offset: 2,
    abbrev: 'CEST',
    altName: 'Central European Summer Time',
    cityName: 'Brussels, Copenhagen, Madrid, Paris',
  },
  {
    value: 'Europe/Amsterdam',
    offset: 2,
    abbrev: 'CEST',
    altName: 'Central European Summer Time',
    cityName: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  },
  {
    value: 'Africa/Cairo',
    offset: 2,
    abbrev: 'EET',
    altName: 'Eastern European Standard Time',
    cityName: 'Cairo',
  },
  {
    value: 'Africa/Harare',
    offset: 2,
    abbrev: 'CAT',
    altName: 'Central Africa Standard Time',
    cityName: 'Harare, Pretoria',
  },
  {
    value: 'Europe/Bucharest',
    offset: 3,
    abbrev: 'EEST',
    altName: 'Eastern European Summer Time',
    cityName: 'Bucharest',
  },
  {
    value: 'Europe/Helsinki',
    offset: 3,
    abbrev: 'EEST',
    altName: 'Eastern European Summer Time',
    cityName: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  },
  {
    value: 'Europe/Athens',
    offset: 3,
    abbrev: 'EEST',
    altName: 'Eastern European Summer Time',
    cityName: 'Athens',
  },
  {
    value: 'Asia/Jerusalem',
    offset: 3,
    abbrev: 'IDT',
    altName: 'Israel Daylight Time',
    cityName: 'Jerusalem',
  },
  {
    value: 'Europe/Moscow',
    offset: 3,
    abbrev: 'MSK',
    altName: 'Moscow Standard Time',
    cityName: 'Istanbul, Minsk, Moscow, St. Petersburg, Volgograd',
  },
  {
    value: 'Asia/Kuwait',
    offset: 3,
    abbrev: 'AST',
    altName: 'Arabian Standard Time',
    cityName: 'Kuwait, Riyadh',
  },
  {
    value: 'Africa/Nairobi',
    offset: 3,
    abbrev: 'EAT',
    altName: 'East Africa Standard Time',
    cityName: 'Nairobi',
  },
  {
    value: 'Asia/Baghdad',
    offset: 3,
    abbrev: 'AST',
    altName: 'Arabian Standard Time',
    cityName: 'Baghdad',
  },
  {
    value: 'Asia/Tehran',
    offset: 3.5,
    abbrev: 'IRST',
    altName: 'Iran Standard Time',
    cityName: 'Tehran',
  },
  {
    value: 'Asia/Dubai',
    offset: 4,
    abbrev: 'GST',
    altName: 'Gulf Standard Time',
    cityName: 'Abu Dhabi, Muscat',
  },
  {
    value: 'Asia/Baku',
    offset: 4,
    abbrev: 'AZT',
    altName: 'Azerbaijan Standard Time',
    cityName: 'Baku, Tbilisi, Yerevan',
  },
  {
    value: 'Asia/Kabul',
    offset: 4.5,
    abbrev: 'AFT',
    altName: 'Afghanistan Standard Time',
    cityName: 'Kabul',
  },
  {
    value: 'Asia/Yekaterinburg',
    offset: 5,
    abbrev: 'YEKT',
    altName: 'Yekaterinburg Standard Time',
    cityName: 'Ekaterinburg',
  },
  {
    value: 'Asia/Karachi',
    offset: 5,
    abbrev: 'PKT',
    altName: 'Pakistan Standard Time',
    cityName: 'Islamabad, Karachi, Tashkent',
  },
  {
    value: 'Asia/Kolkata',
    offset: 5.5,
    abbrev: 'IST',
    altName: 'India Standard Time',
    cityName: 'Chennai, Kolkata, Mumbai, New Delhi',
  },
  {
    value: 'Asia/Colombo',
    offset: 5.5,
    abbrev: 'IST',
    altName: 'India Standard Time',
    cityName: 'Sri Jayawardenepura',
  },
  {
    value: 'Asia/Kathmandu',
    offset: 5.75,
    abbrev: 'NPT',
    altName: 'Nepal Standard Time',
    cityName: 'Kathmandu',
  },
  {
    value: 'Asia/Dhaka',
    offset: 6,
    abbrev: 'BST',
    altName: 'Bangladesh Standard Time',
    cityName: 'Astana, Dhaka',
  },
  {
    value: 'Asia/Almaty',
    offset: 6,
    abbrev: 'ALMT',
    altName: 'East Kazakhstan Standard Time',
    cityName: 'Almaty, Novosibirsk',
  },
  {
    value: 'Asia/Rangoon',
    offset: 6.5,
    abbrev: 'MMT',
    altName: 'Myanmar Standard Time',
    cityName: 'Yangon Rangoon',
  },
  {
    value: 'Asia/Bangkok',
    offset: 7,
    abbrev: 'ICT',
    altName: 'Indochina Standard Time',
    cityName: 'Bangkok, Hanoi, Jakarta',
  },
  {
    value: 'Asia/Krasnoyarsk',
    offset: 7,
    abbrev: 'KRAT',
    altName: 'Krasnoyarsk Standard Time',
    cityName: 'Krasnoyarsk',
  },
  {
    value: 'Asia/Shanghai',
    offset: 8,
    abbrev: 'CST',
    altName: 'China Standard Time',
    cityName: 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  },
  {
    value: 'Asia/Kuala_Lumpur',
    offset: 8,
    abbrev: 'MYT',
    altName: 'Malaysia Standard Time',
    cityName: 'Kuala Lumpur, Singapore',
  },
  {
    value: 'Asia/Taipei',
    offset: 8,
    abbrev: 'CST',
    altName: 'Taipei Standard Time',
    cityName: 'Taipei',
  },
  {
    value: 'Australia/Perth',
    offset: 8,
    abbrev: 'AWST',
    altName: 'Western Australia Standard Time',
    cityName: 'Perth',
  },
  {
    value: 'Asia/Irkutsk',
    offset: 8,
    abbrev: 'IRKT',
    altName: 'Irkutsk Standard Time',
    cityName: 'Irkutsk, Ulaanbaatar',
  },
  {
    value: 'Asia/Seoul',
    offset: 9,
    abbrev: 'KST',
    altName: 'Korean Standard Time',
    cityName: 'Seoul',
  },
  {
    value: 'Asia/Tokyo',
    offset: 9,
    abbrev: 'JST',
    altName: 'Japan Standard Time',
    cityName: 'Osaka, Sapporo, Tokyo',
  },
  {
    value: 'Asia/Yakutsk',
    offset: 9,
    abbrev: 'YAKT',
    altName: 'Yakutsk Standard Time',
    cityName: 'Yakutsk',
  },
  {
    value: 'Australia/Darwin',
    offset: 9.5,
    abbrev: 'ACST',
    altName: 'Australian Central Standard Time',
    cityName: 'Darwin',
  },
  {
    value: 'Australia/Adelaide',
    offset: 9.5,
    abbrev: 'ACST',
    altName: 'Central Australia Standard Time',
    cityName: 'Adelaide',
  },
  {
    value: 'Australia/Sydney',
    offset: 10,
    abbrev: 'AEST',
    altName: 'Eastern Australia Standard Time',
    cityName: 'Canberra, Melbourne, Sydney',
  },
  {
    value: 'Australia/Brisbane',
    offset: 10,
    abbrev: 'AEST',
    altName: 'Brisbane Standard Time',
    cityName: 'Brisbane',
  },
  {
    value: 'Australia/Hobart',
    offset: 10,
    abbrev: 'AEST',
    altName: 'Eastern Australia Standard Time',
    cityName: 'Hobart',
  },
  {
    value: 'Asia/Vladivostok',
    offset: 10,
    abbrev: 'VLAT',
    altName: 'Vladivostok Standard Time',
    cityName: 'Vladivostok',
  },
  {
    value: 'Pacific/Guam',
    offset: 10,
    abbrev: 'ChST',
    altName: 'Chamorro Standard Time',
    cityName: 'Guam, Port Moresby',
  },
  {
    value: 'Asia/Magadan',
    offset: 11,
    abbrev: 'MAGT',
    altName: 'Magadan Standard Time',
    cityName: 'Magadan, Solomon Islands, New Caledonia',
  },
  {
    value: 'Asia/Kamchatka',
    offset: 12,
    abbrev: 'PETT',
    altName: 'Petropavlovsk-Kamchatski Standard Time',
    cityName: 'Kamchatka, Marshall Islands',
  },
  {
    value: 'Pacific/Fiji',
    offset: 12,
    abbrev: 'FJT',
    altName: 'Fiji Standard Time',
    cityName: 'Fiji Islands',
  },
  {
    value: 'Pacific/Auckland',
    offset: 12,
    abbrev: 'NZST',
    altName: 'New Zealand Standard Time',
    cityName: 'Auckland, Wellington',
  },
  {
    value: 'Pacific/Tongatapu',
    offset: 13,
    abbrev: 'TOT',
    altName: 'Tonga Standard Time',
    cityName: "Nuku'alofa",
  },
]

function convertToDecimal(input = '') {
  const parts = input.trim().split(':')

  if (parts.length === 2) {
    const hours = parseInt(parts[0], 10)
    const minutes = parseInt(parts[1], 10)
    const decimalValue = hours + minutes / 60

    return decimalValue.toFixed(1)
  }

  return Number(input)
}

function formatTimezoneOffset(input = '') {
  let output = input.trim()

  if (!output.includes(':')) {
    output += ':00'
  }

  return `(GMT${output})`
}

export const TimezoneOptions: Timezone[] = timezones.map((t) => {
  const date = new Date()
  const formatter = new Intl.DateTimeFormat([], {
    timeZone: t.value,
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'shortOffset',
  })

  const GMT_offset_plus_minus = formatter
    .formatToParts(date)
    .find((p) => p.type === 'timeZoneName')
    ?.value.replace('GMT', '')

  const GMT_offset_number = Number(
    convertToDecimal(GMT_offset_plus_minus?.replace('+', '')) || 0,
  )
  const GMT_offset_string = formatTimezoneOffset(GMT_offset_plus_minus)
  const isDST = GMT_offset_number !== t.offset

  return {
    ...t,
    text: `${GMT_offset_string} ${t.cityName} ${isDST ? '(Summer Time)' : ''}`,
    isDST,
    GMT_offset_number,
    GMT_offset_string,
  }
})
