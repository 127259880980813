import Icon from 'components/Icon/Icon'
import Table, { ViewTableProps } from 'components/Table/Table'
import { AdminSupportTicket } from 'core/types/supportTicket'
import { format } from 'date-fns'

const SupportTable = (props: ViewTableProps<AdminSupportTicket>) => {
  return (
    <Table
      {...props}
      columns={[
        'Ser',
        'Contact',
        'Name',
        'Date',
        'Time Zone',
        'Topic',
        'Message',
        'Attach',
      ]}
    >
      {(data) => [
        data.id,
        <>
          <span className="font-color-blue600 font_14 font-normal">
            {data.owner.phone}
          </span>
          <span className="d-block font-color-blue300 font_12">
            {data.owner.email}
          </span>
        </>,
        <>
          <span className="font-color-blue600 font_14 font-normal">
            {data.owner.name}
          </span>

          {data.student && (
            <span className="d-block font-color-blue300 font_12">
              {data.student.s_id} - {data.student.grade?.name}
            </span>
          )}

          {data.tutor && (
            <span className="d-block font-color-blue300 font_12">
              {data.tutor.t_id}
            </span>
          )}
        </>,
        format(new Date(data.created_at), 'd MMM yyyy'),
        <span className="font-color-blue600 font_14 font-normal">
          {data.student && data.student.timezone}
          {data.tutor && data.tutor.timezone}
        </span>,
        data.topic,
        data.message,
        <Attachment attachmentUrl={data.attachment_url} />,
      ]}
    </Table>
  )
}

const ATTACHMENTICON: any = {
  image: 'image-color',
  video: 'video-color',
  file: 'document-color',
}

function Attachment({ attachmentUrl }: { attachmentUrl?: string }) {
  if (!attachmentUrl) return null

  const type = getFileType(attachmentUrl)

  return (
    <span
      className="font-color-blue600 font_14 font-normal"
      style={{
        cursor: 'pointer',
      }}
      onClick={() => {
        window.open(attachmentUrl, '_blank')
      }}
    >
      <Icon iconName={ATTACHMENTICON[type]} iconSize={25} className="me-1" />
      {type}
    </span>
  )
}

function getFileType(url: string): 'image' | 'video' | 'file' {
  const ext = getFileTypeFromUrl(url)
  const mime = getMimeType(ext)

  if (mime.startsWith('image/')) {
    return 'image'
  } else if (mime.startsWith('video/')) {
    return 'video'
  } else {
    return 'file'
  }
}

function getFileTypeFromUrl(url: string): string {
  const ext = new URL(url).pathname.split('.').pop()
  return ext ? ext.toLowerCase() : ''
}

function getMimeType(ext: string): string {
  switch (ext) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg'
    case 'png':
      return 'image/png'
    case 'gif':
      return 'image/gif'
    case 'webp':
      return 'image/webp'
    case 'mp4':
      return 'video/mp4'
    case 'mov':
      return 'video/quicktime'
    case 'avi':
      return 'video/x-msvideo'
    default:
      return 'application/octet-stream'
  }
}

export default SupportTable
