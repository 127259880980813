import { Select } from 'components/Select/Select'
import { useGetSlots } from 'components/Tables/Admin/classTable'
import { SelectProps } from './types'
import { DayUtil } from 'utils/DayUtil'
import { AdminSlot } from 'core/types/Slots'
import { useMemo } from 'react'

const toGroupedTimeSlots = (array: AdminSlot[] = []) => {
  const dataMap = array
    .sort(
      (a, b) =>
        new Date(a.from_time).getTime() - new Date(b.from_time).getTime(),
    )
    .reduce((all: Record<string, any>, current) => {
      if (!all[current.from_time]) {
        all[current.from_time] = {
          label: current.time_str,
          value: [current.id],
        }
      } else {
        all[current.from_time].value.push(current.id)
      }
      return all
    }, {})

  return Object.values(dataMap).map((item) => ({
    ...item,
    value: item.value.join(','),
  }))
}

const toIndividualTimeSlots = (array: AdminSlot[] = [], allSlots?: boolean) => {
  return array.map((slot) => ({
    data: slot,
    searchIn: [slot.day, slot.time_str],
    label: (
      <div className="d-flex gap-2 align-items-baseline">
        {allSlots && (
          <span className=" font_12 font-color-blue300 text-uppercase ">
            {DayUtil.getDayById(slot.day_id).shortPascal}
          </span>
        )}
        {slot.time_str}
        {' - '}
        <span className="font_italic font_12">{slot.duration} min</span>
      </div>
    ),
    value: slot.id,
  }))
}

export default function SlotSelect(
  props: SelectProps<number> & {
    dayId?: Day
    allSlots?: boolean
    grouped?: boolean
  },
) {
  const { data: slots } = useGetSlots()

  const options = useMemo(() => {
    const availableSlots = slots?.list.filter((slot) =>
      !props.dayId ? true : slot.day_id === props.dayId,
    )

    return props.grouped
      ? toGroupedTimeSlots(availableSlots)
      : toIndividualTimeSlots(availableSlots, props.allSlots)
  }, [props.grouped, props.dayId, slots])

  return (
    <Select
      disabled={props.allSlots ? false : !props.grouped && !props.dayId}
      {...props}
      label={props.label || 'Cairo Time'}
      value={props.value}
      searchable={props.allSlots}
      options={options}
      onChange={props.onChange}
    />
  )
}
