import Icon from 'components/Icon/Icon'
import { ReactNode, useState } from 'react'
import v from '../../scss/helpers/_export-theme-variables.scss'

interface InfoCardProps {
  cardSize?: 'small' | 'med' | 'large'
  expandCollapse?: boolean
  defaultExpand?: boolean
  containerClassName?: string
  icon?: {
    name: string
    size?: number
    color?: string
  }
  title: string | ReactNode
  children: ReactNode
  styleTitle?: {
    className?: string
    fontSize?: number
    fontColor?: string
    display?: string
    alignItems?: string
    fontWeight?:
      | 'extralight'
      | 'light'
      | 'semibold'
      | 'normal'
      | 'medbold'
      | 'extrabold'
      | 'bold'
      | 'regular'
  }
}
const InfoCard = ({
  defaultExpand = true,
  expandCollapse = false,
  cardSize = 'small',
  containerClassName = '',
  icon = {
    name: '',
    size: 16,
    color: v.colorprimary,
  },
  title,
  children,
  styleTitle = {
    className: '',
    fontSize: 19,
    fontColor: 'blue700',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'regular',
  },
}: InfoCardProps) => {
  const [expanded, setExapnded] = useState(() => defaultExpand)

  return (
    <div
      className={`bg-white shadow-sm rounded border border-color-primary100  ${containerClassName} h-100 p-0`}
    >
      <h6
        onClick={expandCollapse ? () => setExapnded((_) => !_) : undefined}
        className={`font_${styleTitle.fontSize} d-flex px-4 py-2 ${
          expandCollapse ? 'cursor-pointer click-hover py-4' : ''
        }  font-${styleTitle.fontWeight} font-color-${styleTitle.fontColor} d-${
          styleTitle.display
        } align-items-${styleTitle.alignItems} ${styleTitle.className}`}
      >
        {expandCollapse && (
          <i
            className={`fa-solid fa-color-gray ${
              expanded ? 'fa-minus' : 'fa-plus'
            } font_14 pe-2`}
          />
        )}

        <Icon
          iconName={icon.name}
          iconSize={icon.size}
          iconColor={icon.color}
        />

        <span className="ms-2 flex-grow-1">{title}</span>
      </h6>

      {expanded && <div className="px-4 pb-4">{children}</div>}
    </div>
  )
}

export default InfoCard
