import AdobeViewSDKClient from 'AdobeViewSDKClient'
import { useAuth } from 'auth/useAuth'
import Icon from 'components/Icon/Icon'
import { saveAs } from 'file-saver'
import {
  ReactNode,
  createContext,
  useContext,
  useLayoutEffect,
  useState,
} from 'react'
import { FileType } from 'utils/fileUrl'
import { isProduction } from 'utils/helpers'
import v from '../../scss/helpers/_export-theme-variables.scss'

export function PreviewFileModalWindow({
  previewUrl,
  type,
  downloadImageName,
}: {
  previewUrl?: string
  type: FileType
  downloadImageName?: string
}) {
  const { user } = useAuth()
  return (
    <div
      className="d-flex overflow-hidden justify-content-center overflow-hidden border border-radius-20 bg-color-primary100-opacity-20"
      style={{
        height: '90vh',
        width: '90vw',
        overflow: 'hidden',
        backgroundColor: '#222',
        position: 'relative',
        zIndex: '99999',
      }}
    >
      {!previewUrl && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: '100%' }}
        >
          <div className="spinner-border text-white" role="status" />
        </div>
      )}
      {previewUrl && (
        <>
          {type === 'video' && (
            <video
              width="100%"
              className="video-style__video"
              controls
              style={{
                objectFit: 'contain',
              }}
              src={previewUrl}
            >
              <source src={previewUrl} type="video/mp4" />
            </video>
          )}
          {type === 'image' && (
            <>
              <button
                onClick={() => saveAs(previewUrl, downloadImageName)}
                style={{
                  position: 'absolute',
                  right: '30px',
                  top: '20px',
                }}
              >
                <Icon
                  iconName="download-file-filled"
                  iconSize={24}
                  iconColor={v.colorwhite}
                />
              </button>
              <img
                src={previewUrl}
                className="h-100 w-100 "
                style={{
                  objectFit: 'contain',
                }}
              />
            </>
          )}
          {!isProduction && type === 'pdf' && (
            <iframe className="w-100 h-100" src={previewUrl} />
          )}
          {type === 'pdf' && isProduction && (
            <AdobeViewer
              pdfName={downloadImageName || 'document.pdf'}
              pdfUrl={previewUrl}
            />
          )}
        </>
      )}
    </div>
  )
}

type AdobeViewer = {
  pdfUrl?: string
  pdfName?: string
}

const AdobeViewerContext = createContext<{
  previewFile: (divId: string, url: string, fileName: string) => void
}>({} as any)

const useAdobeViewer = () => useContext(AdobeViewerContext)

export function AdobeViewerProvider({ children }: { children: ReactNode }) {
  const [AdobeDC, setAdobeDC] = useState(() => new AdobeViewSDKClient())

  const previewFile = (elementId: string, url: string, fileName: string) => {
    AdobeDC.ready().then(() => {
      /* Invoke file preview */
      AdobeDC.previewFile({
        clientId: process.env.REACT_APP_ADOBE_KEY,
        elementId,
        url,
        fileName,
        embedMode: 'FULL_WINDOW',
        defaultViewMode: 'FIT_WIDTH',
        showDownloadPDF: false, // false removes the download PDF button from the viewer
        showPrintPDF: false, // false removes the print PDF button from the viewer
        showLeftHandPanel: false,
        showAnnotationTools: false,
        /* Pass the embed mode option here */
      })
    })
  }

  return (
    <AdobeViewerContext.Provider value={{ previewFile }}>
      {children}
    </AdobeViewerContext.Provider>
  )
}

function AdobeViewer({ pdfUrl = '', pdfName = '' }: AdobeViewer) {
  const { previewFile } = useAdobeViewer()

  useLayoutEffect(() => {
    previewFile('modal-adobe-pdf', pdfUrl, pdfName)
  }, [previewFile])

  return <div id="modal-adobe-pdf" />
}
