import { useState } from 'react'

export function useAnimatedToggle(props?: {
  startTime?: number
  closeTime?: number
  onClose?: () => void
  containerClassName?: string
}) {
  let startTime = props?.startTime || 0
  let closeTime = props?.closeTime || 20

  const [state, setState] = useState<{
    opened: boolean
    className: 'closed' | 'opened' | 'close-start' | 'open-start'
  }>({ opened: false, className: 'closed' })

  function open() {
    setState((o) => ({ className: 'open-start', opened: true }))
    setTimeout(() => {
      setState((o) => ({ className: 'opened', opened: true }))
    }, startTime)
  }

  function close() {
    setState((o) => ({ className: 'close-start', opened: true }))
    setTimeout(() => {
      setState((o) => ({ className: 'closed', opened: false }))
      props?.onClose && props.onClose()
    }, closeTime)
  }

  function forceClose() {
    setState((o) => ({ className: 'closed', opened: false }))
  }

  function toggle() {
    state.opened ? close() : open()
  }

  return {
    open,
    forceClose,
    opened: state.opened,
    close,
    toggle,
    className: `${
      props?.containerClassName ? props.containerClassName : 'toggle-container'
    } ${state.className}`,
  }
}
