import { useQuery } from '@tanstack/react-query'
import { useLoggedIn } from 'auth/useAuth'
import { AxiosInstance } from 'axios'
import { Response } from 'core/types/Response'
import { Curriculum, RoadMap } from 'core/types/curriculum'
import { useOrganization } from 'utils/Providers/OrganizationProvider'

import { AdminAPI, TutorAPI } from './API'
import { QueryKeys } from './QueryKeys'
import { extractResponseData } from './utils'

type Option = {
  value: number
  text: string
  name: string
  id: number
}
export type Grade = Option & {
  levels: Level[]
  lessons: Lesson[]
}

export type Level = Option & {
  grade_id: number
  grade_name: string
  lessons: Lesson[]
}

export type Lesson = {
  level_id: number
  level_name: string
  grade_id: number
  grade_name: string
} & Option

export type Flattened_Curriculum = {
  lessons: {
    [key: number]: Lesson
  }
  levels: {
    [key: number]: Level
  }
  grades: {
    [key: number]: Grade
  }
}

function curriculumMapfunction(nested: Curriculum) {
  const Map: Flattened_Curriculum = { lessons: {}, levels: {}, grades: {} }

  nested.grades.forEach((grade) => {
    const gradeLevels: Level[] = grade.levels.map((level) => {
      let levelLessons = level.lessons.map((lesson) => {
        const _lesson = {
          text: lesson.name,
          value: lesson.id,
          id: lesson.id,
          name: lesson.name,
          level_id: level.id,
          level_name: level.name,
          grade_id: grade.id,
          grade_name: grade.name,
        }
        Map.lessons[_lesson.value] = _lesson
        return _lesson
      })

      const _level = {
        text: level.name,
        value: level.id,
        id: level.id,
        name: level.name,
        grade_id: grade.id,
        grade_name: grade.name,
        lessons: levelLessons,
      }

      Map.levels[_level.value] = _level

      return _level
    })

    const _grade = {
      text: grade.name,
      value: grade.id,
      id: grade.id,
      name: grade.name,
      levels: gradeLevels,
      lessons: gradeLevels.flatMap((l) => l.lessons),
    }

    Map.grades[_grade.value] = _grade
  })

  return { ...Map, curriculum: nested }
}

export function getAdminCurriculum(roadMapId?: number) {
  return getCurriculum(AdminAPI, roadMapId)
}

export function getTutorCurriculum(roadMapId?: number) {
  return getCurriculum(TutorAPI, roadMapId)
}

export function getCurriculum(instance: AxiosInstance, roadMapId?: number) {
  return instance
    .get<Response<Curriculum>>(
      `grades?${!!roadMapId ? `curriculum=${roadMapId || 1}` : ''}`,
    )
    .then(extractResponseData)
    .then(curriculumMapfunction)
}

export function useGetCurriculum(roadMapId?: number) {
  const { loggedInUser } = useLoggedIn()
  const { org } = useOrganization()
  return useQuery({
    queryFn: loggedInUser.isTutor
      ? () => getTutorCurriculum(roadMapId)
      : () => getAdminCurriculum(roadMapId),
    queryKey: loggedInUser.isTutor
      ? [QueryKeys.tutorCurriculum, roadMapId, org?.id]
      : [QueryKeys.adminCurriculum, roadMapId, org?.id],
    // keepPreviousData: true,
    enabled: !!roadMapId && !!org?.id,
    staleTime: Infinity,
  })
}

export function useGetRoadMaps(orgId?: number) {
  const { loggedInUser } = useLoggedIn()

  return useQuery({
    queryFn: () =>
      (loggedInUser.isTutor ? TutorAPI : AdminAPI)
        .get<Response<RoadMap[]>>(
          `/curriculums${orgId ? `?filters[organization_id]=${orgId}` : ''}`,
        )
        .then(extractResponseData)
        .then((r) =>
          r.map(
            (rm) =>
              ({ ...rm, isRoadMap: true, organization_id: orgId } as RoadMap & {
                organization_id: number
              }),
          ),
        ),
    queryKey: ['roadmaps', orgId],
    enabled: !!orgId,
    staleTime: Infinity,
  })
}
