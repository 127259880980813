import { useRef } from 'react'
import { useAnimatedToggle } from './useAnimatedToggle'
import { usePopupPlacement } from './usePopupPlacement'

export function usePopup(
  placement: 'bottom' | 'bottom-left' | 'bottom-right' = 'bottom-left',
  fixToTriggerWidth = false,
) {
  const triggerRef = useRef<HTMLButtonElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)
  const Popup = useAnimatedToggle({ startTime: 0 })
  usePopupPlacement({
    triggerRef,
    popupRef,
    closePopup: Popup.close,
    placement,
    fixToTriggerWidth,
  })

  return { ...Popup, triggerRef, popupRef }
}
