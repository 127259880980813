import { TutorNextClassCard } from 'components/Cards/ClassesCards/NextClass/TutorNextClassCard'
import Notifications from 'components/Notifications/NotificationsDropDown'
import { AdminSession } from 'core/types/session'
import { useGetTutorUpcomingSession } from 'layout/StudentDashboard/StudentDashboardHeader'

export function TutorHeaderBar() {
  const { data } = useGetTutorUpcomingSession()

  return (
    <div
      className="d-flex border-b border-color-grey-opacity-10 relative justify-content-between bg-color-white items-align-start  shadow-sm  "
      style={{ minHeight: '65px' }}
    >
      {data?.id && <TutorNextClassCard session={data as AdminSession} />}

      <div
        className="bg-white"
        style={{ zIndex: 1, position: 'absolute', right: '15px', top: '10px' }}
      >
        <Notifications />
      </div>
    </div>
  )
}
