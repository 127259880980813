import { User } from '@sentry/react'

export default function SelectedUser({ user }: { user?: User }) {
  return (
    <span className="d-flex gap-1 font_14 align-items-baseline">
      <span className="font_12 bg-color-grey-opacity-5 rounded px-1 py-01">
        {user?.s_id || user?.t_id || user?.id}
      </span>
      <span>{user?.name}</span>
    </span>
  )
}
