import { PaginatedResponse, Response } from 'core/types/Response'
import { Role } from 'core/types/Role'
import { AdminAPI } from 'services/API'
import {
  CommonParams,
  extractResponseData,
  pgQueryString,
} from 'services/utils'
import { FilterParams } from './admins'

export function listRoles() {
  return AdminAPI.get<Response<Role[]>>('/roles')
    .then(extractResponseData)
    .then((list) => {
      list.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
      return {
        list,
        mapObject: list.reduce(
          (acc, cur) => ({ [cur.id]: cur as Role, ...acc }),
          {},
        ) as { [key: number]: Role },
      }
    })
}
export function getAllRoles(params: FilterParams & CommonParams) {
  return AdminAPI.get<PaginatedResponse<Role[]>>(
    `/roles${pgQueryString(params)}`,
  ).then((r) => r.data)
}

export function listPermissions() {
  return AdminAPI.get<Response<any[]>>('/roles/permissions').then(
    extractResponseData,
  )
}

export function createRole(roleData: Omit<Role, 'id'>) {
  return AdminAPI.post<Response<Role>>('/roles', roleData)
}

export function updateRole(roleId: number, roleData: Role) {
  return AdminAPI.put<Response<Role>>(`/roles/${roleId}`, roleData)
}
