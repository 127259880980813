import { useLoggedIn } from 'auth/useAuth'
import { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { errorToast } from 'utils/errorToast'

export type ChannelSubscription = ReturnType<
  typeof useCreateChannelSubscription<any>
>

export function useCreateChannelSubscription<T>() {
  const { ActionCable } = useLoggedIn()

  const [data, setData] = useState<T | null>(null)

  const SubscriptionRef =
    useRef<ReturnType<(typeof ActionCable)['subscriptions']['create']>>()

  const subscribe = useCallback(
    (data: { channel: string } & Record<string, any>) => {
      const subscription = ActionCable.subscriptions.create(data, {
        connected: () => {},
        disconnected: () => {},
        received: (data) => {
          data.type === 'error' && toast(data.payload)
          setData(data)
        },
      })
      SubscriptionRef.current = subscription
    },
    [],
  )

  const unsubscribe = useCallback(() => {
    if (SubscriptionRef.current) {
      SubscriptionRef.current.unsubscribe()
      SubscriptionRef.current = undefined
    }
  }, [])

  const sendMessage = useCallback((data: { action: string; payload: T }) => {
    SubscriptionRef.current?.send(data)
  }, [])

  useEffect(() => {
    return () => {
      unsubscribe()
    }
  }, [])

  return {
    data,
    subscribe,
    unsubscribe,
    sendMessage,
  }
}
