import { AxiosResponse } from 'axios'
import { PaginatedResponse, Response } from 'core/types/Response'
import QueryString from 'qs'

export type Optional<T> = null | undefined | '' | T

export function extractResponseData<T>(response: AxiosResponse<Response<T>>) {
  return response.data.data
}
export type CommonParams = {
  sort?: string
  per_page?: number
  page?: number
  keyword?: string
  includes?: string
  minimal?: boolean
}
export type PaginatedRequestParams<T> = CommonParams & {
  filters?: T
}

export function toPaginatedRequestParams<T extends CommonParams>(params: T) {
  const { page, per_page, sort, includes, minimal, ...filters } = params
  return {
    page,
    per_page,
    minimal,
    sort,
    includes,
    filters: {
      ...filters,
      keyword: filters.keyword?.trim(),
    },
  }
}

export function pgQueryString<T extends CommonParams>(params: T) {
  return toPaginatedQueryString(toPaginatedRequestParams(params))
}

export function formatLabel(string: string) {
  let s = string.replaceAll('_', ' ').replaceAll('-', ' ')
  s = `${s.charAt(0).toUpperCase()}${s.slice(1)}`
  return s
}

export function toPaginatedQueryString<T>(
  params: PaginatedRequestParams<T>,
  flatFilters = false,
) {
  let { filters, ...other } = params

  let q1 = QueryString.stringify(flatFilters ? filters : { filters }, {
    arrayFormat: 'brackets',
    indices: false,
  })
  let q2 = QueryString.stringify(other, {
    arrayFormat: 'brackets',
    indices: false,
  })

  const query = q1 && q2 ? `${q1}&${q2}` : q1 || q2
  return query ? `?${query}` : ''
}

export function toQueryString<T extends Object>(params: T) {
  return QueryString.stringify(params, {
    arrayFormat: 'brackets',
    indices: false,
  })
}

export async function fetchAllPages<T, Data>({
  fetchFn,
  mapFn,
  per_page = 100,
}: {
  fetchFn: (params: CommonParams) => Promise<PaginatedResponse<T>>
  mapFn: (item: T) => Data
  per_page?: number
}) {
  const response = await fetchFn({ page: 1, per_page })
  let data: Data[] = []

  if (response?.pagination?.count > per_page) {
    let promises: Promise<PaginatedResponse<T>>[] = []

    for (
      let page = 2;
      page <= Math.ceil(response.pagination.count / per_page);
      page++
    ) {
      promises.push(fetchFn({ page, per_page }))
    }

    const additionalResponses = await Promise.all(promises)

    additionalResponses.forEach((additionalResponse) => {
      const additionalData = additionalResponse.data.map(mapFn)
      data = data.concat(additionalData)
    })
  }

  const initialData = response.data.map(mapFn)

  return data.concat(initialData)
}

export function toFormData(obj: Record<string, any>) {
  const data = new FormData()
  Object.keys(obj).forEach((key) => data.append(key, obj[key]))

  return data
}
