import { useOrganization } from 'utils/Providers/OrganizationProvider'

import { useAuth } from 'auth/useAuth'
import { isArabic } from 'utils/helpers'

export default function WhiteLabelLogo({
  scale = '1',
  useWhiteLogo,
  lg,
}: {
  lg?: boolean
  scale?: string
  useWhiteLogo?: boolean
}) {
  const { org } = useOrganization()
  const { user } = useAuth()

  let url = (isArabic() ? org?.logo_ar_url : org?.logo_en_url) || org?.logo_url

  return (
    <picture>
      <source srcSet={url} />
      {user?.isAdmin && (
        <source srcSet={user?.isAdmin ? org?.logo_url : undefined} />
      )}
      <img
        className="main-logo"
        alt=""
        src={url}
        style={{
          scale,
          width: '100%',
          maxHeight: 'max-content',
        }}
      />
    </picture>
  )
}
