import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AuthProvider } from 'auth/AuthProvider'
import { MediaViewerContextProvider } from 'components/Inputs/FilePreviewInput'
import { useModalEscapeHandler } from 'components/Modal'
import { getScrollbarWidth } from 'components/Modal/ModalContainer'
import { AdobeViewerProvider } from 'components/Modals/PreviewFileModalWindow'
import SideBarContextProvider from 'layout/common/SideBarContextProvider'
import { ReactNode, useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import { OrganizationProvider } from 'utils/Providers/OrganizationProvider'
import SpinnerOverlayProvider from 'utils/SpinnerOverlayProvider'
import { router } from './routes'
import './scss/App.scss'

export const ScrollBarWidth = getScrollbarWidth()
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      notifyOnChangeProps: 'all',
      retry(failureCount, error) {
        return false
      },
    },
  },
})

function App() {
  useCorrectFavIcon()
  useModalEscapeHandler()

  return (
    <SideBarContextProvider>
      <SpinnerOverlayProvider>
        <AdobeViewerProvider>
          <MediaViewerContextProvider>
            <AuthProvider>
              <OrganizationProvider>
                <RouterProvider router={router} />
              </OrganizationProvider>
            </AuthProvider>
          </MediaViewerContextProvider>
        </AdobeViewerProvider>
      </SpinnerOverlayProvider>
    </SideBarContextProvider>
  )
}

function QueryClientProviderApp({ children }: { children: ReactNode }) {
  useCorrectFavIcon()
  useModalEscapeHandler()

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

function useCorrectFavIcon() {
  useEffect(() => {
    // Update favicon on Mode change
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) => setFavIcon(e.matches))

    // Check Mode on load
    setFavIcon(
      window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches,
    )
  }, [window.matchMedia])
}

function setFavIcon(darkMode: boolean) {
  var element = document.querySelector("link[rel='icon']")

  element?.setAttribute('href', darkMode ? 'favicon-white.ico' : 'favicon.ico')
}
export default function () {
  return (
    <QueryClientProviderApp>
      <App />
    </QueryClientProviderApp>
  )
}
