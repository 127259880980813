import Button from 'components/Button/Button'
import { useCreateModal } from 'components/Modal'
import { ModalWindow } from 'components/Modal/ModalWindow'
import { NumberOrString } from 'components/Select/types'
import { useFilters } from 'core/hooks/useFilters'
import { CommonParams } from 'services/utils'
import FilterIcon from './FilterIcon'
import FilterRenderer from './FilterRenderer'
import { QueryFiltersType } from './Provider/QueryProvider'
import { ResourceItem } from './useResource'

export type FilterParams = {
  [key: string]: NumberOrString | undefined
} & CommonParams

export default function QueryFilterModal<
  Filters extends { [key: string]: any } & CommonParams,
  Data extends ResourceItem,
>({ query }: { query: QueryFiltersType<Filters, Data> }) {
  const Modal = useCreateModal()
  const modalFilters = useFilters(query.filters!.params)

  const areAllPinnedFilters =
    query.filterInputs &&
    Object.values(query.filterInputs).some((f) => !f.pinned)

  return (
    <>
      {areAllPinnedFilters && (
        <Button
          onClick={Modal.openModal}
          variant="orange500"
          isBorderButton
          className="p-0  px-2 font_14 border-0  d-flex align-items-center gap-1 font-semibold"
          style={{ borderLeft: '1px solid', height: '30px' }}
        >
          <FilterIcon color="orange" />
        </Button>
      )}
      <Modal.Container>
        <ModalWindow
          titleIcon={<FilterIcon color="primary" />}
          title={'Filter'}
          id={'tutorClassFilter'}
          containerClassName="tutor-class-filter-modal"
          maxWidth={600}
          hasCloseBtn
        >
          <div
            className="d-grid gap-4"
            style={{
              width: '100%',
              display: 'grid',
              alignItems: 'center',
              gridAutoFlow: 'dense',
              gridTemplateColumns: `repeat(auto-fill, minmax(210px, 1fr))`,
            }}
          >
            {query.filterInputs &&
              Object.entries(query.filterInputs).map(([filterName, config]) =>
                config.type === 'search' ? null : (
                  <FilterRenderer
                    {...config}
                    name={filterName}
                    key={filterName}
                    filters={modalFilters}
                    value={modalFilters.params?.[filterName]}
                    onChange={(v: any) => {
                      const update = { [filterName]: v } as Partial<Filters>
                      modalFilters.update(update)
                    }}
                  />
                ),
              )}
          </div>

          <div className="d-flex align-items-center  border-0 justify-content-center tutor-class-filter-modal__actions">
            <Button
              className="font_12 rounded-pill"
              type="reset"
              label="Reset"
              variant="secondary"
              onClick={() => {
                // TODO reset to default code initial params ?
                modalFilters.clearAll()
              }}
            />
            <Button
              className="font_12 rounded-pill"
              label="Search"
              onClick={() => {
                query.filters?.update({ ...modalFilters.params, page: 1 })
                Modal.closeModal()
              }}
            />
          </div>
        </ModalWindow>
      </Modal.Container>
    </>
  )
}
