import { CustomFilter } from 'components/Query/filter'
import { Select } from 'components/Select/Select'
import { useFlattenedGrades } from 'components/Selectors/curriculum/GradeSelect'
import { LevelSelect } from 'components/Selectors/curriculum/LevelSelect'
import { SelectProps } from 'components/Selectors/types'
import { defaultParamNames } from './CurriculumSelectFilter'

export const StudyLevelSelectFilter = (
  p = defaultParamNames,
): CustomFilter<number | undefined> => ({
  type: 'custom',
  element: (ctx) => <StudyLevelSelect {...ctx} label={'Study Level'} />,
})

function StudyLevelSelect(props: SelectProps<number>) {
  const { data } = useFlattenedGrades()

  return (
    <Select
      {...props}
      searchable
      renderTrigger={({ data: item }) => (
        <>
          {' '}
          {data?.grades?.find((g) => g.id === item.grade_id)?.name} -{' '}
          {item.name}
        </>
      )}
      options={data?.levels.map((level) => ({
        value: level.id,
        group: data.grades.find((g) => g.id === level.grade_id)?.name,
        label: level.name,
        data: level,
        searchIn: [
          data.grades.find((g) => g.id === level.grade_id)!.name,
          level.name,
        ],
      }))}
    />
  )
}

export const LevelSelectFilter = (
  p = defaultParamNames,
): CustomFilter<number | undefined> => ({
  type: 'custom',
  element: (ctx) => (
    <LevelSelect
      {...ctx}
      value={ctx.filters?.params[p.level]}
      curriculumId={ctx.filters?.params?.[p.curriculum]}
      gradeId={ctx.filters?.params?.[p.grade]}
      onChange={(levelId) => {
        ctx.filters?.update({
          [p.level]: levelId,
          [p.lesson]: undefined,
        })
      }}
    />
  ),
})
