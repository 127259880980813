import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useLoggedIn } from 'auth/useAuth'
import Button from 'components/Button/Button'
import Icon from 'components/Icon/Icon'
import InputField from 'components/Inputs/InputField'
import InputTextArea from 'components/Inputs/InputTextArea'
import Switch from 'components/Inputs/Switch'
import { useOpenedModal } from 'components/Modal'
import { ModalWindow } from 'components/Modal/ModalWindow'
import { AdminSession } from 'core/types/session'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { QueryKeys } from 'services/QueryKeys'
import { adminSubmitTutorReport } from 'services/admin/sessions'
import { submitTutorReport } from 'services/tutor/sessions'

type ReportModalWindowProps = {
  session?: AdminSession
}

function useSubmitReport() {
  const queryClient = useQueryClient()
  const { closeModal } = useOpenedModal()
  const { loggedInUser } = useLoggedIn()

  return useMutation({
    mutationFn: loggedInUser.isAdmin
      ? adminSubmitTutorReport
      : submitTutorReport,
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.tutorClasses])
      queryClient.invalidateQueries(['adminSessions'])
      toast.success('Report submitted')
      closeModal()
    },
    onError(e: any) {
      toast.error(e?.response?.data?.message)
    },
  })
}

const ReportModalWindow = ({ session }: ReportModalWindowProps) => {
  const isEditable = session?.internal_feedback_status !== 'done'

  const initialValues = {
    internal_feedback: {
      comment: session?.internal_feedback?.comment || '',
      session_feedback: session?.internal_feedback?.session_feedback || [],
      student_feedback: session?.internal_feedback?.student_feedback || [],
      student_joined_late_by: session?.internal_feedback
        .student_joined_late_by || { comment: '', value: undefined },
    },
  }

  const { mutate: SubmitReport } = useSubmitReport()

  const isFormValid = () => {
    const { session_feedback, student_feedback, comment } =
      formik.values.internal_feedback

    const isCheckboxChecked =
      session_feedback?.some((feedback) => feedback.value) ||
      student_feedback?.some((feedback) => feedback.value)
    const hasComment =
      Boolean(comment) || student_feedback?.some((feedback) => feedback.comment)

    return isCheckboxChecked || hasComment
  }

  const formik = useFormik({
    onSubmit: (form) => {
      if (isFormValid()) {
        form.internal_feedback.student_feedback.forEach((feedback, index) => {
          if (!feedback.value) {
            form.internal_feedback.student_feedback[index].comment = ''
          }
        })

        session && SubmitReport({ ...form, sessionId: session.id })
      } else {
        toast.error('Please check at least one feedback option.')
      }
    },
    initialValues,
  })

  return (
    <>
      <ModalWindow
        titleIcon={
          <Icon iconName="filter-filled" iconSize={25} className="pe-2" />
        }
        title={'Internal Feedback'}
        containerClassName="feedback-class-filter-modal"
        maxWidth={700}
        hasCloseBtn
      >
        <form onSubmit={formik.handleSubmit}>
          <div>
            <div className="mb-4">
              <InputTextArea
                rows={5}
                name="internal_feedback.comment"
                value={formik.values.internal_feedback.comment}
                onChange={formik.handleChange}
                error={formik.errors.internal_feedback?.comment}
                touched={formik.touched.internal_feedback?.comment}
                inputClassName="bg-color-primary50-opacity-20 border-0 font_20"
                placeholder="General comment"
                disabled={!isEditable}
              />
            </div>

            <div className="mb-4">
              <h4>Session Feedback</h4>

              {formik.values.internal_feedback.session_feedback.map(
                (feedback, index) => (
                  <div className="d-flex align-items-center mb-2" key={index}>
                    <Switch
                      disabled={!isEditable}
                      onChange={() =>
                        formik.setFieldValue(
                          `internal_feedback.session_feedback[${index}].value`,
                          !feedback.value,
                        )
                      }
                      checked={feedback.value}
                    />
                    <label className="ml-3xs">{feedback.key}</label>
                  </div>
                ),
              )}
            </div>

            <div className="mb-4">
              <h4>Student Feedback</h4>

              {formik.values.internal_feedback.student_feedback.map(
                (feedback, index) => (
                  <div key={index} className="mb-3">
                    <div className="d-flex align-items-center">
                      <Switch
                        disabled={!isEditable}
                        onChange={() => {
                          const newValue =
                            !formik.values.internal_feedback.student_feedback[
                              index
                            ].value
                          formik.setFieldValue(
                            `internal_feedback.student_feedback[${index}].value`,
                            newValue,
                          )
                          if (!newValue) {
                            formik.setFieldValue(
                              `internal_feedback.student_feedback[${index}].comment`,
                              '',
                            )
                          }
                        }}
                        checked={
                          formik.values.internal_feedback.student_feedback[
                            index
                          ].value === true
                        }
                      />
                      <label className="ml-3xs ">{feedback.key}</label>
                    </div>

                    {formik.values.internal_feedback.student_feedback[index]
                      .value && (
                      <div className="mt-2">
                        <InputTextArea
                          disabled={!isEditable}
                          rows={2}
                          name={`internal_feedback.student_feedback[${index}].comment`}
                          value={
                            formik.values.internal_feedback.student_feedback[
                              index
                            ].comment
                          }
                          onChange={formik.handleChange}
                          placeholder="Additional comments"
                        />
                      </div>
                    )}
                  </div>
                ),
              )}
            </div>
            <div>
              <InputField
                disabled={!isEditable}
                label="Student Joined Late By"
                name="internal_feedback.student_joined_late_by.value"
                type="number"
                value={
                  formik.values?.internal_feedback.student_joined_late_by
                    .value || ''
                }
                minValue={5}
                maxValue={60}
                onChange={(e) => {
                  const newValue = e.target.value ? Number(e.target.value) : 0

                  formik.setFieldValue(
                    `internal_feedback.student_joined_late_by.value`,
                    newValue,
                  )

                  if (newValue < 5) {
                    formik.setFieldValue(
                      `internal_feedback.student_joined_late_by.comment`,
                      '',
                    )
                  }
                }}
              />

              {(formik.values?.internal_feedback?.student_joined_late_by
                ?.value ?? 0) >= 5 && (
                <InputTextArea
                  disabled={!isEditable}
                  rows={2}
                  name="internal_feedback.student_joined_late_by.comment"
                  value={
                    formik.values?.internal_feedback.student_joined_late_by
                      .comment || ''
                  }
                  onChange={formik.handleChange}
                  placeholder="Additional comments"
                />
              )}
            </div>
          </div>

          <div className="d-flex align-items-center mt-4 border-0 justify-content-center tutor-class-filter-modal__actions">
            {isEditable && (
              <Button
                className="font_12 rounded-pill"
                label="Submit"
                type="submit"
                disabled={!isFormValid()}
              />
            )}
          </div>
        </form>
      </ModalWindow>
    </>
  )
}

export default ReportModalWindow
