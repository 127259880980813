import Button from 'components/Button/Button'
import React, { ReactNode } from 'react'

const ErrorPage = ({
  errorStateIcon,
  title,
  content,
  children,
}: {
  errorStateIcon: ReactNode
  title: string
  content: string
  children?: ReactNode
}) => {
  return (
    <div className="vh-100 error-page text-center d-flex justify-content-center align-items-center flex-column">
      <div className="">{errorStateIcon}</div>

      <div className="d-flex flex-column align-items-center">
        <h4 className="text-black fs-3 font-semibold m-0 px-3 py-2">{title}</h4>
        <h1 className="text-black fs-6 font-normal m-0 px-3 py-2 mb-45">
          {content}
        </h1>
        {children}

        {/* <Button label="Go back" /> */}
      </div>
    </div>
  )
}

export default ErrorPage
