import { useLoggedInStudent } from 'auth/useAuth'
import Button from 'components/Button/Button'
import { FilePreviewInput } from 'components/Inputs/FilePreviewInput'
import { useCreateModal, useOpenedModal } from 'components/Modal'
import { ModalWindow } from 'components/Modal/ModalWindow'
import { BooleanSelect } from 'components/Selectors/base/BooleanSelect'
import { FormikProvider, useFormik } from 'formik'
import { toast } from 'react-toastify'
import { updateProfile } from 'services/student/profile'
import * as yup from 'yup'

export function StudentCollectLocationModal() {
  const { loggedInStudent } = useLoggedInStudent()

  const Modal = useCreateModal({
    isOpened:
      loggedInStudent.pending_survery_info &&
      loggedInStudent.form_interested_next_level == null,
    disableBackdrop: true,
  })

  return (
    <Modal.Container>
      <StudentCollectLocationModalWindow />
    </Modal.Container>
  )
}
export function StudentCollectLocationModalWindow() {
  return (
    <div dir="rtl">
      <ModalWindow
        titleClassName="font-semibold "
        maxWidth={700}
        borderRadius={20}
        titleFontSize={18}
        title={
          <div>
            <div className="text-start d-flex align-items-baseline gap-2 ">
              <i className={`fa-solid fa-bullhorn font-color-orange500`} />
              <div>{` تأكيد الاستمرار بالمبادرة `}</div>
            </div>
          </div>
        }
        containerClassName="text-center"
      >
        <LocationGradeQuestionsModal />
      </ModalWindow>
    </div>
  )
}

const validationSchema = yup.object({
  question1: yup.string().required('Question 1 is required'),
})

export function LocationGradeQuestionsModal() {
  const { closeModal } = useOpenedModal()
  const { loggedInStudent, loadProfile } = useLoggedInStudent()
  const getLabelForGrade = (gradeId: number) => {
    if (gradeId === 12 || gradeId === 24) {
      return 'هل ترغب بالالتحاق بالمستوى التدريبي الأول من مبادرة أشبال مصر الرقمية في العام التدريبي (24/25)؟'
    } else {
      return 'هل ترغب في الالتحاق بالمستوى التدريبي الأعلى من مبادرة براعم مصر الرقمية في العام التدريبي (24/25)؟'
    }
  }

  const gradeLabel = getLabelForGrade(loggedInStudent?.grade?.id)

  const formik = useFormik({
    initialValues: {
      question1: loggedInStudent.form_interested_next_level,
      school_certificate: null,
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        await updateProfile({
          form_interested_next_level: values.question1,
          school_certificate: values.school_certificate,
        })
        loadProfile()
        closeModal()
      } catch (e: any) {
        toast.error(e?.response?.data?.message || 'An error occurred')
        closeModal()
      }
    },
  })
  return (
    <div className="mt-12 ">
      <div className="text-end d-flex align-items-baseline">

        <span>
          {gradeLabel}
          {loggedInStudent.organization.id === 5 &&
            'في حالة الإجابة بنعم، رجاء ارفاق بيان قيد دراسي حديث يوضح الصف الدراسي للعام 24/25'}
        </span>
      </div>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className=" mt-2 ">
            <div className="d-flex gap-4 my-3">
              <BooleanSelect
                label="اختار"
                value={formik.values.question1}
                onChange={(e) => formik.setFieldValue('question1', e)}
                labels={{
                  yes: 'نعم',
                  no: 'لا',
                }}
              />
            </div>
            <div className="text-end ">
              {/* 5 */}
              {loggedInStudent.organization.id === 2 && (
                <FilePreviewInput
                  label="رجاء ارفاق بيان قيد دراسي حديث يوضح الصف الدراسي للعام 24/25"
                  name="school_certificate"
                  acceptFile="image/*,.pdf"
                  onChange={(file) =>
                    formik.setFieldValue('school_certificate', file)
                  }
                />
              )}
            </div>
          </div>
          {/* 5 */}
          <Button
            type="submit"
            className="mt-4 font_14 rounded-pill d-inline-flex"
            disabled={
              formik.values.question1 === undefined ||

              (loggedInStudent.organization.id === 5 &&
                formik.values.question1 &&
                !formik.values.school_certificate)
            }
          >
            تاكيد
          </Button>
        </form>
      </FormikProvider>
    </div>
  )
}
