import Button from 'components/Button/Button'
import { FromDateFilter } from 'components/Query/Filters/Basic/FromDateFilter'
import { TextSearchFilter } from 'components/Query/Filters/Basic/SearchFilter'
import { ToDateFilter } from 'components/Query/Filters/Basic/ToDateFilter'
import {
  AvailabilityFilter,
  FeedbackFilter,
  FreePaidFilter,
  LanguageFilter,
  OnlineOfflineFilter,
  StudentAbsentFilter,
  TutorJoinTimeFilter,
} from 'components/Query/Filters/Extended'
import { CurriculumSelectFilter } from 'components/Query/Filters/Extended/CurriculumSelectFilter'
import { GradeSelectFilter } from 'components/Query/Filters/Extended/GradeSelectFilter'
import { LessonSelectFilter } from 'components/Query/Filters/Extended/LessonSelectFilter'
import { LevelSelectFilter } from 'components/Query/Filters/Extended/LevelSelectFilter'
import {
  SessionStatusSelectFilter,
  SessionTimeStatusSelectFilter,
} from 'components/Query/Filters/Extended/SessionTimeStatusSelectFilter'
import { SlotDaySelectFilter } from 'components/Query/Filters/Extended/SlotDaySelectFilter'
import { SlotSelectFilter } from 'components/Query/Filters/Extended/SlotSelectFilter'
import { TutorSelectFilter } from 'components/Query/Filters/Extended/UserSelectFilter'
import { ClassesTeacherStatusFilters } from 'components/Query/Filters/Extended/ClassesTeacherStatusFilters'
import { ResourceFilters, useResource } from 'components/Query/useResource'
import ClassTable from 'components/Tables/Admin/classTable'
import { useExport } from 'core/hooks/useExport'
import { AdminSession } from 'core/types/session'
import { AdminPage } from 'layout/AdminDashboard'
import { ReactNode } from 'react'
import { getAdminSessions } from 'services/admin/sessions'
import { GetSessionsRequestQP } from 'services/tutor/sessions'
import { useQueryClient } from '@tanstack/react-query'
import { AdminAPI } from 'services/API'
import { toast } from 'react-toastify'

export const SessionsResourceFilters: ResourceFilters<GetSessionsRequestQP> = {
  keyword: TextSearchFilter,

  status: SessionTimeStatusSelectFilter,
  is_offline: OnlineOfflineFilter,
  session_status: SessionStatusSelectFilter,

  start_at_from: FromDateFilter({ toDateFilterName: 'start_at_to' }),
  start_at_to: ToDateFilter({ fromDateFilterName: 'start_at_from' }),
  tutor_join_time: TutorJoinTimeFilter,

  tutor_id: TutorSelectFilter,
  is_free: FreePaidFilter,

  curriculum: CurriculumSelectFilter(),
  grade: GradeSelectFilter(),
  level: LevelSelectFilter(),
  lesson: LessonSelectFilter(),
  start_at_day: SlotDaySelectFilter({ slotIdFilterName: 'slot_id' }),
  slot_id: SlotSelectFilter({
    slotDayFilterName: 'start_at_day',
    grouped: true,
  }),

  feedback: FeedbackFilter,
  is_student_absent: StudentAbsentFilter,
  language: LanguageFilter,
  filterate: ClassesTeacherStatusFilters,
}

function useSessions() {
  return useResource<GetSessionsRequestQP, AdminSession>({
    filters: SessionsResourceFilters,
    initialParams: {
      is_offline: false,
      status: 'today',
      sort: 'start_at',
      filterate: {},
    },
    name: 'adminSessions',
    queryFn: getAdminSessions,
  })
}
export function ActionButton({
  onClick,
  title,
  icon,
}: {
  title: ReactNode
  icon: string
  onClick: () => void
}) {
  return (
    <Button
      className="dropdown-item text-start justify-content-start px-4 py-1 font_14 b-0  d-flex gap-4"
      onClick={onClick}
      label={''}
      variant="gray"
      isBorderButton
    >
      <i className={icon} />
      {title}
    </Button>
  )
}

const Classes = () => {
  const query = useSessions()
  const { requestExport } = useExport()
  const queryClient = useQueryClient()

  const handleRepopulateIssueFilters = async () => {
    try {
      await AdminAPI.get('/sessions/populate_issues_filters')
      toast.success('Issue filters repopulated successfully!')
      queryClient.invalidateQueries(['adminSessions'])
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <AdminPage
      showOrgName
      title="Classes"
      query={query}
      exportFn={() => requestExport('/sessions', query.filters.toQueryString())}
      actions={[
        {
          icon: 'fa fa-rotate-right',
          hidden: query.filters.params.status !== 'today',
          label: 'Repopulate Issue Filters',
          onClick: handleRepopulateIssueFilters,
        },
      ]}
    >
      <ClassTable {...query} tab={query.filters.params.status} />
    </AdminPage>
  )
}

export default Classes
