import { useDebouncedState } from 'core/hooks/useDebouncedState'
import groupBy from 'lodash.groupby'
import { Fragment, MutableRefObject, ReactNode, useEffect, useRef } from 'react'
import { RequiredOption } from './types'
import { useArrowMove } from './utils/useArrowMove'

export function ArrowKeysNavList<T extends { index: number }>({
  list,
  searchable,
  onEnter,
  value = -1,
  children,
}: {
  children: (i: T, index: number, active: boolean) => ReactNode
  list: T[]
  refsArray: MutableRefObject<(HTMLButtonElement | null)[]>
  value: number
  searchable: boolean
  onEnter: (index: number) => void
}) {
  const listpopupRef = useRef<HTMLDivElement>(null)
  const refsArray = useRef<Array<HTMLButtonElement | null>>([])

  function focusElement(tobeFocused: number) {
    const button = refsArray.current[tobeFocused]
    if (!searchable) {
      button?.focus()
    }
    if (listpopupRef.current && button) {
      scrollToChildElement(listpopupRef.current, button)
      listpopupRef.current.scrollIntoView({ behavior: 'auto', block: 'center' })
    }
  }

  const { navIndex, setNavIndex } = useArrowMove({
    index: value,
    length: list.length,
    onChange: focusElement,
    onEnter,
    containerRef: listpopupRef,
  })

  useEffect(() => {
    setNavIndex(0)
  }, [refsArray.current.length])

  useEffect(() => {
    focusElement(navIndex)
  }, [])

  return (
    <div
      ref={listpopupRef}
      className="d-flex flex-column  border-bottom border-top font-semibold"
      style={{ fontSize: '0.95em' }}
    >
      {list.length === 0 && (
        <div className="p-2 font_12 text-center">No Results Found</div>
      )}
      {Object.entries(groupBy(list, '__select_group__')).map(
        ([groupName, list], groupIndex) => (
          <Fragment key={groupName}>
            {groupName && groupName !== 'undefined' && groupName !== 'null' && (
              <div className="px-2 py-2 border border-color-grey-opacity-10 bg-color-primary-opacity-4 font-color-blue300 font_14">
                {groupName}
              </div>
            )}

            {list.map((item) => (
              <Fragment key={item.index}>
                {children(item, item.index, navIndex === item.index)}
              </Fragment>
            ))}
          </Fragment>
        ),
      )}
    </div>
  )
}

export function OptionsList({
  searchable,
  onSelect: _onSelect,
  options,
  selected: _selected,
  multi,
}: {
  searchable?: boolean
  multi?: boolean
  selected?: RequiredOption<any, any>
  options: RequiredOption<any, any>[]
  onSelect: (o?: RequiredOption<any, any>) => void
  closePopup: () => void
}) {
  const [selected, onSelect] = useDebouncedState(_selected, _onSelect, 100)

  const refsArray = useRef<Array<HTMLButtonElement | null>>([])

  function handleEnterKeydown(index: number) {
    let newSelected = options.find((o) => index == o.index)!
    onSelect(newSelected)
  }

  return (
    <ArrowKeysNavList
      onEnter={handleEnterKeydown}
      list={options}
      refsArray={refsArray}
      searchable={!!searchable}
      value={selected?.index || -1}
      children={(o, index, active) => (
        <button
          type="button"
          ref={(e) => {
            if (refsArray.current) {
              refsArray.current[index] = e
            }
          }}
          autoFocus={index === 0 && !searchable}
          tabIndex={index === 0 && !searchable ? 0 : undefined}
          className={`select-option-button  text-start text-capitalize cursor-pointer font-color-blue600 ${
            !multi && o.isSelected ? `selected` : ''
          } ${active ? 'active-index' : ''}
        } `}
          onClick={() => onSelect(o)}
        >
          {multi && (
            <i
              className={`me-3 font_18 cursor-pointer  font-thin d-inline ${
                o.isSelected
                  ? 'fa-solid font-color-primary fa-square-check'
                  : 'fa-regular font-color-grey fa-square'
              }`}
            />
          )}

          {o.label}
        </button>
      )}
    />
  )
}

export const scrollToChildElement = (
  container: HTMLElement,
  child: HTMLElement,
) => {
  container.scrollTop =
    child.offsetTop + child.clientHeight / 2 - container.clientHeight / 2
}
