import * as yup from 'yup'

export const PasswordStringValidation = yup
  .string()
  .min(8, 'Password must be at least 8 characters')
  .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
  .matches(/[0-9]/, 'Password must contain at least one number')
  .matches(
    /[!@#$%^&*(),.?":{}|<>_-]/,
    'Password must contain at least one special character',
  )

export const PasswordValidation = yup.string().when(['id'], {
  is: (id: any): any => !id,
  then: () => PasswordStringValidation.required('Password is required'),
  otherwise: () => PasswordStringValidation.optional(),
})
