import { AdminAPI } from 'services/API'
import { toast } from 'react-toastify'
import { PaginatedResponse } from 'core/types/Response'
import { CommonParams, pgQueryString } from 'services/utils'

export const useExport = () => {
  function requestExport(pathname: string, filtersQuery: string) {
    AdminAPI.get(`${pathname}/export${filtersQuery}`)
      .then(() => {
        toast.success('Your export will be ready soon in the exports center')
      })
      .catch(() => {
        toast.error(
          "Couldn't process your export request, please try again later",
        )
      })
  }

  function getExportSheets(params: CommonParams) {
    return AdminAPI.get<PaginatedResponse<any>>(
      `export_sheets${pgQueryString(params)}`,
    ).then((response) => response.data)
  }

  return {
    requestExport,
    getExportSheets,
  }
}
