import { useAuth } from 'auth/useAuth'
import { Role } from 'core/types/User'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { formatLabel } from 'services/utils'

export default function StudentAutoLogin() {
  const { autoLogin } = useAuth()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  let token = searchParams.get('token')
  const role = formatLabel(searchParams.get('role') || 'Student') as Role
  useEffect(() => {
    if (token) {
      autoLogin(role, token).then((u) => navigate('/'))
    }
  }, [token])
  return null
}
