import { BaseFilter, CustomFilter } from 'components/Query/filter'
import { SelectSearch } from 'components/Select/SelectSearch'

export interface SearchFilter extends BaseFilter<string | undefined> {}

export function SearchFilter(props: BaseFilter<string | undefined>) {
  return (
    <SelectSearch
      {...props}
      placeholder={props.label || 'Search'}
      value={props.value || ''}
      onChange={(s) => props.onChange && props.onChange(s)}
    />
  )
}

export const TextSearchFilter: SearchFilter = {
  type: 'search',
  pinned: true,
}

export const CustomSearchFilter: CustomFilter<string | undefined> = {
  type: 'custom',
  element: (ctx) => <SearchFilter {...ctx} type="search" />,
}
