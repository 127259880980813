export type FileType = 'image' | 'video' | 'file' | 'pdf'
export type FileMimeType =
  | 'image/jpeg'
  | 'image/png'
  | 'image/webp'
  | 'video/mp4'
  | 'image/gif'
  | 'image/svg+xml'
  | 'video/quicktime'
  | 'video/x-msvideo'
  | 'application/pdf'
  | 'application/octet-stream'

export function getFileInfoFromUrl(string_url?: string) {
  if (!string_url) return null

  const { ext, name, fullName } = getExtensionFromStringUrl(string_url)
  const mime = getMimeTypeFromExtension(ext)
  const type = getFileTypeFromMimeType(mime)

  return {
    name,
    fullName,
    type,
    mime,
    ext,
  }
}

export function getFileTypeFromMimeType(mime: string): FileType {
  let type: FileType = 'file'

  if (mime.startsWith('image/')) {
    type = 'image'
  } else if (mime.startsWith('video/')) {
    type = 'video'
  } else if (mime === 'application/pdf') {
    type = 'pdf'
  }

  return type
}
const isValidUrl = (urlString: string) => {
  var urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ) // validate fragment locator
  return !!urlPattern.test(urlString)
}

export function getExtensionFromStringUrl(string_url: string): {
  ext: string
  name: string
  fullName: string
} {
  let fileParts = new URL(string_url).pathname.split('.')
  // Create a URL object
  const urlObject = new URL(string_url)
  let filename: string

  // Get the value of the response-content-disposition parameter
  const dispositionParam = urlObject.searchParams.get(
    'response-content-disposition',
  )

  if (dispositionParam) {
    // Extract the filename from the disposition parameter
    const matches = dispositionParam.match(/filename\*?=(?:UTF-8['']?)?"(.*?)"/)

    if (matches && matches.length > 1) {
      filename = decodeURIComponent(matches[1])
      fileParts = filename.split('.')
    } else {
      console.log(
        'Filename not found in the response-content-disposition parameter.',
      )
    }
  } else {
    console.log('response-content-disposition parameter not found in the URL.')
  }

  const ext = fileParts.pop()?.toLowerCase() || ''
  const name = decodeURIComponent(fileParts.pop()?.split('/').pop() || '')

  return { ext, name, fullName: name + '.' + ext }
}

export function getMimeTypeFromExtension(ext: string): FileMimeType {
  const map: { [ext: string]: FileMimeType } = {
    jpg: 'image/jpeg',
    jpeg: 'image/png',
    png: 'image/png',
    gif: 'image/gif',
    svg: 'image/svg+xml',
    webp: 'image/webp',
    mp4: 'video/mp4',
    mov: 'video/quicktime',
    avi: 'video/x-msvideo',
    pdf: 'application/pdf',
  }
  return map[ext] || 'application/octet-stream'
}
