import { Fragment, ReactNode } from 'react'

export interface InputSelectProps
  extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'value'> {
  values?: {
    id?: string | number
    value?: string | number
    text?: string
    label?: string
    option?: ReactNode
  }[]
  label?: any
  defaultSelected?: any
  error?: string
  touched?: boolean
  inputClassName?: string
  labelClassName?: string
  continerClassName?: string
  isFormInline?: boolean
  isRequired?: boolean
  value?: string | number | null
  emptySelect?: boolean
  emptySelectValue?: string
  emptySelectText?: string
  labelColor?: string
  labelFontSize?: number
  disabled?: boolean
}
const InputSelect = ({
  values,
  label,
  defaultSelected,
  error,
  touched,
  inputClassName = '',
  labelClassName = '',
  continerClassName = '',
  isFormInline,
  emptySelect = false,
  emptySelectText,
  emptySelectValue = '',
  isRequired = false,
  value,
  labelColor = 'blue700-opacity-60',
  labelFontSize = 14,
  disabled,
  ...props
}: InputSelectProps) => {
  const _value =
    value?.toString() ||
    defaultSelected ||
    (emptySelect ? emptySelectValue : undefined)

  return (
    <div
      className={`input-main__select ${
        isFormInline ? 'd-flex align-items-center gap-3' : ''
      } ${continerClassName}`}
    >
      {label && (
        <label
          htmlFor=""
          className={`form-label font-light font-color-${labelColor} font_${labelFontSize} ${labelClassName}`}
        >
          {label}
          {isRequired && <span className="required">*</span>}
        </label>
      )}
      <select
        className={`form-select ${inputClassName}`}
        aria-label="Default select example"
        id={label}
        value={_value}
        {...props}
        disabled={disabled}
      >
        {defaultSelected && <option disabled>{defaultSelected}</option>}
        {emptySelect && (
          <option value={emptySelectValue}>{emptySelectText}</option>
        )}
        {values?.map((value) =>
          value.option ? (
            value.option
          ) : (
            <Fragment key={value.value}>
              {value.label && <optgroup label={value.label} />}
              <option key={value.value} value={value.value}>
                {value.text}
              </option>
            </Fragment>
          ),
        )}
      </select>
      {error && touched && (
        <div className="font_14 font-color-red500 ">{error}</div>
      )}
    </div>
  )
}

export default InputSelect
