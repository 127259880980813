import { TutorMobileNextClassCard } from 'components/Cards/ClassesCards/NextClass/TutorNextClassCard'
import Notifications from 'components/Notifications/NotificationsDropDown'
import { AdminSession } from 'core/types/session'
import { useGetTutorUpcomingSession } from 'layout/StudentDashboard/StudentDashboardHeader'
import MobileLayout from 'layout/common/MobileLayout'
import { TutorNavLinks } from './TutorNavLinks'

export function TutorMobileLayout() {
  const { data } = useGetTutorUpcomingSession()
  return (
    <MobileLayout
      header={
        <>
          {data?.id && (
            <TutorMobileNextClassCard session={data as AdminSession} />
          )}
          <Notifications />
        </>
      }
      sideBar={<TutorNavLinks />}
    />
  )
}
