import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export function useUrlParams<T extends Object>(value?: T) {
  const [searchParams, setSearchParams] = useSearchParams()
  const params = useMemo(
    () => Object.fromEntries(searchParams),
    [searchParams],
  ) as unknown as T
  function setParams(params: Partial<T>) {
    // @ts-ignore
    setSearchParams(params, { replace: true })
  }

  useEffect(() => {
    // @ts-ignore
    if (searchParams.size == 0 && value) {
      setParams(value)
    }
  }, [])

  return {
    params,
    setParams,
  }
}
