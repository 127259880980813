import { useQuery } from '@tanstack/react-query'
import { useLoggedIn, useLoggedInAdmin } from 'auth/useAuth'
import { useCreateModal } from 'components/Modal'
import { AdminRoleModalWindow } from 'components/Modals/Admin/AdminRoleModalWindow'
import AdminUserModal from 'components/Modals/Admin/AdminUserModal'
import { TextSearchFilter } from 'components/Query/Filters/Basic/SearchFilter'
import { AdminRoleSelectFilter } from 'components/Query/Filters/Extended'
import { useResource } from 'components/Query/useResource'
import RoleMangeTable from 'components/Tables/Admin/RoleMangeTable'
import UserMangeTable from 'components/Tables/Admin/UserMangeTable'
import Tabs from 'components/Tabs/Tabs'
import { Role } from 'core/types/Role'
import { Admin } from 'core/types/User'
import { AdminPage } from 'layout/AdminDashboard'
import { useState } from 'react'
import { listAdmins } from 'services/admin/admins'
import { listRoles } from 'services/admin/roles'

export function useGetRoles() {
  const { loggedInUser } = useLoggedIn()

  return useQuery({
    queryFn: listRoles,
    queryKey: ['adminRoles'],
    staleTime: Infinity,
    enabled: loggedInUser.isAdmin,
  })
}
export function useAdminUserTableQuery(enabled = true) {
  const { data: roles } = useGetRoles()
  const { loggedInUser } = useLoggedIn()

  return useResource({
    queryFn: listAdmins,
    name: 'adminUserTable',
    initialParams: { page: 1 },
    enabled: !!roles && loggedInUser.isAdmin && enabled,
    filters: {
      keyword: TextSearchFilter,
      role_id: AdminRoleSelectFilter,
    },
  })
}

const UserManage = () => {
  const query = useAdminUserTableQuery()
  const { data: roles } = useGetRoles()

  const UserModal = useCreateModal<Admin>()

  const RoleModal = useCreateModal<Role>()

  const { loggedInAdmin } = useLoggedInAdmin()
  const isReadOnly = loggedInAdmin?.permissions['User Manage']?.length === 0

  const [currentTab, clickHandler] = useState<'users' | 'roles'>('users')

  return (
    <AdminPage
      title="User Manage"
      query={currentTab === 'users' ? query : undefined}
      buttons={
        <Tabs
          className="order-1 order-md-0 mb-0"
          activeTab={currentTab}
          tabsConfig={[
            {
              name: 'users',
              label: 'Users',
              clickHandler,
            },
            {
              name: 'roles',
              label: 'Roles',
              clickHandler,
            },
          ]}
        />
      }
      actions={
        currentTab === 'users'
          ? [
              {
                hidden: isReadOnly,
                onClick: UserModal.openModal,
                label: 'Admin',
                icon: 'fa fa-user-plus',
              },
            ]
          : [
              {
                hidden: isReadOnly,
                onClick: RoleModal.openModal,
                label: 'Role',
                icon: 'fa fa-plus',
              },
            ]
      }
    >
      {currentTab === 'users' && (
        <UserMangeTable
          {...query}
          handleEditUser={UserModal.openModal}
          roles={roles?.mapObject}
        />
      )}

      {currentTab === 'roles' && (
        <RoleMangeTable
          {...roles}
          list={roles?.list}
          handleEditRole={RoleModal.openModal}
        />
      )}

      <UserModal.Container>
        <AdminUserModal />
      </UserModal.Container>

      <RoleModal.Container>
        <AdminRoleModalWindow />
      </RoleModal.Container>
    </AdminPage>
  )
}

export default UserManage
