import { BasicDatePickerInput } from 'components/Inputs/DatePickerInput'
import { SelectButton } from 'components/Select/SelectButton'
import { SelectProps } from 'components/Selectors/types'
import { useState } from 'react'
import { DATE_FORMAT, dateOrUndefined, formatDate } from 'utils/dateUtils'

export function DateSelect(
  props: SelectProps<Date> & {
    label?: string
    minDate?: Date
    maxDate?: Date
    dateHour?: 'startOfDay' | 'endOfDay'
  },
) {
  const { value, label, onChange } = props

  const [opened, setOpened] = useState(false)

  return (
    <BasicDatePickerInput
      onClickOutside={() => setOpened(false)}
      onChange={(d) => {
        onChange && onChange(dateOrUndefined(d))
        setOpened(false)
      }}
      label={label}
      isFormInline
      showTimeInput={false}
      open={opened}
      placeholderText={props.label}
      value={dateOrUndefined(value)}
      customInput={
        <SelectButton
          {...props}
          labelPlacement="compact"
          leftIcon={<i className="fa-regular fa-calendar font-blue300" />}
          onClicK={() => setOpened((_) => !_)}
          placeholder={props.label}
          onClear={() => onChange && onChange(undefined)}
        >
          {formatDate(value, DATE_FORMAT)}
        </SelectButton>
      }
      dateHour={props.dateHour}
      maxDate={props.maxDate}
      minDate={props.minDate}
    />
  )
}
