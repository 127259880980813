import { Select } from 'components/Select/Select'
import { SelectProps } from './types'

export default function LanguageSelect(props: SelectProps<string>) {
  return (
    <Select
      {...props}
      label={'Language'}
      options={[
        {
          label: 'English',
          value: 'english',
        },
        {
          label: 'Arabic',
          value: 'arabic',
        },
      ]}
    />
  )
}
