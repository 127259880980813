export function IschoolSvgIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="25"
      viewBox="0 0 826.3 1000"
      xmlSpace="preserve"
    >
      <path
        id="yellow-trail"
        fill="#ffd700"
        d="M455.5,420.1C315,479,207.2,562.6,154.5,690.1c-54,132.2,58.4,202.7,179,169.8     c83.7-21.3,164.6-81.6,231.9-150.8c134.4-130.5,173.2-355.6,39-498.2C462.5,56.7,233.5,113.6,85.6,237     c-28.4,22.1-62.5-19.2-35.7-43C170.3,86.1,349.8,7.3,515.5,61.1c172.9,51.3,284.2,233.8,271.4,410.7     c-9.4,179.4-122.6,327.4-261.3,430c-132.9,105.4-364.1,150-480.9-8.2C-47.9,765.1,17.6,591.3,107,483.4     c73.8-91.9,157.8-168,253.6-225.7c96.2-53.6,183.1,92,88,147.8L455.5,420.1z"
      />
      <path
        fill="#056fec"
        id="blue-body"
        d="M503.5,550.8l9.1,243.3c1.8,46-23.9,75.5-67.5,77.2c-43.5,1.7-71.4-25.7-73.2-71.8l-9.1-243.3     c-1.8-46,23.9-75.5,67.5-77.2C473.8,477.3,501.7,504.8,503.5,550.8z"
      />
      <circle id="orange-ball" fill="#ff7f1c" cx="415.2" cy="334.7" r="94.5" />
    </svg>
  )
}
