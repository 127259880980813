import { MEDIA_BREAKPOINT_MD } from '../../constants'
import { useEffect, useState } from 'react'

const useViewPort = () => {
  const [width, setWidth] = useState(0)

  const handleWindowResize = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [width])

  return { width, isMobile: width <= MEDIA_BREAKPOINT_MD }
}

export default useViewPort
