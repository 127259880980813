import { Admin, Student, Tutor, User } from 'core/types/User'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext, AuthState } from './AuthProvider'
import { getHomePage } from './RequireAuth'

export function useAuth<
  T extends User | null | undefined = User | null | undefined,
>() {
  return useContext(AuthContext) as AuthState<T>
}

export function useLoggedIn<T extends User = User>() {
  const {
    user: loggedInUser,
    login,
    isGuest,
    isAuthenticated,
    isLoading,
    ...rest
  } = useContext(AuthContext) as AuthState<T>

  const navigate = useNavigate()

  useEffect(() => {
    if (!loggedInUser) {
      navigate(getHomePage())
    }
  }, [loggedInUser])

  return {
    loggedInUser,
    ...rest,
  }
}

function useRemoveFlags<T extends User = User>() {
  const {
    loggedInUser: { isAdmin, isStudent, isTutor, role, ...loggedInUser },
    ...rest
  } = useLoggedIn<T>()
  return { loggedInUser, ...rest }
}

export function useLoggedInStudent() {
  const { loggedInUser: loggedInStudent, ...rest } = useRemoveFlags<Student>()

  return {
    loggedInStudent,
    ...rest,
  }
}

export function useLoggedInTutor() {
  const { loggedInUser: loggedInTutor, ...rest } = useRemoveFlags<Tutor>()

  return { loggedInTutor, ...rest }
}

export function useLoggedInAdmin() {
  const { loggedInUser: loggedInAdmin, ...rest } = useRemoveFlags<Admin>()
  return { loggedInAdmin, ...rest }
}
