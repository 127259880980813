import {
  QueryKey,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useLoggedIn } from 'auth/useAuth'
import { XPagination } from 'components/Pagination/XPagination'
import { useIsMobile } from 'components/useMediaQueries'
import { useFilters } from 'core/hooks/useFilters'
import { PaginatedResponse } from 'core/types/Response'
import { useMemo } from 'react'
import { CommonParams } from 'services/utils'
import { Filter } from './filter'
import { HeaderProp } from './QueryTable/QueryTable'

export type ResourceItem = {
  id: number
  __state?: 'is_updating' | 'is_deleting' | 'is_deleted'
}

export type ResourceFilters<Filters> = {
  [key in keyof Filters]: Filter<Filters[key]>
}
export type ResourceQuery = ReturnType<
  typeof useResource<{ [key: string]: any } & CommonParams, ResourceItem>
>

export type QueryParams = Partial<{ [key: string]: any } & CommonParams>

export type QueryResource<
  P extends QueryParams = QueryParams,
  D extends ResourceItem = ResourceItem,
> = Partial<ReturnType<typeof useResource<P, D>>>

export function useResource<
  Filters extends CommonParams,
  Item extends ResourceItem,
>(props: {
  filters?: ResourceFilters<Filters>
  name?: string
  querykey?: QueryKey
  queryFn: (params: Filters) => Promise<PaginatedResponse<Item>>
  initialParams: Filters
  enabled?: boolean
  exportFn?: (params: Filters) => void
  table?: HeaderProp[]
  modals?: any[]
}) {
  const { currentRole } = useLoggedIn()
  const {
    name,
    queryFn,
    initialParams,
    querykey,
    enabled,
    filters: filtersConfig,
  } = props
  const queryClient = useQueryClient()
  const filters = useFilters<Filters>(initialParams)
  const isMobile = useIsMobile()

  // add current role to keys to invalidate queries on role switching
  let qk: QueryKey = [name, filters.toString(), currentRole]
  if (querykey) {
    qk = qk.concat([...querykey])
  }
  const indexQuery = useQuery({
    queryFn: () => queryFn(filters.params),
    queryKey: qk,
    keepPreviousData: true,
    enabled,
  })

  const Pagination = useMemo(() => {
    return (
      indexQuery.data &&
      indexQuery.data.pagination &&
      indexQuery.data.data.length > 0 && (
        <XPagination
          minimal={isMobile}
          pagination={{
            ...indexQuery.data?.pagination,
            current:
              (indexQuery.isPreviousData
                ? filters.params.page
                : indexQuery.data.pagination?.current) || 0,
          }}
          // @ts-ignore
          onPageChange={(page) => filters.update({ page })}
        />
      )
    )
  }, [indexQuery.data, filters.params.page])

  const API = {
    name,
    filtersConfig,
    list: indexQuery.data?.data,
    filters,
    Pagination,
    filterInputs: filtersConfig,
    qk,
    exportFn: props.exportFn,
    ...indexQuery,
  }

  return API
}

export function useInfiniteResource<
  Filters extends CommonParams,
  Item extends ResourceItem,
>(props: {
  filters?: ResourceFilters<Filters>
  name?: string
  querykey?: QueryKey
  queryFn: (params: Filters) => Promise<PaginatedResponse<Item>>
  initialParams: Filters
  enabled?: boolean
  exportFn?: (params: Filters) => void
  table?: HeaderProp[]
  modals?: any[]
}) {
  const { currentRole } = useLoggedIn()
  const {
    name,
    queryFn,
    initialParams,
    querykey,
    enabled,
    filters: filtersConfig,
  } = props
  const queryClient = useQueryClient()
  const filters = useFilters<Filters>(initialParams)
  const isMobile = useIsMobile()

  // add current role to keys to invalidate queries on role switching
  let qk: QueryKey = (querykey || []).concat([filters.toString(), currentRole])
  const infiniteQuery = useInfiniteQuery({
    queryFn: ({ pageParam: page }) => {
      return queryFn({ ...filters.params, page })
    },
    queryKey: qk,
    keepPreviousData: true,
    enabled,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.pagination.next) {
        return lastPage.pagination.next
      }
      return undefined
    },
  })

  const API = {
    filtersConfig,
    list: infiniteQuery.data?.pages?.flatMap((i) => i.data),
    filters,
    filterInputs: filtersConfig,
    qk,
    exportFn: props.exportFn,
    ...infiniteQuery,
  }

  return API
}

export type ResourceItemUpdatePatcher<Item extends ResourceItem> = (
  item: Partial<Item> & { id: number },
  updateFn: () => Promise<Item>,
) => void
