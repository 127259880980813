import { BaseFilter } from 'components/Query/filter'
import { Select } from 'components/Select/Select'
import { NumberOrString } from 'components/Select/types'
import { ReactNode } from 'react'

export interface SelectFilter extends BaseFilter<string | undefined> {
  label: string
  type: 'select'
  options?: (NumberOrString | { label: ReactNode; value: NumberOrString })[]
  searchable?: boolean
  multi?: boolean
}

export function SelectFilter<T>(
  props: Omit<SelectFilter, 'type' | 'name'> & {
    disabled?: boolean
    name?: string
  },
) {
  return (
    // @ts-ignore
    <Select {...props} />
  )
}
