import { CustomFilter } from 'components/Query/filter'
import {
  AdminRole,
  PaginatedAdminSelect,
} from 'components/Selectors/users/AdminSelect'
import { PaginatedStudentSelect } from 'components/Selectors/users/StudentSelect'
import { PaginatedTutorSelect } from 'components/Selectors/users/TutorSelect'
import { Role } from 'core/types/User/Auth'
import { UserTypeSelect } from 'pages/admin/Support'

export const TutorSelectFilter: CustomFilter<number | undefined> = {
  type: 'custom',
  element: (ctx) => (
    <PaginatedTutorSelect
      {...ctx}
      params={{}}
      value={ctx.filters?.params?.[ctx.name!]}
      onChange={(id) => ctx.filters?.update({ [ctx.name!]: id })}
    />
  ),
}

export const AdminSelectFilter = (
  adminRole?: AdminRole,
): CustomFilter<number | undefined> => ({
  type: 'custom',
  element: (ctx) => (
    <PaginatedAdminSelect
      {...ctx}
      adminRole={adminRole}
      params={{}}
      value={ctx.filters?.params?.[ctx.name!]}
      onChange={(id) => ctx.filters?.update({ [ctx.name!]: id })}
    />
  ),
})
export const TeamLeadSelectFilter = AdminSelectFilter('is_team_lead')
export const EduMentorSelectFilter = AdminSelectFilter('is_edu_mentor')
export const OpsSupervisorSelectFilter = AdminSelectFilter('is_ops_supervisor')
export const OpsModeratorSelectFilter = AdminSelectFilter('is_ops_moderator')
export const SalesOwnerSelectFilter = AdminSelectFilter('is_sales_owner')
export const RetentionOwnerSelectFilter =
  AdminSelectFilter('is_retention_owner')

export const StudentSelectFilter: CustomFilter<number | undefined> = {
  type: 'custom',
  element: (ctx) => (
    <PaginatedStudentSelect
      {...ctx}
      params={{}}
      value={ctx.filters?.params?.[ctx.name!]}
      onChange={(id) => ctx.filters?.update({ [ctx.name!]: id })}
    />
  ),
}

export const UserTypeSelectFilter = ({
  allowed = ['Admin', 'Student', 'Tutor'],
}: {
  allowed?: Role[]
}): CustomFilter<string | undefined> => ({
  type: 'custom',
  element: (ctx) => <UserTypeSelect {...ctx} allowed={allowed} />,
})
