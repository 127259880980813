import { Select } from 'components/Select/Select'
import { SelectProps } from './types'

export default function TutorStatusSelect(props: SelectProps<string>) {
  return (
    <Select
      {...props}
      label="Status"
      options={[
        // { value: 'training', label: 'Training' },
        { value: 'working', label: 'Working' },
        { value: 'resigned', label: 'Resigned' },
      ]}
      clearable={false}
    />
  )
}
