import { Select } from 'components/Select/Select'
import { useGetCurriculum } from 'services/curriculum'
import { SelectProps } from '../types'

export function LessonSelect(
  props: SelectProps<number> & { levelId?: number; curriculumId?: number },
) {
  const { data } = useGetCurriculum(props.curriculumId)

  let lessons = props?.levelId
    ? data?.levels?.[props.levelId]?.lessons
    : undefined

  return (
    <Select
      searchable
      label="Lesson"
      disabled={!props.levelId}
      options={lessons?.map((l) => ({ label: l.name, value: l.value }))}
      value={props.value}
      onChange={props.onChange}
    />
  )
}
