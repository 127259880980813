import Button from 'components/Button/Button'
import { DropDown } from 'components/Dropdown/Dropdown'
import HotPickFilters from 'components/Query/HotPickFilters'
import { QueryFiltersType } from 'components/Query/Provider/QueryProvider'
import QueryFilterModal from 'components/Query/QueryFilterModal'
import { usePopup } from 'components/Select/utils/usePopup'
import groupBy from 'lodash.groupby'
import { Fragment, ReactNode, RefObject, useMemo } from 'react'
import { CommonParams } from 'services/utils'
import { ResourceItem } from 'utils/usePaginatedResource'

export type ActionButtonProp = {
  label: ReactNode
  onClick?: () => void
  icon?: string
  appliedOnFilter?: boolean
  hidden?: boolean
  group?: string
  disabled?: boolean
  showGroupName?: boolean
}
interface HeaderPageProps<
  Filters extends CommonParams,
  Data extends ResourceItem,
> {
  title?: ReactNode
  hasQueryFilter?: boolean
  primaryButton?: ReactNode
  query: QueryFiltersType<Filters, Data>
  actions?: ActionButtonProp[]
}

export function ActionButton(a: {
  onClick: () => void
  icon?: ReactNode
  disabled?: boolean
  label: ReactNode
}) {
  return (
    <Button
      className="dropdown-item text-start align-items-baseline justify-content-start px-4 py-2 font_14 b-0  d-flex gap-1"
      onClick={a.onClick}
      label={''}
      variant="gray"
      disabled={a.disabled}
      isBorderButton
    >
      <i className={`${a.icon} font-color-blue300`} style={{ width: '25px' }} />
      {a.label}
    </Button>
  )
}

export function ActionsDropdown({
  actions,
  trigger,
  placement = 'bottom-right',
}: {
  placement?: 'bottom-left' | 'bottom-right'
  actions?: ActionButtonProp[]
  trigger?: (p: {
    toggle: () => void
    triggerRef: RefObject<HTMLButtonElement>
  }) => ReactNode
}) {
  const Popup = usePopup(placement)
  const _actions = (actions || [])
    .filter((a) => !a.hidden)
    .map((a) => ({
      ...a,
      onClick: () => {
        if (a.onClick) {
          Popup.close()
          a.onClick()
        }
      },
    }))

  const nonFilterActions = _actions.filter((a) => !a.appliedOnFilter)

  const filtersAppliedActions = _actions.filter((a) => a.appliedOnFilter)

  const groupedFilters = useMemo(
    () => Object.entries(groupBy(filtersAppliedActions || [], 'group')),
    [filtersAppliedActions],
  )

  return (
    <>
      <DropDown
        placement={placement}
        trigger={({ toggle, triggerRef }) =>
          _actions.length > 0 && trigger
            ? trigger({ toggle, triggerRef })
            : undefined
        }
      >
        {nonFilterActions?.map((a, index) => (
          <ActionButton key={index} {...a} />
        ))}
        {filtersAppliedActions.length > 0 && (
          <>
            <div className="font_12 d-flex align-items-center gap-2 ps-2 font-color-orange500 mt-2  font-semibold">
              Filter Applied
            </div>

            <div
              className="ms-3 flex-1  border-color-orange500"
              style={{ borderLeft: '2px solid' }}
            >
              {groupedFilters.map(([groupName, actions], index) => (
                <Fragment key={groupName}>
                  {groupName && groupName !== 'undefined' && (
                    <span className="px-2 d-flex py-2 border border-color-grey-opacity-10 bg-color-primary-opacity-4 text-capitalize font-color-blue300 font_14">
                      {groupName}
                    </span>
                  )}
                  {actions.map((a) => (
                    <ActionButton key={index} {...a} />
                  ))}
                </Fragment>
              ))}
            </div>
          </>
        )}
      </DropDown>
    </>
  )
}

function HeaderPage<Filters extends CommonParams, Data extends ResourceItem>({
  title,
  actions,
  query,
  hasQueryFilter = true,
}: HeaderPageProps<Filters, Data>) {
  return (
    <div className=" ">
      {hasQueryFilter && (
        <div
          style={{
            width: '100%',
            display: 'grid',
            alignItems: 'center',
            gap: '10px',
            gridAutoFlow: 'dense',
            overflow: 'hidden',
            gridTemplateColumns: `repeat(auto-fill, minmax(210px, 1fr))`,
          }}
          className=" rounded px-2 border-color-orange500-opacity-20 py-2"
        >
          <div
            className="gap-1 d-flex   justify-content-end"
            style={{
              gridRow: 1,
              gridColumnStart: '-2',
              maxHeight: '45px',
            }}
          >
            <div
              className="border d-flex  rounded overflow-hidden md-justify-content-end"
              style={{ borderCollapse: 'collapse', height: '45px' }}
            >
              <ActionsDropdown actions={actions} />

              <div
                className=" bg-color-grey-opacity-10"
                style={{
                  height: '100%',
                  width: '1px',
                }}
              />

              {hasQueryFilter && <QueryFilterModal query={query} />}
            </div>
          </div>
          <HotPickFilters query={query} />
        </div>
      )}
    </div>
  )
}

export default HeaderPage
