import ISchoolLogo from './ISchoolLogo'
import WhiteLabelLogo from './WhiteLabelLogo'

interface LogoProps {
  secondaryLogoBigger?: boolean
  showWhiteLabelLogo?: boolean
  showIschoolLogo?: boolean
  useWhiteLogo?: boolean
  lgWhitelabel?: boolean
}

const Logo = ({
  useWhiteLogo = false,
  secondaryLogoBigger = true,
  showIschoolLogo = true,
  showWhiteLabelLogo = true,
  lgWhitelabel = false,
}: LogoProps) => {
  return (
    <div className="d-flex mb-4 gap-4 flex-column align-items-center justify-content-center">
      {showWhiteLabelLogo && (
        <WhiteLabelLogo
          lg={lgWhitelabel}
          scale={secondaryLogoBigger ? '1.3' : '1'}
        />
      )}
      {showIschoolLogo && <ISchoolLogo useWhiteLogo={useWhiteLogo} />}
    </div>
  )
}

export default Logo
