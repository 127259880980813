import { PaginatedResponse, Response } from 'core/types/Response'
import { AdminAPI } from 'services/API'
import { extractResponseData } from 'services/utils'

export type SubscriptionPlan = {
  id: number
  name: string
  slug: string
  price: number
  price_egp: number
  months_interval: number
  classes_count: number
  active: boolean
  freeze_count: number
  reschedule_count: number
  prices: {
    AED: 0
    BHD: 0
    EGP: 0
    EUR: 0
    KWD: 0
    OMR: 0
    QAR: 0
    SAR: 0
    USD: 0
  }
}

export function getPlans() {
  return AdminAPI.get<PaginatedResponse<SubscriptionPlan>>(
    'subscription_plans',
  ).then((r) => r.data)
}

export function updatePlan(plan: Partial<SubscriptionPlan>) {
  return AdminAPI.put<Response<SubscriptionPlan>>(
    `subscription_plans/${plan.id}`,
    plan,
  ).then(extractResponseData)
}

export function createPlan(plan: Partial<SubscriptionPlan>) {
  return AdminAPI.post<Response<SubscriptionPlan>>(
    `subscription_plans`,
    plan,
  ).then(extractResponseData)
}
