import { CustomFilter } from 'components/Query/filter'
import SlotSelect from 'components/Selectors/SlotSelect'
import { numberOrUndefined } from 'utils/helpers'

export const SlotSelectFilter = ({
  slotDayFilterName,
  grouped,
}: {
  slotDayFilterName?: string
  grouped?: boolean
}): CustomFilter<number | undefined> => ({
  type: 'custom',
  element: (props) => {
    return (
      <SlotSelect
        grouped={grouped}
        allSlots={!slotDayFilterName}
        dayId={
          slotDayFilterName
            ? (numberOrUndefined(props.filters?.params?.[slotDayFilterName]) as
                | Day
                | undefined)
            : undefined
        }
        {...props}
      />
    )
  },
})
