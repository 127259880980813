import StarsRating from 'react-star-rate'
// SCSS
import './Rating.scss'

interface IRatingProps {
  readOnly?: boolean
  defaultValue?: number
  rateColor?: string
  starColor?: string
  containerClassName?: string
  starSize?: number
  onChange?: (rating?: number) => void
  opacity?: number
  isShowValue?: boolean
  label?: string
  value?: number
  labelClassName?: string
  count?: number
}
const Rating = ({
  readOnly = false,
  value = 0,
  rateColor = 'blue-700',
  starSize = 16,
  onChange,
  opacity,
  starColor = 'secondary',
  containerClassName = '',
  isShowValue = false,
  label,
  labelClassName = '',
  count = 5,
}: IRatingProps) => {
  return (
    <>
      <div className={`d-flex align-items-center gap-3 ${containerClassName}`}>
        {label && (
          <p
            className={`font-color-text-light font-light font_16 mb-0 flex-fill ${labelClassName}`}
          >
            {label}
          </p>
        )}
        <StarsRating
          defaultValue={value}
          disabled={readOnly}
          allowHalf={false}
          value={value}
          onChange={onChange}
          classNamePrefix="rating"
          count={count}
          symbol={
            <i
              className={`fa-solid fa-star font_${starSize} font-color-${starColor}${
                opacity ? `-opacity-${opacity}` : ''
              } `}
            />
          }
        />
        {isShowValue && (
          <p
            className={`font-color-${rateColor}${
              opacity ? `-opacity-${opacity}` : ''
            } align-bottom ms-12 lh-0 mb-0`}
          >
            {value}
          </p>
        )}
      </div>
    </>
  )
}

export default Rating
