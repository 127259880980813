import { useLoggedInStudent } from 'auth/useAuth'
import { useCommunityUnreadStatus } from 'core/hooks/useCommunityUnreadStatus'
import { IschoolSvgIcon } from 'layout/common/IschoolSvgIcon'
import {
  SideBarContext,
  useShowWhiteLabelFirst,
} from 'layout/common/NavSidebar'
import { SideBarNavItem } from 'layout/common/SideBarNavItem'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { EnrollmentQuizPageStates } from 'routes/StudentRoutes'
import { useOrganization } from 'utils/Providers/OrganizationProvider'

export function StudentLinks() {
  const { loggedInStudent } = useLoggedInStudent()
  const { org } = useOrganization()
  const { t } = useTranslation(['common'])
  const { hasUnreadMessages } = useCommunityUnreadStatus()
  const location = useLocation()

  const showWhiteLabelLogoFirst = useShowWhiteLabelFirst()
  const { collapsed, setCollapsed } = useContext(SideBarContext)

  const hasPractical = org?.student_permissions?.includes('Practical Classes')

  if (EnrollmentQuizPageStates.includes(loggedInStudent.state)) {
    return (
      <SideBarNavItem
        icon={<i className="fa fa-edit" />}
        to={'enrollment-quiz'}
        text={t('quiz')}
      />
    )
  }

  return (
    <>
      {showWhiteLabelLogoFirst && collapsed && (
        <div
          className="d-flex align-items-center justify-content-center my-3"
          style={{ minWidth: '25px', minHeight: '25px' }}
        >
          <IschoolSvgIcon />
        </div>
      )}
      <SideBarNavItem
        icon={<i className="fa fa-desktop" />}
        to={'classes'}
        text={t('classes')}
        tabName={'Classes'}
      />
      <SideBarNavItem
        icon={<i className="fa fa-book-open-reader" />}
        to={'content'}
        text={t('content')}
        tabName="LMS"
      />
      <SideBarNavItem
        icon={<i className="fa fa-calendar" />}
        to={'schedule'}
        text={t('schedule')}
        tabName={'Schedule'}
      />
      {hasPractical && (
        <SideBarNavItem
          icon={<i className="fa fa-building " />}
          to={'practical-classes'}
          text={t('practical_sessions')}
          tabName={'Practical Classes'}
        />
      )}
      <SideBarNavItem
        to={'progress'}
        text={t('progress')}
        icon={<i className="fa-solid fa-flag fa-fw" />}
        tabName={'Progress'}
      />
      <SideBarNavItem
        icon={<i className="fa-solid fa-book-open-reader" />}
        to={'reference'}
        text={t('resources')}
        tabName={'Resources'}
      />
      <SideBarNavItem
        to={'activities'}
        icon={<i className="fa-solid fa-wrench" />}
        text={t('projects')}
        tabName={'Activities'}
      />
      <SideBarNavItem
        icon={<i className="fa-solid fa-store fa-fw" />}
        to={'subscription'}
        text={t('subscription')}
        tabName={'Subscription'}
      />
      <SideBarNavItem
        to={'help_desk'}
        text={t('help_desk')}
        icon={<i className="fa-solid fa-headset" />}
        tabName={'Help Desk'}
      />

      <SideBarNavItem
        icon={<i className="fa fa-trophy" />}
        to={'achievements'}
        text={t('achievements')}
        tabName={'Achievements'}
      />

      <SideBarNavItem
        icon={<i className="fa fa-comment" />}
        to={'community'}
        text={t(org?.domain?.includes('deci') ? 'deci_community' : 'community')}
        tabName={'Community'}
        showIndicator={
          hasUnreadMessages && !location.pathname.includes('community')
        }
      />
      <SideBarNavItem
        icon={<i className="fa fa-user" />}
        to={'profile'}
        text={t('Profile')}
      />
    </>
  )
}
