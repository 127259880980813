import { useQueryClient } from '@tanstack/react-query'
import { useLoggedIn } from 'auth/useAuth'
import { TutorNextClassStudentAvatar } from 'components/Avatar/Avatar'
import Button from 'components/Button/Button'
import { useCreateModal } from 'components/Modal'
import { ModalWindow } from 'components/Modal/ModalWindow'
import ConfirmationModalWindow from 'components/Modals/ConfirmationModal'
import { useIsMobile } from 'components/useMediaQueries'
import { AdminSession, Session } from 'core/types/session'
import { differenceInMinutes } from 'date-fns'
import { ReactNode, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { QueryKeys } from 'services/QueryKeys'
import {
  joinAdminSession,
  joinTutorSession,
  pingParent,
} from 'services/tutor/sessions'
import {
  formatCairoDate,
  formatCairoTime,
  formatDate,
  formatTime,
} from 'utils/dateUtils'
import { errorToast } from 'utils/errorToast'
import { formatNumber } from 'utils/numbers'
import { Location } from '../ClassCard'
import { useNextSessisoServerCountdown } from './useNextSessisonCountdown'

type NextClassCardProps = { session: AdminSession }

function useTutorNextSessionCountdown(session: AdminSession) {
  const nextSessionCountdown = useNextSessisoServerCountdown(session)
  const queryClient = useQueryClient()

  let enableJoin =
    nextSessionCountdown.days == 0 &&
    nextSessionCountdown.hours === 0 &&
    nextSessionCountdown.minutes <= (session.is_offline ? 10 : 25) &&
    !nextSessionCountdown.isEnded

  useEffect(() => {
    if (nextSessionCountdown.isEnded) {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.sessionsList],
      })

      queryClient.invalidateQueries({
        queryKey: [QueryKeys.upcomingSession],
      })
    }
  }, [nextSessionCountdown.isEnded])

  return { nextSessionCountdown, enableJoin }
}

export function TutorNextClassCard({ session }: NextClassCardProps) {
  const { nextSessionCountdown, enableJoin } =
    useTutorNextSessionCountdown(session)

  const [opened, setOpened] = useState(false)
  const toggle = () => setOpened((opened) => !opened)

  return (
    <>
      <div className="flex-1 shadow-sm ">
        <div
          className={`text-start  px-2  border-b border-color-grey-opacity-10 py-2  d-flex align-items-center flex-wrap gap-2 font-color-blue300 font-semibold `}
        >
          <Button
            isBorderButton
            className="p-2 py-2 border-0 gap-2 font_16"
            onClick={toggle}
            buttonSize="small"
          >
            <span className="font-color-blue300 d-flex gap-2 align-items-baseline">
              <i
                style={{ width: '20px' }}
                className={
                  opened ? 'fa fa-chevron-down' : 'fa fa-chevron-right'
                }
              />
            </span>
          </Button>

          <CountDownNumber value={nextSessionCountdown.days} label="Days" />
          <CountDownNumber value={nextSessionCountdown.hours} label="Hrs" />
          <CountDownNumber value={nextSessionCountdown.minutes} label="Mins" />
          <JoinNowButton session={session} disabled={!enableJoin} />
          <SessionManual session={session} />
          <SessionSlides session={session} />
        </div>
        {opened && (
          <div className="bg-color-primary300-opacity-10 border-b border-color-grey-opacity-10 ">
            <div className="font_14 pt-3  border-color-grey-opacity-5 flex-wrap px-4 text-start gap-4  d-flex  font-color-blue300">
              {session.is_offline ? (
                <Location location={session.location} showDateTime={false} />
              ) : (
                <TutorNextClassStudentAvatar
                  userImage={session.student.avatar_url}
                  userName={session.student.display_name}
                >
                  <span className="d-block font-semibold py-2">
                    {session.student.s_id}
                  </span>
                  <PingParentButton disabled={!enableJoin} session={session} />
                </TutorNextClassStudentAvatar>
              )}
              <SessionTime session={session} />

              <div className="gap-2 font-color-blue300 d-flex flex-column gap-2 font_14">
                <div>{session.grade.name}</div>
                <div>{session.level.name}</div>
                <div>{session.lesson.name}</div>
              </div>
            </div>
            <div className="d-flex  align-items-center flex-wrap  gap-3 py-2 px-4"></div>
          </div>
        )}
      </div>
    </>
  )
}

function SessionTime({ session }: NextClassCardProps) {
  const [classDate, classTime] = [
    (session.is_offline ? formatCairoDate : formatDate)(
      session?.start_at,
      'EEEE d MMMM yyyy',
    ),
    (session.is_offline ? formatCairoTime : formatTime)(
      session?.start_at,
      'h:mm a',
    ),
  ]

  return (
    <div className={`d-grid text-start text-nowrap  gap-2`}>
      <div className="font_14 d-flex  align-items-center  text-nowrap gap-2">
        <i className="fa fa-language  font-color-primary" />

        <span className="text-capitalize">{session.student.language}</span>
      </div>
      <div className="font_14 d-flex  align-items-center text-nowrap gap-2">
        <i className="fa-regular fa-calendar font-color-primary" />

        <span>{classDate}</span>
      </div>
      <div className="font_14 d-flex  align-items-center text-nowrap gap-2">
        <i className="fa-regular fa-clock font-color-primary" />
        <span>{classTime}</span>
      </div>
      <div className="font_14 d-flex  align-items-center text-nowrap gap-2">
        <i className="fa-regular fa-hourglass font-color-primary" />
        <span>
          {differenceInMinutes(
            new Date(session.end_at),
            new Date(session.start_at),
          )}{' '}
          Minutes
        </span>
      </div>
    </div>
  )
}

export function SessionManual({ session }: NextClassCardProps) {
  return (
    <LessonMaterial
      icon="fa fa-file"
      name="Manual"
      file_url={session.lesson.manual_url}
    />
  )
}

type LessonMaterial = {
  icon: string
  name: string
  file_url?: string
}

export function LessonMaterial({ icon, name, file_url }: LessonMaterial) {
  return (
    <a
      target="_blank"
      style={{ maxWidth: 'min-content' }}
      className=" align-items-center gap-2 d-flex  bg-white border rounded  w-100 px-2 py-02 font_14 text-nowrap font-regular "
      href={file_url}
    >
      <i className={icon} />
      {name}
    </a>
  )
}

export function LessonHomework({ session }: NextClassCardProps) {
  return (
    <LessonMaterial
      icon="fa fa-pencil-alt"
      name="Homework"
      file_url={session.lesson.homework_url}
    />
  )
}

export function LessonRevision({ session }: NextClassCardProps) {
  return (
    <LessonMaterial
      icon="fa fa-clipboard-list"
      name="Revision"
      file_url={session.lesson.revision_url}
    />
  )
}

export function SessionSlides({ session }: NextClassCardProps) {
  return (
    <LessonMaterial
      icon="fa fa-laptop"
      name="Slides"
      file_url={session.lesson.slides_url}
    />
  )
}

type SessionButtonProps = { session: AdminSession; disabled?: boolean }

function PingParentButton({ session, disabled }: SessionButtonProps) {
  const ConfirmationModal = useCreateModal()
  if (session.is_offline) return null

  return (
    <>
      <Button
        disabled={disabled}
        variant="orange500"
        onClick={() => ConfirmationModal.openModal()}
        className="rounded font_14 px-2 gap-2  font-semibold py-1 font_14 text-nowrap "
        label="Ping Parent"
      >
        <i className="fa fa-bell font_12" />
      </Button>
      <ConfirmationModal.Container>
        <ConfirmationModalWindow
          confirmationMessage={'Are you sure you want to ping this parent?'}
          onSubmit={async () => {
            try {
              await pingParent(session?.id)
              ConfirmationModal.closeModal()
              toast.success('Parent Successfully Pinged')
            } catch (e: any) {
              toast.error(e?.response?.data?.message)
            }
          }}
        />
      </ConfirmationModal.Container>
    </>
  )
}

export function JoinNowButton({ session, disabled }: SessionButtonProps) {
  const LocationModal = useCreateModal<Session>()
  const { loggedInUser } = useLoggedIn()

  const [isLoading, setIsLoading] = useState(false)
  async function clickHandler() {
    try {
      setIsLoading(true)
      if (loggedInUser.isAdmin) {
        await joinAdminSession(session.id)
      } else {
        await joinTutorSession(session.id)
      }

      toast.success(
        `Session join time logged successfully\n ${
          !session.is_offline ? 'Opening meeting...' : ''
        }`,
      )
    } catch (e) {
      errorToast(e)
      errorToast('Session join time could not be logged')
    } finally {
      setIsLoading(false)
      if (!session.is_offline) {
        setTimeout(() => {
          window.open(session?.meeting_url, '_blank')
        }, 1200)
      }
    }
  }

  return (
    <>
      <Button
        disabled={disabled || isLoading}
        variant="primary"
        onClick={clickHandler}
        isLoading={isLoading}
        className="rounded gap-2 font-semibold font_14 px-2 py-2 text-nowrap "
        label={session.is_offline ? 'Start Now' : 'Join Now'}
      >
        <i className="fa-solid  fa-right-to-bracket" />
      </Button>
    </>
  )
}

type CountDownNumberProps = {
  value: number
  label: ReactNode
}
function CountDownNumber({ value, label }: CountDownNumberProps) {
  const isMobile = useIsMobile()
  return (
    <div
      className={
        isMobile
          ? 'd-flex   gap-1 align-items-center'
          : 'd-flex bg-white  gap-2 align-items-center border  rounded p-2'
      }
    >
      <div
        className={`font-color-primary font-bold ${
          isMobile ? 'font_16' : 'font_20'
        }`}
      >
        {formatNumber(value, 2)}
      </div>
      <span className="font-color-blue-700 font_14">{label}</span>
    </div>
  )
}

export function TutorMobileNextClassCard({ session }: NextClassCardProps) {
  const { nextSessionCountdown, enableJoin } =
    useTutorNextSessionCountdown(session)
  const Modal = useCreateModal()

  return (
    <>
      <button
        onClick={Modal.openModal}
        className={`text-start rounded border bg-color-primary300-opacity-50 px-3 flex-1 d-flex align-items-center flex-wrap gap-2 font-color-blue300 font-semibold `}
      >
        <CountDownNumber value={nextSessionCountdown.days} label="D" />
        <CountDownNumber value={nextSessionCountdown.hours} label="H" />
        <CountDownNumber value={nextSessionCountdown.minutes} label="M" />

        <i className="ms-auto fa fa-right-to-bracket font-color-primary" />
      </button>

      <Modal.Container>
        <ModalWindow
          hasCloseBtn
          title={<JoinNowButton session={session} disabled={!enableJoin} />}
          headerClassName="pt-4 pb-2 px-4"
        >
          <div className="font_14   border-color-grey-opacity-5 flex-wrap  text-start gap-4  d-flex flex-column font-color-blue300">
            {session.is_offline ? (
              <Location location={session.location} showDateTime={false} />
            ) : (
              <div className="d-flex align-items-center gap-2">
                <i className="fa fa-user-graduate p-4 border rounded font_20 " />
                <div className="d-flex flex-column gap-1">
                  <span className="d-block font-semibold text-capitalize">
                    {session.student.display_name}
                  </span>
                  <span className="d-block font-semibold ">
                    {session.student.s_id}
                  </span>
                </div>
              </div>
            )}
            {!session.is_offline && (
              <PingParentButton disabled={!enableJoin} session={session} />
            )}

            <SessionTime session={session} />

            <div className="d-grid gap-2 font-color-blue300 align-items-start  font_14">
              <div>{session.grade.name}</div>
              <div>{session.level.name}</div>
              <div className="">{session.lesson.name}</div>
              <div className="d-flex gap-3">
                <SessionManual session={session} />
                <SessionSlides session={session} />
              </div>
            </div>
          </div>
        </ModalWindow>
      </Modal.Container>
    </>
  )
}
