import { getPaymentMethod } from 'services/student/payment'

const StorageKeys = {
  LANGUAGE: 'lng',
  TOKEN: 'token',
  ROLE: 'role',
  ORGANIZATION_ID: 'OrgId',
  MARKETING_PARAMS: 'marketingParams',
  DEV_DOMAIN: 'dov_domain',
}

export const LocalStorage = {
  set(key: keyof typeof StorageKeys, value: string) {
    localStorage.setItem(key, value)
  },

  get(key: keyof typeof StorageKeys) {
    return localStorage.getItem(key)
  },
}

export const storeCurrency = async (
  urlLocation?: string,
  urlCurrency?: string,
) => {
  const response = await getPaymentMethod(urlLocation, urlCurrency)
  localStorage.setItem('currency', response?.data?.data?.currency)
}
