import { useQuery, useQueryClient } from '@tanstack/react-query'
import Button from 'components/Button/Button'
import { QuerySpinFetchEffect } from 'components/misc'
import Popup from 'components/Modals/Popup/Poup'
import { useInfiniteResource } from 'components/Query/useResource'
import { PaginatedResponse } from 'core/types/Response'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { CommonParams } from 'services/utils'
import { Spinner } from 'utils/Spinner'
import { ResourceItem } from 'utils/usePaginatedResource'
import { ArrowKeysNavList } from './OptionsList'
import { SelectError, useDefaults } from './Select'
import { SelectButton } from './SelectButton'
import SelectContainer from './SelectContainer'
import { SelectSearch } from './SelectSearch'

export type PaginatedSelectProps<
  T extends ResourceItem,
  P extends CommonParams,
> = {
  onChange?: (v?: T) => void
  renderSelected: (v?: T) => ReactNode
  renderOption: (v: T) => ReactNode
  valueQueryFn?: (id: number) => Promise<T>
  value?: T['id']
  label?: ReactNode
  leftIcon?: ReactNode
  watchList?: any[]
  enabled?: boolean
  showHeader?: boolean
  querykey: string[]
  searchable?: boolean
  optionsQueryFn: (params: P) => Promise<PaginatedResponse<T>>
  labelPlacement?: 'compact' | 'inline' | 'block'
  labelClassName?: string
  error?: string
  initialValue?: T
  params?: P
}

export default function PaginatedSelect<
  T extends ResourceItem,
  P extends CommonParams,
>(props: PaginatedSelectProps<T, P>) {
  const { label, labelPlacement } = useDefaults(props)
  const [isOpen, setIsOpen] = useState(false)
  const refsArray = useRef<Array<HTMLButtonElement | null>>([])

  const optionsQuery = useInfiniteResource({
    queryFn: props.optionsQueryFn,
    querykey: props.querykey,
    initialParams: { ...props.params, page: 1 } as P,
  })

  const valueQuery = useQuery({
    queryFn: () =>
      props.value ? props.valueQueryFn?.(props.value) : undefined,
    enabled: !!props.value && !!props.valueQueryFn,
    queryKey: [...props.querykey, props.value?.toString()],
    initialData: props.initialValue,
    staleTime: Infinity,
  })

  const queryClient = useQueryClient()
  useEffect(() => {
    if (optionsQuery.data && !!props.valueQueryFn) {
      // Iterate through all pages of data
      optionsQuery.data.pages?.forEach((page) => {
        // Assuming each page has a 'data' array containing the items
        page.data.forEach((item) => {
          // Cache each item individually
          queryClient.setQueryData(
            props.querykey.concat(item.id.toString()),
            item,
          )
        })
      })
    }
  }, [optionsQuery.data?.pageParams, queryClient, props.valueQueryFn])

  const { ref, inView } = useInView({ threshold: 0 })

  useEffect(() => {
    if (
      inView &&
      optionsQuery.hasNextPage &&
      !optionsQuery.isFetchingNextPage
    ) {
      optionsQuery.fetchNextPage()
    }
  }, [
    inView,
    optionsQuery.hasNextPage,
    optionsQuery.isFetchingNextPage,
    optionsQuery.fetchNextPage,
  ])

  function handleEnterKeydown(index: number) {
    let newSelected = optionsQuery.list?.find((o, i) => index == i)!
    setIsOpen(false)

    props.onChange && props.onChange(newSelected)
  }

  function togglePopup() {
    setIsOpen((isOpen) => !isOpen)
  }
  return (
    <Popup
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      renderTrigger={({ setAnchor }) => (
        <SelectContainer
          {...props}
          label={label}
          labelPlacement={labelPlacement}
        >
          <SelectButton
            {...props}
            ref={setAnchor}
            label={label}
            labelPlacement={labelPlacement}
            onClicK={togglePopup}
            onClear={() => {
              setIsOpen(false)
              optionsQuery.filters.update({ keyword: '', page: 1 } as P)
              props.onChange && props.onChange(undefined)
            }}
          >
            {props.renderSelected(valueQuery.data)}
          </SelectButton>
          <SelectError error={props.error} />
        </SelectContainer>
      )}
    >
      {() => (
        <>
          {props.showHeader && (
            <div className="d-flex justify-content-between font-color-text-normal p-sm">
              {label && (
                <span className="text-sm">
                  {optionsQuery.data?.pages[0].pagination.count} {label}s
                </span>
              )}
              <Button
                isBorderButton
                className="p-3xs border-0 text-sm  font-color-text-normal"
                onClick={() => optionsQuery.refetch()}
              >
                <i className="fa fa-rotate" />
              </Button>
            </div>
          )}
          {props.searchable && (
            <SelectSearch
              onChange={(keyword) => {
                optionsQuery.filters.update({ keyword, page: 1 } as P)
              }}
              value={optionsQuery.filters.params.keyword || ''}
            />
          )}
          <div className="flex-scroll" style={{ width: '300px' }}>
            <QuerySpinFetchEffect isFetching={optionsQuery.isRefetching}>
              <ArrowKeysNavList
                value={0}
                onEnter={handleEnterKeydown}
                searchable
                refsArray={refsArray}
                list={
                  optionsQuery.list?.map((i, index) => ({ ...i, index })) || []
                }
              >
                {(item, index, active) => (
                  <button
                    ref={(e) => {
                      if (refsArray.current) {
                        refsArray.current[index] = e
                      }
                    }}
                    className={`select-option-button text-start cursor-pointer font-color-blue600 ${
                      item.id === valueQuery.data?.id ? `selected` : ''
                    } ${active ? 'active-index' : ''}
    
              } `}
                    onClick={() => handleEnterKeydown(index)}
                  >
                    {props.renderOption(item)}
                  </button>
                )}
              </ArrowKeysNavList>
              {optionsQuery.hasNextPage && (
                <div
                  ref={ref}
                  style={{ minHeight: '1px' }}
                  className="d-flex justify-content-center align-items-center gap-2 p-3 bg-color-primary300 font_12 font_italic"
                >
                  {optionsQuery.isFetchingNextPage && (
                    <Spinner className="font_12" inline />
                  )}
                </div>
              )}
            </QuerySpinFetchEffect>
          </div>
        </>
      )}
    </Popup>
  )
}
