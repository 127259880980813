import { useQuery, useQueryClient } from '@tanstack/react-query'
import { oneDay } from 'components/Cards/ClassesCards/NextClass/useTime'
import { useEffect } from 'react'
import { QueryKeys } from 'services/QueryKeys'
import { getUpcomingOrLastSession } from 'services/student/sessions'
import { numberOrUndefined } from 'utils/helpers'
import { useUrlParams } from 'utils/useUrlParams'

export function useGetUpcomingOrLastSession(
  type: 'any' | 'online' | 'offline',
  enabled = true,
) {
  return useQuery({
    enabled,
    queryFn: () => getUpcomingOrLastSession('any').catch(() => null),
    staleTime: oneDay,
    queryKey: [QueryKeys.upcomingOrLastSession, type],
  })
}

export function useSessionId(type: 'offline' | 'online') {
  const { params, setParams } = useUrlParams<{ id: string }>()
  const sessionId = numberOrUndefined(params.id)

  function setSessionId(id: number) {
    setParams({ id: id.toString() })
  }

  const qc = useQueryClient()
  const query = useQuery({
    enabled: !sessionId,
    retry: false,
    queryFn: () => getUpcomingOrLastSession(type),
    staleTime: oneDay,
    queryKey: [QueryKeys.upcomingOrLastSession, type],
  })

  useEffect(() => {
    if (query.data) {
      setSessionId(query.data.id)
      qc.setQueryData(
        [QueryKeys.sessions, type, query.data.id],
        () => query.data,
      )
    }
  }, [query.data])

  return { sessionId, setSessionId, isError: query.isError }
}
