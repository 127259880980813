import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import { RouteObject } from 'react-router-dom'

export const TutorClassesRoute: RouteObject = {
  path: 'my-classes',
  lazy: async () => {
    const { TeacherClasses: Component } = await import(
      'pages/teacher/Classes/TeacherClasses'
    )
    return { Component }
  },

  ErrorBoundary,
}
