import { SessionAreasFillters } from 'pages/admin/SessionAreas'
import { SelectProps } from './types'
import PaginatedSelect from 'components/Select/PaginatedSelect'
import { QueryKeys } from 'services/QueryKeys'
import { PaginatedResponse, Response } from 'core/types/Response'
import { AdminAPI } from 'services/API'
import { CommonParams, pgQueryString } from 'services/utils'
import { ResourceItem } from 'utils/usePaginatedResource'
import { Group } from 'services/admin/groups'
export interface GroupsRespone extends ResourceItem {
  id: number
  slug: string
}

export type GroupsFillters = Partial<{
  isOffline?: boolean
}> &
  CommonParams

export function getGroups(
  params: GroupsFillters,
): Promise<PaginatedResponse<GroupsRespone>> {
  const modifiedParams = {
    ...params,
    is_offline: params.isOffline,
  }
  delete modifiedParams.isOffline

  const queryString = pgQueryString(modifiedParams)

  return AdminAPI.get<PaginatedResponse<Group>>(`groups${queryString}`).then(
    (r) => r.data,
  )
}

export function getGrouopsById(id: number): Promise<GroupsRespone> {
  return AdminAPI.get<Response<Group>>(`groups/${id}`).then((r) => r.data.data)
}

export default function GroupsSelect({
  isOffline,
  ...props
}: SelectProps<number> & { isOffline: boolean }) {
  return (
    <PaginatedSelect
      {...props}
      searchable
      querykey={[QueryKeys.groupsList]}
      onChange={(v) => {
        props.onChange && props.onChange(v?.id)
      }}
      optionsQueryFn={(params) => getGroups({ ...params, isOffline })}
      valueQueryFn={(id) => getGrouopsById(id)}
      renderOption={(option?: GroupsRespone) => <>{option?.slug}</>}
      renderSelected={(option?: GroupsRespone) => <>{option?.slug}</>}
    />
  )
}
