import { useLoggedInStudent } from 'auth/useAuth'
import Button from 'components/Button/Button'
import Icon from 'components/Icon/Icon'
import { ModalWindow } from 'components/Modal/ModalWindow'
import { useCreateModal } from 'components/Modal/hooks/useCreateModal'
import Rating from 'components/Rating/Rating'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { submitTutorFeedback } from 'services/student/sessions'

const RATINGSYMBOL: any = {
  1: 'sad-emoji',
  2: 'meh-emoji',
  3: 'laughing-emoji',
  4: 'love-emoji',
  5: 'excited-emoji',
}
interface RatingModalProps {}
const StudentSessionRatingModal = ({}: RatingModalProps) => {
  const { loggedInStudent, loadProfile } = useLoggedInStudent()

  const { t } = useTranslation(['common'])

  const RateModal = useCreateModal({
    isOpened: !!loggedInStudent.pending_session_feedback,
  })

  const [rating, setRating] = useState(3)

  const session = loggedInStudent.pending_session_feedback
  return (
    <RateModal.Container>
      <ModalWindow
        title={
          <div className="text-start">
            <span className="d-block font_12">
              {session?.grade.name} - {session?.level.name}{' '}
            </span>
            {session?.lesson.name}
          </div>
        }
        titleClassName="font-semibold"
        maxWidth={472}
        borderRadius={20}
        containerClassName="text-center"
        hasCloseBtn
      >
        <span className="d-block font_16 mb-3">{t('class_satisfaction')}</span>
        <Icon iconName={RATINGSYMBOL[rating]} iconSize={70} />
        <Rating
          starSize={40}
          value={rating}
          onChange={(e) => setRating(e || 1)}
          starColor="yellow300"
          containerClassName="justify-content-center mt-3"
        />

        <Button
          disabled={rating === 0}
          label="Submit"
          onClick={() => {
            session &&
              submitTutorFeedback(session.id, rating).then(() => {
                toast.success('Session Feedback Submitted')
                loadProfile()
              })
          }}
          className="rounded-pill w-100 py-13 font_16 font-semibold mt-5"
        />
      </ModalWindow>
    </RateModal.Container>
  )
}

export default StudentSessionRatingModal
