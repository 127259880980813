import { useAuth } from 'auth/useAuth'
import { useEffect } from 'react'
import useHotjar from 'react-use-hotjar'

export default function HotjarProvider() {
  const { initHotjar, identifyHotjar } = useHotjar()
  const { user } = useAuth()

  useEffect(() => {
    initHotjar(3734361, 6, false)
  }, [initHotjar])

  useEffect(() => {
    if (user) {
      const userObject = {
        id: user.id,
        name: user.name,
        role: user.role,
        email: user.email,
      }
      if (user.isStudent) {
        identifyHotjar(user.s_id, {
          ...userObject,
          organization: user.organization.name,
        })
      }
      if (user.isTutor) {
        identifyHotjar(user.t_id, userObject)
      }
      if (user.isAdmin) {
        identifyHotjar('admin_' + user.id, {
          ...userObject,
          admin_role: user.role_id,
        })
      }
    }
  }, [user?.id, identifyHotjar])

  return null
}
